import * as React from 'react';
import { debounce } from 'perfect-debounce';
import { accentUtils } from '../../services/HelperService';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';
import { AccentTextUnbound } from '../unbound/AccentTextUnBound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { useValidation, setOptionValueChanged, isReadOnly, useChanged, toGuid, useSizeChanged, getOptionModel } from './ProductConfiguratorShared';

export const ConfigPicklist = props => {

    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);

    const updateSpecify = React.useRef(debounce(setOptionValueChanged, 800, { trailing: false }));

    const validation = useValidation(props.optionID, "ConfigPicklist");
    const changed = useChanged(props.optionID, "ConfigPicklist", true);
    const sizeChanged = useSizeChanged(props.optionID, "ConfigPicklist");

    const [customValue, setCustomValue] = React.useState(optionModel.CustomValue);

    const data = React.useMemo(() => optionModel.FilteredItems.toArray().map(x => ({ ID: x.ItemKey.toString().toUpperCase(), Value: x.ItemValue })), [optionModel, sizeChanged.changeID, changed.changeID]);

    const onTextChange = React.useCallback(e => {

        let newValue = e;
        if (optionModel.OrderLineOption.ProductOptionValueID != toGuid(accentUtils.getEmptyGuid())) {

            const pov = optionModel.GetProductOptionValue(optionModel.OrderLineOption.ProductOptionValueID);

            if (pov != null) {
                newValue = `${pov.Description}: ${e}`;
            }
        }

        if (optionModel.OrderLineOption != null) {
            optionModel.OrderLineOption.CustomValue = newValue;
        }

        updateSpecify.current(ctx, optionModel, changed.tag);


    }, [ctx, optionModel, updateSpecify, changed.tag]);




    const onChange = React.useCallback(e => {

        const id = toGuid(e.target.value);

        const value = optionModel.GetProductOptionValue(id);

        optionModel.SetValue(window.InsyteConfig.OptionModelSetValueArgs.From(id, value?.Description?? null));

        setOptionValueChanged(ctx, optionModel, changed.tag);


        if (accentUtils.isNull(value) || (value?.CustomValue??false)) {
            setCustomValue(null);
        }

    }, [ctx, optionModel, setCustomValue, changed.tag]);

    

    const readOnly = isReadOnly(optionModel);

    if (optionModel.GetProductOption().Description === "Head Height") {

        const rr = 2;
        const d = rr;

    }

    const pov = optionModel.GetProductOptionValue(optionModel.OrderLineOption.ProductOptionValueID);

    const isCusomValue = accentUtils.isNull(pov) ? false : pov.CustomValue;

    const currValue = optionModel.CurrentValueAsString?.toUpperCase();

    const isRequired = validation.required && accentUtils.isEmpty(currValue);


    return <div className="config-control config-picklist">
        <div className="config-picklist-picklist">
            <select
                key={`${changed.changeID}_${sizeChanged.changeID}`}
                className={`config-select ${isRequired ? "required" : ""}`}
                onChange={onChange}
                disabled={readOnly}
                defaultValue={currValue}
            >
                {data.map(x => <option key={`k_${x.ID}`} value={x.ID}>{x.Value}</option>)}
            </select>
        </div>
        {isCusomValue && <div className="config-picklist-custom">
            <AccentTextUnbound
                key={`${changed.changeID}_${sizeChanged.changeID}`}
                required={validation.required}
                defaultValue={customValue}
                onChange={onTextChange}
                disabled={readOnly}
            />
        </div>}
    </div>;
};