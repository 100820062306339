import * as React from 'react'
import { ButtonToolbar, Row, Col, Collapse, ButtonGroup } from 'reactstrap'
import { accentUtils, t, from, viewStateHelper, getColorAlpha } from '../services/HelperService'
import AccentIcons from '../icons/Icons'
import { isNumeric } from 'jquery';


const stateExpiryTimes = {};
export class AccentSectionHeader extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        const parts = window.location.pathname.split('/');
        const last = from(parts).lastOrDefault();
        const isNumber = !isNaN(Number(last));
        const location = isNumber ? parts.join('/') : window.location.pathname


        var stateID = location + '-' + props.title;

        const stateExpired = accentUtils.isNull(stateExpiryTimes[stateID]) || stateExpiryTimes[stateID] < new Date();

        if (stateExpired) {
            viewStateHelper.clearState(stateID);

            let newExpiryDate = new Date();
            newExpiryDate.setHours(newExpiryDate.getHours() + 2);

            stateExpiryTimes[stateID] = newExpiryDate;
        }

        var collapsed = viewStateHelper.getState(stateID, props.collapsed);

        this.state = {
            stateID: stateID,
            open: !collapsed,
            hasOpened: !collapsed
        }
        this.onClick = this.onClick.bind(this);
        this.doOnExpand = this.doOnExpand.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.updateViewState = this.updateViewState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        if (this.props.onIsExpandedOnLoad && this.state.hasOpened) {
            this.props.onIsExpandedOnLoad();
        }
    }

    updateViewState() {

        var collapsed = !this.state.open;

        viewStateHelper.setState(this.state.stateID, collapsed);
    }

    doOnExpand() {
        if (!accentUtils.isNull(this.props.onExpand)) {
            this.props.onExpand();
        }
    }

    onClick(open) {
        this.setState({ open: open, hasOpened: this.state.hasOpened || open });
    }


    onExpand() {
        this.doOnExpand();
    }

    render() {

        this.updateViewState();

        var toolbar = <div />
        var buttons = accentUtils.isNull(this.props.buttons) ? [] : this.props.buttons;
        if (!accentUtils.isNull(buttons) && buttons.length && buttons.length > 0 || !accentUtils.isEmpty(buttons)) {
            toolbar = (
                <ButtonGroup className="p-2">
                    {buttons}
                </ButtonGroup>
            );
        }


        var stateIcon = (this.state.open) ? <AccentIcons.Up onClick={e => this.onClick(false)} top="0" /> : <AccentIcons.Down onClick={e => this.onClick(true)} top="0" />;

        var className = "accentSectionHeader ";

        if (!accentUtils.isEmpty(this.props.className)) {
            className = className + this.props.className;
        }

        const color = accentUtils.isNull(this.props.color) ? "#428bca" : this.props.color;
        const bgColor = getColorAlpha(color, .1);
        const style = {
            color: color,
            backgroundColor: bgColor,
            borderBottomWidth: "2px",
            borderBottomBolor: color,
            borderBottomStyle: "solid",            
        };

        return (
            <div className={className} data-tagname={this.props.title}>
                <div style={style}>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-start" >
                            {this.props.pre}
                            <div className="p-2" style={{cursor: "pointer"} } onClick={ e=> this.onClick(!this.state.open)}>{t(this.props.title)}</div>
                            <div>{stateIcon}</div>
                        </div>
                        <div className="justify-content-end" >
                            {toolbar}
                        </div>
                    </div>
                </div>
                <Collapse isOpen={this.state.open} onEntered={this.onExpand} style={{paddingTop: "5px", paddingBottom:"5px"}}>
                    <Row>
                        {(!this.props.lazyLoad || this.state.hasOpened) ? this.props.children : null}
                    </Row>
                </Collapse>
            </div>
        );
    }
}
