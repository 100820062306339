import * as React from 'react'
import { TextArea } from '@progress/kendo-react-inputs'
import { accentUtils, t, formHelper } from '../../services/HelperService'

export class AccentTextArea extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        if (this.props.model.options.translate) {
            this.initialValue = t(this.props.model.getValue());
        } else {
            this.initialValue = this.props.model.getValue();
        }

        this.currentUserValue = this.initialValue;
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.getControlValue = this.getControlValue.bind(this);
        this.insertText = this.insertText.bind(this);

    }


    insertText(pos, value) {

        var currValue = this.props.model.getValue();

        var newValue = accentUtils.stringInsert(currValue, value, pos);


        this.props.model.setValue(newValue);
        this.props.model.modified();
        this.forceUpdate();
    }

    handleFocus(e) {

    }

    handleBlur(e) {

        
        var value = e.target.element.current.value;
        //this.props.model.setValue(value);
        if (!accentUtils.isNull(this.props.model.options.onFocusLost)) {
            this.props.model.options.onFocusLost({ selectionStart: e.target.element.current.selectionStart });
        }

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange({ previous: this.initialValue, value: value });
        }

    }


    handleChange(e) {

        var value = e.value;
        this.currentUserValue = value;
        this.props.model.setValue(value);
        this.props.model.modified();

        if (!accentUtils.isNull(this.props.model.options.onFocusLost)) {
            this.props.model.options.onFocusLost({ selectionStart: e.target.selectionStart });
        }

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange({ previous: this.initialValue, value: value });
        }

    }

    focus() {
        alert(" $(ReactDOM.findDOMNode(this.ctrl.current)).trigger('focus')");
    }


    getControlValue() {
        return this.ctrl.current.value;
    }



    render() {
        const disabled = formHelper.isControlReadOnly(this.props.model);

        const max = this.props.model.options.maxLength;

        return (

            <TextArea
                ref={this.ctrl}
                fillMode="outline"
                onBlur={this.handleBlur}
                maxLength={max}
                readOnly={disabled}
                autoSize={this.props.model.options.autoSize}
                rows={this.props.model.options.rows}
                label={this.props.model.displayLabel}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                defaultValue={this.initialValue ?? ""}
                placeholder={t(this.props.model.options.placeHolder)}
                onContextMenu={this.props.model.options.onContextMenu} />
        );
    }
}

