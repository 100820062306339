import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { accentUtils } from '../../services/HelperService';


export const SignaturePad = ({ onSignatureSave, readOnly, onCancel }) => {
    const sigCanvas = useRef({});

    const [showAccept, setShowAccept] = React.useState(false);

    const clear = () => {
        sigCanvas.current.clear();
        setShowAccept(false);
    };

    const save = () => {

        const isEmpty = sigCanvas.current.isEmpty();

        if (isEmpty) {
            onSignatureSave(null);        
            return;
        }

        const dataUrl = sigCanvas.current.toDataURL('image/png');

        onSignatureSave(dataUrl);        
    };


    const style = readOnly ? { pointerEvents: 'none' } : undefined;

    const btnStyle = { minWidth: "80px" };

    return (
        <div style={style}>
            <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: 'signature-pad' }}
                onBegin={e => setShowAccept(true) }
            />
            {!readOnly && <div className="d-flex justify-content-between">
                <div><button style={ btnStyle} onClick={onCancel}>Cancel</button></div>
                <div><button style={btnStyle} onClick={clear}>Clear</button></div>
                <div>{<button style={btnStyle} onClick={save} disabled={!showAccept}>Accept</button>}</div>
            </div>}
        </div>
    );
};


