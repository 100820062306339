import * as React from 'react'
import { Row, Col } from 'reactstrap'
import { AccentButton } from '../controls/AccentButton';
import { accentUtils, t } from '../services/HelperService';
import { AccentDialog } from './AccentDialog'
import { SignaturePad } from './../controls/documents/SignaturePad'
import { isMobileOnly, isTablet } from '../controls/AccentDisplay';
export const SignatureDlg = React.memo(props => {

    const [signatureRefreshKey, setSignatureRefreshKey] = React.useState(1);
    const [signature, setSignature] = React.useState(null);

    const onSignatureSave = React.useCallback(e => {
        setSignature(e);        
    }, [props, setSignature]);

    const recapture = React.useCallback(e => {
        setSignature(null);
        setSignatureRefreshKey(k => k + 1);
    }, [props, setSignature, setSignatureRefreshKey]);

    const close = React.useCallback(e => {

        props.onClose({ signature: signature });
    },[props, signature])


    const allowClose = !accentUtils.isNull(signature);
    const showCancel = !accentUtils.isNull(signature);
    return <AccentDialog
        fullscreen={isMobileOnly}
        maxWidth={isMobileOnly ? undefined : 600}
        noCancel={ !showCancel}
        headerContent={t("application_strings.application.dialogs.signature.title")}
        onClose={props.onClose}
        actionsContent={!showCancel ? undefined :   [
            <AccentButton key="3" tagName="closeBtn" disabled={!allowClose} onClick={recapture}>{t("application_strings.application.buttons.recapture")}</AccentButton>,
            <AccentButton key="2" tagName="closeBtn" disabled={!allowClose} onClick={close}>{t("application_strings.application.buttons.ok")}</AccentButton>
        ]}
    >
        <SignaturePad key={signatureRefreshKey} onSignatureSave={onSignatureSave} readOnly={allowClose} onCancel={e => props.onClose({canceled : true}) } />
    </AccentDialog >;

});