import * as React from 'react';
import { debounce } from 'perfect-debounce';
import classNames from 'classnames'
import { accentUtils, from, showDialog, t } from '../../services/HelperService';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { useValidation, setOptionValueChanged, isReadOnly, useChanged, toGuid, fabric_GetRailroadingOption, fabric_GetIgnoreFabricWidth, fabric_GetIgnoreRailroadedFabricWidth, fabric_GetAllowContinuous, fabric_GetDropAdjustment, fabric_GetWidthAdjustment, fabric_GetAllowRailroading, fabric_GetCustomFabric, fabric_OrderLineOptionSet, fabric_OrderLineOptionClear, getOptionModel, compareValues } from './ProductConfiguratorShared';
import AccentIcons from '../../icons/Icons';
import { AccentButton } from '../AccentButton';
import { RTLGridCtrl } from '../grids/RTLGridCtrl';
import { AccentDialog } from '../../dialogs/AccentDialog';
import { FabricDetailsDlg, SelectFabricDlg } from './SelectFabricDlg';




export const ConfigSingleLineFabric = props => {

    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);
    const [currentValue, setCurrentValue] = React.useState(null);

    const changed = useChanged(props.optionID, "ConfigSingleLineFabric", true);
    const validation = useValidation(props.optionID, "ConfigSingleLineFabric");

    const readOnly = isReadOnly(optionModel);


    

    const onClear = React.useCallback(e => {

        fabric_OrderLineOptionClear(optionModel);
        optionModel.UpdateFrom(toGuid(accentUtils.getEmptyGuid()), null);
        setOptionValueChanged(ctx, optionModel, changed.tag);

        setCurrentValue(null);

    }, [ctx, optionModel, setCurrentValue, changed.tag]);


    const onShow = React.useCallback(e => {

        showDialog(<SelectFabricDlg ctx={ctx} optionModel={optionModel} />).then(res => {

            if (!res.canceled && res.selected) {

                const parts = res.selected.ID.split('_');

                const id = toGuid(parts[0]);
                const supplierID = toGuid(parts[1]);

                const value = optionModel.GetProductOptionValue(id);

                if (!accentUtils.isNull(value) && value.CustomValue) {
                    fabric_OrderLineOptionSet(optionModel, id, supplierID);
                    showDialog(<FabricDetailsDlg readOnly={readOnly} ctx={ctx} optionModel={optionModel} productOptionValue={value} />);

                } else {


                    fabric_OrderLineOptionSet(optionModel, id, supplierID);
                    const fab = fabric_GetCustomFabric(optionModel, value);
                    optionModel.UpdateFrom(id, fab);

                    setOptionValueChanged(ctx, optionModel, changed.tag);

                }
                

            }

        });
    }, [ctx, optionModel, readOnly, changed.tag]);
   


    optionModel.HasValue = React.useCallback(() => {
        var v = optionModel.GetProductOptionValue(optionModel.OrderLineOption.ProductOptionValueID);
        return v != null && !v.CustomValue || v != null && window.SoftFurnishings.FabricHelper.GetCustomFabric(optionModel, v).IsValid(true, true);
    }, [optionModel]);



    optionModel.Validate = React.useCallback( () => {

        let errMsg = "";

        if (compareValues(optionModel.OrderLineOption.ProductOptionValueID, toGuid(accentUtils.getEmptyGuid()))) {
            errMsg = "Fabric Details is required";
        }

        if (errMsg == "") {

            var val = optionModel.GetProductOptionValue(optionModel.OrderLineOption.ProductOptionValueID);
            if (val != null && val.CustomValue) {
                var cf = window.SoftFurnishings.FabricHelper.GetCustomFabric(optionModel, val);
                if (!cf.IsValid(true, true)) {
                    errMsg = "Custom Fabric Details is required";
                }
            }
        }

        return errMsg;
    }, [optionModel]);


    


    const value = optionModel.GetProductOptionValue(optionModel.OrderLineOption.ProductOptionValueID);

    const hasValue = optionModel.HasValue() || (value?.CustomValue??false);


    const customfabric = window.SoftFurnishings.FabricHelper.GetCustomFabric(optionModel, value);


    const className = classNames("config-single-line-fabric-fabric",
        {
            "required": validation.required && !hasValue
        }
    );

    const btnClasses = classNames({
        "acc-click": !readOnly
    })

    return <div className={`config-control config-single-line-fabric ${readOnly ? "readonly" : ""}`}>
        <div className={className} >
            <div className={`config-single-line-fabric-display`} onClick={readOnly ? undefined : onShow }>
                <div className="config-single-line-fabric-supplier">{customfabric?.SupplierName}</div>
                <div className="config-single-line-fabric-name">{customfabric?.Fabric}</div>
                <div className="config-single-line-fabric-color">{customfabric?.Colour}</div>
            </div>
            <div className="config-single-line-fabric-btn">                
                <div className={btnClasses} onClick={onClear}>{!readOnly && hasValue && <span className="k-icon k-font-icon k-i-x"></span>}</div>
                <div className={btnClasses} onClick={onShow}>{!readOnly && <span className="k-button-icon k-icon k-font-icon k-i-arrow-s"></span>}</div>
            </div>
        </div>
    </div>;
};



