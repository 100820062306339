import React from 'react';
import { debounce } from 'perfect-debounce'
import memoize from 'memoizee'
import Enumerable from 'linq'
import { EntityQuery, EntityManager, EntityState, EntityAction, MappingContext, DataService, config, MergeStrategy } from "breeze-client";
import { AjaxFetchAdapter } from "breeze-client/adapter-ajax-fetch";
import { DataServiceWebApiAdapter } from "breeze-client/adapter-data-service-webapi";
import { ModelLibraryBackingStoreAdapter } from "breeze-client/adapter-model-library-backing-store";
import { UriBuilderJsonAdapter } from "breeze-client/adapter-uri-builder-json";

import { AjaxPostWrapper } from "breeze-client/adapter-ajax-post";

import authService from './../api-authorization/AuthorizeService';
import { accentUtils, enqueue, processVersion, showDialog, showOK, showWait, t } from './HelperService';
import { DisplayURLDlg } from '../dialogs/DisplayURLDlg';


const DEFAULT_DEBOUNCE = 500;


const cachedQueries = [];


export const clearCache = () => {

    cachedQueries.map(q => q.clear());

};

window.cachedQueries = cachedQueries;

export class EntityManagerProvider {

    masterManager = null;

    activeManager = null;

    dataService = null;

    async Initialize() {



        const isAuthenticated = await authService.isAuthenticated();

        if (!isAuthenticated) return;

        this.activeManager = null;
        this.masterManager = null;

        
        // configure breeze adapters
        ModelLibraryBackingStoreAdapter.register();
        const jsonAdapter = UriBuilderJsonAdapter.register();

        // example of adding headers to requests via requestInterceptor
        // AjaxFetchAdapter.register().requestInterceptor = (req => {
        //     console.log(req);
        //     req.config.headers.Authorization = "Bearer oiwjeglkwjelkj";
        //   }) as any;

        // example of adding headers to requests via defaultSettings

        var adapter = AjaxFetchAdapter.register();


        AjaxPostWrapper.wrapAjax(adapter);

        this.dataService = DataServiceWebApiAdapter.register();
        
        

        // register entity metadata
        this.masterManager = new EntityManager("api/Data");
        
        this.newManager();

        console.log("Data Manager Initialized");

    }

    async getToken() {
        return await authService.getAccessToken();
    }

    async getAuthHeader() {

        const token = await this.getToken();

        return {
            header: 'Authorization',
            value : `Bearer ${token}`
        };
    }

    /** Return empty manager configured with dataservice and metadata */
    newManager() {

        this.activeManager = this.masterManager.createEmptyCopy();

        window.am = this.activeManager;

        console.log(this.activeManager);

    }

    getActiveManager() {
        return this.activeManager;
    }

    getDataServiceManager() {
        return this.dataService;
    }

    /** Call forceUpdate() on the component when an entity property or state changes */
    subscribeComponent(component) {
        let subid = this.activeManager.entityChanged.subscribe((data) => {
            if (data.entityAction === EntityAction.PropertyChange || data.entityAction === EntityAction.EntityStateChange) {
                component.forceUpdate();
            }
        });
        component.subid = subid;
    }

    /** Remove subscription created with subscribeComponent() */
    unsubscribeComponent(component) {
        if (component.subid) {
            this.activeManager.entityChanged.unsubscribe(component.subid);
        }
    }
}



export const entityManagerProvider = new EntityManagerProvider();

export class QuariableArray {

    constructor(arrayResult, search, array, isCached) {
        this.arrayResult = arrayResult;
        this.search = search;
        this.isCached = isCached;
        this.array = array;
    }

    async executeWithAlter(alter, cacheKey) {

        const query = alter(this.array);

        
        if (this.isCached)
            return await this.arrayResult.internalCachedGetAll(this.search, query, cacheKey);

        return await this.arrayResult.internalGetAllDebounce(this.search, query, cacheKey);
    }
}

class QuariableQuery {

    constructor(queryResult, params, isFirstOrDefault, isCached) {
        this.queryResult = queryResult;
        this.params = params;
        this.isFirstOrDefault = isFirstOrDefault;
        this.isCached = isCached;
    }


    async executeWithAlter(alter) {

        const query = alter(this.queryResult.query);

        if (this.isFirstOrDefault)
            return await this.queryResult.internalFirstOrDefaultDebounce(query, this.params);

        if (this.isCached)
            return await this.queryResult.internalCachedGetAll(this.params, query);

        return await this.queryResult.internalGetAllDebounce(query, this.params);
    }
}

class ArrayResult {

    debounce = DEFAULT_DEBOUNCE;

    constructor(debounceMS) {

        this.debounce = accentUtils.isNull(debounceMS) ? 0 : debounceMS;

        this.internalGetAllDebounce = this.debounce === 0
            ? async (search, array) => this.executeQuery(search, array)
            : debounce(async (search, array) => this.executeQuery(search, array), this.debounce, { trailing: false });


        this.getAllAsQueryableWithCache = this.getAllAsQueryableWithCache.bind(this);
        this.executeQuery = this.executeQuery.bind(this);

        this.internalCachedGetAll = memoize((s, a, k) => {
            return new Promise(r => {
                this.internalGetAllDebounce(s, a, k).then(result => r(result));
            })
        },
            {
                promise: true,
                primitive: true,
                normalizer: function (args) {
                    return args[0] + args[2];
                }
            });

        cachedQueries.push(this.internalCachedGetAll);

    }

    clearCache() {
        if (this.internalCachedGetAll) {
            this.internalCachedGetAll.clear();
        }
    }


    getAllAsQueryableWithCache(search, array) {
        return new QuariableArray(this, search, array, true);
    }
    getAllAsQueryable(search, array) {
        return new QuariableArray(this, search, array, false);
    }


    async executeQuery(search, array) {

        if (accentUtils.isEmpty(search)) {
            return array;
        }

        const upperSearch = search.toUpperCase();

        const newData = array.filter(x => {            
            return Enumerable.from(Object.keys(x)).any(f => x[f]?.toString()?.toUpperCase()?.includes(upperSearch));
        });


        return newData;
    }

}


async function addAjaxInterceptor(headers) {

    console.log(`addAjaxInterceptor before getAuthHeader`);
    const authHeader = await entityManagerProvider.getAuthHeader();

    console.log(`addAjaxInterceptor after getAuthHeader`);

    var ajaxAdapter = config.getAdapterInstance("ajax");
    ajaxAdapter.requestInterceptor = function (requestInfo) {


        requestInfo.config.headers.Authorization = authHeader.value;

        if (headers) {

            Object.keys(headers).forEach(function (item) {

                requestInfo.config.headers[item] = headers[item];

            });
        }



    };

}


class QueryResult {

    action = null;
    query = null;
    debounce = DEFAULT_DEBOUNCE;

    constructor(action, forEdit, debounceMS, isPost, startThenDebounce, cacheOptions) {

        this.cacheOptions = cacheOptions ?? { allowCaching: true };
        this.isPost = isPost;
        this.debounce = accentUtils.isNull(debounceMS) ? 0 : debounceMS;
        this.startThenDebounce = startThenDebounce;
        this.forEdit = forEdit;
        this.action = action;
        this.query = new EntityQuery()
            .from(action);

        this.internalFirstOrDefaultDebounce = this.debounce === 0
            ? async (query, params) => this.executeQuery(true, query, params)
            : debounce(async (query, params) => this.executeQuery(true, query, params), this.debounce, { trailing: false, leading: startThenDebounce });
        this.internalGetAllDebounce = this.debounce === 0
            ? async (query, params) => this.executeQuery(false, query, params)
            : debounce(async (query, params) => {
                return await this.executeQuery(false, query, params);
            }, this.debounce, { trailing: false, leading: startThenDebounce });



        this.getFirstOrDefault = this.getFirstOrDefault.bind(this);
        this.getAll = this.getAll.bind(this);
        this.getAllWithCache = this.getAllWithCache.bind(this);
        this.getAllAsQueryableWithCache = this.getAllAsQueryableWithCache.bind(this);
        this.executeQuery = this.executeQuery.bind(this);


        if (!this.cacheOptions.allowCaching) return;

        this.internalCachedGetFirstOrDefault = memoize((e, q) => {
            return new Promise(r => {
                this.internalFirstOrDefaultDebounce(q, e).then(result => r(result));
            })
        },
            {
                promise: true,
                primitive: true,
                normalizer: function (args) {

                    const query = args[1];

                    const ds = DataService.resolve([entityManagerProvider.activeManager.dataService]);

                    const mappingContext = new MappingContext({
                        query: query,
                        entityManager: entityManagerProvider.getActiveManager(),
                        dataService: ds,
                    });

                    var url = mappingContext.getUrl();

                    return JSON.stringify(args[0]) + url;
                }
            });

        this.internalCachedGetAll = memoize((e, q) => {
            return new Promise(r => {
                this.internalGetAllDebounce(q, e).then(result => r(result));
            })
        },
            {
                promise: true,
                primitive: true,
                normalizer: function (args) {

                    const query = args[1];

                    const ds = DataService.resolve([entityManagerProvider.activeManager.dataService]);

                    const mappingContext = new MappingContext({
                        query: query,
                        entityManager: entityManagerProvider.getActiveManager(),
                        dataService: ds,
                    });

                    var url = mappingContext.getUrl();

                    return (accentUtils.isNull(query.customHeaders) ? "" : JSON.stringify(query.customHeaders)) + JSON.stringify(args[0]) + url;
                }
            });


        if (this.cacheOptions.permanent) {
            return;
        }

        cachedQueries.push(this.internalCachedGetAll);
        cachedQueries.push(this.internalCachedGetFirstOrDefault);

    }

    clearCache() {
        if (this.internalCachedGetAll) {
            this.internalCachedGetAll.clear();
        }
        if (this.internalCachedGetFirstOrDefault) {
            this.internalCachedGetFirstOrDefault.clear();
        }
    }

    async getFirstOrDefault(params) {
        return await this.internalFirstOrDefaultDebounce(this.query, params);
    }

    async getAll(params) {
        return await this.internalGetAllDebounce(this.query, params);
    }


    async getFirstOrDefaultWithCache(params) {
        return await this.internalCachedGetFirstOrDefault(params, this.query);
    }


    async getAllWithCache(params) {
        return await this.internalCachedGetAll(params, this.query);
    }


    getAllAsQueryable(params) {
        return new QuariableQuery(this, params, false);
    }

    getAllAsQueryableWithCache(params) {
        return new QuariableQuery(this, params, false, true);
    }



    async executeQuery(top1, query, params, retryCount = 0) {


        var post = {
            $method: 'POST',
            $encoding: 'JSON',
            $data: params
        };



        var q = top1
            ? query.top(1).noTracking(!this.forEdit)
            : query.noTracking(!this.forEdit);

        q = params ? q.withParameters(this.isPost ? post : params) : q;

        if (accentUtils.isNull(entityManagerProvider)) {
            return top1 ? null : [];
        }

        const manager = entityManagerProvider.getActiveManager();

        if (accentUtils.isNull(manager)) {
            return top1 ? null : [];
        }


        const headers = query.customHeaders;


        await addAjaxInterceptor(headers);


        try {

            const res = await manager.executeQuery(q);

            processVersion(res.httpResponse.getHeaders('RTLVersion'));

             
            if (top1) {
                return res.results.length === 0 ? null : res.results[0];
            }


            if (!accentUtils.isNull(res.inlineCount)) {
                return {
                    data: res.results,
                    total: res.inlineCount
                };
            }

            return res.results;
        } catch (ex) {

            if (retryCount < 3 && ex.status === 401) {
                await entityManagerProvider.getToken();

                return await this.executeQuery(top1, query, params, retryCount + 1);

            }

            throw ex;
            

        }
    }



}



export function loadEntityNavigationPropertyValue(entity, member) {

    
    const singleResult = entity.entityType.getNavigationProperty(member).isScalar;

    

    

    return new Promise(function (resolve, reject) {


        const manager = entityManagerProvider.getActiveManager();


        const query = EntityQuery
            .fromEntityNavigation(entity, member);

        manager.executeQuery(query)
            .then(result => {

                if (singleResult) {
                    if (result.results.length == 0) {
                        resolve(null);
                    } else {
                        entity.entityAspect.markNavigationPropertyAsLoaded(member);
                        //entity[member] = result.results[0];
                        resolve(result.results[0]);
                    }
                }
                else {
                    entity.entityAspect.markNavigationPropertyAsLoaded(member);
                    //entity[member] = result.results;
                    resolve(result.results);
                }

            }).catch(r => {
            reject(r);
        });



        ////entity.entityAspect.loadNavigationProperty(member)
        ////    .then(function (result) {

                
        ////        if (singleResult) {
        ////            if (result.results.length == 0) {
        ////                resolve(null);
        ////            } else {
        ////                resolve(result.results[0]);
        ////            }
        ////        }
        ////        else {
        ////            resolve(result.results);
        ////        }

        ////    }).catch(function (r) {
        ////        reject(r);
        ////    });
    });


    
}


export function setEntityMemberValue(entity, path, value) {


    var fieldPath = path.split(".");

    var member = fieldPath[fieldPath.length - 1];

    var e = fieldPath.length == 1 ? entity : entity[fieldPath[0]];

    if (!accentUtils.isNull(e)) {
        e[member] = value;        
    }

}


export function getEntityMemberValue(entity, path) {


    var fieldPath = path.split(".");

    var member = fieldPath[fieldPath.length - 1];

    var e = fieldPath.length == 1 ? entity : entity[fieldPath[0]];

    if (accentUtils.isNull(e))
        return null;

    return e[member];

}

export function isDirtyEntity(entity) {
    return entity.entityAspect.entityState === EntityState.Modified;
}


export function isNewEntity(entity) {
    return entity.ID <= 0;
}


export function deleteEntity(entity) {
    try {
        entity.entityAspect.setDeleted();
    } catch {

    }
}

export function dirtyEntity(entity) {
    try {
        entity.entityAspect.setModified();
    } catch {

    }
}


export function detachEntity(entity) {
    entity.entityAspect.setDetached ();
}

export function addEntity(typeName, entity, parent = null, custom = null, onCreate = null) {

    return new Promise((resolve, reject) => {


        const doOnCreate = accentUtils.isNull(onCreate) ? () => Promise.resolve() : onCreate;


        try {

            const type = entityManagerProvider.activeManager.metadataStore.getEntityType(typeName);


            const newEntity = type.createEntity(entity);

            const attachedEntity = entityManagerProvider.activeManager.addEntity(newEntity);

            if (window.DefaultValueSetter[typeName]) {
                window.DefaultValueSetter[typeName](attachedEntity, parent, custom).then(defaultedEntity => {

                    doOnCreate(defaultedEntity).then(() => {
                        resolve(defaultedEntity);
                    });

                    
                });
            } else {

                doOnCreate(attachedEntity).then(() => {
                    resolve(attachedEntity);
                });
            }

        } catch (err) {
            
            if (err.message?.includes("Unable to locate a 'Type' by the name")) {
                resolve(entity); // assume custom data
                return;
            }

            console.log(err);

            reject(err);

        }
    });

}


export function attachEntity(entity, typeName, asModified = false) {
    const type = entityManagerProvider.activeManager.metadataStore.getEntityType(typeName);

    const newEntity = type.createEntity(entity);

    return entityManagerProvider.activeManager.attachEntity(newEntity, asModified ? EntityState.Modified : EntityState.Unchanged, MergeStrategy.PreserveChanges);    
}

export function attachEntityAsNew(entity) {

    return entityManagerProvider.activeManager.attachEntity(entity, EntityState.Added, MergeStrategy.PreserveChanges);
}




export function clearEntities() {
    entityManagerProvider.getActiveManager().clear();
}


export function saveEntities() {

    return new Promise((resolve, reject) => {

        addAjaxInterceptor().then(() => {


            console.log("saveEntities::before saveChanges");

            entityManagerProvider.getActiveManager().saveChanges().then(function (r) {

                console.log("saveEntities::after saveChanges");

                resolve(r);

            }).catch(function (err) {

                console.log("saveEntities::after saveChanges error");

                console.log(err);
                if (err.entityErrors) {
                    if (err.entityErrors.length > 0) {
                        reject(err);
                        return;
                    }
                }
                reject(err);
            });


        });

        
    });

    
}




export function useEntityChangedMemo(factory, entity, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10) {


    const [token, setToken] = React.useState(null);
    const info = React.useMemo(() => {
        return { factory, entity, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10 };
    }, [factory, entity, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10]);

    const [value, setValue] = React.useState({ value: factory(), valueKey:1});
    

    

    const onChange = React.useCallback(e => {


        if (e.entityAction !== EntityAction.PropertyChange) return;

        if (e.entity !== info.entity) return;

        const isField = e.args.propertyName === info.field1 ||
            e.args.propertyName === info.field2 ||
            e.args.propertyName === info.field3 ||
            e.args.propertyName === info.field4 ||
            e.args.propertyName === info.field5 ||
            e.args.propertyName === info.field6 ||
            e.args.propertyName === info.field7 ||
            e.args.propertyName === info.field8 ||
            e.args.propertyName === info.field9 ||
            e.args.propertyName === info.field10;

        if (!isField) return;


        setValue(v => { return { value: info.factory(), valueKey: v.valueKey + 1 }; });

    }, [info]);

    React.useEffect(() => {

        const token = entityManagerProvider.getActiveManager().entityChanged.subscribe(e=> onChange(e));

        
        return () => {
            entityManagerProvider.getActiveManager().entityChanged.unsubscribe(token);
        };
    }, [info, setValue]);



    return value;
}


export function queryTracking(action, debounceMillis = DEFAULT_DEBOUNCE, isPost = false, startThenDebounce = false) {
    return new QueryResult(action, true, debounceMillis, isPost, startThenDebounce)
}

export function queryNoTracking(action, debounceMillis = DEFAULT_DEBOUNCE, isPost = false, startThenDebounce = false, cacheOptions = null) {
    return new QueryResult(action, false, debounceMillis, isPost, startThenDebounce, cacheOptions);
}

export function queryArray(debounceMillis = DEFAULT_DEBOUNCE) {
    return new ArrayResult(debounceMillis);
}


export function useQueryFirstOrDefaultNoTracking(action, params, debounceMillis = DEFAULT_DEBOUNCE, isPost = false, startThenDebounce = false) {
    return useQueryFirstOrDefault(action, params, false, debounceMillis, isPost, startThenDebounce);
}

export function useQueryFirstOrDefaultTracking(action, params, debounceMillis = DEFAULT_DEBOUNCE, isPost = false, startThenDebounce = false) {
    return useQueryFirstOrDefault(action, params, true, debounceMillis, isPost, startThenDebounce);
}

function useQueryFirstOrDefault(action, params, forEdit, debounceMillis = DEFAULT_DEBOUNCE, isPost, startThenDebounce = false) {

    const [query, setQuery] = React.useState(new QueryResult(action, forEdit, debounceMillis, isPost, startThenDebounce, { allowCaching : false}));

    const [state, setState] = React.useState({        
        data: null,
        loading: true
    });


    React.useEffect(() => {


        query.getFirstOrDefault(params).then(result => {
            setState({ loading: false, data: result});
        });

        setState({ loading: true, data: null });

    }, [query, params]);

    return state;
}

export function useQueryAllTracking(action, params, debounceMillis = DEFAULT_DEBOUNCE, isPost = false, startThenDebounce = false, mapData = null, onLoaded = null) {
    return useQueryAll(action, params, true, debounceMillis, isPost, startThenDebounce, mapData, onLoaded);
}

export function useQueryAllNoTracking(action, params, debounceMillis = DEFAULT_DEBOUNCE, isPost = false, startThenDebounce = false, mapData = null, onLoaded = null) {
    return useQueryAll(action, params, false, debounceMillis, isPost, startThenDebounce, mapData, onLoaded);
}

export function useQueryArgs(params) {

    const args = React.useRef({ ...params }).current;


    return args;

}


function useQueryAll(action, params, forEdit, debounceMillis, isPost, startThenDebounce, mapData, onLoaded) {


    const [query, setQuery] = React.useState(new QueryResult(action, forEdit, debounceMillis, isPost, startThenDebounce, { allowCaching : false}));


    const [state, setState] = React.useState({
        data: [],
        loading: true,
        updateKey: 1
    });



    const updateData = React.useCallback((map, preventUpdateKey) => {

        setState(s => ({ ...s, loading: false, data: s.data.map(map), updateKey: (s.updateKey ?? 1) + (preventUpdateKey ? 0: 1) }));

    }, [setState]);

    
    React.useEffect(() => {
        
        query.getAll(params).then(result => {

            const dataResult = accentUtils.isNull(mapData) ? result : mapData(result);

            setState({ loading: false, data: dataResult, updateKey: 1 });

            if (onLoaded) onLoaded(dataResult);

        });


        setState({ loading: true, data: [], updateKey : 1 });

    }, [params]);



    return {
        loading: state.loading,
        updateData: updateData,
        updateKey: state.updateKey,
        data: state.data
    };
}



export async function queryAPIScript(controller, action, args) {

    const isAuthenticated = await authService.isAuthenticated();

    if (!isAuthenticated) {
        return;
    }


    const token = await authService.getAccessToken();

    const query = !args ? "" : `/${args}`;

    const response = await fetch(`api/${controller}/${action}${query}`, {
        headers: {
            ...(!token ? {} : { 'Authorization': `Bearer ${token}` })
        }
    });

    processVersion(response.headers?.get('RTLVersion'));

    var scriptText = await response.text();

    const script = window.document.createElement("script");
    script.text = scriptText;

    window.document.body.appendChild(script);



}


export async function postExternal(url, content, headers) {

    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify(content)
    });

    if (!response.ok) {

        const text = await response.text();

        return { responseError: `${response.status} : ${text}` };
    }

    

    const result = await response.text();
    return { responseResult : result };
}

export async function queryOther(controller, action, method, args) {

    //const isAuthenticated = await authService.isAuthenticated();

    //if (!isAuthenticated) {
    //    return null;
    //}

    console.log("queryOther: before token");

    const token = await authService.getAccessToken();

    console.log("queryOther: after token");

    const query = method == "POST" || !args ? "" : `/${args}`;

    const response = await fetch(`api/${controller}/${action}${query}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            ...(!token ? {} : { 'Authorization': `Bearer ${token}` })
        },
        body: (method == "POST") ? JSON.stringify(args) : undefined
    });

    processVersion(response.headers?.get('RTLVersion'));

    if (!response.ok) {
        
        const text = await response.text();

        return { queryError: `${response.status} : ${text}`};
        
    }
    
    const result = await response.json();
    return { data : result };

}







export function useQueryOther(controller, action, method, args) {

    const [data, setData] = React.useState({ data: [], loading: true });


    React.useEffect(() => {


        queryOther(controller, action, method, args).then(res => {


            if (res.queryError) {
                setData({ data: [], queryError: res.queryError, loading: false });
            } else {
                setData({ data: res.data, loading: false });
            }

            

        });

        setData({ data: [], loading: true });

    }, [args])


    return data;
}



async function showException(err) {
    await showOK("application_strings.application.dialogs.viewValidationTitle", t(err.Message, err.Data), ["application_strings.application.buttons.close"], false, null, true, 600);
}

export async function update(action, args, showWait) {


    const done = showWait ? window.insytwebAppDlg.showWait() : null;

    const token = await authService.getAccessToken();
    const response = await fetch(`api/Update/${action}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...(!token ? {} : { 'Authorization': `Bearer ${token}` })
        },
        body: JSON.stringify(args)
    });

    const clonedResponse = response.clone();

    processVersion(response.headers?.get('RTLVersion'));

    try {

        
        const result = await response.json();


        if (response.status === 500) {
            if (result?.IsFrameworkException) {

                await showException(result);

                return result;
            }
        }

        return result;
         
    } catch (e) {

        console.log("Update Response: ", e);

        if (e.message.includes("Unexpected token")) {

            try {

                
                const text = await clonedResponse.text(); // read body of cloned response as text

                if (text?.toUpperCase() === "TRUE") return true;
                if (text?.toUpperCase() === "FALSE") return false;

                return text;
            } catch (ex) {

                console.log("Update Response: ", ex);        

            }
        }

        const vv = e;

    } finally {
        if (!accentUtils.isNull(done)) {
            await done();
        }
    }

    return null;
    
}

export async function updateAsFormData(action, args) {


    const formData = new FormData();

    Object.keys(args).map(k => {

        if (Array.isArray(args[k])) {

            args[k].map(i => {
                formData.append(k, i);
            });


        } else {
            formData.append(k, args[k]);    
        }

        
    });

    const token = await authService.getAccessToken();
    const response = await fetch(`api/Update/${action}`, {
        method: 'POST',
        headers: {
            ...(!token ? {} : { 'Authorization': `Bearer ${token}` })
        },
        body: formData
    });

    processVersion(response.headers?.get('RTLVersion'));

    try {

        const result = await response.json();

        return result;

    } catch (e) {

    }

    return null;

}


export function downloadExternalLink(url){

    return new Promise(p => {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = '';

        // Append the anchor tag to the body (required for Firefox)
        document.body.appendChild(anchor);

        // Trigger the download by simulating a click
        anchor.click();

        // Remove the anchor tag after a short delay
        setTimeout(() => {
            document.body.removeChild(anchor);
            p();
        }, 2000);

    });

}

export async function downloadAllExternalLinks(urls){


    const ref = { current: null };

    const done = showWait(ref)

    urls.forEach((url, index) => {
        enqueue(() => {
            return new Promise(p => {
                ref.current?.updateSubMessage(`downloading ${index + 1} of ${urls.length}`);
                downloadExternalLink(url).then(() => {
                    p();
                });
            });
            
        });
    });

    enqueue(() => {
        done();
    });
}

const getFileName = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/g;


export async function downloadObject(jsonObject, filename = 'data.txt') {

    const jsonString = (typeof jsonObject === "string") ? jsonObject : JSON.stringify(jsonObject, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

}

export async function download(url, title) {

    const isAuthenticated = await authService.isAuthenticated();

    if (!isAuthenticated) {
        return;
    }


    const token = await authService.getAccessToken();


    const response = await fetch(new Request(url), {
        headers: {
            ...(!token ? {} : { 'Authorization': `Bearer ${token}` })
        }
    });

    processVersion(response.headers?.get('RTLVersion'));

    const blob = await response.blob();

    //const newBlob = new Blob([blob], { type: 'text/html' });

    const newUrl = window.URL.createObjectURL(
        blob
    );


    const type = response.headers.get("content-disposition");

    

    let link = null;

    

    if (accentUtils.isNull(type)) {

        await showDialog(<DisplayURLDlg title={title} url={newUrl} />);
        
    } else {


        let fileName = Array.from(type.matchAll(getFileName), m => m[1])[0];

        if (fileName.startsWith('"'))
            fileName = fileName.substring(1);

        if (fileName.endsWith('"'))
            fileName = fileName.slice(0, -1);

        fileName = fileName.split('\\').pop().split('/').pop();

        link = document.createElement('a');
        link.href = newUrl;        
        link.setAttribute(
            'download',
            fileName.trim(),
        );
    }


    document.body.appendChild(link);

    
    const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
    });
    link.dispatchEvent(clickEvent);

    link.parentNode.removeChild(link);

    

}


export function useURL(url, allowQuery = true, data = null) {


    
    const activeUrl = React.useRef(url);

    const [loading, setLoading] = React.useState(!accentUtils.isEmpty(url));
    const [previousInputUrl, setPreviousInputUrl] = React.useState(null);
    const [forceRefresh, setForceRefresh] = React.useState(1);


    const loadURL = async urlToLoad => {



        const isAuthenticated = await authService.isAuthenticated();

        if (!isAuthenticated) {
            return;
        }


        const token = await authService.getAccessToken();

        const hasData = !accentUtils.isNull(data);

        const contentHeader = hasData ? { 'Content-Type': 'application/json' } : {};

        const response = await fetch(new Request(urlToLoad), {
            headers: {                
                ...contentHeader,
                ...(!token ? {} : { 'Authorization': `Bearer ${token}` })                
            },
            method: hasData ? 'POST' : "GET",
            body: hasData ? JSON.stringify(data) : undefined
        });

        processVersion(response.headers?.get('RTLVersion'));

        try {

            const blob = await response.blob();

            const newUrl = URL.createObjectURL(blob);


            activeUrl.current = newUrl;

            setLoading(false);
            setForceRefresh(r => r + 1);

        } catch (e) {

        }

    };


    React.useEffect(() => {


        //loadURL(url);

        return () => {

            if (!accentUtils.isEmpty(activeUrl.current)) {
                URL.revokeObjectURL(activeUrl.current);
            }
        };

    },[]);


    React.useEffect(() => {

        if (!allowQuery) return;


        if (activeUrl.current != url) {
            if (!accentUtils.isEmpty(activeUrl.current)) {
                URL.revokeObjectURL(activeUrl.current);
            }
        }
        
        setLoading(true);

        loadURL(url);

        setPreviousInputUrl(url);

    }, [url, allowQuery]);



    return { loading: previousInputUrl !== url || loading , url: activeUrl.current };

}



window.query = queryNoTracking
window.entityManagerProvider = entityManagerProvider;
