import React from "react";
import { Col, Row} from "reactstrap";
import * as KendoLayout from '@progress/kendo-react-layout'
import { AccentDialog } from "./AccentDialog";
import { queryNoTracking, update } from "../services/DataService";
import { accentUtils, from, t } from "../services/HelperService";
import { getProducts } from "../services/ProductService";
import { AccentButton } from "../controls/AccentButton";
import { SortListCtrl } from "../controls/SortListCtrl";

const query_JobLine_SortOrderWizard = queryNoTracking("JobLine_SortOrderWizard");




export const SortJobLineDlg = props => {

    const [selectedTab, setSelectedTab] = React.useState(1);
    const [lines, setLines] = React.useState([]);
    const [products, setProducts] = React.useState([]);
  

    const handleSelect = React.useCallback(e => {
        setSelectedTab(e.selected);
    }, [setSelectedTab]);

   

    const save = React.useCallback(e => {

        update("SaveJobLineSortOrder", {
            ProductIDs: products.map(p => p.ID),
            Lines: lines.map((l, i) => {
                l.SortIndex = i;
                return l;
            })
        }).then(r => {

            props.onClose({ canceled: false });

            e.promise();
        });


    }, [ products, lines]);

    

    React.useEffect(() => {

        query_JobLine_SortOrderWizard
            .getFirstOrDefault({ jobID: props.jobID })
            .then(model => {

                setLines(from(model.Lines).orderBy(l => accentUtils.isNull(l.SortIndex) ? l.ID + 1000 : l.SortIndex).thenBy(l => l.LineNo).toArray());
                const productsByID = from(getProducts()).select(p => { return { ID: p.ID, Product: p.ProductName }; }).toDictionary(p => p.ID.toUpperCase());

                setProducts( ((model.ProductIDs.length == 0) ? from(lines).select(l => l.ProductID).distinct().toArray() : model.ProductIDs).map(p => {
                    return {
                        ID: p,
                        ProductName: productsByID.contains(p.toUpperCase()) ? productsByID.get(p.toUpperCase()).Product : "Unknown"
                    };
                }));

            });


    }, []);



    return <AccentDialog
        fullscreen
        onClose={props.onClose}
        actionLayout="end"
        actionsContent={[<AccentButton key="1" tagName="save" onClick={save}>{t("application_strings.application.buttons.save")}</AccentButton>]}
    >

        <KendoLayout.TabStrip className="emailDlg" selected={selectedTab} onSelect={handleSelect} keepTabsMounted>
            
            <KendoLayout.TabStripTab title={t("application_strings.wizards.jobLineSort.lines.title")}>
                <SortListCtrl key={lines.length } items={lines} idField="ID" rowItem={JobLineSortLineItem} onSort={e => setLines(e)} />
            </KendoLayout.TabStripTab>

            <KendoLayout.TabStripTab title={t("application_strings.wizards.jobLineSort.products.title")}>
                <SortListCtrl key={products.length} items={products} idField="ID" rowItem={JobLineSortProductItem} onSort={e => setProducts(e)} />
            </KendoLayout.TabStripTab>
        </KendoLayout.TabStrip>

    </AccentDialog>;


};






const JobLineSortLineItem = (props) => {
    const { isDisabled, isActive, style, attributes, dataItem, forwardRef } = props;
    const classNames = ['row', 'rtl-drag-chk'];

    const getBaseItemStyle = (isActive) => ({
        height: 35,
        lineHeight: '33px',
        fontSize: '16px',
        outline: 'none',
        border: '1px solid',
        cursor: 'move',
        background: isActive ? '#27aceb' : '#bfe7f9',
        color: isActive ? '#fff' : '#1494d0',
        borderColor: isActive ? '#27aceb' : '#fff',
        overflow: "hidden"
    });

    if (isDisabled) {
        classNames.push('k-state-disabled');
    }

    return (
        <div
            ref={forwardRef}
            style={{
                ...getBaseItemStyle(isActive),
                ...style
            }}
            {...attributes}
            className={classNames.join(' ')}
        >
            <Col md="1">{dataItem.LineNo}</Col>
            <Col md="2">{dataItem.LocationOther}</Col>
            <Col md="3">{dataItem.ProductName}</Col>
            <Col md="2">{dataItem.DisplayOption1}</Col>
            <Col md="2">{dataItem.DisplayOption2}</Col>
            <Col md="2">{dataItem.DisplayOption3}</Col>

        </div>
    );
};


const JobLineSortProductItem = (props) => {
    const { isDisabled, isActive, style, attributes, dataItem, forwardRef } = props;
    const classNames = ['row', 'rtl-drag-chk'];

    const getBaseItemStyle = (isActive) => ({
        height: 35,
        lineHeight: '33px',
        fontSize: '16px',
        outline: 'none',
        border: '1px solid',
        cursor: 'move',
        background: isActive ? '#27aceb' : '#bfe7f9',
        color: isActive ? '#fff' : '#1494d0',
        borderColor: isActive ? '#27aceb' : '#fff',
        overflow: "hidden"
    });

    if (isDisabled) {
        classNames.push('k-state-disabled');
    }

    return (
        <div
            ref={forwardRef}
            style={{
                ...getBaseItemStyle(isActive),
                ...style
            }}
            {...attributes}
            className={classNames.join(' ')}
        >
            <Col md="12">{dataItem.ProductName}</Col>
        </div>
    );
};