import React from 'react'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'
import { filterBy } from "@progress/kendo-data-query";
import { accentUtils, t ,from} from '../../services/HelperService'
import { AccentLabel } from '../AccentLabel';


export class AccentComboUnbound extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();


        

        var valueField = this.props.valueField;
        var val = this.props.defaultValue;
        var value = from(this.props.data).where(v => v[valueField] === val).firstOrDefault();


        if (this.props.allowCustom) {

            if (!accentUtils.isEmpty(val) && accentUtils.isNull(value)) {
                value = {
                    [this.props.valueField]: new Date().getTime(),
                    [this.props.displayField]: val
                };
            }

        }


        if (accentUtils.isNull(value) && this.props.defaultSingle) {
            if (this.props.data.length == 1) {
                value = this.props.data[0];
            }
        }

        this.state = {
            value: value,
            show: undefined,
            filter: null
        };

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    onOpen(e) {

        if (this.props.openOnFocus) {
            this.setState({ show: true });
        }

    }

    onClose(e) {
        if (this.props.openOnFocus) {
            this.setState({ show: false });
        }
    }

    onFocus(e) {
        if (this.props.openOnFocus) {
            this.setState({ show: true });
        }
    }

    onChange(e) {

        if (!accentUtils.isNull(this.props.onChange)) {
            this.props.onChange(accentUtils.isNull(e.target.value) ? null : e.target.value[this.props.valueField]);
        }

        if (!accentUtils.isNull(this.props.onDisplayChange)) {
            this.props.onDisplayChange(accentUtils.isNull(e.target.value) ? null : e.target.value[this.props.displayField]);
        }


        this.setState({ value: e.target.value, filter: null });
    }

    onFilterChange(e) {
        this.setState({ filter : e.filter})
    }


    render() {


        const isNotValue = this.props.required && (accentUtils.isNull(this.state.value) || (!accentUtils.isNull(this.props.emptyValue) && this.state.value[this.props.valueField] === this.props.emptyValue));
        const isValid = !isNotValue;

        var className = isValid ? "accentComboUnbound" : "accentComboUnbound invalid";


        const itemRender = accentUtils.isNull(this.props.itemRender) ? undefined : (li, itemProps) => React.cloneElement(li, li.props, this.props.itemRender(li, itemProps));

        const data = this.props.data;

        if (this.props.allowCustom) {

            const currVal = this.state.value;


            if (!accentUtils.isNull(currVal)) {

                var currItem = from(data).firstOrDefault(x => x === currVal);


                if (accentUtils.isNull(currItem)) {

                    data.push(currVal);
                }
            }
        }

        const filteredData = accentUtils.isNull(this.state.filter) ? data : filterBy(data.slice(), this.state.filter);

        const combo = <ComboBox
            className={`${className} ${this.props.className}`}
            data={filteredData}
            value={this.state.value}
            onChange={this.onChange}
            textField={this.props.displayField}
            dataItemKey={this.props.valueField}
            disabled={this.props.disabled}
            itemRender={itemRender}
            opened={this.state.show}
            filterable
            //loading={this.props.loading}
            onFilterChange={this.onFilterChange}
            style={{ width: "100%" }}
            onFocus={this.onFocus}
            onClose={this.onClose}
            onOpen={this.onOpen}
            placeholder={this.props.placeHolder}
            clearButton={!this.props.hideClear}
            popupSettings={{ className: this.props.popupClassName }}
            allowCustom={this.props.allowCustom}
            valueRender={this.props.valueRender}
        />

        if (accentUtils.isEmpty(this.props.label)) {
            return <div data-tagname={this.props.dataTagName}>{combo}</div>;
        }

        return <AccentLabel text={this.props.label}>
            <div data-tagname={this.props.dataTagName}>{combo}</div>
        </AccentLabel>;

    }

}



export class AccentMultiComboUnbound extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();


        var valueField = this.props.valueField;
        var val = this.props.defaultValue;
        var value = accentUtils.isNull(val) ? null : from(this.props.data).where(v => val.includes(v[valueField])).toArray();

        if (accentUtils.isNull(value) && this.props.defaultSingle) {
            if (this.props.data.length == 1) {
                value = this.props.data[0];
            }
        }

        this.state = { value: value };

        this.onChange = this.onChange.bind(this);
        
    }


    onChange(e) {

        if (!accentUtils.isNull(this.props.onChange)) {
            this.props.onChange(accentUtils.isEmpty(e.target.value) ? [] : e.target.value.map(v=> v[this.props.valueField]));
        }

        this.setState({ value: e.target.value })
    }

    render() {

        var isValid = !this.props.required || !accentUtils.isNull(this.state.value);

        var className = isValid ? "accentComboUnbound" : "accentComboUnbound invalid";


        const itemRender = accentUtils.isNull(this.props.itemRender) ? undefined : (li, itemProps) => React.cloneElement(li, li.props, this.props.itemRender(li, itemProps));

        return <MultiSelect
            placeholder={this.props.placeHolder}
            className={`${className} ${this.props.className}`}
            data={this.props.data}
            value={this.state.value}
            onChange={this.onChange}
            textField={this.props.displayField}
            dataItemKey={this.props.valueField}
            disabled={this.props.disabled}
            itemRender={itemRender}
            label={t(this.props.label)}
            filterable
            style={{ width: "100%" }}
        />

    }

}
