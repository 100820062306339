import * as React from 'react'
import { Col, Row } from 'reactstrap';
import { AccentSpinner } from '../controls/AccentSpinner';
import { AccentAddress, AccentAddressNoViewState } from '../controls/bound/AccentAddress';
import { AccentFormCtrlNoViewState } from '../controls/bound/AccentFormCtrl';
import { queryNoTracking, update } from '../services/DataService';
import { accentUtils, formHelper, t } from '../services/HelperService';
import { accountTypes } from '../services/JobService';
import { AccentWizard, AccentWizardPage } from './RTLWizard';

const query_CompanyForID = queryNoTracking("CompanyForID");
const query_Company_SelectList = queryNoTracking("Company_SelectList");

export default class NewContactWizard extends React.Component {

    constructor(props) {
        super(props);


        

        this.defaultValues = this.props.pageprops.defaultValues;
        this.contactDetailsRequired = this.props.pageprops.contactDetailsRequired;


        this.loadedWithCompanyID = !accentUtils.isEmpty(this.defaultValues.CompanyID);
        var loadCompany = !accentUtils.isEmpty(this.defaultValues.CompanyID);
        var enableCompany = false;
        if (loadCompany) {

            this.loadCompany(this.defaultValues.CompanyID);
        }




        this.state = {
            loading: loadCompany,
            company: null,
            enableCompany: enableCompany
        };

        this.model = {

            CompanyAddress: {
                setProperty: function (name, val) {
                    this[name] = val;
                },
            },
            ContactAddress: {
                setProperty: function (name, val) {
                    this[name] = val;
                },
            },
            setProperty: function (name, val) {
                this[name] = val;
            },
        };

        if (!accentUtils.isNull(this.defaultValues)) {

            this.model.FirstName = accentUtils.titleCase(this.defaultValues.FirstName);
            this.model.LastName = accentUtils.titleCase(this.defaultValues.LastName);

            if (!accentUtils.isNull(this.defaultValues.Name)) {
                this.model.CompanyName = this.defaultValues.Name;
                this.model.AddCompany = true;
            }

        }


        this.setCompanyValues = this.setCompanyValues.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.validateContactPhone = this.validateContactPhone.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.getCompanyQuery = this.getCompanyQuery.bind(this);
        this.loadCompany = this.loadCompany.bind(this);
    }

    loadCompany(companyID) {

        var me = this;

        query_CompanyForID.getFirstOrDefault({ id: companyID , includeAddress: true}).then(function (company) {
            me.setCompanyValues(company);
            me.setState({ loading: false, company: company, enableCompany: !accentUtils.isNull(company) });
        });
    }

    getCompanyQuery() {
        return query_Company_SelectList.getAllAsQueryableWithCache({ includeID: accentUtils.isNull(this.model.CompanyID) ? null : this.model.CompanyID });
    }


    setCompanyValues(company) {

        this.model.CompanyID = company !== null ? company.ID : null;
        this.model.CompanyName = company !== null ? company.Name : null;
        this.model.CompanyEmail = company !== null ? company.Email : null;
        this.model.CompanyPhone = company !== null ? company.Phone : null;
        this.model.CompanyAccountType = company !== null ? company.AccountType : null;
        this.model.CompanyMobile = company !== null ? company.Mobile : null;
        this.model.CompanyWWW = company !== null ? company.WWW : null;
        this.model.CompanyAddress = {
            AddressLines: company !== null ? company.Address.AddressLines : null,
            City: company !== null ? company.Address.City : null,
            State: company !== null ? company.Address.State : null,
            Postcode: company !== null ? company.Address.Postcode : null,
            Country: company !== null ? company.Address.Country : this.props.pageprops.userModel.DefaultSettings.Country,
            setProperty: function (name, val) {
                this[name] = val;
            },
        };


    }

    setDefaults() {
        this.model.AddCompany = false;
        this.model.FirstName = null;
        this.model.LastName = null;
        this.model.WorkPhone = null;
        this.model.HomePhone = null;
        this.model.Mobile = null;
        this.model.Email = null;
        this.model.CompanyID = this.props.pageprops.viewModel.entity.CompanyID;
        this.model.ContactAddress = {
            AddressLines: null,
            City: null,
            State: null,
            Postcode: null,
            Country: this.props.pageprops.userModel.DefaultSettings.Country,
            setProperty: function (name, val) {
                this[name] = val;
            },
        };

        this.setCompanyValues(null);
    }

    validateContactPhone(value) {

        var isValid = !accentUtils.isEmpty(this.model.WorkPhone) ||
            !accentUtils.isEmpty(this.model.HomePhone) ||
            !accentUtils.isEmpty(this.model.Mobile) ||
            !accentUtils.isEmpty(this.model.Email);


        if (!isValid)
            return { Msg: "At least one contact number/email is required", Type: "Error" };

        return null;
    }

    onFinish() {

        return update("NewContactWizard", this.model);

    }

    render() {

        var me = this;

        if (this.state.loading) {
            return (<AccentSpinner />);
        }



        var contactDetailsRequired = accentUtils.isNull(this.contactDetailsRequired) ? { details: true, address: true } : this.contactDetailsRequired;


        var companyReadOnly = !this.model.AddCompany;
        var firstName = formHelper.getFieldCtrlModel(this.model, "FirstName", "text", true, { label: "application_strings.wizards.newContactWizard.firstName" });
        var lastName = formHelper.getFieldCtrlModel(this.model, "LastName", "text", true, { label: "application_strings.wizards.newContactWizard.lastName" });
        var workPhone = formHelper.getFieldCtrlModel(this.model, "WorkPhone", "phone", (contactDetailsRequired.details ? this.validateContactPhone : false), { label: "application_strings.wizards.newContactWizard.workPhone" });
        var homePhone = formHelper.getFieldCtrlModel(this.model, "HomePhone", "phone", (contactDetailsRequired.details ? this.validateContactPhone : false), { label: "application_strings.wizards.newContactWizard.homePhone" });
        var mobile = formHelper.getFieldCtrlModel(this.model, "Mobile", "mobile", (contactDetailsRequired.details ? this.validateContactPhone : false), { label: "application_strings.wizards.newContactWizard.mobile" });
        var email = formHelper.getFieldCtrlModel(this.model, "Email", "email", (contactDetailsRequired.details ? this.validateContactPhone : false), { label: "application_strings.wizards.newContactWizard.email" });
        var addCompany = formHelper.getFieldCtrlModel(this.model, "AddCompany", "check", false, { readOnly: this.loadedWithCompanyID, label: "application_strings.wizards.newContactWizard.company" });
        var companyName = formHelper.getFieldCtrlModel(this.model, "CompanyName", "textLookup", true, { readOnly: companyReadOnly, label: "application_strings.wizards.newContactWizard.companyName", displayField: "Name", source: this.getCompanyQuery, sourceType: "Company" });

        var companyEmail = formHelper.getFieldCtrlModel(this.model, "CompanyEmail", "text", false, { readOnly: companyReadOnly, label: "application_strings.wizards.newContactWizard.companyEmail" });
        var companyPhone = formHelper.getFieldCtrlModel(this.model, "CompanyPhone", "phone", false, { readOnly: companyReadOnly, label: "application_strings.wizards.newContactWizard.companyPhone" });
        var companyAccountType = formHelper.getFieldCtrlModel(this.model, "CompanyAccountType", "select", true, { label: "application_strings.wizards.newContactWizard.companyAccountType", readOnly: companyReadOnly, source: accountTypes.getPicklistSource() });
        var companyMobile = formHelper.getFieldCtrlModel(this.model, "CompanyMobile", "phone", false, { readOnly: companyReadOnly, label: "application_strings.wizards.newContactWizard.companyMobile" });
        var companyWWW = formHelper.getFieldCtrlModel(this.model, "CompanyWWW", "text", false, { readOnly: companyReadOnly, label: "application_strings.wizards.newContactWizard.companyWWW" });

        companyName.field.options.onChange = function (e) {

            if (accentUtils.isEmpty(e.value)) {
                me.setCompanyValues(null);
            } else if (typeof e.value === "string") {

            } else {

                var companyID = Number(e.value.ID);

                me.loadCompany(companyID);
            }

            me.forceUpdate();

        };


        addCompany.field.options.onChange = function () {
            me.setState({ enableCompany: me.model.AddCompany });
        };

        workPhone.field.options.onChange = function (e) {
            me.refs.phoneHomeCtrl.refresh();
            me.refs.phoneMobileCtrl.refresh();
            me.refs.emailCtrl.refresh();
        };
        homePhone.field.options.onChange = function (e) {
            me.refs.phoneWorkCtrl.refresh();
            me.refs.phoneMobileCtrl.refresh();
            me.refs.emailCtrl.refresh();
        };
        mobile.field.options.onChange = function (e) {
            me.refs.phoneWorkCtrl.refresh();
            me.refs.phoneHomeCtrl.refresh();
            me.refs.emailCtrl.refresh();
        };
        email.field.options.onChange = function (e) {
            me.refs.phoneWorkCtrl.refresh();
            me.refs.phoneMobileCtrl.refresh();
            me.refs.phoneHomeCtrl.refresh();
        };

        var col = 6;

        return (
            <AccentWizard className="accentNewContactWizard" wizardProps={this.props}>
                <AccentWizardPage title="application_strings.wizards.newContactWizard.page01Title">
                    <p>{t("application_strings.wizards.newContactWizard.page01Message")}</p>
                    <Row>
                        <Col md={col}>
                            <h5>{t("application_strings.wizards.newContactWizard.contactHeader")}</h5>
                            <Row><Col className="accentNewContactWizard-cs" md={12}>&nbsp;</Col></Row>
                            <AccentFormCtrlNoViewState ref="ctrlFirstName" model={firstName} />
                            <AccentFormCtrlNoViewState model={lastName} />
                            <AccentFormCtrlNoViewState ref="emailCtrl" model={email} />
                            <AccentFormCtrlNoViewState ref="phoneMobileCtrl" model={mobile} />
                            <AccentFormCtrlNoViewState ref="phoneWorkCtrl" model={workPhone} />
                            <AccentFormCtrlNoViewState ref="phoneHomeCtrl" model={homePhone} />
                            <Row><Col md={12}>&nbsp;</Col></Row>
                            <Row><Col md={12}><label>{t("application_strings.wizards.newContactWizard.contactSiteAddressHeader")}</label></Col></Row>
                            <AccentAddressNoViewState entity={this.model} addressMember="ContactAddress" required={contactDetailsRequired.address} />
                        </Col>
                        <Col md={col}>
                            <h5>{t("application_strings.wizards.newContactWizard.companyHeader")}</h5>
                            <AccentFormCtrlNoViewState model={addCompany} />
                            <AccentFormCtrlNoViewState model={companyName} />
                            <AccentFormCtrlNoViewState model={companyPhone} />
                            <AccentFormCtrlNoViewState model={companyMobile} />
                            <AccentFormCtrlNoViewState model={companyEmail} />
                            <AccentFormCtrlNoViewState model={companyWWW} />
                            <AccentFormCtrlNoViewState model={companyAccountType} />
                            <Row><Col md={12}>&nbsp;</Col></Row>
                            <Row><Col md={12}><label>{t("application_strings.wizards.newContactWizard.companyAddressHeader")}</label></Col></Row>
                            <AccentAddressNoViewState entity={this.model} addressMember="CompanyAddress" readOnly={companyReadOnly} required={true} />
                        </Col>
                    </Row>
                </AccentWizardPage>
            </AccentWizard>
        );
    }
}
