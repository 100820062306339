import { useMemo } from 'react';
import { Badge } from 'reactstrap';
import { accentUtils } from "../../services/HelperService";
import { getProduct } from '../../services/ProductService';



export const ProductItemVersion = ({ version, versionID, productID}) => {


    const product = useMemo(() => {

        const prod = getProduct(productID, versionID);

        return prod;

    }, [productID, versionID]);


    let v = version;

    if (v && v.charAt(0) !== 'v') {
        v = 'v' + v;
    }

    if (!v) return null;

    return <Badge color={product?.IsActiveVersion ? "success" : "warning"}>{product?.IsDraft ? "DRAFT" : v}</Badge>;
}

export const ProductItemTemplate = function (li, itemProps) {


    

    const version = <ProductItemVersion version={itemProps.dataItem.Version} versionID={itemProps.dataItem.VersionID} productID={itemProps.dataItem.ID } />;

    return (
        <div className="accentProductList d-flex justify-content-between">
            <div className="p-2" style={{ fontWeight: "bolder" }}><span style={{ paddingRight: "10px" }}>{itemProps.dataItem.Product}</span>{version }</div>
            <div className="p-2" style={{ fontStyle: "italic" }}>{accentUtils.isNull(itemProps.dataItem.Supplier) ? '' : itemProps.dataItem.Supplier}</div>
        </div>);
};