import React, { useState } from 'react';
import { Col, Row, Container, Table } from 'reactstrap';
import { AccentButton } from "../controls/AccentButton";
import { AccentSpinner } from "../controls/AccentSpinner";
import { AccentRadioUnboundCustom } from '../controls/unbound/AccentRadioUnbound';
import { useQueryAllNoTracking } from "../services/DataService";
import { from, t } from "../services/HelperService";
import { jobLineStages } from '../services/JobService';
import { AccentDialog } from "./AccentDialog";


const JobItemsList = ({ jobItems, onChange }) => {
    const [items, setItems] = useState(
        jobItems.map((item) => ({ ...item, Status: 'Completed' }))
    );

    const handleStatusChange = (itemID, newStatus) => {
        const updatedItems = items.map((item) =>
            item.ID === itemID ? { ...item, Status: newStatus } : item
        );
        setItems(updatedItems);

        onChange(updatedItems);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Job Line No</th>
                                <th>Location</th>
                                <th>Product Name</th>
                                <th>Stage</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr key={item.ID}>
                                    <td>{item.JobLineNo}</td>
                                    <td>{item.LocationOther}</td>
                                    <td>{item.ProductName}</td>
                                    <td>{t(item.Stage)}</td>
                                    <td>
                                        <div>
                                            <AccentRadioUnboundCustom
                                                checked={item.Status === 'Completed'}
                                                onChange={() => handleStatusChange(item.ID, 'Completed')}
                                                label="Completed"
                                                name={`statusGroup${item.ID}`}
                                            />
                                            {(item.Stage !== jobLineStages.Unconfirmed_CM) && <AccentRadioUnboundCustom
                                                checked={item.Status === 'Reschedule'}
                                                onChange={() => handleStatusChange(item.ID, 'Reschedule')}
                                                label="Reschedule"
                                                name={`statusGroup${item.ID}`}
                                            />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};


export const CMCompleteDlg = props => {

    const args = React.useRef({ id: props.jobID });

    const [rescheduled, setRescheduled] = React.useState([]);

    const lines = useQueryAllNoTracking("Job_CompleteCMLines", args.current);

    const onClose = e => {


        props.onClose({ rescheduled: rescheduled });

        e.promise();
    };


    const onChange = e => {


        setRescheduled(from(e).where(e=> e.Status === 'Reschedule').select(e=> e.ID).toArray());

    };


    let content = null;

    if (lines.loading) {
        content = <AccentSpinner />;
    } else {
        content = <JobItemsList jobItems={lines.data} onChange={onChange} />;
    }


    return <AccentDialog        
        onClose={props.onClose}
        headerContent={t("application_strings.application.dialogs.completeCMDlg.title")}
        actionsContent={[
            <AccentButton key="1" tagName="completeCMDlgClose" onClick={onClose}>{t("application_strings.application.buttons.ok")}</AccentButton>
        ]}
    >
        { content}
    </AccentDialog>;

};