import { Badge } from 'reactstrap'
import * as React from 'react'
import $ from 'jquery'
import { accentUtils, t, format, A, showDialog } from '../../services/HelperService';
import { AccentTooltip } from '../AccentTooltip';
import AccentIcons from '../../icons/Icons';
import { download, update } from '../../services/DataService';
import { AccentCheckUnbound } from '../unbound/AccentCheckUnbound';
import { JobHealthCheck } from '../../dialogs/JobHealthCheck';
import { ContactInfoCtrl } from '../contacts/ContactInfoCtrl';
import { onTemplateCellClick } from './RTLGridCellRender';
import { SketchDlg } from '../../dialogs/SketchDlg';
import { SnoozeDlg } from '../../dialogs/SnoozeDlg';
import { getProduct } from '../../services/ProductService';





export const OpportunitySnoozeTemplate = props => {

    const onSnooze = React.useCallback(() => {

        showDialog(<SnoozeDlg title="application_strings.views.leads.leadGrid.snooze" settingsKey="leads" showNotes />).then(e => {
            if (!e.canceled) {
                update("SnoozeLead", { ID: props.dataItem.ID, SnoozeDate: e.date.toISOString(), Notes: e.notes }).then(r => {
                    props.onRefresh();
                });
            }
        });


    }, [props]);

    const onClearSnooze = React.useCallback(() => {

        update("WakeLead", { ID: props.dataItem.ID, SnoozeDate: null }).then(r => {
            props.onRefresh();
        });


    }, [props]);


    const value = format.formatDateTime(props.value, true, true);

    const canClear = !accentUtils.isNull(props.value);


    return <div className="d-flex">
        <div><AccentIcons.Snooze onClick={onSnooze} top={5} /></div>
        <div>{value}</div>
        {canClear && <div><AccentIcons.Clear onClick={onClearSnooze} top={5} /></div>}
    </div>;
};



export const DownloadDocumentTemplate = props => {

    const onDownload = React.useCallback(() => {

        if (props.dataItem.Type === "Sketch") {
            download('/Download/DocumentSketchUri/?uri=' + encodeURIComponent(props.value));
        } else {
            download('/Download/DocumentUri/?uri=' + encodeURIComponent(props.value));
        }
        
    }, [props]);

    const onEdit = React.useCallback(() => {

        if (props.dataItem.Type === "Sketch") {
            showDialog(<SketchDlg documentID={props.dataItem.ID} />).then(res => {

            });
        } 

    }, [props]);


    const showEdit = props.dataItem.Type === "Sketch";

    return <div className="d-flex">
        <div><AccentIcons.Download onClick={onDownload} top={5} /></div>
        {showEdit && <div><AccentIcons.Edit onClick={onEdit} top={5} /></div>}
    </div>;
};


export class TranslateCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);
    }



    render() {

        let value = this.props.value;

        if (value === 'true') value = true;
        if (value === 'false') value = false;

        const res = (typeof (value) === "boolean") ?
            (value ? <span className="acc-grid-chk" /> : <span />)
            : <span>{t(value)}</span>;


        return (
            res
        );
    }
}




export class HtmlCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);
    }



    render() {

        const value = this.props.value;


        return (
            <div dangerouslySetInnerHTML={{ __html: value }}>
            </div>
        );
    }
}





export class NumericCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);
    }



    render() {

        const value = this.props.dataItem[this.props.field];


        return (<div style={{ textAlign: "right" }}>
            {value}
        </div>
        );
    }
}


export class MobileCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);
    }



    render() {

        const value = this.props.dataItem[this.props.field];

        return (<div>
            <AccentIcons.SMS maxWidth="28px" maxHeight="28px" onClick={e => this.props.onClick({ row: this.props.dataItem, field: this.props.field })} />{value}
        </div>
        );

    }
}

export class EmailCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);
    }



    render() {

        const value = this.props.dataItem[this.props.field];


        return (<div onClick= {onTemplateCellClick} >
            <AccentIcons.Email maxWidth="28px" maxHeight="28px" onClick={e => this.props.onClick({ row: this.props.dataItem, field: this.props.field })} />{value}            
        </div>
        );
    }
}



export class CurrencyCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);
    }



    render() {

        const value = this.props.value;


        return (<div style={{ textAlign: "right" }}>
            {format.formatCurrency(value)}
        </div>
        );
    }
}


export class TagCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);



    }

    render() {

        const value = this.props.value;



        return (<div className="acc-tag-cell">
            {accentUtils.isEmpty(value) ? value : value.split(',').map((t, ix) => {
                return <Badge key={ix}  pill color="secondary">{t}</Badge>;
            })}
        </div>);
    }
}


export class SummaryCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);

    }

    render() {

        const value = this.props.value;


        return (<div className="acc-tag-cell">
            {accentUtils.isEmpty(value) ? value : value.split(',').map((s, i) => {

                var text = s;

                if (this.props.translate) {

                    text = s.replaceAll(/\[(.*?)\]/g, function (a, b) {
                        return t(b);
                    });

                }

                return <Badge key={i} pill color={this.props.color}>{text}</Badge>;
            })}
        </div>);
    }
}


export class EntityLinkCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);

        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.onLinkClick = this.onLinkClick.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    onLinkClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

   
    render() {


        var value = this.props.value;
        const linkID = this.props.linkID;

        if (this.props.filterType == "date") {
            value = format.formatDate(value);
        }

        
        const hasValue = !accentUtils.isEmpty(value);
        
        return (
            <div className="accentEntityCol" id={"accentEntityCol_" + this.props.linkEntity + '_' + linkID}>
                {hasValue && this.props.linkEntity === "Job" && <JobHealthCheck jobID={linkID} />}
                {hasValue && this.props.linkEntity === "Contact" && <ContactInfoCtrl id={linkID} />}
                <A href={"/" + this.props.linkEntity + '/' + linkID} onClick={this.onLinkClick}>{value}</A>
            </div>
        );
    }
}



export const JobForecastGridTemplate = React.memo((props) => {

    const [value, setValue] = React.useState(props.dataItem[props.field]);
    const [refreshKey, setRefreshKey] = React.useState(0);

    
    const linkID = props.dataItem[props.jobIDField];


    const onCheckChanged = React.useCallback(e => {

        const [itemType, itemID] = e.tagName.split('_');
        const checked = e.checked;

        update("SaveForecastItem", { Type: "Job", ID: itemID, Forecasted: checked, OppID: props.dataItem.OppID });

        setValue(checked);
        

    }, [props, setValue]);


    React.useEffect(() => {

        if (props.dataItem.OppForecastValue === false) {
            setValue(false);
            setRefreshKey(k => k + 1);
        }

    }, [props.dataItem.OppForecastValue, setRefreshKey, setValue]);


    return <AccentCheckUnbound key={refreshKey} disabled={false} tagName={`Job_${linkID}`} defaultValue={value} onChange={onCheckChanged} />;
});





export const OppForecastGridTemplate = React.memo((props) => {

    const value = props.dataItem[props.field];
    const linkID = props.dataItem[props.opportunityIDField];


    const onCheckChanged = React.useCallback(e => {

        const [itemType, itemID] = e.tagName.split('_');
        const checked = e.checked;


        update("SaveForecastItem", { Type: "Opportunity", ID: itemID, Forecasted: checked, OppID: itemID });


        props.updateDataItem({ ...props.dataItem, [props.field] : checked });


    }, [props]);



    return <AccentCheckUnbound disabled={false} tagName={`Opp_${linkID}`} defaultValue={value} onChange={onCheckChanged} />;
});




export class LinkCellTemplate extends React.PureComponent {


    constructor(props) {
        super(props);

        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.onLinkClick = this.onLinkClick.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    onLinkClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }


    render() {


        var value = this.props.value;

        if (this.props.filterType == "date") {
            value = format.formatDate(value);
        }

        return (
            <div className="accentEntityCol">                
                <A href={this.props.path} onClick={this.onLinkClick}>{value}</A>
            </div>
        );
    }
}




export class ProductNameTemplate extends React.PureComponent {


    constructor(props) {
        super(props);

        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.onLinkClick = this.onLinkClick.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    onLinkClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }


    render() {


        var value = this.props.value;


        const product = getProduct(value);

        return (
            <div>
                {product?.ProductName}  
            </div>
        );
    }
}

