import React from 'react'
import $  from 'jquery'
import { Input } from '@progress/kendo-react-inputs';
import { accentUtils, t, newGuid } from '../services/HelperService'
import { RTLGridCtrl } from './grids/RTLGridCtrl'
import { MultiSelectDropBtn } from './jobs/JobLineTreeMultiSelectCtrl';
import { OutsideAlerter } from './OutsiderAlerter'
import { AccentPopup } from '../dialogs/AccentPopup';
import AccentIcons from '../icons/Icons';
import { debounce } from 'perfect-debounce';
import { isMobileOnly } from './AccentDisplay';


const onSearchChangedDebounced = debounce(action => action(), 800, {trailing: false});

export const CustomSearchDropdownCtrl = props => {

    const anchor = React.useRef(null);


    const [inputRefreshKey, setInputRefreshKey] = React.useState(1);
    const [gridRefreshKey, setGridRefreshKey] = React.useState(1);
    const [key, setKey] = React.useState(newGuid());
    const [searchText, setSearchText] = React.useState(null);
    const [showDetail, setShowDetail] = React.useState(false);
    const [selection, setSelection] = React.useState(props.delfaultValue);
    const [searchData, setSearchData] = React.useState([]);


    const targetID = `key${key}`;
    const btnID = `btn${targetID}`;




    const onToggle = React.useCallback(e => {
        setShowDetail(showDetail => !showDetail);
    }, [showDetail, setShowDetail]);

    const onShow = React.useCallback(e => {
        if (!showDetail) {
            setShowDetail(true);
        }
    }, [showDetail, setShowDetail]);

    const onOutsideClicked = React.useCallback(e => {

        const elem = $(e.target);

        const isBtn = (elem.attr("id") == btnID || (!accentUtils.isNull(elem.parent()) && elem.parent().attr("id") == btnID));

        const isColumnChooser = elem.hasClass('k-checkbox-label');

        if (!isBtn && !isColumnChooser) {
            setShowDetail(false);
        }


    }, [showDetail, setShowDetail]);




    const onSelectionChanged = React.useCallback(e => {
        setSelection(accentUtils.isNull(e) ? e : e.value);

        setInputRefreshKey(inputRefreshKey => inputRefreshKey + 1);

        if (props.onChange)
            props.onChange(accentUtils.isNull(e) ? { value: null } : e);

    }, [props, selection, setSelection, inputRefreshKey, setInputRefreshKey]);


    const onRemove = React.useCallback(e => {
        setSearchText("");
        onSelectionChanged(null);
    }, [selection, setSelection, setSearchText]);



    const onChange = React.useCallback(e => {

        onSearchChangedDebounced(() => {

            if (accentUtils.isEmpty(e.value)) {

                onRemove();

            } else {

                onShow();


            }

            setSearchText(e.value);

            setGridRefreshKey(gridRefreshKey => gridRefreshKey + 1);
        });

    }, [searchText, setSearchText, gridRefreshKey, setGridRefreshKey]);


    const onGridSearch = React.useCallback(e => {


    }, [searchText, setSearchText, gridRefreshKey, setGridRefreshKey]);


    const onRowClick = React.useCallback(e => {

        const value = e.row[props.valueField];
        const desc = e.row[props.descriptionField];

        setSearchText(desc);
        onSelectionChanged({ value: value, row: e.row });

        setShowDetail(false);


    }, [searchText, setSearchText, showDetail, setShowDetail, onSelectionChanged]);




    React.useEffect(e => {

        if (!accentUtils.isNull(props.defaultValue)) {

            props.keyLookupQuery(props.defaultValue).then(r => {

                if (!accentUtils.isNull(r)) {
                    setSearchText(r[props.descriptionField]);
                    setInputRefreshKey(inputRefreshKey => inputRefreshKey + 1);
                }
            });
        }

    }, []);


    const hasLabel = !accentUtils.isEmpty(props.label);

    const dropWidth = anchor.current ? `${anchor.current.offsetWidth}px` : undefined;
    const left = anchor.current ? `${anchor.current.offsetLeft}px` : 0;


    const className = !accentUtils.isNull(props.className) ? props.className : "";

    return <div ref={anchor} className={`d-flex acc-search-sel-ctrl ${className}`} id={targetID}>
        <div className="flex-fill">
            <Input key={inputRefreshKey} label={props.label} defaultValue={searchText} onChange={onChange} disabled={props.readOnly} />
        </div>
        <div>
            <MultiSelectDropBtn id={btnID} onClick={onShow} readOnly={props.readOnly} />
        </div>
        <AccentPopup
            anchor={anchor}
            show={showDetail}
            actionsContent={isMobileOnly ? [<div className="me-auto" key="1" />, <AccentIcons.Close key="2" onClick={onOutsideClicked} />] : undefined}
            bodyStyle={{ maxWidth: dropWidth }}
            onAutoClose={onOutsideClicked}
        >
            <RTLGridCtrl
                height={isMobileOnly ? undefined : 400}
                allowFilter={false}
                defaultSearchText={searchText}
                onSearch={onGridSearch}
                showToolbar={false}
                gridID={props.gridID}
                showDetails={false}
                processColumn={props.processColumn}
                query={() => props.query(searchText)}
                strings={props.strings}
                columns={props.columns}
                onRowClick={onRowClick}
                withinPopup
                hideActions
            />
        </AccentPopup>


    </div>;

};