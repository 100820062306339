import React from 'react'
import { Row, Col, Alert } from 'reactstrap';

import { t} from '../services/HelperService'
import { AccentTextUnbound } from './unbound/AccentTextUnBound';



export const EmailAccountDetailsCtrl = props => {


    const onPasswordChanged = React.useCallback(v => {
        props.onPasswordChange(v);
    }, [props]);

    const onUsernameChanged = React.useCallback(v => {
        props.onUsernameChange(v);
    }, [props]);

    return <Row>
        <Col md={12}>
            <Row>
                <Col md={12}><Alert>{t("application_strings.views.settings.emailIntegration.smtp.enterAccountPasswordInfo")}</Alert></Col>
            </Row>
            <Row>
                <Col md={12}><AccentTextUnbound disabled defaultValue={props.email} label="application_strings.views.settings.emailIntegration.smtp.email" /></Col>
            </Row>
            <Row>
                <Col md={12}><AccentTextUnbound onChange={onUsernameChanged}  label="application_strings.views.settings.emailIntegration.smtp.userName" /></Col>
            </Row>
            <Row>
                <Col md={12}><AccentTextUnbound password onChange={onPasswordChanged} label="application_strings.views.settings.emailIntegration.smtp.password" /></Col>
            </Row>
        </Col>
    </Row>

};