import React from 'react';
import { accentUtils, t, from, goTo} from '../../services/HelperService'
import * as ActivityService from '../../services/ActivityService'
import * as JobService from '../../services/JobService'
import { queryNoTracking, deleteEntity, addEntity, isNewEntity } from '../../services/DataService';
import { JobLineTreeMultiSelectCtrl } from './../jobs/JobLineTreeMultiSelectCtrl';
import { CustomSearchDropdownCtrl } from './../CustomSearchDropdownCtrl';
import { JobGrid } from '../../grids/JobGrid';
import { AccentLabel } from '../AccentLabel';
import GRIDS from './../../grids/_GridDefinitions';


const query_JobLinesForSelection = queryNoTracking('Activity_JobLinesForSelection', undefined, true);
const query_Search_Job_ForContact = queryNoTracking('Search_Job_ForContact');
const query_Job_SelectKeyLookup = queryNoTracking('Job_SelectKeyLookup');

export const ActivityJobLineCtrl = props => {


    const [selectedJobLines, setSelectedJobLines] = React.useState(from(props.viewModel.entity.JobLines).select(l => l.JobLineID).toArray());

    const onChanged = React.useCallback(e => {

        const itemsToAdd = from(e).where(lID => !from(props.viewModel.entity.JobLines).any(l => l.JobLineID == lID)).toArray();
        const itemsToRemove = from(props.viewModel.entity.JobLines).where(l => !from(e).any(lID => l.JobLineID == lID)).toArray();

        itemsToRemove.map(l => deleteEntity(l));

        itemsToAdd.map(lID => {
            addEntity("ActivityJobLine", { Activity: props.viewModel.entity, JobID: props.viewModel.entity.JobID, JobLineID: lID })            
        });

        props.viewModel.setDirty();

        setSelectedJobLines(e);
        

    }, [props, setSelectedJobLines]);

    const onValidate = React.useCallback(e => {



        for (let index = 0; index < e.Lines.length; ++index) {
            const line = e.Lines[index];
            if (!ActivityService.Helper.isProductValid(line.JobIsConfirmed, line.Stage)) {
                return "Stage is not valid for activity/job line";
            }

            if (props.viewModel.entity.ActivityType == ActivityService.Helper.typeInstall) {

                var ajl = from(props.viewModel.entity.JobLines).firstOrDefault(l => l.JobLineID === line.ID);

                var isNew = isNewEntity(ajl);

                if (isNew && line.DispatchStage === JobService.jobLineDispatchStages.PendingService) {
                    return "Cannot select lines with pending service";
                }

            }

        }
        

        return undefined;

    }, [props]);

    const onDataLoaded = React.useCallback(e => {

        if (selectedJobLines.length == 0 && (props.viewModel.entity.DefaultProductList.length > 0 || props.viewModel.entity.DefaultJobLineList.length > 0)) {

            const newSelection = [];

            const lineFilter = props.viewModel.entity.DefaultJobLineList.length > 0
                ? l => props.viewModel.entity.DefaultJobLineList.includes(l.ID)
                : l => props.viewModel.entity.DefaultProductList.includes(l.GroupKey);


            const newItems = from(e)
                .where(lineFilter)
                .select(l => {
                    newSelection.push(l.ID);
                    return addEntity("ActivityJobLine", { Activity: props.viewModel.entity, JobID: props.viewModel.entity.JobID, JobLineID: l.ID });                    
                })
                .toArray();

            if (newSelection.length > 0) {
                setSelectedJobLines(newSelection);
                return newSelection;
            }

        }

        
    }, [props, selectedJobLines, setSelectedJobLines]);



    
    
    const lineQuery = accentUtils.isNull(props.viewModel.entity.JobID) ? () => Promise.resolve([]) : () => query_JobLinesForSelection.getAll({ jobID: props.viewModel.entity.JobID, currSelection: selectedJobLines.join(",") });
    const className = props.required && accentUtils.isEmpty(selectedJobLines) ? "accentInvalidCtrl" : "";

    return <AccentLabel text="Job Lines"  >
        <div className="acc-act-job-line-ctl">
            <JobLineTreeMultiSelectCtrl                
                className={className}
                delfaultSelection={selectedJobLines}
                lineQuery={lineQuery}
                readOnly={props.readOnly}
                onChange={onChanged}
                onDataLoaded={onDataLoaded }
                onValidate={onValidate} />                      
    </div>
    </AccentLabel>
};



export const ActivityJobCtrl = props => {



    const onChanged = React.useCallback(e => {
        props.viewModel.entity.JobID = e.value;

        if (e.row) {
            props.viewModel.entity.OpportunityID = e.row.OpportunityID;

            if (accentUtils.isNull(props.viewModel.entity.ContactID)) {
                props.viewModel.entity.ContactID = e.row.SiteContactID;
            }

        }

        props.onChange();

    }, [props]);

    const onValidate = React.useCallback(e => {

        if (props.required)
            return "Job is Require for activity type";

    }, [props]);


    const onProcessColumn = React.useCallback(e => {

        e.width = 100;

        return JobGrid.ProcessColumn(e);

    }, [props]);


    

    const query = (s) => query_Search_Job_ForContact.getAllWithCache({ searchString: s, contactID: (accentUtils.isNull(props.viewModel.entity.ContactID) ? null : props.viewModel.entity.ContactID) });

    const keyLookupQuery = (id) => query_Job_SelectKeyLookup.getFirstOrDefault({ jobID: id });
    
    const columns = GRIDS.JobGridView.Default.map(c => ({...c}));
    if (columns.length > 0) {
        columns[0].template = null;
        columns[0].linkEntity = null;
        columns[0].linkField = null;
    }
    if (columns.length > 1) {
        columns[1].template = null;
        columns[1].linkEntity = null;
        columns[1].linkField = null;
    }
    const className = props.required && accentUtils.isNull(props.viewModel.entity.JobID) ? "accentInvalidCtrl" : undefined;

    const post = accentUtils.isEmpty(props.post) ? null : props.post.map((c, i) => <div key={i}>{c}</div>)

    return <AccentLabel text="Job" onClick={() => goTo(`/Job/${props.viewModel.entity.JobID}`)} disabled={accentUtils.isEmpty(props.viewModel.entity.JobID)}>
            <div className='d-flex'>            
                <div className='flex-fill'>
                    <CustomSearchDropdownCtrl
                        className={ className}
                        defaultValue={props.viewModel.entity.JobID}
                        query={query}
                        keyLookupQuery={keyLookupQuery}
                        readOnly={props.readOnly}
                        onChange={onChanged}                
                        columns={columns}
                        processColumn={onProcessColumn}
                        strings="jobGridView"
                        valueField="ID"
                        gridID={ "EF099D31-C536-474C-8C64-D5234FDE0760" }
                        descriptionField="Reference"
                    />
                </div>
                {post}
            </div>
        </AccentLabel>;

};



