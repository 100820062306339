import * as React from 'react';
import { queryNoTracking } from './DataService'
import { accentUtils } from './HelperService'
import { HubConnectionBuilder , LogLevel} from '@microsoft/signalr'

export const MessageTypes = {
    ShowContextChanged: "ShowContextChanged",
    ContextChanged: "ContextChanged",
    Tracking: "Tracking",
    InvoiceStatusChanged: "InvoiceStatusChanged",
    JobStatusChanged: "JobStatusChanged",
    CalendarChanged: "CalendarChanged",
    BulkUpdateChanged: "BulkUpdateChanged",
    UserChanged: "UserChanged",
    AppRestarted: "AppRestarted",
    ReportGenerated: "ReportGenerated",
    CommissionRunUpdate: "CommissionRunUpdate_",
    JobLinePriced: "JobLinePriced",
    PermanentCacheClear: "PermanentCacheClear",
    JobViewRequest: "JobViewRequest",
    JobViewNotify: "JobViewNotify",
    JobViewDone: "JobViewDone"
};

class MessageService {

    token = null;
    clients = {};




    register(key, messageType, callback) {

        var msgClient = this.clients[messageType];
        if (accentUtils.isNull(msgClient)) {
            this.clients[messageType] = {};
            msgClient = this.clients[messageType]
        }
        msgClient[key] = callback;
    }

    unregister(key, messageType) {

        var msgClient = this.clients[messageType];
        if (accentUtils.isNull(msgClient)) {
            this.clients[messageType] = {};
            msgClient = this.clients[messageType]
        }

        if (!accentUtils.isNull(msgClient[key])) {
            delete msgClient[key];
        }

        
    }

    notify(messageType, message) {

        var msgClient = this.clients[messageType];

        if (!accentUtils.isNull(msgClient)) {

            Object.keys(msgClient).map(key => {

                if (!accentUtils.isNull(msgClient[key])) {

                    msgClient[key](message, key);

                }


            });
            
        }


    }

    async Initialize() {

        try {

            this.token = await queryNoTracking("Messages_GetToken").getFirstOrDefault();

            var options = {
                accessTokenFactory: () => this.token.Token,
            };

            const connection = new HubConnectionBuilder()
                .withUrl(this.token.URL, options)
                .withAutomaticReconnect([0, 1000, 5000, null])
                .configureLogging(LogLevel.Information)
                .build();
            connection.on('newMessage', (messageKey, message) => {

                if (messageKey.startsWith(MessageTypes.CommissionRunUpdate)) {
                    this.notify(MessageTypes.CommissionRunUpdate, message);
                } else {
                    this.notify(messageKey, message);
                }


            });

            connection.start()
                .catch(console.error);

        } catch (e) {

        }
    }

}


export function useMessage(key, messageType) {

    const [value, setValue] = React.useState(null);

    const onReceive = React.useCallback(e => {
        setValue(e);
    }, []);

    React.useEffect(() => {

        Helper.register(key, messageType, onReceive);

        return () => {
            Helper.unregister(key, messageType);
        };
    }, []);

    return value;
}

export const Helper = new MessageService();

