import * as React from 'react'
import { Settings } from '../../services/AppSettingsService';
import { queryNoTracking } from '../../services/DataService';
import { accentUtils, def, from } from '../../services/HelperService';
import { AccentSpinner } from '../AccentSpinner';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';


const query_DocumentTemplate_Select = queryNoTracking("DocumentTemplate_Select");

export const DocumentTemplateSelection = props => {

    const [defaultValue, setDefaultValue] = React.useState(def(props.defaultValue, Settings.GetAppSettings()[`Default${props.type}DocumentTemplate`]));
    const [data, setData] = React.useState(null);

    const onChanged = React.useCallback(e => {
        props.onChange(e);

        const v = {};
        v[`Default${props.type}DocumentTemplate`] = e;

        Settings.SetAppSettings(v);

    }, [props, data]);


    React.useEffect(e => {

        query_DocumentTemplate_Select.getAllWithCache({type: props.type}).then(r => {



            if (r.length == 1 && (accentUtils.isEmpty(defaultValue) || !from(r).any(i => i.ID == defaultValue))) {
                setDefaultValue(r[0].ID);
                onChanged(r[0].ID);
                setData(r);
                if (props.onReady) props.onReady(r[0].ID);
            } else {
                setData(r);
                if (props.onReady) props.onReady(defaultValue);
            }


        });

    }, []);


    if (data == null) return <AccentSpinner />;

    return <AccentComboUnbound displayField="Name" required={props.required} valueField="ID" defaultValue={defaultValue} onChange={onChanged} label="Document Template" data={data} />;

};