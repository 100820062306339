import * as React from 'react';
import PQueue from 'p-queue'
import memoize from 'memoizee'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import * as Moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import Enumerable from 'linq'
import { alpha, lighten, darken } from '@mui/material/styles';
import accentUtils from './helpers/AccentUtils'
import formHelper from './helpers/FormHelper'
import i18n from 'i18next';
import './helpers/app.myinsyte.strings.js'
import { getUser } from './UserService'
import {  extend} from 'string-format'
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber'
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { OutsideAlerter } from '../controls/OutsiderAlerter';
import { navigateBackMainWindow } from '../app-framework/AccentRouter';


extend(String.prototype);

const localToCurrencyCode = {
    "en-AU": "AUD",
    "en-NZ": "NZD",
    "en-GB": "GBP", // UK
    "en-CA": "CAD", // Canada
    "en-ZA": "ZAR", // South Africa
    "en-US": "USD", // US
    "en-IE": "EUR"  // Ireland
};


let accentUserLang = "en-AU";

window.uuidv1 = uuidv4;

let currencySymbol = null;

const queue = new PQueue({ concurrency: 1 });

export function proRata(total, rounding, items) {


    var baseItems = items.map(i => window.InsyteProduct.InsyteWeb.ProRataItem.FromJS(i.ID, i.BaseAmount));

    var calc = new window.InsyteProduct.InsyteWeb.ProrataCalculator();
    return calc.Calculate(new window.System.Decimal(total), rounding, baseItems).map(r => r.ToJS());


}

export function isDateBetween(targetDate, startDate, endDate) {
    if (!targetDate || !startDate || !endDate) {
        return false;
    }

    let target = new Moment(new Date(targetDate));
    let start = new Moment(new Date(startDate));
    let end = new Moment(new Date(endDate));

    return target.isBetween(start, end, null, '[]');
}


export function ConvertDateToBrowserTimezoneFromUTC(date) {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return utcToZonedTime(new Date(date), browserTimezone);
}

export function ConvertDateFromBrowserTimezoneToUTC(date) {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return zonedTimeToUtc(new Date(date), browserTimezone);
}
export function ConvertDateToUTC(date, timeZone) {
    return zonedTimeToUtc(new Date(date), timeZone);
}
export function ConvertDateFromUTC(date, timeZone) {
    return utcToZonedTime(new Date(date), timeZone);
}

export async function waitForCondition(checkCondition, sleepMilliseconds = 200, maxSleepCount = 10) {

    let sleepCount = 0;

    function sleep() {
        return new Promise(function (p) {
            window.setTimeout(function () {
                sleepCount++;
                p();
            }, sleepMilliseconds);
        });
    }

    async function wait() {

        if (sleepCount >= maxSleepCount) {
            console.log("waitForCondition Max Sleep Count Exceeded");
            return;
        }

        if (!accentUtils.isNull(checkCondition) && !checkCondition()) {


            console.log("waitForCondition Sleeping");
            await sleep();
            await wait();

        } else {
            console.log("waitForCondition No Sleep");
        }
    }

    
    await wait();

}

export function compareObjects(obj1, obj2) {

    if (accentUtils.isNull(obj1) && !accentUtils.isNull(obj2)) return false;
    if (!accentUtils.isNull(obj1) && accentUtils.isNull(obj2)) return false;

    if (from(Object.keys(obj1)).any(k => obj1[k] !== obj2[k])) return false;
    if (from(Object.keys(obj2)).any(k => obj1[k] !== obj2[k])) return false;

    return true;
}

export function isMobileNumber(number, countryCode) {
    try {
        var numUtils = PhoneNumberUtil.getInstance();
        return numUtils.getNumberType(numUtils.parse(number, countryCode)) === PhoneNumberType.MOBILE;
    } catch (e) {
        return false;
    }
}

export function isPhoneNumber(number, countryCode) {

    try {
        var numUtils = PhoneNumberUtil.getInstance();
        return numUtils.isPossibleNumber(numUtils.parse(number, countryCode));
    } catch (e) {
        return false;
    }
}


export const enqueue = (task) => {
    queue.add(task);
}

export function toastInfo(msg) {
    window.addToast(false, msg);
}
export function toastError(msg) {
    window.addToast(true, msg);
}

export const format = {

    formatDate : function (date) {
        if (accentUtils.isEmpty(date)) {
            return '';
        }

        if (typeof date === 'string') {
            date = Date.parse(date);
        }

        if (isNaN(date)) {
            return '';
        }

        return Intl.DateTimeFormat(accentUserLang).format(date);
    },

    formatDateTimeFileName: function(date) {


        if (accentUtils.isEmpty(date)) {
            return '';
        }

        if (typeof date === 'string') {
            date = Date.parse(date);
        }

        if (isNaN(date)) {
            return '';
        }

        date = new Date(date);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}-${minutes}-${seconds}`;
    },
    formatDuration: function (minutes) {
        const duration = Moment.duration(minutes, 'minutes');
        let formatted = [];
        if (duration.days() > 0) {
            formatted.push(duration.days() + ' d' + (duration.days() > 1 ? 's' : ''));
        }
        if (duration.hours() > 0) {
            formatted.push(duration.hours() + ' hr' + (duration.hours() > 1 ? 's' : ''));
        }
        if (duration.minutes() > 0) {
            formatted.push(duration.minutes() + ' min' + (duration.minutes() > 1 ? 's' : ''));
        }
        return formatted.join(', ');
    },
    formatTime: function (date, amPM = true, showSeconds = false) {
        if (accentUtils.isEmpty(date)) {
            return '';
        }

        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (isNaN(date)) {
            return '';
        }

        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: amPM
        };

        if (showSeconds) {
            options.second = '2-digit';
        }


        return new Intl.DateTimeFormat(accentUserLang, options).format(date);
    },


    formatDateTime: function (date, amPM = true, shortDate = true) {

        if (accentUtils.isEmpty(date)) {
            return '';
        }

        if (typeof date === 'string') {
            date = Date.parse(date);
        }

        if (isNaN(date)) {
            return '';
        }

        const options = {
            weekday: shortDate ? undefined : 'short', // "short", "long", or "narrow"
            year: 'numeric',
            month: shortDate ? 'numeric' : 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: amPM
        };


        return Intl.DateTimeFormat(accentUserLang, options).format(date);
    },
    formatDateMonthYear : function (date) {

        if (accentUtils.isEmpty(date)) {
            return '';
        }

        if (typeof date === 'string') {
            date = Date.parse(date);
        }

        if (isNaN(date)) {
            return '';
        }


        return Intl.DateTimeFormat(accentUserLang, { year: 'numeric', month: 'short' }).format(date);
    },

    formatDateDayMonth: function (date) {

        if (accentUtils.isEmpty(date)) {
            return '';
        }

        if (typeof date === 'string') {
            date = Date.parse(date);
        }

        if (isNaN(date)) {
            return '';
        }


        return Intl.DateTimeFormat(accentUserLang, { day: 'numeric', month: 'short' }).format(date);
    },

    formatDateDayOfWeekMonth: function (date) {

        if (accentUtils.isEmpty(date)) {
            return '';
        }

        if (typeof date === 'string') {
            date = Date.parse(date);
        }

        if (isNaN(date)) {
            return '';
        }


        return Intl.DateTimeFormat(accentUserLang, { weekday: 'short', day: 'numeric', month: 'short' }).format(date);
    },


    toUTC : function (date) {

        var dte = new Date(date);

        return new Date(dte.getTime() + (dte.getTimezoneOffset() * 60000));
    },

    formatCurrency: function (value, emptyIfZero) {

      
        if (accentUtils.isNull(value)) {
            return '';
        }

        if (Math.abs(value) < 0.01) {
            value = 0;
        }


        if (emptyIfZero && value === 0) {
            return '';
        }

        const currencyCode = localToCurrencyCode[accentUserLang] ?? localToCurrencyCode['en-AU'];

        return Intl.NumberFormat(accentUserLang, { style: 'currency', currency: currencyCode }).format(value);

    },

    getCurrencySymbol: function () {
        if (currencySymbol === null) {
            currencySymbol = new Intl.NumberFormat(accentUserLang, { style: 'currency', currency: localToCurrencyCode[accentUserLang] }).formatToParts().find(part => part.type === 'currency').value;
        }

        return currencySymbol;
     },

    formatNumber : function (value, emptyIfZero, minDescimals) {

        if (!isFinite(value) || isNaN(value)) {
            if (emptyIfZero) {
                return '';
            } else {
                return 0;
            }
        }


        if (accentUtils.isNull(value)) {
            return '';
        }

        if (emptyIfZero && value === 0) {
            return '';
        }

        minDescimals = 2 || minDescimals;


        return Intl.NumberFormat(accentUserLang, { currency: localToCurrencyCode[accentUserLang], minimumFractionDigits: minDescimals }).format(accentUtils.round(value));
    },

    formatInt: function (value, emptyIfZero) {

        if (!isFinite(value) || isNaN(value)) {
            if (emptyIfZero) {
                return '';
            } else {
                return 0;
            }
        }


        if (accentUtils.isNull(value)) {
            return '';
        }

        if (emptyIfZero && value === 0) {
            return '';
        }

        const minDescimals = 0;


        return Intl.NumberFormat(accentUserLang, { currency: localToCurrencyCode[accentUserLang], minimumFractionDigits: minDescimals }).format(accentUtils.round(value));
    },



    formatPercentage : function (value, emptyIfZero, maximumSignificantDigits) {

        if (!isFinite(value) || isNaN(value)) {
            if (emptyIfZero) {
                return '';
            } else {
                return 0;
            }
        }


        if (accentUtils.isNull(value)) {
            return '';
        }

        if (emptyIfZero && value === 0) {
            return '';
        }


        maximumSignificantDigits = 4 || maximumSignificantDigits;


        return Intl.NumberFormat(accentUserLang, { style: 'percent', currency: localToCurrencyCode[accentUserLang], maximumSignificantDigits: maximumSignificantDigits }).format(value);
    }


};


const internalFormat = format;



export class A extends React.PureComponent {

    constructor(props) {
        super(props);

        this.linkID = `link_${newGuid() }`;

        this.offset = {
            left: 0,
            top: 0,
        };

        this.state = {
            showContext: false
        };

        this.onClick = this.onClick.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.onAlert = this.onAlert.bind(this);
        this.onContextMenuSelect = this.onContextMenuSelect.bind(this);
        this.hasLinkID = this.hasLinkID.bind(this);
    }

    hasLinkID(linkID, target) {
        let element = target;
        while (element !== document.body) {
            if (element.hasAttribute('data-linkid') && element.getAttribute('data-linkid') === linkID) {
                return true;
            }
            element = element.parentNode;
        }
     
        return false;
    }


    onContextMenuSelect(e) {
        e.nativeEvent.preventDefault();
        this.setState({ showContext: false });

        window.open(this.props.href, '_blank')
    }

    onAlert(e) {
        if (e.target.hasAttribute('data-linkid') && e.target.getAttribute('data-linkid') === this.linkID) return;

        this.setState({ showContext: false })

    }

    handleContextMenu(e) {
        this.offset = {
            left: e.pageX,
            top: e.pageY,
        };
        e.preventDefault();
        this.setState({showContext : true});
    }

    onClick(e) {

        if (!this.hasLinkID(this.linkID,  e.target)) return;
        
        goTo(this.props.href).then(allow => {

            if (!allow) return;

            if (this.props.onClick) {
                this.props.onClick(e);
            }

        });

    }

    render() {
        return <div
            {...this.props}
            data-linkid={ this.linkID}
            className={this.props.className ?? 'acc-link'}
            style={{ cursor: "pointer", ...this.props.style }}
            onClick={this.onClick}
            onContextMenu={this.handleContextMenu}
        >
            {this.props.children}
            
            <Popup show={this.state.showContext} offset={this.offset}>
                < OutsideAlerter onAlert={this.onAlert}>
                    <Menu
                        onSelect={ this.onContextMenuSelect }
                        vertical={true}
                        style={{
                            display: "inline-block",
                        }}
                    >
                        <MenuItem text="Open in new tab" />
                    </Menu>
                </OutsideAlerter>
            </Popup>
        </div>;
    }

}


export const t = (str, paramsObj) => {

    if (!paramsObj) {
        paramsObj = {};
    }

    paramsObj.accentCurrencySymbol = format.getCurrencySymbol();
    
    return i18n.t(str, paramsObj);

}
 
export function from(array) {
    return Enumerable.from(array);
}

export function def(value, defaultValue) {

    if (accentUtils.isNull(value)) {
        return defaultValue;
    }

    return value;

};

export function newGuid() {    
    return uuidv4();
};



export function showOK(title, body, buttons, asTextArea, className, noCancel, maxWidth) {
        return window.insytwebOKDlg.open(title, body, buttons, asTextArea, className, noCancel, maxWidth);
};



export function showDialog(dialog, onShown) {

    let closeDlg = null;

    return new Promise(p => {

        const isFunction = typeof dialog === "function";

        const props = {
            onClose: e => {
                closeDlg().then(() => {
                    p(e);
                });
                
            }
        };

        closeDlg = window.insytwebAppDlg.setDisplayItem(
            isFunction ? dialog(props) : React.cloneElement(dialog, props),
            onShown,
            e => {            
                p({canceled: true, forceCanceled: true});
            }
        );

    });

    
};

export function reloading() {
    const isReloading = localStorage.getItem("reloading");
    if (isReloading !== undefined) {
        if (isReloading !== null) {
            return isReloading === "true";
        }
    }
    return false;
}
export function clearReloading() {
    localStorage.setItem("accent_reloading", false);
}

export function getLoginParameters() {
    var deviceId = localStorage.getItem("accent_context");
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem("accent_context", deviceId);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const stateParam = urlParams.get('state');
    const impersonateEmail = urlParams.get('impersonateEmail');
   return { accentDeviceId: deviceId, redirectState: stateParam, impersonateEmail:impersonateEmail };
    
};

export function showWait(ref) {
    return window.insytwebAppDlg.showWait(ref);
};

export function processVersion(version) {

    if (!accentUtils.isEmpty(version)) {

        if (!accentUtils.isNull(window.insyteAppVersion) && window.insyteAppVersion !== version && window.insytwebOKDlg) {

            showOK("application_strings.application.dialogs.newVersionTitle", "application_strings.application.dialogs.newVersionMsg", ["application_strings.application.buttons.ok"], false, null, true).then(function (btn) {
                window.location.hash = "";
                window.location.reload();
            });

        }
    }
};

let oneTimeCache = {};

export function cacheAdd(item, key=null) {
    const itemID =  key??`c${newGuid()}`;

    oneTimeCache[itemID] = item;

    return itemID;
};

export function cacheGet(itemID) {

    if (accentUtils.isNull(itemID)) return null;

    const res = oneTimeCache[itemID];

    return res;
};

export function cacheRemove(itemID) {

    if (accentUtils.isNull(itemID)) return null;

    const res = oneTimeCache[itemID];

    delete oneTimeCache[itemID];

    return res;
};

export function cancelAllDialogs() {
    if (window.insytwebAppDlg) {
        return window.insytwebAppDlg.cancelAllDialogs();
    }
}


export const toolbarActionStates = {
    saving : "SAVING",
    closing : "CLOSING",
    undoing : "UNDOING"
};



let toolbarAction = null;

export function getToolbarActionState() {
    return toolbarAction;
}

export function setToolbarActionState(state) {
    toolbarAction = state;
}


let navigationKey = 0;

function incrementNavigationKey() {
    navigationKey = (navigationKey + 1) % 10000000000000;
}

export function getNavigationKey() {
    return navigationKey;
}

export function goBack() {

    return new Promise(p => {

        const canSave = window.accentAutoSave ? window.accentAutoSave({navigatingBack: true}) : Promise.resolve({ allow: true });


        canSave.then(saveResult => {

            if (saveResult.allow) {
                incrementNavigationKey();    

                cancelAllDialogs().then(() => {
                    navigateBackMainWindow();
                });                
            }

            p(saveResult.allow);
        });


    });

    
}

export function goTo(path, additionalViewData = null) {

    return new Promise(p => {

        const canSave = window.accentAutoSave ? window.accentAutoSave() : Promise.resolve({ allow: true });


        canSave.then(saveResult => {

            if (saveResult.allow) {

                cancelAllDialogs().then(() => {

                    if (!accentUtils.isNull(additionalViewData)) {
                        cacheAdd(additionalViewData, "AdditionalViewData");
                    }


                    const finalPath = path.startsWith("/") ? path : `/${path}`;

                    incrementNavigationKey();

                    if (saveResult.replace || finalPath === window.accentRouter.location.pathname) {
                        window.accentRouter.replace(finalPath);
                    } else {
                        window.accentRouter.push(finalPath);
                    }


                });

                
            }

            p(saveResult.allow);
        });


    });

    
    
};

export async function goToCreated(path, entity, additionalViewData = null) {
    const cacheID = cacheAdd({ entity, created: true });

    // Ensure the path starts with "/"
    if (!path.startsWith("/")) {
        path = `/${path}`;
    }

    // Split the path from any existing query string
    const [basePath, queryString] = path.split('?');

    // Append /NEW to the basePath
    const newPath = `${basePath}/NEW`;

    // Construct the final query string
    // Check if the original path included a query string, and append it with the new parameter
    const finalQueryString = queryString ? `${queryString}&newEntity=${cacheID}` : `newEntity=${cacheID}`;

    // Combine newPath with the finalQueryString
    const finalPath = `${newPath}?${finalQueryString}`;

    goTo(finalPath, additionalViewData);
}





export async function goToNew(path, recordType, defaults, parent = null, custom = null, additionalViewData = null, onCreate = null) {

    

    const cacheID = cacheAdd({ recordType, defaults, parent, custom, onCreate });

    if (path.startsWith("/"))
        goTo(`${path}/NEW?newEntity=${cacheID}`, additionalViewData);
    else
        goTo(`/${path}/NEW?newEntity=${cacheID}`, additionalViewData);




};



export const useMousePos = () => {

    const [position, setPosition] = React.useState({ x: 0, y: 0 });

    React.useEffect(() => {
        const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
        window.addEventListener("mousemove", setFromEvent);

        return () => {
            window.removeEventListener("mousemove", setFromEvent);
        };
    }, []);

    return position;



};



export function getColorAlpha(color, opacity){

    if (accentUtils.isEmpty(color)) return color;

    return alpha(color, opacity);

};

export function getColorLighter(color, perc){

    if (accentUtils.isEmpty(color)) return color;

    return lighten(color, perc);

};

export function getColorDarker(color, perc) {
    if (accentUtils.isEmpty(color)) return color;

    return darken(color, perc);
};


export const useMouseUp = () => {

    const [up, setUp] = React.useState(false);

    React.useEffect(() => {
        const setUpEvent = (e) => setUp(true);
        const setDownEvent = (e) => setUp(false);
        window.addEventListener("mouseup", setUpEvent);
        window.addEventListener("mousedown", setDownEvent);

        return () => {
            window.removeEventListener("mouseup", setUpEvent);
            window.removeEventListener("mousedown", setDownEvent);
        };
    }, []);

    return up;



};


class ViewStateHelper {

    constructor() {
        this.state = {};
        this.stateDates = {};

        this.getState = this.getState.bind(this);
        this.setState = this.setState.bind(this);
        this.clearState = this.clearState.bind(this);
    }

    getState(id, defaultVal) {
        if (accentUtils.isNull(this.state[id])) {
            this.state[id] = defaultVal;
        }

        return this.state[id];
    }

    setState(id, value) {
        this.state[id] = value;

    }

    clearState(id) {
        this.state[id] = null;

    }
}



export async function InitializeHelpers() {

    const user = getUser();

    if (!accentUtils.isNull(user)) {
        accentUserLang = user.Locale;
    }

    await i18n.init({
        lng: 'en',
        debug: false,
        resources: window.myinsyteresources,
        fallbackLng: false,
        interpolation: {
            escapeValue: false,
            format: (value, formatStr, lng) => {

                if (formatStr) {

                    if (formatStr === "dateTime") {
                        return internalFormat.formatDateTime(value);
                    } else {
                        return internalFormat.formatDate(value);
                    }

                }
                return value;
            }
        }
    }, function (err, t) {

    });


}


const viewStateHelper = new ViewStateHelper();

export { accentUtils, formHelper, accentUserLang, memoize, viewStateHelper };


window.memoize = memoize;
