import React from 'react'
import { TextArea } from '@progress/kendo-react-inputs'
import { accentUtils, t, newGuid } from '../../services/HelperService'
import { AccentTooltip } from '../AccentTooltip'
import AccentIcons from '../../icons/Icons'
import { AccentLabel } from '../AccentLabel'

export class AccentTextAreaUnbound extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();


        this.id = this.props.id;

        if (accentUtils.isNull(this.id)) {
            this.id = 'id_' + newGuid();
        }

        var value = this.props.defaultValue;

        this.state = { value: value};

        this.onChange = this.onChange.bind(this);
        this.toggleHide = this.toggleHide.bind(this);

    }

    onChange(e) {

        if (!accentUtils.isNull(this.props.onChange)) {
            this.props.onChange(accentUtils.isNull(e.value) ? null : e.value);
        }

        this.setState({ value: e.value })
    }

    toggleHide() {
        this.setState({ hideText: !this.state.hideText });
    }

    render() {

        const formatValidationError = accentUtils.isNull(this.props.onValidate) ? null : this.props.onValidate(this.state.value);
        const isFormatValid = accentUtils.isEmpty(formatValidationError);


        var isValid = isFormatValid && (!this.props.required || !accentUtils.isEmpty(this.state.value));

        var className = isValid ? "accentTextAreaUnbound" : "accentTextAreaUnbound invalid";


        
        const input = <TextArea
            style={ this.props.style}
            className={className}
            ref={this.ctrl}
            fillMode="outline"
            maxLength={this.props.max}
            readOnly={this.props.disabled}
            rows={this.props.rows}
            label={this.props.label}
            onChange={this.onChange}
            defaultValue={this.state.value ?? ""}
            />;
        
        const labelCtrl = accentUtils.isEmpty(this.props.label) ? input : <AccentLabel text={t(this.props.label)}>{input}</AccentLabel>

        

        const control = <div className="acc-unbound-txt-ctrl" data-tagname={this.props.dataTagName}>
            {!isFormatValid && <AccentTooltip target={this.id} text={formatValidationError} />}
            {labelCtrl}
        </div>;

        return control;
    }

}
