import * as React from 'react'
import { Settings } from '../../services/AppSettingsService';
import { queryNoTracking } from '../../services/DataService';
import { accentUtils, def, from } from '../../services/HelperService';
import { AccentSpinner } from '../AccentSpinner';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';


const query_PrintSetting_Select = queryNoTracking("PrintSetting_Select");


export const PrintSettingSelection = props => {

    const defaultValue = def(props.defaultValue, Settings.GetAppSettings().DefaultPrintSettings);
    const [data, setData] = React.useState(null);

    const onChanged = React.useCallback(e => {
        props.onChange(e);

        Settings.SetAppSettings({ DefaultPrintSettings: e });

    }, [props, data]);


    React.useEffect(e => {

        query_PrintSetting_Select.getAllWithCache().then(r => {


            const defaultExists = !accentUtils.isEmpty(defaultValue) && from(r).any(i => i.ID == defaultValue);

            setData(r);

            if (props.onReady)
                props.onReady(defaultExists ? defaultValue : null);

        });

    }, []);


    if (data == null) return <AccentSpinner />;

    return <AccentComboUnbound displayField="Name" valueField="ID" defaultValue={defaultValue} onChange={onChanged} label="Product Settings" data={data} />;

};
