import React from 'react';
import { queryNoTracking } from "./components/services/DataService";
import { accentUtils } from './components/services/HelperService';
import { Helper, MessageTypes } from './components/services/MessageService';

export const query_Calendar_UserList = queryNoTracking("Calendar_UserList", undefined, undefined, undefined, { permanent: true, allowCaching: true });
export const query_User_TeamFilter = queryNoTracking("User_TeamFilter", undefined, undefined, undefined, { permanent: true, allowCaching: true });
export const query_Calendar_BusinessUnitList = queryNoTracking("Calendar_BusinessUnitList", undefined, undefined, undefined, { permanent: true, allowCaching: true });
export const query_GetScriptControlData = queryNoTracking("GetScriptControlData", undefined, undefined, undefined, { permanent: true, allowCaching: true });
export const query_UnitTypes = queryNoTracking("PL_UnitTypes")

export function registerPermanentQueries() {
    Helper.register('PermanentQueries', MessageTypes.PermanentCacheClear, clearPermanentQueryCache);
}


export function clearPermanentQueryCache(e) {
    query_Calendar_UserList.clearCache();
    query_User_TeamFilter.clearCache();
    query_Calendar_BusinessUnitList.clearCache();    
}

export function usePermanentQuery(query) {

    const [data, setData] = React.useState(null);


    React.useEffect(() => {

        query.getAllWithCache().then(res => {
            setData(res);
        });

    }, []);

    return {
        data: accentUtils.isNull(data) ? [] : data.map(d => ({ ...d })),
        loading: accentUtils.isNull(data)
    };

}


