import * as React from 'react';
import { accentUtils, from, showDialog } from '../../services/HelperService';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { useValidation, setOptionValueChanged, isReadOnly, useChanged, toGuid, fabric_GetRailroadingOption, fabric_GetIgnoreFabricWidth, fabric_GetIgnoreRailroadedFabricWidth, fabric_GetAllowContinuous, fabric_GetDropAdjustment, fabric_GetWidthAdjustment, fabric_GetAllowRailroading, fabric_GetCustomFabric, fabric_OrderLineOptionSet, fabric_OrderLineOptionClear, getProductOptionValue, getOptionModel, compareValues } from './ProductConfiguratorShared';
import { FabricDetailsDlg } from './SelectFabricDlg';



export const ConfigTwoLineFabric = props => {

    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);

    const additionalValue = optionModel.OrderLineOption.AdditionalValue;
    
    const validation = useValidation(props.optionID, "ConfigTwoLineFabric");
    const changed = useChanged(props.optionID, "ConfigTwoLineFabric", true);

    const value = getProductOptionValue(optionModel);
    const fabric = React.useMemo(() => accentUtils.isNull(value) ? null : optionModel.GetLinkedProduct(value?.LinkedProductID), [value, optionModel]);
    const [fabricName, setFabricName] = React.useState(fabric?.Fabric);
    const [fabricSupplier, setFabricSupplier] = React.useState(optionModel.OrderLineOption?.SupplierID?.toString());
    const [fabricCustomValue, setFabricCustomValue] = React.useState(!(value?.CustomValue ?? false) ? accentUtils.getEmptyGuid() : value.ID.toString());
    const [fabricColor, setFabricColor] = React.useState(!(value?.CustomValue ?? false) ? value?.ID?.toString() ?? accentUtils.getEmptyGuid() : accentUtils.getEmptyGuid());

    const [lastChangeID, setLastChangeID] = React.useState(null);

    const requireClearFabric = React.useRef();
    requireClearFabric.current = lastChangeID !== changed.changeID

    const [fabricRefreshKey, setFabricRefreshKey] = React.useState(0);

    console.log(changed.changeID);
    console.log(value);


    React.useEffect(() => {
        setLastChangeID(changed.changeID);
        
    }, [changed.changeID])


    React.useEffect(() => {


        const currentFabric =accentUtils.isNull(value) ? null : optionModel.GetLinkedProduct(value?.LinkedProductID);
        const currentFabricName = currentFabric?.Fabric;
        const currentFabricSupplier = optionModel.OrderLineOption?.SupplierID?.toString();
        const currentFabricCustomValue = !(value?.CustomValue ?? false) ? accentUtils.getEmptyGuid() : value.ID.toString();
        const currentFabricColor = !(value?.CustomValue ?? false) ? value?.ID?.toString() ?? accentUtils.getEmptyGuid() : accentUtils.getEmptyGuid();


        const keepExistingFabricName = currentFabric === null && !requireClearFabric.current;




        setFabricName(x => {

            if (keepExistingFabricName) return x;


            if (x !== currentFabricName) {

                setFabricRefreshKey(k => k + 1);

                return currentFabricName;
            }

            return x;

        });

        

        setFabricSupplier(x => {

            if (keepExistingFabricName) return x;

            if (x !== currentFabricSupplier) {

                setFabricRefreshKey(k => k + 1);

                return currentFabricSupplier;
            }

            return x;

        });            



        setFabricCustomValue(x => {

            if (x !== currentFabricCustomValue) {

                setFabricRefreshKey(k => k + 1);

                return currentFabricCustomValue;
            }

            return x;

        });            


        setFabricColor(x => {

            if (x !== currentFabricColor) {

                setFabricRefreshKey(k => k + 1);

                return currentFabricColor;
            }

            return x;

        });            

        

    }, [optionModel, additionalValue, value, requireClearFabric]);




    const fabricColors = React.useMemo(() => GetQuery(ctx.config, optionModel, false).select(f => ({ ...f, Key: getFabricKey(f)})).toArray(), [ctx, optionModel, changed.hasChanged]);
    const fabrics = React.useMemo(() => from(fabricColors).groupBy(x => x.Key).select(g => ({ ID: g.key(), Description: g.key().split('__')[0] })).toArray(), [fabricColors]);
    const colors = React.useMemo(() => accentUtils.isNull(fabricName) ? [] : from(fabricColors).where(x => x.LinkedProduct?.Fabric === fabricName && x.Supplier.ID.toString() === fabricSupplier).select(f => ({...f, ID: f.Value.ID.toString(), Description: f.LinkedProduct.FabricColour })).toArray(), [fabricColors, fabricName, fabricSupplier]);


    const readOnly = isReadOnly(optionModel);

    const onChangeFabric = React.useCallback(e => {

        const val = e.target.value;

        if (accentUtils.isEmpty(val)) {
            //setSelectedFabric(null);
            setFabricName(null);
            setFabricSupplier(null);
            setFabricCustomValue(null);

            fabric_OrderLineOptionClear(optionModel);
            optionModel.UpdateFrom(toGuid(accentUtils.getEmptyGuid()), null);

            setOptionValueChanged(ctx, optionModel, changed.tag);

        } else {

            const parts = val.split("__");


            
            if (!accentUtils.isEmpty(parts[0]) && !accentUtils.isEmpty(parts[1]) && !accentUtils.isEmpty(parts[2])) {

                const id = toGuid(parts[2]);
                const value = optionModel.GetProductOptionValue(id);

                fabric_OrderLineOptionSet(optionModel, value, toGuid(parts[1]));
                showDialog(<FabricDetailsDlg readOnly={readOnly} ctx={ctx} optionModel={optionModel} productOptionValue={value} />).then(res => {

                    if (!res.canceled) {

                        const actualFabric = optionModel.GetLinkedProduct(res.productOptionValue?.LinkedProductID);

                        setFabricName(actualFabric?.Fabric);
                        setFabricSupplier(res.customFabric?.Supplier?.toString());
                        setFabricCustomValue(res.productOptionValue?.ID?.toString());

                    }

                });

            } else {


                setFabricName(parts[0]);
                setFabricSupplier(parts[1]);
                setFabricCustomValue(parts[2]);


                fabric_OrderLineOptionClear(optionModel);
                optionModel.UpdateFrom(toGuid(accentUtils.getEmptyGuid()), null);
                setOptionValueChanged(ctx, optionModel, changed.tag);
                setFabricColor(null);
            }


           
        }

    }, [ctx, optionModel, setFabricName, setFabricSupplier, setFabricCustomValue, setFabricColor, readOnly, changed.tag]);


    const onChangeColor = React.useCallback(e => {

        const val = e.target.value;

        if (accentUtils.isEmpty(val)) {
            fabric_OrderLineOptionClear(optionModel);
            optionModel.UpdateFrom(toGuid(accentUtils.getEmptyGuid()), null);
        } else {

            const id = toGuid(val);
            const supplierID = toGuid(fabricSupplier);

            const value = optionModel.GetProductOptionValue(id);

            fabric_OrderLineOptionSet(optionModel, id, supplierID);
            const fab = fabric_GetCustomFabric(optionModel, value);
            optionModel.UpdateFrom(id, fab);

        }


        setOptionValueChanged(ctx, optionModel, changed.tag);

        setFabricColor(val);

    }, [ctx, optionModel, fabricSupplier, changed.tag, setFabricColor]);


    optionModel.HasValue = React.useCallback(() => {
        var v = optionModel.GetProductOptionValue(optionModel.OrderLineOption.ProductOptionValueID);
        return v != null && !v.CustomValue || v != null && window.SoftFurnishings.FabricHelper.GetCustomFabric(optionModel, v).IsValid(true, true);
    }, [optionModel]);



    optionModel.Validate = React.useCallback(() => {

        let errMsg = "";

        if (compareValues(optionModel.OrderLineOption.ProductOptionValueID, toGuid(accentUtils.getEmptyGuid()))) {
            errMsg = "Fabric Details is required";
        }

        if (errMsg == "") {

            var val = optionModel.GetProductOptionValue(optionModel.OrderLineOption.ProductOptionValueID);
            if (val != null && val.CustomValue) {
                var cf = window.SoftFurnishings.FabricHelper.GetCustomFabric(optionModel, val);
                if (!cf.IsValid(true, true)) {
                    errMsg = "Custom Fabric Details is required";
                }
            }
        }

        return errMsg;
    }, [optionModel]);


    

    const defaultFabric = accentUtils.isNull(fabricName) ? null : `${fabricName}__${fabricSupplier}__${fabricCustomValue}`;
    

    const dataFabric = fabrics;
    const dataColor = colors;

    const isCustomFabric = !accentUtils.isEmpty(fabricCustomValue);

    const showColor = !accentUtils.isEmpty(fabricName) && !isCustomFabric;        

    const isRequiredFabric = validation.required && accentUtils.isEmpty(defaultFabric);
    const isRequiredColor = validation.required && accentUtils.isEmpty(fabricColor);

    return <div className="config-control config-two-line-fabric">
        <div className="config-two-line-fabric-fabric">

            <select
                key={`${changed.changeID}_${fabricRefreshKey}`}
                className={`config-select ${isRequiredFabric ? "required" : ""}`}
                onChange={onChangeFabric}
                disabled={readOnly}
                defaultValue={defaultFabric}
            >
                <option value={ accentUtils.getEmptyGuid() }></option>
                {dataFabric.map(x => <option key={`k_${x.ID}`} value={x.ID}>{x.Description}</option>)}
            </select>
        </div>
        <div className="config-two-line-fabric-color">
            {showColor && <select
                key={`${changed.changeID}_${fabricName}_${fabricSupplier}_${fabricCustomValue}_${fabricRefreshKey}`}
                className={`config-select ${isRequiredColor ? "required" : ""}`}
                onChange={onChangeColor}
                disabled={readOnly}
                defaultValue={fabricColor}
            >
                <option value={accentUtils.getEmptyGuid()}></option>
                {dataColor.map(x => <option key={`k_${x.ID}`} value={x.ID}>{x.Description}</option>)}
            </select>}
        </div>
    </div>;
};


function getFabricKey(queryItem) {

    return `${queryItem.LinkedProduct?.Fabric}__${queryItem.Supplier?.ID?.toString()}__${(queryItem.Value?.CustomValue ?? false) ? queryItem.Value?.ID.toString() : "00000000-0000-0000-0000-000000000000"}`;
    
}

function GetQuery(config, optionModel, allowCurrentValue) {

    const railroadingValue =
        config.GetOptionValue$1(fabric_GetRailroadingOption(optionModel));

    const railRoading = window.SoftFurnishings.FabricQuery.GetBoolValueFromOptionValue(railroadingValue);


    return window.SoftFurnishings.FabricQuery.QueryFabrics(
        optionModel,
        fabric_GetIgnoreFabricWidth(optionModel),
        allowCurrentValue,
        fabric_GetIgnoreRailroadedFabricWidth(optionModel),
        railRoading,
        fabric_GetDropAdjustment(optionModel),
        fabric_GetWidthAdjustment(optionModel),
        fabric_GetAllowContinuous(optionModel),
        fabric_GetAllowRailroading(optionModel)
    );

}