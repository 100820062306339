const GRIDS = {
	"SalesSkill": {
		"Default": [
			{
				"fieldName": "Representative",
			},
			{
				"fieldName": "ProductOfInterest",
			},
			{
				"fieldName": "Notes",
				details: {}
			},
		]
	},
	"FitterSkill": {
		"Default": [
			{
				"fieldName": "Representative",
			},
			{
				"fieldName": "ProductID",
			},
			{
				"fieldName": "Notes",
				details: {}
			},
		]
	},
	"PostcodeArea": {
		"Default": [
			{
				"fieldName": "ID",
				"caption": "Postcode Area",
				"linkEntity": "PostcodeArea",
			},
			{
				"fieldName": "AreaID",
				"caption": "Area ID",
				"linkEntity": "Area",
				"linkEntityIDField": "AreaID"
			},
			{
				"fieldName": "SalesArea",
				"linkEntity": "Area",
				"linkEntityIDField": "AreaID"
			},
			{
				"fieldName": "InstallArea",
				"linkEntity": "Area",
				"linkEntityIDField": "AreaID"
			},
			{
				"fieldName": "BusinessUnit",
			},
			{
				"fieldName": "State",
			},
			{
				"fieldName": "City"
			},
			{
				"fieldName": "Postcode"
			}
		]
	},
	"ProductSupplier": {
		"Default": [
			{
				"fieldName": "Supplier",
				"caption": "Supplier",
				"linkEntity": "Supplier",
				"linkEntityIDField": "SupplierID"
			},
			{
				"fieldName": "Active",
				"requireTranslate": true,
				"fieldType": "check"
			},
			{
				"fieldName": "IsDefault",
				"requireTranslate": true,
				"fieldType": "check"
			},
			{
				"fieldName": "SendUBL",
				"requireTranslate": true,
				"fieldType": "check"
			},
			{
				"fieldName": "OutOfStock",
				"requireTranslate": true,
				"fieldType": "check"
			},
			{
				"fieldName": "InStockETA",
				"requireTranslate": true,
				"fieldType": "date"
			},
			{
				"fieldName": "LeadTime",
				"requireTranslate": true,
			},
			{
				"fieldName": "ReworkLeadTime",
				"requireTranslate": true,
			}
		]
	},	"CompanyPricelistMappingGrid": {
		"Default": [
			{
				"fieldName": "PriceList",
				"linkEntity": "CompanyPriceMapping"
			},
			{
				"fieldName": "Product"
			},
			{
				"fieldName": "Supplier"
			},
		]
	},
	"CommissionAccountTransactionGrid": {
		"Default": [
			{
				"fieldName": "Plan"
			},
			{
				"fieldName": "Run"
			},
			{
				"fieldName": "Date",
				"fieldType": "date"
			},
			{
				"fieldName": "User"
			},
			{
				"fieldName": "Type",
				"requireTranslate": true
			},
			{
				"fieldName": "Description"
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "Amount",
				"fieldType": "currency"
			},
			{
				"fieldName": "Notes",
				"details": {
					"lookupEntity": "CommissionAccountTransactionGrid"
				}
			}
		]
	},
	"Representative_Grid": {
		"Default": [
			{
				"fieldName": "FullName",
				"linkEntity": "Representative"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
				"requireTranslate": true
			},
			{
				"fieldName": "DefaultTeam"
			},
			{
				"fieldName": "DefaultBusinessUnit"
			},
			{
				"fieldName": "Email"
			}
		]
	},
	"TemplateResponseGrid": {
		"Default": [
			{
				"fieldName": "ID",
				"linkEntity": "TemplateResponse"
			},
			{
				"fieldName": "Condition"
			},
			{
				"fieldName": "Value"
			},
			{
				"fieldName": "Action"
			}
		]
	},
	"PurchaseOrderGrid": {
		"ContextKey": "PurchaseOrder",
		"Default": [
			{
				"fieldName": "OrderNumber",
				"linkEntity": "SupplyJob"
			},
			{
				"fieldName": "CustomerReference"
			},
			{
				"fieldName": "Rep"
			},
			{
				"fieldName": "OrderDate",
				"fieldType": "date"
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "ETADate",
				"fieldType": "date"
			},
			{
				"fieldName": "Supplier"
			},
			{
				"fieldName": "CostPrice",
				"fieldType": "currency"
			}
		],
		"OverDue": [
			{
				"fieldName": "OrderNumber",
				"linkEntity": "SupplyJob"
			},
			{
				"fieldName": "OrderDate",
				"fieldType": "date"
			},
			{
				"fieldName": "ETADate",
				"fieldType": "date"
			},
			{
				"fieldName": "Supplier"
			},
			{
				"fieldName": "CostPrice",
				"fieldType": "currency"
			}
		]
	},
	"TOTPDeviceGrid": {
		"Default": [
			{
				"fieldName": "Type"
			},
			{
				"fieldName": "Id"
			}
		]
	},
	"SalesDiscountRuleBusinessUnitGrid": {
		"Default": [
			{
				"fieldName": "BusinessUnit",
				"linkEntity": "SalesDiscountRule"
			},
			{
				"fieldName": "MinimumMargin",
				"fieldType": "decimal"
			},
			{
				"fieldName": "MinimumMarkup",
				"fieldType": "decimal"
			}
		]
	},
	"SalesDiscountRuleUserGrid": {
		"Default": [
			{
				"fieldName": "User",
				"linkEntity": "SalesDiscountRule"
			},
			{
				"fieldName": "MinimumMargin",
				"fieldType": "decimal"
			},
			{
				"fieldName": "MinimumMarkup",
				"fieldType": "decimal"
			}
		]
	},
	"CompanyGrid": {
		"Default": [
			{
				"fieldName": "Name",
				"linkEntity": "Company"
			},
			{
				"fieldName": "Type"
			},
			{
				"fieldName": "Phone"
			},
			{
				"fieldName": "Fax"
			},
			{
				"fieldName": "Mobile"
			},
			{
				"fieldName": "Email"
			},
			{
				"fieldName": "WWW"
			},
			{
				"fieldName": "AddressLines"
			},
			{
				"fieldName": "City"
			},
			{
				"fieldName": "State"
			},
			{
				"colIndex": 10,
				"fieldName": "Postcode"
			},
			{
				"colIndex": 11,
				"fieldName": "InternalNotes",
				"details": {
					"lookupEntity": "CompanyGrid"
				}
			}
		]
	},
	"DocumentGrid": {
		"Default": [
			{
				"fieldName": "Uri",
				"caption": " ",
				"defaultWidth": 100,
				"allowFilter": false
			},
			{
				"fieldName": "Description",
				"linkEntity": "DocumentView",
				"linkEntityIDField": "ID"
			},
			{
				"fieldName": "Date",
				"fieldType": "date"
			},
			{
				"fieldName": "Version"
			},
			{
				"fieldName": "Type"
			},
			{
				"fieldName": "Job",
				"encoded": false
			},
			{
				"fieldName": "JobLine",
				"encoded": false
			},
			{
				"fieldName": "SendToCustomer",
				"requireTranslate": true,
				"fieldType": "check"
			},
			{
				"fieldName": "SendToSupplier",
				"requireTranslate": true,
				"fieldType": "check"
			},
			{
				"fieldName": "Signed",
				"requireTranslate": true,
				"fieldType": "check"
			}
		]
	},
	"SupplySheetTemplate": {
		"Default": [
			{
				"fieldName": "Uri",
				"caption": " ",
				"defaultWidth": 100,
				"allowFilter": false
			},
			{
				"fieldName": "Description",
				"linkEntity": "SupplySheetTemplate",
				"linkEntityIDField": "ID"
			},
			{
				"fieldName": "Date",
				"fieldType": "date"
			},
			{
				"fieldName": "Version"
			},
			{
				"fieldName": "Supplier",
				"encoded": false
			}
		]
	},
	"CommunicationGrid": {
		"Default": [
			{
				"fieldName": "Date",
				"fieldType": "date"
			},
			{
				"fieldName": "Type",
				"requireTranslate": true
			},
			{
				"fieldName": "Category",
				"requireTranslate": true
			},
			{
				"fieldName": "Description"
			},
			{
				"fieldName": "ContactPoint"
			},
			{
				"fieldName": "Outcome"
			},
			{
				"fieldName": "User",
			},
			{
				"fieldName": "Job",
				"linkEntity": "Job"
			},
			{
				"fieldName": "Invoice",
				"linkEntity": "Invoice"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"colIndex": 10,
				"fieldName": "SupplyJob",
				"linkEntity": "SupplyJob"
			},
			{
				"colIndex": 11,
				"fieldName": "Supplier"
			}
		]
	},
	"StatementGrid": {
		"ContextKey": "Statement",
		"Default": [
			{
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "Email"
			},
			{
				"fieldName": "Address"
			},
			{
				"fieldName": "OutstandingBalance",
				"fieldType": "currency"
			},
			{
				"fieldName": "Overdue",
				"fieldType": "currency"
			}
		]
	},
	"OrderLineGrid": {
		"Default": [
			{
				"fieldName": "OrderLineNo",
				"linkEntity": "SupplyJobLine"
			},
			{
				"fieldName": "ProductName"
			},
			{
				"fieldName": "Qty"
			},
			{
				"fieldName": "Width"
			},
			{
				"fieldName": "Drop"
			},
			{
				"fieldName": "LocationOther",
				"caption": "Location"
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "CostPrice",
				"fieldType": "currency",
				"aggregate": "sum"
			},
			{
				"fieldName": "ValidationErrors",
				"class": "acc-error-cell"
			}
		]
	},
	"LookupTableItemGrid": {
		"Default": [
			{
				"fieldName": "ID",
				"linkEntity": "LookupTableItem"
			},
			{
				"fieldName": "From"
			},
			{
				"fieldName": "To"
			},
			{
				"fieldName": "Text"
			},
			{
				"fieldName": "ProductName"
			},
			{
				"fieldName": "RepresentativeName"
			},
			{
				"fieldName": "Value",
				"encoded": false
			},
			{
				"fieldName": "Notes",
				"encoded": false
			}
		]
	},
	"ActivityGrid": {
		"ContextKey": "Activity",
		"Default": [
			{
				"fieldName": "Subject",
				"linkEntity": "Activity"
			},
			{
				"fieldName": "Start",
				"fieldType": "date"
			},
			{
				"fieldName": "ActivityType"
			},
			{
				"fieldName": "ActivityCategory"
			},
			{
				"fieldName": "Closed",
				"fieldType": "check"
			},
			{
				"fieldName": "Representative"
			},
			{
				"fieldName": "Contact",
				"encoded": false
			},
			{
				"fieldName": "Company",
				"encoded": false
			},
			{
				"fieldName": "Opportunity",
				"encoded": false
			},
			{
				"fieldName": "Notes",
				"details": {
					"lookupEntity": "ActivityGrid"
				}
			}
		]
	},
	"ExternalLeadGrid": {
		"ContextKey": "WebLead",
		"Default": [
			{
				"fieldName": "Date",
				"fieldType": "date"
			},
			{
				"fieldName": "LeadSource"
			},
			{
				"fieldName": "ContactFirstName"
			},
			{
				"fieldName": "ContactLastName"
			},
			{
				"fieldName": "ContactMobile"
			},
			{
				"fieldName": "ContactWorkPh"
			},
			{
				"fieldName": "ContactHomePh"
			},
			{
				"fieldName": "ContactEmail"
			},
			{
				"fieldName": "SnoozeDate",
				"fieldType": "datetime"
			}
		]
	},
	"OpportunityGrid": {
		"ContextKey": "Opportunity",
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "Opportunity"
			},
			{
				"fieldName": "LeadDate",
				"fieldType": "date"
			},
			{
				"fieldName": "Forecast",
				"fieldType": "check"
			},
			{
				"fieldName": "LeadSource"
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "PipelineStage",
				"requireTranslate": true
			},
			{
				"fieldName": "ConfidenceLevel"
			},
			{
				"fieldName": "Representative"
			},
			{
				"fieldName": "BusinessUnit"
			},
			{
				"fieldName": "SalesArea"
			},
			{
				"fieldName": "InstallArea"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"colIndex": 10,
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"colIndex": 11,
				"fieldName": "Notes",
				"details": {
					"lookupEntity": "OpportunityGrid"
				}
			}
		],
		"Leads": [
			{
				"fieldName": "SnoozeDate",
				"fieldType": "datetime"
			},
			{
				"fieldName": "Description",
				"linkEntity": "Opportunity",
				"linkEntityIDField": "ID"
			},
			{
				"fieldName": "LeadDate",
				"fieldType": "date"
			},
			{
				"fieldName": "Forecast",
				"fieldType": "check"
			},
			{
				"fieldName": "LeadSource"
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "PipelineStage",
				"requireTranslate": true
			},
			{
				"fieldName": "ConfidenceLevel"
			},
			{
				"fieldName": "Representative"
			},
			{
				"fieldName": "BusinessUnit"
			},
			{
				"fieldName": "SalesArea"
			},
			{
				"fieldName": "InstallArea"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"colIndex": 10,
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"colIndex": 11,
				"fieldName": "Notes",
				"details": {
					"lookupEntity": "OpportunityGrid"
				}
			}
		]
	},
	"InvoiceGrid": {
		"ContextKey": "Invoice",
		"Default": [
			{
				"fieldName": "InvoiceNo",
				"linkEntity": "Invoice"
			},
			{
				"fieldName": "ProjectCustomerPO"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"fieldName": "Rep"
			},
			{
				"fieldName": "BusinessUnit"
			},
			{
				"fieldName": "InvoiceDate",
				"fieldType": "date"
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "DueDate",
				"fieldType": "date"
			},
			{
				"fieldName": "Amount",
				"fieldType": "currency"
			},
			{
				"colIndex": 10,
				"fieldName": "Tax",
				"fieldType": "currency"
			},
			{
				"colIndex": 11,
				"fieldName": "AmountIncTax",
				"fieldType": "currency"
			}
		]
	},
	"DiscountItemGrid": {
		"Default": [
			{
				"fieldName": "Start",
				"linkEntity": "DiscountItem",
				"fieldType": "date"
			},
			{
				"fieldName": "Finish",
				"fieldType": "date"
			},
			{
				"fieldName": "Supplier"
			},
			{
				"fieldName": "Product"
			},
			{
				"fieldName": "Option1"
			},
			{
				"fieldName": "Option2"
			},
			{
				"fieldName": "Option1GroupCode"
			},
			{
				"fieldName": "Option2GroupCode"
			},
			{
				"fieldName": "Option1Value"
			},
			{
				"fieldName": "Option2Value"
			},
			{
				"colIndex": 10,
				"fieldName": "Base",
				"requireTranslate": true
			},
			{
				"colIndex": 11,
				"fieldName": "Options",
				"requireTranslate": true
			},
			{
				"colIndex": 12,
				"fieldName": "Delivery",
				"requireTranslate": true
			},
			{
				"colIndex": 13,
				"fieldName": "Install",
				"requireTranslate": true
			},
			{
				"colIndex": 14,
				"fieldName": "Percentage",
				"fieldType": "decimal"
			}
		]
	},
	"ItemGrid": {
		"Default": [
			{
				"fieldName": "Code",
				"linkEntity": "Item"
			},
			{
				"fieldName": "Name"
			},
			{
				"fieldName": "ProductCategory"
			},
			{
				"fieldName": "UnitType"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
				"requireTranslate": true

			},
			{
				"fieldName": "CanSell",
				"fieldType": "check",
				"requireTranslate": true
			},
			{
				"fieldName": "OutOfStock",
				"fieldType": "check",
				"requireTranslate": true
			},
			{
				"fieldName": "InStockETA",
				"fieldType": "date",
			},
			{
				"fieldName": "OutOfStockNotes",
			},
			{
				"fieldName": "DiscontinuedDate",
				"fieldType": "date",
			},
		]
	},
	"SupplierItemGrid": {
		"Default": [
			{
				"fieldName": "Code",
				"linkEntity": "SupplierItem"
			},
			{
				"fieldName": "Name"
			},
			{
				"fieldName": "ProductCategory"
			},
			{
				"fieldName": "Supplier"
			},
			{
				"fieldName": "UnitType"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
				"requireTranslate": true

			},
			{
				"fieldName": "CanSell",
				"fieldType": "check",
				"requireTranslate": true
			},
			{
				"fieldName": "OutOfStock",
				"fieldType": "check",
				"requireTranslate": true
			},
			{
				"fieldName": "InStockETA",
				"fieldType": "date",
			},
			{
				"fieldName": "OutOfStockNotes",
			},
			{
				"fieldName": "DiscontinuedDate",
				"fieldType": "date",
			},
		]
	},
	"FabricGrid": {
		"Default": [
			{
				"fieldName": "Code",
				"linkEntity": "Fabric"
			},
			{
				"fieldName": "Name"
			},
			{
				"fieldName": "Supplier",
				"linkEntity": "Supplier"
			},
			{
				"fieldName": "FabricCategory"
			},
			{
				"fieldName": "FabricName"
			},
			{
				"fieldName": "FabricColour"
			},
			{
				"fieldName": "DefaultGroupCode"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
				"requireTranslate": true

			},
			{
				"fieldName": "CanSell",
				"fieldType": "check",
				"requireTranslate": true
			},
			{
				"fieldName": "OutOfStock",
				"fieldType": "check",
				"requireTranslate": true
			},
			{
				"fieldName": "InStockETA",
				"fieldType": "date",
			},
			{
				"fieldName": "OutOfStockNotes",
			},
			{
				"fieldName": "DiscontinuedDate",
				"fieldType": "date",
			},
		]
	},
	"ProductGrid": {
		"Default": [
			{
				"fieldName": "Product",
				"linkEntity": "Product"
			},
			{
				"fieldName": "Supplier"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
				"requireTranslate": true

			},
			{
				"fieldName": "ServiceProduct",
				"fieldType": "check",
				"requireTranslate": true
			}
		]
	},
	"ProductVersionGrid": {
		"Default": [
			{
				"fieldName": "VersionNumber",
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "PublishedBy",
			},
			{
				"fieldName": "PublishedDate",
				"fieldType": "date",
			},
			{
				"fieldName": "ProductVersionID",
			},
		]
	},
	"LeadSearchGrid": {
		"Default": [
			{
				"fieldName": "LastName",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "FirstName"
			},
			{
				"fieldName": "Email"
			},
			{
				"fieldName": "WorkPhone"
			},
			{
				"fieldName": "HomePhone"
			},
			{
				"fieldName": "Mobile"
			},
			{
				"fieldName": "City"
			},
			{
				"fieldName": "State"
			},
			{
				"fieldName": "Postcode"
			},
			{
				"fieldName": "JobCategory"
			},
			{
				"colIndex": 10,
				"fieldName": "JobTitle"
			},
			{
				"colIndex": 11,
				"fieldName": "Company",
				"linkEntity": "Company"
			}
		]
	},
	"JobGridView": {
		"ContextKey": "Job",
		"Default": [
			{
				"fieldName": "Reference",
				"linkEntity": "Job"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"fieldName": "ProjectCustomerPO"
			},
			{
				"fieldName": "SalesRep"
			},
			{
				"fieldName": "BusinessUnit"
			},
			{
				"fieldName": "JobDate",
				"fieldType": "date"
			},
			{
				"fieldName": "MinOrderDate",
				"fieldType": "date"
			},
			{
				"fieldName": "MaxOrderDate",
				"fieldType": "date"
			},
			{
				"fieldName": "JobType",
				"requireTranslate": true
			},
			{
				"fieldName": "Stage",
				"requireTranslate": true
			},
			{
				"fieldName": "Stages"
			},
			{
				"colIndex": 10,
				"fieldName": "Products"
			},
			{
				"colIndex": 11,
				"fieldName": "Dispatch"
			},
			{
				"colIndex": 12,
				"fieldName": "Expiry",
				"fieldType": "date"
			},
			{
				"colIndex": 13,
				"fieldName": "ETADate",
				"fieldType": "date"
			},
			{
				"colIndex": 14,
				"fieldName": "CustomStatus"
			},
			{
				"colIndex": 15,
				"fieldName": "SalesPriceInc",
				"fieldType": "currency"
			},
			{
				"colIndex": 16,
				"fieldName": "InvoicedAmount",
				"fieldType": "currency"
			},
			{
				"colIndex": 17,
				"fieldName": "InvoicedAmountIncTax",
				"fieldType": "currency"
			},
			{
				"colIndex": 18,
				"fieldName": "UnInvoicedAmount",
				"fieldType": "currency"
			},
			{
				"colIndex": 19,
				"fieldName": "UnInvoicedAmountIncTax",
				"fieldType": "currency"
			}
		]
	},
	"ContactGrid": {
		"ContextKey": "Contact",
		"Default": [
			{
				"fieldName": "LastName",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "FirstName"
			},
			{
				"fieldName": "Email"
			},
			{
				"fieldName": "JobCategory"
			},
			{
				"fieldName": "WorkPhone"
			},
			{
				"fieldName": "HomePhone"
			},
			{
				"fieldName": "Mobile"
			},
			{
				"fieldName": "AddressLines"
			},
			{
				"fieldName": "City"
			},
			{
				"fieldName": "State"
			},
			{
				"colIndex": 10,
				"fieldName": "Postcode"
			},
			{
				"colIndex": 11,
				"fieldName": "Company",
				"encoded": false
			},
			{
				"colIndex": 12,
				"fieldName": "JobTitle",
				"encoded": false
			},
			{
				"colIndex": 13,
				"fieldName": "InternalNotes",
				"details": {
					"lookupEntity": "ContactGrid"
				}
			}
		]
	},
	"CommissionRunGrid": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "CommissionRunView"
			},
			{
				"fieldName": "StartDate",
				"fieldType": "date"
			},
			{
				"fieldName": "EndDate",
				"fieldType": "date"
			},
			{
				"fieldName": "LastRunBy"
			},
			{
				"fieldName": "PaidBy"
			},
			{
				"fieldName": "Plan"
			}
		]
	},
	"LargestQuoteGrid": {
		"Default": [
			{
				"fieldName": "Reference",
				"linkEntity": "Job"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "ExpiryDate"
			},
			{
				"fieldName": "SalesPriceInc"
			}
		]
	},
	"PaymentGrid": {
		"Default": [
			{
				"fieldName": "ReceiptNo",
				"linkEntity": "Payment"
			},
			{
				"fieldName": "Date",
				"fieldType": "date"
			},
			{
				"fieldName": "Method"
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "Amount",
				"fieldType": "currency",
				"aggregate": "sum"
			},
			{
				"fieldName": "UnAllocated",
				"fieldType": "currency",
				"aggregate": "sum"
			}
		]
	},
	"Team": {
		"Default": [
			{
				"fieldName": "Name",
				"linkEntity": "Team"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
				"requireTranslate": true
			}
		]
	},
	"Contact": {
		"Default": [
			{
				"fieldName": "LastName",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "FirstName"
			},
			{
				"fieldName": "Email"
			},
			{
				"fieldName": "WorkPhone"
			},
			{
				"fieldName": "HomePhone"
			},
			{
				"fieldName": "Mobile"
			},
			{
				"fieldName": "AddressLines"
			},
			{
				"fieldName": "City"
			},
			{
				"fieldName": "State"
			},
			{
				"fieldName": "Postcode"
			},
			{
				"colIndex": 10,
				"fieldName": "Company",
				"encoded": false
			},
			{
				"colIndex": 11,
				"fieldName": "InternalNotes",
				"details": {
					"lookupEntity": "Contact"
				}
			}
		]
	},
	"PL_CommitmentReason": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_CommitmentReason"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_UnitType": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_CommitmentReason"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			},
			{
				"fieldName": "Code",
			},
			{
				"fieldName": "Symbol",
			}
		]
	},
	"PL_FabricCategory": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_CommitmentReason"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			},
			{
				"fieldName": "Code",
			}
		]
	},
	"Supplier": {
		"Default": [
			{
				"fieldName": "Name",
				"linkEntity": "Supplier"
			},
			{
				"fieldName": "Code"
			},
			{
				"fieldName": "Email"
			},
			{
				"fieldName": "Phone"
			},
			{
				"fieldName": "Fax"
			},
			{
				"fieldName": "Mobile"
			},
			{
				"fieldName": "WWW"
			}
		]
	},
	"PL_JobCustomStatus": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_JobCustomStatus"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_ActivityCategory": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_ActivityCategory"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_ActivityColor": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_ActivityColor"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"Activity": {
		"Default": [
			{
				"fieldName": "Start",
				"fieldType": "date"
			},
			{
				"fieldName": "ActivityType"
			},
			{
				"fieldName": "Subject"
			},
			{
				"fieldName": "AllDay"
			},
			{
				"fieldName": "Closed"
			},
			{
				"fieldName": "Representative"
			},
			{
				"fieldName": "Contact",
				"encoded": false
			},
			{
				"fieldName": "Company",
				"encoded": false
			},
			{
				"fieldName": "Opportunity",
				"encoded": false
			},
			{
				"fieldName": "Notes",
				"details": {
					"lookupEntity": "Activity"
				}
			}
		]
	},
	"TaxRate": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "TaxRate"
			},
			{
				"fieldName": "Rate"
			}
		]
	},
	"DiscountLevel": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "DiscountLevel"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
				"requireTranslate": true
			}
		]
	},
	"PL_PaymentMethod": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_PaymentMethod"
			},
			{
				"fieldName": "IsCreditCard",
				"fieldType": "check",
				"requireTranslate": true
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
				"requireTranslate": true

			}
		]
	},
	"LookupTableItem": {
		"Default": [
			{
				"fieldName": "From"
			},
			{
				"fieldName": "To"
			},
			{
				"fieldName": "Text"
			},
			{
				"fieldName": "ProductID"
			},
			{
				"fieldName": "RepresentativeID"
			},
			{
				"fieldName": "Value"
			},
			{
				"fieldName": "Notes"
			}
		]
	},
	"QuoteTrackingView": {
		"ContextKey":"Job",
		"Default": [
			{
				"fieldName": "Reference",
				"linkEntity": "Job"
			},
			{
				"fieldName": "SalesRep"
			},
			{
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "SiteContact",
				"linkEntity": "Contact",
				"linkEntityIDField": "SiteContactID"
			},
			{
				"fieldName": "City"
			},
			{
				"fieldName": "SalesArea"
			},
			{
				"fieldName": "InstallArea"
			},
			{
				"fieldName": "ProjectCustomerPO",
			},
			{
				"fieldName": "CommitReason",
			},
			{
				"fieldName": "BusinessUnit",
			},
			{
				"fieldName": "Tags"
			},
			{
				"fieldName": "Stages"
			},
			{
				"fieldName": "Products"
			},
			{
				"fieldName": "Dispatch"
			},
			{
				"fieldName": "QuoteDate",
				"fieldType": "date"
			},
			{
				"fieldName": "DaysSinceSent",				
				"fieldType": "numeric"
			},
			{
				"fieldName": "DaysToExpiry",
				"fieldType": "numeric"
			},
			{
				"fieldName": "Paid",
				"fieldType": "currency"
			},
			{
				"fieldName": "SalePrice",
				"fieldType": "currency"
			},
			{
				"fieldName": "SalePriceIncTax",
				"fieldType": "currency"
			}
		]
	},
	"ActiveJobTrackingView": {
		"ContextKey": "Job",
		"Default": [
			{
				"fieldName": "Reference",
				"linkEntity": "Job"
			},
			{
				"fieldName": "SalesRep"
			},
			{
				"fieldName": "SalesRepMobile"
			},
			{
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "SiteContact",
				"linkEntity": "Contact",
				"linkEntityIDField": "SiteContactID"
			},
			{
				"fieldName": "City"
			},
			{
				"fieldName": "SalesArea"
			},
			{
				"fieldName": "InstallArea"
			},
			{
				"fieldName": "JobType",
				"requireTranslate": true
			},
			{
				"fieldName": "ProjectCustomerPO",
			},
			{
				"fieldName": "CommitReason",
			},
			{
				"colIndex": 10,
				"fieldName": "BusinessUnit",
			},
			{
				"colIndex": 11,
				"fieldName": "Tags",
				"requireTranslate": true
			},
			{
				"colIndex": 12,
				"fieldName": "Stages"
			},
			{
				"colIndex": 13,
				"fieldName": "Products"
			},
			{
				"colIndex": 14,
				"fieldName": "Dispatch"
			},
			{
				"colIndex": 15,
				"fieldName": "ETA",
				"fieldType": "date"
			},
			{
				"colIndex": 16,
				"fieldName": "OrderDate",
				"fieldType": "date"
			},
			{
				"colIndex": 17,
				"fieldName": "Paid",
				"fieldType": "currency"
			},
			{
				"colIndex": 18,
				"fieldName": "SalePrice",
				"fieldType": "currency"
			},
			{
				"colIndex": 19,
				"fieldName": "SalePriceIncTax",
				"fieldType": "currency"
			},
			{
				"colIndex": 20,
				"fieldName": "InvoicedAmount",
				"fieldType": "currency"
			},
			{
				"colIndex": 21,
				"fieldName": "InvoicedAmountIncTax",
				"fieldType": "currency"
			},
			{
				"colIndex": 22,
				"fieldName": "UnInvoicedAmount",
				"fieldType": "currency"
			},
			{
				"colIndex": 23,
				"fieldName": "UnInvoicedAmountIncTax",
				"fieldType": "currency"
			}

		],
		"AllOrders": [
			{
				"fieldName": "Reference",
				"linkEntity": "Job"
			},
			{
				"fieldName": "SalesRep"
			},
			{
				"fieldName": "SalesRepMobile"
			},
			{
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "SiteContact",
				"linkEntity": "Contact",
				"linkEntityIDField": "SiteContactID"
			},
			{
				"fieldName": "City"
			},
			{
				"fieldName": "SalesArea"
			},
			{
				"fieldName": "InstallArea"
			},
			{
				"fieldName": "JobType",
				"requireTranslate": true
			},
			{
				"fieldName": "ProjectCustomerPO",
			},
			{
				"fieldName": "CommitReason",
			},
			{
				"colIndex": 10,
				"fieldName": "BusinessUnit",
			},
			{
				"colIndex": 11,
				"fieldName": "Tags",
				"requireTranslate": true
			},
			{
				"colIndex": 12,
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"colIndex": 13,
				"fieldName": "Stages"
			},
			{
				"colIndex": 14,
				"fieldName": "Products"
			},
			{
				"colIndex": 15,
				"fieldName": "Dispatch"
			},
			{
				"colIndex": 16,
				"fieldName": "ETA",
				"fieldType": "date"
			},
			{
				"colIndex": 17,
				"fieldName": "OrderDate",
				"fieldType": "date"
			},
			{
				"colIndex": 18,
				"fieldName": "Paid",
				"fieldType": "currency"
			},
			{
				"colIndex": 19,
				"fieldName": "SalePrice",
				"fieldType": "currency"
			},
			{
				"colIndex": 20,
				"fieldName": "SalePriceIncTax",
				"fieldType": "currency"
			}
		]
	},
	"OpportunityTrackingView": {
		"ContextKey":"Opportunity",
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "Opportunity"
			},
			{
				"fieldName": "LeadDate",
				"fieldType": "date"
			},
			{
				"fieldName": "Forecast",
				"fieldType": "check"
			},
			{
				"fieldName": "ActivityID"
			},
			{
				"fieldName": "LeadSource",
			},
			{
				"fieldName": "PipelineStage",
				"requireTranslate": true
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "ConfidenceLevel"
			},
			{
				"fieldName": "Representative"
			},
			{
				"fieldName": "BusinessUnit"
			},
			{
				"fieldName": "SalesArea"
			},
			{
				"fieldName": "InstallArea"
			},
			{
				"colIndex": 10,
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"colIndex": 11,
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"colIndex": 12,
				"fieldName": "ExpectedRevenue",
				"fieldType": "currency"
			},
			{
				"colIndex": 13,
				"fieldName": "Tags",
				"requireTranslate": true
			}
		]
	},
	"MFSchedulingTrackingView": {
		"ContextKey": "PurchaseOrder",
		"Default": [
			{
				"fieldName": "OrderNumber",
				"linkEntity": "SupplyJob",
				"linkEntityIDField": "PurchaseOrderID"
			},
			{
				"fieldName": "CustomerReference"
			},
			{
				"fieldName": "Job",
				"linkEntity": "Job",
				"linkEntityIDField": "JobID"
			},
			{
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"fieldName": "Contact",
				"linkEntity": "Contact"
			},
			{
				"fieldName": "Supplier"
			},
			{
				"fieldName": "Internal",
				"requireTranslate": true,
				"fieldType": "check",
			},
			{
				"fieldName": "Product",
				"requireTranslate": true
			},
			{
				"fieldName": "Department",
			},
			{
				"fieldName": "BusinessUnit",
			},
			{
				"colIndex": 10,
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"colIndex": 11,
				"fieldName": "CommitReason",
			},
			{
				"colIndex": 12,
				"fieldName": "Qty",
				"fieldType": "numeric"
			},
			{
				"colIndex": 13,
				"fieldName": "DispatchDays",
				"fieldType": "numeric"
			},
			{
				"colIndex": 14,
				"fieldName": "Age",
				"fieldType": "numeric"
			},
			{
				"colIndex": 15,
				"fieldName": "DispatchDate",
				"fieldType": "date"
			},
			{
				"colIndex": 16,
				"fieldName": "OrderDate",
				"fieldType": "date"
			},
			{
				"colIndex": 17,
				"fieldName": "ETADate",
				"fieldType": "date"
			},
			{
				"colIndex": 18,
				"fieldName": "MFDate",
				"fieldType": "date"
			}
		]
	},
	"RestockTrackingView": {
		"ContextKey": "JobLine",
		"Default": [
			{
				"fieldName": "Job",
				"linkEntity": "Job",
				"linkEntityIDField": "JobID"
			},
			{
				"fieldName": "JobLineNo",
				"linkEntity": "JobLine",
				"linkEntityIDField": "ID"
			},
			{
				"fieldName": "Product"
			},
			{
				"fieldName": "Width",
				"fieldType": "numeric"
			},
			{
				"fieldName": "Height",
				"fieldType": "numeric"
			},
			{
				"fieldName": "StockLocation"
			},
			{
				"fieldName": "Stage",
				"requireTranslate": true
			},
			{
				"fieldName": "StageDate",
				"fieldType": "date"
			},
			{
				"fieldName": "StageDays",
				"fieldType": "numeric"
			},
			{
				"fieldName": "RestockDate",
				"fieldType": "date"
			},
			{
				"fieldName": "RestockDays",
				"fieldType": "numeric"
			},
			{
				"fieldName": "CostPrice",
				"fieldType": "currency"
			},
			{
				"fieldName": "StandardPrice",
				"fieldType": "currency"
			},
			{
				"fieldName": "DisplayOption1",
			},
			{
				"fieldName": "DisplayOption2",
			},
			{
				"fieldName": "DisplayOption3",
			},
			{
				"fieldName": "DisplayOption4",
			},
			{
				"fieldName": "DisplayOption5",
			},
			{
				"fieldName": "RestockNotes",
				details: {}
			}

		]
	},
	"PL_FaultReason": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_FaultReason"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"ProjectTrackingViewGrid": {
		"ContextKey":"Job",
		"Default": [
			{
				"fieldName": "Reference",
				"linkEntity": "Job"
			},
			{
				"fieldName": "Company",
				"linkEntity": "Company"
			},
			{
				"fieldName": "SiteContact",
				"linkEntity": "Contact",
				"linkEntityIDField": "SiteContactID"
			},
			{
				"fieldName": "CustomStatus"
			},
			{
				"fieldName": "ProjectCustomerPO"
			},
			{
				"fieldName": "SiteAddress"
			},
			{
				"fieldName": "SalesRep"
			},
			{
				"fieldName": "Tags"
			},
			{
				"fieldName": "HandoverDate",
				"fieldType": "date"
			},
			{
				"fieldName": "ETA",
				"fieldType": "date"
			},
			{
				"colIndex": 10,
				"fieldName": "SalePrice",
				"fieldType": "currency"
			}
		]
	},
	"PL_MFDepartment": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_MFDepartment"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",

			}
		]
	},
	"PL_OrderLineLocation": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_OrderLineLocation"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_QuoteOutcomeReason": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_QuoteOutcomeReason"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_OppConfidenceLevel": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_OppConfidenceLevel"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"JobLine": {
		"Default": [
			{
				"fieldName": "JobLineNo",
				"linkEntity": "JobLine"
			},
			{
				"fieldName": "ProductName"
			},
			{
				"fieldName": "Qty"
			},
			{
				"fieldName": "Width"
			},
			{
				"fieldName": "Drop"
			},
			{
				"fieldName": "LocationOther"
			},
			{
				"fieldName": "Stage",
				"requireTranslate": true
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "CostPrice",
				"fieldType": "currency",
				"aggregate": "sum"
			},
			{
				"fieldName": "SalePriceIncTax",
				"fieldType": "currency",
				"aggregate": "sum"
			},
			{
				"colIndex": 10,
				"fieldName": "ValidationErrors",
				"errorField": true
			}
		]
	},
	"TemplateResponse": {
		"Default": [
			{
				"fieldName": "Condition"
			},
			{
				"fieldName": "Value"
			},
			{
				"fieldName": "Action"
			}
		]
	},
	"PL_ProductOfInterest": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_ProductOfInterest"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_ProductCategory": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_ProductCategory"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_LeadDiscardReason": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_LeadDiscardReason"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_OrganisationType": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_OrganisationType"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"Payment": {
		"Default": [
			{
				"fieldName": "ReceiptNo",
				"linkEntity": "Payment"
			},
			{
				"fieldName": "Date",
				"fieldType": "date"
			},
			{
				"fieldName": "Method"
			},
			{
				"fieldName": "Status",
				"requireTranslate": true
			},
			{
				"fieldName": "Amount",
				"fieldType": "currency",
				"aggregate": "sum"
			}
		]
	},
	"PL_Tag": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_Tag"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"Template": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "Template"
			},
			{
				"fieldName": "Type"
			},
			{
				"fieldName": "Category"
			}
		]
	},
	"Product": {
		"Default": [
			{
				"fieldName": "ProductName",
				"linkEntity": "Product"
			},
			{
				"fieldName": "Supplier"
			}
		]
	},
	"Automation": {
		"Default": [
			{
				"fieldName": "Name",
				"linkEntity": "Automation"
			},
			{
				"fieldName": "Type"
			},
			{
				"fieldName": "Enabled",
				"fieldType": "check",
			}
		]
	},
	"BusinessUnit": {
		"Default": [
			{
				"fieldName": "Name",
				"linkEntity": "BusinessUnit"
			},
			{
				"fieldName": "Phone"
			},
			{
				"fieldName": "Mobile"
			}
		]
	},
	"Company": {
		"Default": [
			{
				"fieldName": "Name",
				"linkEntity": "Company"
			},
			{
				"fieldName": "Type"
			},
			{
				"fieldName": "Phone"
			},
			{
				"fieldName": "Fax"
			},
			{
				"fieldName": "Mobile"
			},
			{
				"fieldName": "Email"
			},
			{
				"fieldName": "WWW"
			},
			{
				"fieldName": "AddressLines"
			},
			{
				"fieldName": "City"
			},
			{
				"fieldName": "State"
			},
			{
				"colIndex": 10,
				"fieldName": "Postcode"
			},
			{
				"colIndex": 11,
				"fieldName": "InternalNotes",
				"details": {
					"lookupEntity": "Company"
				}
			}
		]
	},
	"PL_LeadSource": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_LeadSource"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_ContactJobCategory": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_ContactJobCategory"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_CreditCard": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_CreditCard"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			},
			{
				"fieldName": "Provider",
			}
		]
	},
	"PL_CustomerAvailability": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_CustomerAvailability"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			},
			{
				"fieldName": "StartTime",
			},
			{
				"fieldName": "EndTime",
			}
		]
	},
	"PL_CommissionAmendmentCategory": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_CommissionAmendmentCategory"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	},
	"PL_StockLocation": {
		"Default": [
			{
				"fieldName": "Description",
				"linkEntity": "PL_StockLocation"
			},
			{
				"fieldName": "Active",
				"fieldType": "check",
			}
		]
	}
}


export default GRIDS;