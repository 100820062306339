import { AccentSpinner } from "../controls/AccentSpinner";
import { t } from "../services/HelperService";

export const InProgressOverlay = ({ children, block, showText, text }) => {

    return (
        <div className="config-overlayContainer">
            {block && (
                <div className="config-overlay">
                    {showText && <span className="config-overlayText">{t(text)}</span>}
                    {showText && <AccentSpinner type="pulsing" style={{ display: "inline-block", paddingLeft: "10px", marginTop: "10px" }} />}
                </div>
            )}
            {children}
        </div>
    );
};

export default InProgressOverlay;
