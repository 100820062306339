import React, { useState } from 'react';

import { queryNoTracking } from '../../services/DataService';
import { AccentTextUnbound } from '../unbound/AccentTextUnBound';
import { AccentButton } from '../AccentButton';
import { accentUtils, from } from '../../services/HelperService';


const query_GetAPIJobFilter = queryNoTracking("GetAPIJobFilter");

export const InsyteAIQueryCtrl = ({ readOnly , onChange}) => {
    const [prompt, setPrompt] = useState('');
    const [result, setResult] = useState({});
    


    const handlePromptChange = (value) => {
        setPrompt(value);
    };

    const fetchResult = async (e) => {
        const res = await query_GetAPIJobFilter.getFirstOrDefault({ prompt : prompt});

        setResult(res ?? {...result});
        
        if (!accentUtils.isNull(res)) {
            //onChange({ ...res.Filter.filter.filter[0] });
            onChange({ ...(from(res.Filter?.filter?.filter ??[]).firstOrDefault()) });
        }
        

        e.promise(); // Mark the button's promise
        
    };

    return (
        <div >
            <div className="d-flex align-items-center">
                <div style={{ flexGrow: 1, marginRight: '10px' }}>
                    <AccentTextUnbound
                        disabled={readOnly}
                        defaultValue={prompt}
                        onChange={handlePromptChange}
                        label="Tell Insyte AI what you want to find"
                    />
                </div>
                <div>
                    <AccentButton disabled={readOnly} onClick={fetchResult}>Update</AccentButton>
                </div>
            </div>     
            {result && (
                <div>
                    <h3>Result:</h3>
                    <p>{JSON.stringify(result)}</p>
                </div>
            )}
        </div>
    );
};


