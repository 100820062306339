import React from 'react';
import $ from 'jquery'
import { accentUtils, t, from, newGuid } from '../../services/HelperService'
import { AccentDropdownButton } from './../AccentDropdownButton'
import { isMobileOnly } from '../AccentDisplay';
import { AccentPopup } from '../../dialogs/AccentPopup';
import AccentIcons from '../../icons/Icons';


export const GridToolbarActions = React.memo(props => {


    const buttonID = React.useRef(`btn_${newGuid()}`);
    const [show, setShow] = React.useState(false);
    const ctrl = React.useRef();


    const onAutoClose = React.useCallback(e => {

        

        if ($(e.target).parents(`#${buttonID.current}`).length !== 0) {

        } else {
            setShow(false);
        }
    
        

    }, [props, setShow, show, buttonID]);


    const onButtonClick = React.useCallback(e => {

        setShow(v=>!v);

    }, [props, setShow, show, buttonID]);

    const onSelect = React.useCallback((e, item) => {
        if (!accentUtils.isNull(item)) {
            item.onClick(e);
        }

        setShow(false);

    }, [props, setShow]);


    const items = props.actions.map((a, i) => {

        if (a.divider) return <div key={i} className="moreSep" />;
        var text = a.title;
        return <div key={a.divider ? `div_${i}` : a.title} className="p-2 acc-popup-item" style={{ cursor: "pointer" }} onClick={e => onSelect(e, a)}>{text}</div>;
    });





    if (props.additionalItems?.length > 0) {

        if (items.length > 0) {
            items.push(<div key="add_divider" className="moreSep" />);
        }

        props.additionalItems.map((a, i) => {

            items.push(
                <div key={a.divider ? `div_${i}` : a.title} className={`p-2 acc-popup-item ${a.active ? 'active' : ''}`} style={{ cursor: "pointer" }} onClick={e=> onSelect(e, a)}>{a.title}</div>
                );
        });

    }


    

    return <div>
        <div ref={ctrl} onClick={onButtonClick} id={buttonID.current}>
            <AccentDropdownButton                
                tagName="gridToolbarActions"
                disabled={props.readOnly}
                className="acc-grid-toolbar-actions"
                title={isMobileOnly ? undefined : t("application_strings.application.buttons.actions")}
                bsStyle={props.bsStyle} />
        </div>        
        <AccentPopup className="trackingActionsPopoup" popupAlign={{ horizontal: "right" }} anchorAlign={{ horizontal: "left", vertical: "center" }} show={show}
            anchor={ctrl}
            onAutoClose={onAutoClose}
            headerContent={<div className="d-flex">
                <div className="me-auto">{t("application_strings.application.buttons.actions")}</div>
                <div><AccentIcons.Close className="acc-btn" onClick={onAutoClose} top={0} noPadding /></div>
            </div>}
        >            
                {items}            
        </AccentPopup>
    </div>;


});
