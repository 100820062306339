import React, { useState } from 'react';
import * as Moment from 'moment';
import { AccentComboUnbound } from './AccentComboUnbound';
import { AccentDateTimeUnbound } from './AccentDateTimeUnbound';
import { accentUtils } from '../../services/HelperService';

const snoozeOptions = [
    { ID: '5m', Name: '5 minutes' },
    { ID: '10m', Name: '10 minutes' },
    { ID: '15m', Name: '15 minutes' },
    { ID: '30m', Name: '30 minutes' },
    { ID: '1h', Name: '1 hour' },
    { ID: '2h', Name: '2 hours' },
    { ID: '4h', Name: '4 hours' },
    { ID: '8h', Name: '8 hours' },
    { ID: '1d', Name: '1 day' },
    { ID: '2d', Name: '2 days' },
    { ID: '4d', Name: '4 days' },
    { ID: '1w', Name: '1 week' },
    { ID: '2w', Name: '2 weeks' },
    { ID: 'custom', Name: 'Custom' },
];



function saveLastUsed(settingsKey, value){
    localStorage.setItem(`AccentSnoozeUnbound_Default_${settingsKey}`, value);
}

function getLastUsed(settingsKey) {

    if (accentUtils.isEmpty(settingsKey)) return '1d';


    const res = localStorage.getItem(`AccentSnoozeUnbound_Default_${settingsKey}`);

    return res ?? '1d';
}

function getAdjustedDate(value) {

    let newDate = Moment();

    switch (value) {
        case '5m':
            newDate.add(5, 'minutes');
            break;
        case '10m':
            newDate.add(10, 'minutes');
            break;
        case '15m':
            newDate.add(15, 'minutes');
            break;
        case '30m':
            newDate.add(30, 'minutes');
            break;
        case '1h':
            newDate.add(1, 'hour');
            break;
        case '2h':
            newDate.add(2, 'hours');
            break;
        case '4h':
            newDate.add(4, 'hours');
            break;
        case '8h':
            newDate.add(8, 'hours');
            break;
        case '1d':
            newDate.add(1, 'day');
            break;
        case '2d':
            newDate.add(2, 'days');
            break;
        case '4d':
            newDate.add(4, 'days');
            break;
        case '1w':
            newDate.add(1, 'week');
            break;
        case '2w':
            newDate.add(2, 'weeks');
            break;
        default:
            console.warn("Unknown snooze option:", value);
            break;
    }

    return newDate.toDate();
}

export const AccentSnoozeUnbound = ({ onChange, defaultValue, required, settingsKey }) => {

    const lastUsedSelection = getLastUsed(settingsKey);

    const [selectedValue, setSelectedValue] = useState(lastUsedSelection);
    const [customDateTime, setCustomDateTime] = useState(lastUsedSelection === "custom" ? new Date(defaultValue) : getAdjustedDate(lastUsedSelection));

    const handleComboChange = (value) => {
        setSelectedValue(value);

        saveLastUsed(settingsKey, value);
        
        if (value !== 'custom') {
                        
            onChange(getAdjustedDate(value));
        }
    };

    const handleDateTimeChange = (newDate) => {
        setCustomDateTime(newDate);
        onChange(newDate);
    };
    const handleDateTimeValidate = (newDate) => {
        if (!required) return null;

        if (accentUtils.isNull(newDate)) return "Required";

        if (new Date(newDate) < new Date()) {
            return "Must be a future date";
        }

        return null;
    };


    React.useEffect(() => {

        if (defaultValue !== customDateTime) {
            onChange(customDateTime);
        }


    }, []);

    return (
        <>
            <AccentComboUnbound
                required={required}
                displayField="Name"
                valueField="ID"
                defaultValue={selectedValue}
                onChange={handleComboChange}
                label="Snooze"
                data={snoozeOptions}
            />
            {selectedValue === 'custom' && (
                <AccentDateTimeUnbound
                    required={required}
                    defaultValue={customDateTime}
                    onChange={handleDateTimeChange}
                    label="Custom DateTime"
                    onValidate={handleDateTimeValidate }
                />
            )}
        </>
    );
};
