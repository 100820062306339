import * as React from 'react'
import { Alert } from 'reactstrap'
import { accentUtils, t, formHelper, showDialog, getAccess } from '../services/HelperService'
import * as DataService from '../services/DataService'
import * as UserService from '../services/UserService'
import { AccentDateUnbound } from '../controls/unbound/AccentDateUnbound'
import { AccentDialog } from './AccentDialog'
import { AccentButton } from '../controls/AccentButton'

export class BulkUpdateCommissionDateDlg extends React.PureComponent{

    static Show(jobIDs) {

        return new Promise(p => {

            showDialog(<BulkUpdateCommissionDateDlg />).then(s => {
                if (!s.canceled) {
                    DataService.update("Jobs_BulkSetCommissionDates", {
                        Date: s.commissionDate,
                        JobIDs: jobIDs
                    }).then(r => {
                        p(r);
                    });
                }
            });
        });
    }


    constructor(props) {
        super(props)


        this.state = {
            commissionDate: null
        }

        this.allowOk = this.allowOk.bind(this);
        this.getBody = this.getBody.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChanged = this.onChanged.bind(this);


    }

    onChanged(e) {
        this.setState({ commissionDate: e });

    }


    getTitle() {
        return t("application_strings.views.tracking.updateCommissionDateTitle");
    }

    allowOk() {
        return !accentUtils.isEmpty(this.state.commissionDate);
    }

    onClose(e) {
        this.props.onClose({ ...e, commissionDate: this.state.commissionDate });
    }

    getBody() {

        const content = UserService.getAccess().features().FeatureAllowEditCommissionDate() ?
            <AccentDateUnbound defaultValue={null} onChange={this.onChanged} label="application_strings.views.tracking.updateCommissionDateLabel" />
            : <Alert color="danger">{t("application_strings.views.tracking.updateCommissionDateNoPermission") }</Alert>;

        return <div className="center">
            { content}            
        </div>;
    }


    render() {


        return <AccentDialog
            maxWidth={ 600}
            headerContent={this.getTitle()}
            onClose={this.props.onClose}
            actionsContent={[<AccentButton key="2" disabled={!this.allowOk()} tagName="okBtn" onClick={this.onClose}>{t("application_strings.application.buttons.update")}</AccentButton> ]}
        >
        { this.getBody()}
        </AccentDialog >

    }

}