import React from 'react'
import { Menu } from '@progress/kendo-react-layout'
import { accentUtils, t } from '../../services/HelperService'
import { filterOps, parseFilterPredicate, findSchemaItem, filterTypes } from './AdvancedFilterConstants';



export const changeFilterPredicateOp = (item, op) => {

    var itemInfo = parseFilterPredicate(item);

    var value = itemInfo.arg;

    
    const isEmpty = op === filterOps.isempty;
    const isNotEmpty = op === filterOps.isnotempty;

    if (op === filterOps.relative && accentUtils.isEmpty(value)) {
        value = [0, 0];
    }

    if (isEmpty) {
        op = filterOps.eq;
        value = null;
    }

    if (isNotEmpty) {
        op = filterOps.ne;
        value = null;
    }

    if (!isEmpty && !isNotEmpty) {
        if (op === filterOps.eq) {
            if (accentUtils.isNull(value)) {
                value = ''; // as null indicates isempty
            }
        }
    }

    if (op === filterOps.isoneof) {
        value = null;
    }

    item.op = op;
    item.value = value;

};


export const InsyteQueryOpCtrl = React.memo(props => {


    const opInfo = parseFilterPredicate(props.item);


    const schemaItem = findSchemaItem(props.schema, opInfo.field);

    const type = accentUtils.isNull(schemaItem) ? "text" : schemaItem.Type;


    const onSelect = React.useCallback(e => {
        changeFilterPredicateOp(props.item, e);
        props.onItemChanged();
    }, [props]);

    switch (type) {
        case filterTypes.select: return <InsyteQueryOpSelectCtrl readOnly={ props.readOnly} onSelect={onSelect} opInfo={opInfo} />;
        case filterTypes.check: return <InsyteQueryOpCheckCtrl readOnly={props.readOnly} opInfo={opInfo} />;
        case filterTypes.date: return <InsyteQueryOpDateCtrl readOnly={props.readOnly} onSelect={onSelect} opInfo={opInfo} />;
        case filterTypes.numeric: return <InsyteQueryOpNumericCtrl readOnly={props.readOnly} onSelect={onSelect} opInfo={opInfo} />;
        default: return <InsyteQueryOpTextCtrl onSelect={onSelect} readOnly={props.readOnly} opInfo={opInfo} />;
    }
});

export const InsyteQueryOpTextCtrl = React.memo(props => {
    const opItems = [
        {
            disabled: props.readOnly,
            text: t(`application_strings.operators.${props.opInfo.op}`),
            itemId: props.opInfo.op,
            items: [
                { itemId: filterOps.startswith, text: t(`application_strings.operators.${filterOps.startswith}`) },
                { itemId: filterOps.eq, text: t(`application_strings.operators.${filterOps.eq}`) },
                { itemId: filterOps.ne, text: t(`application_strings.operators.${filterOps.ne}`) },
                { itemId: filterOps.isempty, text: t(`application_strings.operators.${filterOps.isempty}`) },
                { itemId: filterOps.isnotempty, text: t(`application_strings.operators.${filterOps.isnotempty}`) }
            ]
        }

    ];

    const onSelect = React.useCallback(e => {
        if (!accentUtils.isNull(e.item)) {
            const id = e.item.itemId;
            props.onSelect(id);
        }
    }, [props]);


    return <Menu
        className="acc-qry-pred-ops"
        onSelect={onSelect}
        items={opItems} />;

});


export const InsyteQueryOpSelectCtrl = React.memo(props => {

    const opItems = [
        {
            disabled: props.readOnly ,
            text: t(`application_strings.operators.${props.opInfo.op}`),
            itemId: props.opInfo.op,
            items: [
                { itemId: filterOps.in, text: t(`application_strings.operators.${filterOps.in}`) },
                { itemId: filterOps.notin, text: t(`application_strings.operators.${filterOps.notin}`) },
                { itemId: filterOps.isempty, text: t(`application_strings.operators.${filterOps.isempty}`) },
                { itemId: filterOps.isnotempty, text: t(`application_strings.operators.${filterOps.isnotempty}`) },
            ]
        }

    ];


    const onSelect = React.useCallback(e => {
        if (!accentUtils.isNull(e.item)) {
            const id = e.item.itemId;
            props.onSelect(id);
        }
    }, [props]);


    return <Menu
        className="acc-qry-pred-ops"
        onSelect={onSelect}
        items={opItems} />;

});

export const InsyteQueryOpCheckCtrl = React.memo(props => {

    return <span>Is</span>;

});

export const InsyteQueryOpNumericCtrl = React.memo(props => {
    const opItems = [
        {
            disabled: props.readOnly,
            text: t(`application_strings.operators.${props.opInfo.op}`),
            itemId: props.opInfo.op,
            items: [
                { itemId: filterOps.gt, text: t(`application_strings.operators.${filterOps.gt}`) },
                { itemId: filterOps.ge, text: t(`application_strings.operators.${filterOps.ge}`) },
                { itemId: filterOps.lt, text: t(`application_strings.operators.${filterOps.lt}`) },
                { itemId: filterOps.le, text: t(`application_strings.operators.${filterOps.le}`) },
                { itemId: filterOps.eq, text: t(`application_strings.operators.${filterOps.eq}`) },
                { itemId: filterOps.ne, text: t(`application_strings.operators.${filterOps.ne}`) },
                { itemId: filterOps.isempty, text: t(`application_strings.operators.${filterOps.isempty}`) },
                { itemId: filterOps.isnotempty, text: t(`application_strings.operators.${filterOps.isnotempty}`) }
            ]
        }

    ];

    const onSelect = React.useCallback(e => {
        if (!accentUtils.isNull(e.item)) {
            const id = e.item.itemId;
            props.onSelect(id);
        }
    }, [props]);


    return <Menu
        className="acc-qry-pred-ops"
        onSelect={onSelect}
        items={opItems} />;

});


export const InsyteQueryOpDateCtrl = React.memo(props => {
    const opItems = [
        {
            disabled: props.readOnly,
            text: t(`application_strings.operators.${props.opInfo.op}`),
            itemId: props.opInfo.op,
            items: [
                { itemId: filterOps.gt, text: t(`application_strings.operators.${filterOps.gt}`) },
                { itemId: filterOps.ge, text: t(`application_strings.operators.${filterOps.ge}`) },
                { itemId: filterOps.lt, text: t(`application_strings.operators.${filterOps.lt}`) },
                { itemId: filterOps.le, text: t(`application_strings.operators.${filterOps.le}`) },
                { itemId: filterOps.eq, text: t(`application_strings.operators.${filterOps.eq}`) },
                { itemId: filterOps.ne, text: t(`application_strings.operators.${filterOps.ne}`) },
                { itemId: filterOps.isempty, text: t(`application_strings.operators.${filterOps.isempty}`) },
                { itemId: filterOps.isnotempty, text: t(`application_strings.operators.${filterOps.isnotempty}`) },
                { itemId: filterOps.within, text: t(`application_strings.operators.${filterOps.within}`) },
                { itemId: filterOps.relative, text: t(`application_strings.operators.${filterOps.relative}`) }
            ]
        }

    ];

    const onSelect = React.useCallback(e => {
        if (!accentUtils.isNull(e.item)) {
            const id = e.item.itemId;
            props.onSelect(id);
        }
    }, [props]);


    return <Menu
        className="acc-qry-pred-ops"
        onSelect={onSelect}
        items={opItems} />;

});
