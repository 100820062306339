import * as React from 'react'
import { Marker, StandaloneSearchBox, GoogleMap, DirectionsRenderer } from '@react-google-maps/api'

import { A, accentUtils, showOK } from './../services/HelperService'
import { download, queryNoTracking } from '../services/DataService';

var gDragUnknownLoc = {
    id: null
};

const AGMap =  React.memo(props => {

    var directions = null;
    var search = null;
    if (!accentUtils.isNull(props.directions)) {
        directions = < DirectionsRenderer options={{ draggable: true, suppressMarkers: true, preserveViewport: props.directionsPreserveViewport}} directions={props.directions} />;
    }



    var pos = { lat: -34.397, lng: 150.644 };

    if (!accentUtils.isNull(props.defaultLocation)) {
        pos = props.defaultLocation;
    }


    var zoomLevel = props.zoom;

    if (accentUtils.isNull(zoomLevel)) {
        zoomLevel = 8;
    }

    const customControls = props.customControls && props.customControls();




    const options = { closeBoxURL: '', enableEventPropagation: true };


    return (
        <GoogleMap
            mapContainerStyle={{
                height: "100%",
                width: "100%"
            }}
            zoom={zoomLevel}
            center={pos}
            onMouseOver={function (e) {
                if (gDragUnknownLoc !== null && gDragUnknownLoc.id !== null) {
                    props.onDropAppointment(gDragUnknownLoc.id, e.latLng.lat(), e.latLng.lng());
                }
            }}
            onLoad={map => {
                if (props.onMounted) {
                    props.onMounted(map);
                }
            }}
            onUnmount={map => {
                if (props.onUnMounted) {
                    props.onUnMounted(map);
                }
            }}
        >

            <StandaloneSearchBox
                ref={ref => search = ref}
                bounds={props.bounds}
                controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
                onPlacesChanged={function () {
                    var newPos = null;

                    const places = search.getPlaces();
                    if (places.length > 0) {

                        newPos = {
                            lat: places[0].geometry.location.lat(),
                            lng: places[0].geometry.location.lng()
                        };


                    }
                    if (!accentUtils.isNull(props.onSearch)) {
                        props.onSearch(newPos);
                    }

                }}
            >
                <input
                    type="text"
                    placeholder="Search to center map"
                    className="acc-map-search"
                />
            </StandaloneSearchBox>
            {props.markers}
            {directions}
            {customControls}
        </GoogleMap>
    );
});

const AccentMarker = Marker;

export class AccentMap extends React.Component {

    constructor(props) {
        super(props);


        var me = this;
        this.map = null;
        this.geolocate = this.geolocate.bind(this);
        this.searchPos = this.props.defaultLocation;
        this.refreshKey = 1;
        this.onSearch = this.onSearch.bind(this);
        this.onMapMounted = this.onMapMounted.bind(this);
        this.onMapUnMounted = this.onMapUnMounted.bind(this);
        this.onGeolocateError = this.onGeolocateError.bind(this);

        if (accentUtils.isEmpty(this.searchPos)) {
            this.geolocate().then(function (res) {

                me.searchPos = res;

                if (me.props.onLocate)
                    me.props.onLocate(res);

            });
        }
    }

    onMapMounted(ref) {
        this.map = ref;
        if (this.props.onMounted) {
            this.props.onMounted(ref);
        }

        this.forceUpdate();
    }


    onMapUnMounted(ref) {
        //this.map = ref;
        if (this.props.onUnMounted) {
            this.props.onUnMounted(ref);
        }
    }


    onGeolocateError(error) {

        if (this.props.onLocate)
            this.props.onLocate(null);


        if (error.code === error.PERMISSION_DENIED) {
            showOK(
                "application_strings.application.dialogs.locationServicesOffTitle",
                <AccentLocationServiceMsg />,
                ["application_strings.application.buttons.ok"], false, null, true)
                .then(function (res) { });
        }
    }

    geolocate() {

        var me = this;


        return new Promise(function (p) {

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    p({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });

                },me.onGeolocateError);
            }


        });


    }
    onSearch(pos) {

        if (!accentUtils.isNull(pos)) {

            this.searchPos = pos;


            if (!accentUtils.isNull(pos)) {

                var mapBounds = this.map.getBounds();
                mapBounds.extend(new window.google.maps.LatLng(pos.lat, pos.lng));

                this.map.fitBounds(mapBounds);


            }


        }

    }

    render() {
        return <div key={this.refreshKey} className="acc-map">
            <AGMap
                defaultLocation={this.searchPos}
                onMounted={this.onMapMounted}
                onUnMounted={this.onUnMapMounted}
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "100%" }} />}
                markers={ accentUtils.isNull(this.map) ? [] :  this.props.markers}
                directions={this.props.directions}
                directionsPreserveViewport={this.props.directionsPreserveViewport}
                onDropAppointment={this.props.onDropAppointment}
                onSearch={this.onSearch}
                zoom={this.props.zoom}
                customControls={this.props.customControls}
            />            
        </div>
    }

}


export class AccentLocationServiceMsg extends React.Component {

    constructor(props) {
        super(props);


        this.query = queryNoTracking("Help_URL");
    }

    render() {
        return <p>Location services is disabled for this site. See <div className="acc-link" onClick={e =>
            this.query.getFirstOrDefault({ id: "locationservices" }).then(url => {
                window.open(url, '_blank').focus();                
            })            
        }>here</div> on how to enable it.</p>;
    }
}
