import * as React from 'react';
import { accentUtils } from '../../services/HelperService';
import { AccentNumberUnbound } from '../unbound/AccentNumberUnbound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { isReadOnly, onSimpleOptionValueChanged, fromInt, useChanged, useValidation, getOptionModel, configEventControlType, configEvents } from './ProductConfiguratorShared';

export const ConfigDecimal = props => {

    const focusBug = React.useRef(false);

    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);

    const changed = useChanged(props.optionID, "ConfigNumeric", false);
    const validation = useValidation(props.optionID, "ConfigNumeric");

    const [value, setValue] = React.useState(accentUtils.isEmpty(optionModel.CurrentValueAsString) ? null : Number(optionModel.CurrentValueAsString?.toString()));

    const fireChange = React.useMemo(() => onSimpleOptionValueChanged(), [props.optionID]);


    const onChange = React.useCallback(e => {

        focusBug.current = false;

        fireChange(ctx, optionModel, e, changed.tag).then(() => {
            console.log("............................ x");
            ctx.config?.Publish(configEvents.controlLostFocus, configEventControlType.decimal);
        });
        setValue(e);

    }, [setValue, ctx, optionModel, fireChange, changed.tag]);

    const onFocus = React.useCallback(e => {
        console.log("............................ ");

        if (!focusBug.current) {
            focusBug.current = true;
            ctx.config?.Publish(configEvents.controlGotFocus, configEventControlType.decimal);
        }

    }, [ctx]);

    const notifyDirty = React.useCallback(e => {

        if (ctx.notifyDirty) {
            ctx.notifyDirty();
        }

    }, [ctx]);

    const readOnly = isReadOnly(optionModel);

    const min = fromInt(optionModel.MinValue);
    const max = fromInt(optionModel.MaxValue);


    return <div className="config-control config-decimal">
        <AccentNumberUnbound
            key={changed.changeID}
            required={validation.required}
            defaultValue={accentUtils.isEmpty(optionModel.CurrentValueAsString) ? null : Number(optionModel.CurrentValueAsString?.toString())}
            onBlur={onChange}
            onChange={notifyDirty}
            onFocus={onFocus}
            disabled={readOnly}
            min={min}
            max={max === 0 ? undefined : max}
            debounceMilliseconds={50}
        />
    </div>;

};