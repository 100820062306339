import * as React from 'react'
import { isTablet, isDesktop as pc, useMobileOrientation, isMobileOnly, isAndroid } from 'react-device-detect'
import { accentUtils, newGuid } from './../services/HelperService'
import { debounce } from 'perfect-debounce';

const isDesktop = !isTablet && pc;

export { isTablet, isDesktop, useMobileOrientation, isMobileOnly, isAndroid };

export const useAccentDisplay = () => {

    const size = useWindowSize();
    const { isLandscape } = useMobileOrientation();


    return {
        tablet: isTablet,
        large: isDesktop,
        mobile: isMobileOnly,
        width: size.width,
        height: size.height,
        landscape: isLandscape,        
    };
};


export const AccentDisplay = (p) => {

    const size = useWindowSize();


    const { isLandscape } = useMobileOrientation();



    
    const isMin = accentUtils.isEmpty(p.min) ? false : size.width >= p.min;
    const isMax = accentUtils.isEmpty(p.max) ? false : size.width <= p.max;

    const args = {
        tablet: isTablet,
        large: isDesktop,
        mobile: isMobileOnly,
        width: size.width,
        height: size.height,
        landscape: isLandscape
    };

    var show = p.exp ? p.exp(args) : isMobileOnly && p.mobile
        || isTablet && p.tablet
        || isDesktop && p.large
        || isMin || isMax;


    const args2 = { ...args, show };

    const isFunc = accentUtils.isNull(p.children) || typeof p.children === 'function';

    if (p.debug) {

        const w = window.innerWidth;

        if (show) {
            var ss = 2;
        } else {
            var ss2 = 2;
        }

        var display = `M:${isMobileOnly}, T:${isTablet}, B:${isDesktop}`;

        return <div><span>{display}</span>{isFunc ? accentUtils.isNull(p.children) ? args2 : p.children(args2) : (show ? p.children : null)}</div>;
    }




    return isFunc ? accentUtils.isNull(p.children) ? args2 : p.children(args2) : (show ? p.children : null);
};




export const usePosition = (id) => {


    const display = useAccentDisplay();

    const [, setRefresh] = React.useState(1);
    const pos = React.useRef(null);
    const observer = React.useRef(null);

    const onChangeDebounced = React.useRef(debounce(action => action(), 800, { trailing: false }));


    const isDialog = React.useCallback(e => {

        let elm = document.getElementById(id);

        while (elm != null) {

            if (elm.classList.contains("k-dialog-content")) {

                return { isDialog: true, element: elm };

            }

            elm = elm.parentElement;

        }
        return { isDialog: false, element: null };
    }, [id]);

    const onChange = React.useCallback(e => {
        const element = document.getElementById(id);

        if (accentUtils.isNull(element)) return;

        const p = element.getBoundingClientRect();




        if (accentUtils.isNull(pos.current) || pos.current.top != p.top || pos.current.left != p.left) {


            

            
            pos.current = {
                top: p.top,
                left: p.left,
                bottom: p.bottom,
                right:p.right,
            };

//            setRefresh(r => r + 1);            

            onChangeDebounced.current(() => {
                setRefresh(r => r + 1);            
            });            
           
        }
    }, [pos, setRefresh, id, onChangeDebounced]);



    React.useEffect(() => {

        
        observer.current = new MutationObserver(e => onChange(e));

        observer.current.observe(document.body, {
            childList: true,
            attributes: true,
            characterData: false,
            subtree: true,
            attributeOldValue: false,
            characterDataOldValue: false
        });


        onChange();

        return () => {
            observer.current.disconnect();
        };

    }, [id, onChange]);


    const res = isDialog();

    const toScreenHeight = res.isDialog ? (res.element?.nextElementSibling?.getBoundingClientRect()?.top??0) - (pos.current?.top ?? 0) - 20 : display.height - (pos.current?.top ?? 0);


    return {
        positionKnown: !accentUtils.isNull(pos.current),
        display: display,
        ...pos.current,
        toScreenWidth: display.width - (pos.current?.left??0),
        toScreenHeight: toScreenHeight,

    }; 


};






export const withPosition = (Component) => React.memo(props => {


    const [id, ] = React.useState(`id_${newGuid()}`);
    const position = usePosition(id);
   
    let content = <Component {...props} position={position} posID={id} />;

    return content
    
});




export const useWindowSize = (p) => {
    const isClient = typeof window === 'object';
    const onChangeDebounced = React.useRef(debounce(action => action(), 800, { trailing: false }));

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = React.useState(getSize);


    const handleResize = React.useCallback(() => {

        const s = getSize();


        onChangeDebounced.current(() => {
            setWindowSize(s);
        }); 

    }, [onChangeDebounced]) 


    React.useEffect(() => {
        if (!isClient) {
            return false;
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); 

    return windowSize;
}



