import { isNumeric } from "jquery";
import { queryNoTracking } from "./DataService";
import accentUtils from "./helpers/AccentUtils";
import { Helper, MessageTypes } from "./MessageService";

const query_GetEntityContext = queryNoTracking("GetEntityContext");

let context = null;

export const contextSources = {
    grid: "GRID",
    view: "VIEW",
    mfGrid: "MF_GRID",
    commissionGrid: "COMMISSION_GRID"
};


const contextDescriptor = (type, id) => {
    return { type, id };
};


const contextProviders = {

    "ViewDefault": (key, id) => (isNumeric(id)) ? contextDescriptor(key, id) : null,
    "GridDefault": (key, data) => (!accentUtils.isNull(data)) ? contextDescriptor(key, data.ID) : null,
    "MFGrid": (key, data) => (!accentUtils.isNull(data)) ? contextDescriptor(key, data.PurchaseOrderID) : null,
    "CommissionGrid": (key, data) => (!accentUtils.isNull(data)) ? contextDescriptor(key, data.JobID) : null,
};


export function setContext(source, contextKey, data) {

    

    if (accentUtils.isNull(contextKey) || accentUtils.isNull(source)) return;

    context = null;


    if (source === contextSources.commissionGrid) {
        context = contextProviders["CommissionGrid"](contextKey, data);
    }


    if (source === contextSources.mfGrid) {
        context = contextProviders["MFGrid"](contextKey, data);
    }



    if (source === contextSources.grid ) {
        context = contextProviders["GridDefault"](contextKey, data);        
    }


    if (accentUtils.isNull(context))
        context = contextProviders["ViewDefault"](contextKey, data);

    Helper.notify(MessageTypes.ContextChanged, context);


    
}

export function getContext() {
    return context;
};
export async function getContextData(context) {

    if (accentUtils.isNull(context)) return null;

    return await query_GetEntityContext.getFirstOrDefault(context);

};




