import React from 'react';
import { AccentButton } from '../controls/AccentButton';
import { t } from '../services/HelperService';
import { AccentDialog } from './AccentDialog';

class JobOnHoldErrorDlg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: props.result, // Assume result is passed as a prop
        };

        
    }

    renderErrorMessages() {
        const { result } = this.state;
        const errorMessages = [];

        if (result.HasCMBooked) {
            errorMessages.push(t("application_strings.application.dialogs.jobOnHoldErrorDlg.hasCMBooked"));
        }
        if (result.HasCMInprogress) {
            errorMessages.push(t("application_strings.application.dialogs.jobOnHoldErrorDlg.hasCMInprogress"));
        }
        if (result.HasDispatch) {
            errorMessages.push(t("application_strings.application.dialogs.jobOnHoldErrorDlg.hasDispatch"));
        }
        if (result.HasStockConsumed) {
            errorMessages.push(t("application_strings.application.dialogs.jobOnHoldErrorDlg.hasStockConsumed"));
        }
        if (result.HasStockDiscarded) {
            errorMessages.push(t("application_strings.application.dialogs.jobOnHoldErrorDlg.hasStockDiscarded"));
        }
        if (result.HasEdit) {
            errorMessages.push(t("application_strings.application.dialogs.jobOnHoldErrorDlg.hasEdit"));
        }

        return errorMessages.map((msg, index) => <p key={index}>{msg}</p>);
    }

    render() {
        return (
            <AccentDialog
                maxWidth={600}
                title="Job On Hold Errors"
                onClose={this.props.onClose}
                actionsContent={[<AccentButton key="1" tagName="ok" onClick={this.props.onClose}>{t("application_strings.application.buttons.ok")}</AccentButton>]}
            >
                <div>
                    {this.renderErrorMessages()}
                </div>
            </AccentDialog>
        );
    }
}

export default JobOnHoldErrorDlg;
