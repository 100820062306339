import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import { AccentDialog } from '../../dialogs/AccentDialog';

const msgNotStarted = "Not started";
const msgLoadingProduct = "Loading product...";
const msgApplyingChanges = "Applying changes..."
const msgDone = "Done";

const statusIcons = {
    [msgNotStarted]: '🔴', // Example icon, replace with appropriate icons
    [msgLoadingProduct]: '🟡',
    [msgApplyingChanges]: '🟠',
    [msgDone]: '🟢',
};



const ApplyLineItem = ({ item }) => {

    return <li >
        {statusIcons[item.message]} {item.productName}: <Badge>{item.message}</Badge>
    </li>;
};


export const MultiConfigApplyOutstandingDlg = ({ onClose, actions }) => {
    const [items, setItems] = useState(actions.map(action => ({
        ...action,
        message: msgNotStarted,
    })));


    const closeWithDelay = React.useCallback(() => {

        return new Promise(p => {

            setTimeout(async () => {
                await onClose({});

                p();

            }, 1000);


        });

        
    }, [onClose]);


    useEffect(() => {
        const processActionsSequentially = async () => {
            for (let i = 0; i < items.length; i++) {
                // Load product
                setItems(currentItems =>
                    currentItems.map((item, index) =>
                        index === i ? { ...item, message: msgLoadingProduct } : item
                    )
                );
                await items[i].loadConfig(items[i]);

                // Apply changes
                setItems(currentItems =>
                    currentItems.map((item, index) =>
                        index === i ? { ...item, message: msgApplyingChanges } : item
                    )
                );
                await items[i].applyChanges(items[i]);

                // Mark as done
                setItems(currentItems =>
                    currentItems.map((item, index) =>
                        index === i ? { ...item, message: msgDone } : item
                    )
                );
            }

            await closeWithDelay();

            
        };

        processActionsSequentially();
    }, []); // Ensure useEffect does not re-run inadvertently

    return (
        <AccentDialog
            noCancel
            maxWidth={800}
            onClose={onClose}
            headerContent={"Applying Changes"}
        >
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {items.map((item, index) => (
                    <ApplyLineItem key={ index} item={ item } />
                ))}
            </ul>
        </AccentDialog>
    );
};
