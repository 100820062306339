import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Progress } from 'reactstrap'
import $ from 'jquery'
import { Loader } from '@progress/kendo-react-indicators'
import { accentUtils } from '../services/HelperService';

export class AccentSpinner extends React.Component {

    constructor(props) {
        super(props);


        this.hide = this.hide.bind(this);

        this.state = { show: true };

    }

    hide() {
        this.setState({ show: false });
    }

    render() {
        if (this.state.show) {

            const size = accentUtils.isNull(this.props.large) ? 'small' : 'large'; 

            const type = this.props.type ?? "converging-spinner"; //pulsing

            return (<div className={this.props.className} style={this.props.style}><Loader size={size} type={ type} /></div>);
        }
        return <div></div>
    }

}


export class LoadingPanel extends React.Component {

    constructor(props) {
        super(props);

        this.componentDidMount = this.componentDidMount.bind(this);
        this.state = { content: null };
    }


    componentDidMount() {

        var me = this;

        var g = me.props.onGetDOM();
        var content = $(g).find('.k-grid-content');

        if (content && content.length > 0)
            this.setState({
                content: content[0]
            });

    }

    render() {

        var me = this;



        const loadingPanel = (
            <div className="k-loading-mask" onContextMenu={this.props.onContextMenu }>
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
            </div>
        );



        return this.state.content ? ReactDOM.createPortal(loadingPanel, this.state.content) : loadingPanel;
    }
}


export const ProgressBar = props => {
    return <Progress animated color="primary" value="100" />;
}