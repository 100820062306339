import * as React from 'react';

export default class GridErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service

    }

    render() {

        if (this.state.showResetGrid) {
            return <label>This grid encountered an error while loading. Click <div className="acc-link" style={{ display: "inline-block" }} onClick={()=> alert('TODO')}>here</div> to reset grid to default settings</label>;
        }
        return this.props.children;
    }
}