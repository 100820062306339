import * as React from 'react';
import classNames from 'classnames'
import {
    Excalidraw,
    exportToBlob
} from "@excalidraw/excalidraw";

import { accentUtils, newGuid } from '../../services/HelperService';

import './Excalidraw.css';
import AccentIcons from '../../icons/Icons';
import { Settings } from '../../services/AppSettingsService';
import { getUser } from '../../services/UserService';



function getDefaultAppState() {

    const settings = Settings.GetSketchSettings();

    if (accentUtils.isEmpty(settings)) return {};

    const appState = JSON.parse(settings);

    delete appState.collaborators;

    return appState;

}

export const StetchCtrl = props => {


    const [defaultValue, setDefaultValue] = React.useState({ ...props.defaultValue, appState: getDefaultAppState() });
    const [fullscreen, setFullscreen] = React.useState(false);
    const [refresKey, setRefreshKey] = React.useState(0);
    const [excalidrawAPI, setExcalidrawAPI] = React.useState(null);
    const [pointerData, setPointerData] = React.useState(null);

    


    React.useImperativeHandle(props.methods, () => ({
        getSketch: async () => {


            const elements = excalidrawAPI.getSceneElements();
            const appState = excalidrawAPI.getAppState();
            const files = excalidrawAPI.getFiles();


            const user = getUser();

            const maxWidth = Number(user.MaxImageWidth);
            const maxQuality = Number(user.MaxImageQuality);

            const blob = await exportToBlob({
                elements: elements,
                files: files,
                appState: appState,
                getDimensions: (width, height) => {

                    if (width < maxWidth) {
                        return { width: width, height: height };
                    }

                    const scale = maxWidth / width;
                    return { width: maxWidth, height: height * scale, scale: scale };
                },
                exportPadding: 10,
                quality: maxQuality
            });


            const image = await accentUtils.blobToBase64(blob);

            Settings.SetSketchSettings(JSON.stringify(appState));


            return {
                elements: elements,
                image: image,
                files: files
            };
        },
    }), [excalidrawAPI]);


    const setFullScreenMode = React.useCallback(full => {

        setRefreshKey(k => k + 1);
        setFullscreen(full);

    }, [setFullscreen, setRefreshKey]);




    const className = classNames("stetch-ctrl", { "fullscreen": fullscreen })
    
    return <div className={className} >
        <Excalidraw        
            key={ refresKey}
            ref={(api) => setExcalidrawAPI(api)}
            initialData={defaultValue}
            gridModeEnabled
            onChange={(elements, state) => {
                if (props.onChange) props.onChange({ elements: elements });
                }}
            onPointerUpdate={(payload) => setPointerData(payload)}
            name="Custom name of drawing"
            UIOptions={{ canvasActions: { loadScene: false } }}
            renderTopRightUI={() => {
                return (
                    (fullscreen) ? <AccentIcons.FullscreenClose onClick={() => setFullScreenMode(false)} /> : <AccentIcons.Fullscreen onClick={() => setFullScreenMode(true)} left={-25} />
                );
            }}

        >    
        </Excalidraw>
    </div>;

};



