import * as React from 'react'
import { Table } from 'reactstrap'
import * as DataService from '../../services/DataService';
import { accentUtils, format } from '../../services/HelperService';

import { InstallTimeBadge } from './InstallTimeBadge'
import { AccentSpinner } from '../AccentSpinner'


const query_BookServiceContactInfo = DataService.queryNoTracking("BookServiceContactInfo", undefined, true);

export class CalendarServiceCtrl extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            data: null
        };


        
        this.componentDidMount = this.componentDidMount.bind(this);

    }


    componentDidMount() {

        const me = this;
        query_BookServiceContactInfo.getFirstOrDefault({
            JobID: this.props.id,
            LineIDs: (accentUtils.isNull(this.props.lineFilter) ? null : this.props.lineFilter),
        }).then(function (r) {
            me.setState({ data: r });
        });

    }

    render() {

        if (this.state.data === null) return <AccentSpinner />;



        return <Table className="accentCalendarCheckMeasureCtrl">
            <tr>
                <td><label>Last Service Date: &nbsp;</label>{format.formatDate(this.state.data.ServiceData.length > 0 ? this.state.data.ServiceData[0].Service_Date : null)}</td>
                <td><label>Last Service By: &nbsp;</label>{this.state.data.ServiceData.length > 0 ? this.state.data.ServiceData[0].Service_By : null}</td>
                <td><label>Address: &nbsp;</label>{this.state.data.Address}</td>
                <td><label>Mobile: &nbsp;</label>{this.state.data.Mobile}</td>
                <td><label>Work: &nbsp;</label>{this.state.data.WorkPhone}</td>
                <td><label>Home: &nbsp;</label>{this.state.data.HomePhone}</td>
                <td><label>Email: &nbsp;</label>{this.state.data.Email}</td>
                <td><label>Est Install Time: &nbsp;</label><InstallTimeBadge EstInstallTime={this.state.data.EstInstallTime} /></td>
            </tr>
        </Table>;
    }
}

