import * as React from 'react';
import classNames from 'classnames'
import { AccentDialog } from '../../dialogs/AccentDialog';
import AccentIcons from '../../icons/Icons';
import { accentUtils, showDialog } from '../../services/HelperService';
import { AccentButton } from '../AccentButton';
import { getOptionModel, getProductOptionValue, isReadOnly, setOptionValueChanged, toGuid, useActionType, useChanged } from './ProductConfiguratorShared';
import { ConfiguratorContext } from './ConfigContextProvider';


export const ValueActionBtn = props => {

    const actionType = useActionType(props.optionID);


    const ctx = React.useContext(ConfiguratorContext);
    const optionModel = getOptionModel(ctx, props.optionID);

    const changed = useChanged(props.optionID, "ValueActionBtn", true);


    const readOnly = isReadOnly(optionModel);



    const onClick = React.useCallback(e => {

        showDialog(<SelectValueDlg ctx={ctx} optionModel={optionModel} readOnly={readOnly} />).then(res => {

            if (!res.canceled) {

                const id = toGuid(res.selected?.id);

                const value = optionModel.GetProductOptionValue(id);

                optionModel.SetValue(window.InsyteConfig.OptionModelSetValueArgs.From(id, value?.Description ?? null));

                setOptionValueChanged(ctx, optionModel);

            }

        });

    }, [ctx, optionModel]);

    if (!actionType.isPicklist) {
        return null;
    }

    const className = classNames("config-value-action",
        {
            "readonly" : readOnly
        }
    );

    return <div className={className}>
        <AccentIcons.More onClick={readOnly ? undefined : onClick} noPadding />
    </div>;

};


const ValueItem = React.memo(props => {


    const [expanded, setExpanded] = React.useState(false);

    const src = props.baseImagePath.replace("{0}", props.image);

    const onClick = React.useCallback(() => {

        const isClear = props.item.ItemKey.toString() === accentUtils.getEmptyGuid();
        props.onSelect({ id: props.item.ItemKey.toString(), description: isClear? "<Clear>" : props.item.ItemValue });

    }, [props]);


    const className = classNames("config-value-item", {
        "selected" : props.selected
    });

    var value = accentUtils.isEmpty(props.item.ItemKey) ? null : props.optionModel.GetProductOptionValue(props.item.ItemKey);

    const hasImage = !accentUtils.isEmpty(props.image);

    const imgStyle = expanded ? undefined : {
        maxWidth: "150px",
        maxHeight: "120px"
    }


    return <div className={className} onClick={onClick }>
        <div className="config-value-item-header">
            <AccentIcons.ExpandContent noPadding style={{ width:"30px"}} onClick={e => setExpanded(x => !x)} />
            <div>{value?.Description ?? "<CLEAR>"}</div>            
        </div>
        <div className="config-value-item-image">{hasImage && <img style={imgStyle} src={src} />}</div>
    </div>;

});

function getImage(optionModel, id) {

    const val = optionModel.GetProductOptionValue(id);

    if (!accentUtils.isEmpty(val?.Image)) {
        return val.Image;
    }

    return null;

}

export const SelectValueDlg = props => {


    const value = getProductOptionValue(props.optionModel);

    const [selectedValue, setSelectedValue] = React.useState(accentUtils.isNull(value) ? null : { id: value.ID.toString(), description: value.Description  });



    const onClose = React.useCallback(() => {

        if (props.readOnly) {
            props.onClose({ canceled: true });
        } else {
            props.onClose({ selected: selectedValue });
        }
    }, [props, selectedValue]);


    const values = props.optionModel.FilteredItems.toArray().map(i => <ValueItem optionModel={props.optionModel} item={i} onSelect={setSelectedValue} selected={(selectedValue?.id ?? "XXX") === i.ItemKey.toString()} baseImagePath={props.ctx.baseImagePath} image={getImage(props.optionModel, i.ItemKey)} />);

    const btns = accentUtils.isNull(selectedValue) ? [] : [<AccentButton key="optCloseBtn" tagName="optCloseBtn" onClick={onClose}>{`${selectedValue?.description}`}</AccentButton>];

    return <AccentDialog        
        onClose={props.onClose}
        headerContent={"Select Value"}
        actionsContent={btns}        
    >
        <div className="config-value-item-container">
            {values}
        </div>        
    </AccentDialog>

};