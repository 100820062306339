import * as React from 'react'
import $ from 'jquery'
import { Row, Col } from 'reactstrap'
import { accentUtils, from } from '../../services/HelperService'
import * as DataService from '../../services/DataService'
import * as ProductService from '../../services/ProductService'
import { AccentSpinner } from '../AccentSpinner';
import { MultiProductConfigurator, ProductConfigurator } from '../products/ProductConfigurator'

const query_JobLineForID = DataService.queryNoTracking("JobLineForID");
const query_JobLineOtherMultiLines = DataService.queryNoTracking("JobLineOtherMultiLines");



export class BulkUpdateCtrl extends React.Component {

    constructor(props) {
        super(props);


        this.configCtrl = React.createRef();

        this.configChanged = this.configChanged.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);        
        this.updateMandatoryOptions = this.updateMandatoryOptions.bind(this);
        this.findProduct = this.findProduct.bind(this);
        this.diffObjects = this.diffObjects.bind(this);
        this.save = this.save.bind(this);
        this.getChangesForLine = this.getChangesForLine.bind(this);
        this.getChanges = this.getChanges.bind(this);
        this.queryMultiLines = this.queryMultiLines.bind(this);
        this.getLines = this.getLines.bind(this);
        
        this.currentOLOsByLine = {};


        this.state = { entity: null, additionalLines: null };

        this.productID = '00000000-0000-0000-0000-000000000000';






    }

    async queryMultiLines(line) {

        var res = await query_JobLineOtherMultiLines.getAll({ id: line.ID, jobID: line.JobID, multiGroupID: line.MultiGroupID });


        res.forEach(l => {
            this.currentOLOsByLine[l.ID] = JSON.parse(l.OrderLineOptionsData);
        });

        this.setState({ additionalLines: res });

        return res;

    }

    getLines() {
        return this.configCtrl.current?.getLines() ?? [];
    }

    findProduct(id) {
        return ProductService.getProduct(id);
    }

    diffObjects(original, final) {

        var res = {
        };

        for (var key in final) {
            if (final[key] !== original[key]) {

                if (accentUtils.isEmpty(final[key]) && accentUtils.isEmpty(original[key])) {
                    continue;
                }

                if (!accentUtils.isEmpty(final[key]) && !accentUtils.isEmpty(original[key])) {
                    if (("" + final[key]).toUpperCase() == ("" + original[key]).toUpperCase()) {
                        continue;
                    }
                }



                res[key] = final[key]
            }
        }

        return res;

    }

    async getChanges() {

        const me = this;

        await me.save();


        const changesByLine = {};


        if (!me.configCtrl.current) return;

        const lines = this.configCtrl.current.getLines();


        lines.forEach(line => {

            const changes = me.getChangesForLine(line);

            changesByLine[line.ID] = changes;


        });

        return changesByLine;

    }

    getChangesForLine(line) {

        const me = this;

        const oringalOLOs = from(me.currentOLOsByLine[line.ID]).select(function (o) { return { ...o }; }).toArray();


        const current = JSON.parse(line.OrderLineOptionsData);

        const changes = from(current).select(
            function (o) {

                var originalOLO = from(oringalOLOs).firstOrDefault(function (oo) { return oo.ProductOptionID.toUpperCase() == o.ProductOptionID.toUpperCase(); });

                if (accentUtils.isNull(originalOLO)) {
                    return o;
                }

                //preserve userset
                var isUserSet = o.UserSet;

                var res = me.diffObjects(originalOLO, o);

                if (Object.keys(res).length == 0) {
                    return null;
                }


                res.UserSet = isUserSet;
                res.SupplierID = o.SupplierID;

                res.ProductOptionID = o.ProductOptionID;
                return { ...o, ...res };
            }).where(function (o) { return o != null; }).toArray();


        return {
            changes: changes,
            original: oringalOLOs,
            productID: line.ProductID,
            multiConfigID: line.multiConfigID
        };
    }

    updateMandatoryOptions() {


    }



    componentDidMount() {

        const me = this;
        query_JobLineForID.getFirstOrDefault({ id: this.props.id, include: null }).then(function (data) {
            me.productID = accentUtils.isNull(data.ProductID) ? '00000000-0000-0000-0000-000000000000' : accentUtils.isEmpty(data.MultiProductID) ?  data.ProductID : data.MultiProductID;

            if (!accentUtils.isEmpty(data.OrderLineOptionsData)) {
                me.currentOLOsByLine[data.ID] = JSON.parse(data.OrderLineOptionsData);
            }

            me.setState({ entity: data });
        });


    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }


    async save() {
        await this.configCtrl.current?.save();
    }
    
    configChanged() {
        


    }




    render() {

        var me = this;

        if (accentUtils.isNull(me.state.entity)) return <AccentSpinner />;


        var product = this.findProduct(me.productID);


        var configCrl = <MultiProductConfigurator
            product={product}
            methods={this.configCtrl}
            onNewMultiLine={undefined}
            queryMultiLines={this.queryMultiLines}
            orderLine={this.state.entity}
            isQuote={this.props.isQuote}
            productID={product.ID}
            onChange={this.configChanged}
            mpResellerKeys={product.ResellerMarketplaceKeys}
            businessUnitID={this.props.businessUnitID}
            onLoaded={this.updateMandatoryOptions}
            ignoreSizes
            hideSize
            hideExtras
            filter={[]}
            allowExcludeExistingLine={undefined}
            onExcludeExistingLineAttempt={undefined}
            allowIncludeExclude={ false}
        />;


        return (
            <Col sx={12} className="product-line-vw">
                {configCrl}
            </Col>
        );
    }
}


const LineChangeReviewCtrl = ({ line, changes, original }) => {



    const rows = changes.map((c, index) => {

        const originalOLO = from(original).firstOrDefault(x => x.ProductOptionID.toUpperCase() === c.ProductOptionID.toUpperCase());

        return <Row key={`o_${c.ProductOptionID}`}>
            <Col xs={3} sm={3} >{index === 0 ? line.ProductName : ""}</Col>
            <Col xs={3} sm={3} xl={1} md={2}>{c.ProductOptionName}</Col>
            <Col xs={3} sm={3} xl={1} md={2}>{originalOLO?.CustomValue ?? "<NONE>"}</Col>
            <Col xs={3} sm={3} xl={1} md={2}>{c.CustomValue ?? "<NONE>"}</Col>
        </Row>;
    });

    return <React.Fragment>        
            {rows}        
    </React.Fragment>
};

export const ProductChangeReviewCtrl = ({ lines, changes }) => {



    const rows = lines.map(line => {

        const lineChanges = changes[line.ID];

        if (accentUtils.isNull(lineChanges)) return <div key={line.ID} />;

        return <LineChangeReviewCtrl key={line.ID} line={line} changes={lineChanges.changes} original={lineChanges.original} />;

    });



    return <Col md={12}>
            <Row style={{ fontWeight: "bold" }}>
                <Col xs={3} sm={3} >Product</Col>
                <Col xs={3} sm={3} xl={1} md={2}>Value</Col>
                <Col xs={3} sm={3} xl={1} md={2}>Original</Col>
                <Col xs={3} sm={3} xl={1} md={2}>Changed</Col>
            </Row>
            <hr />
            {rows}
    </Col>;


};