import * as MessageService   from './MessageService'
import * as JobService from './JobService'
import * as AppSettingsService from './AppSettingsService'
import userService from './UserService'
import { format, InitializeHelpers } from './HelperService'
import { Loader } from '@googlemaps/js-api-loader';
import accentUtils from './helpers/AccentUtils';
import './../../assets/expression-builder.css'
import './../../assets/expression-builder-v2'
import { DefaultValueSetter } from './DefaultValueService';
import { loadLocallyCachedProducts, refreshProductExclusionList, refreshProducts } from './ProductService';
import { locale as devextremeLocale } from "devextreme/localization";

import {
    load,
} from "@progress/kendo-react-intl";
import { queryNoTracking } from './DataService';
import { registerPermanentQueries } from '../../PermanentQueries';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const query_InitializeBreeze = queryNoTracking("InitializeBreeze")

let googleMaps = null;
let googleMapsLoadCalled = false;
let google = null;
let appInsightsReactPlugin = reactPlugin;
let appInsytes = null;

export const registerServices = async () => {


    const user = await userService.Initialize();

    if (accentUtils.isNull(user)) {
        return false;

    }


    
    if (googleMaps === null) {
        googleMaps = new Loader({
            apiKey: user.MapsKey,
            version: "quarterly",
            libraries: ["places", "drawing", "geometry"]
        });
    }

    await query_InitializeBreeze.getAll();

    await InitializeHelpers();

    loadLocallyCachedProducts(user);

    refreshProducts();


    refreshProductExclusionList();

    const appsettingsLoad = AppSettingsService.Settings.Initialize();

    const googlePromise = googleMapsLoadCalled ? Promise.resolve() : googleMaps.load();

    if (DefaultValueSetter == null) {
        console.log("DefaultValueSetter: ", DefaultValueSetter);
    }

    window.userCurrencySymbol = format.getCurrencySymbol();
    console.log("window.userCurrencySymbol: " + window.userCurrencySymbol);

    let likelySubtags = null;
    let currencyData = null;
    let weekData = null;


    let curr = null;
    let num = null;
    let calgen = null;
    let calgra = null;
    let dateFlds = null;
    let tmx = null;

    const loadAnyway = true;

    const locale = (user.Locale === "en-US") ? "en" : user.Locale;


    if (process.env.NODE_ENV !== 'development' || loadAnyway) {


        likelySubtags = import("cldr-core/supplemental/likelySubtags.json");
        currencyData = import("cldr-core/supplemental/currencyData.json");
        weekData = import("cldr-core/supplemental/weekData.json");




        curr = import(`cldr-numbers-full/main/${locale}/currencies.json`);
        num = import(`cldr-numbers-full/main/${locale}/numbers.json`);
        calgen = import(`cldr-dates-full/main/${locale}/ca-generic.json`);
        calgra = import(`cldr-dates-full/main/${locale}/ca-gregorian.json`);
        dateFlds = import(`cldr-dates-full/main/${locale}/dateFields.json`);
        tmx = import(`cldr-dates-full/main/${locale}/timeZoneNames.json`);

    }

    devextremeLocale(locale);

    appInsytes = new ApplicationInsights({
        config: {
            connectionString: user.AppInsightsKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: null }
            }
        }
    }).loadAppInsights();

    const modules = await Promise.all([
        // required for loading
        googlePromise,
        likelySubtags,
        currencyData,
        weekData,
        curr,
        num,
        calgen,
        calgra,
        dateFlds,
        tmx,
        Promise.resolve(), //AppSettingsService.Settings.Initialize(),
    //    settingsPromise,
        Promise.resolve(), //refreshProducts(),
        Promise.resolve(), //refreshProductExclusionList()
    ]);



    google = modules[0];


    if (process.env.NODE_ENV !== 'development' || loadAnyway) {


        await appsettingsLoad;

        const startOfWeek = AppSettingsService.Settings.GetCalendar().StartOfWeek ?? 0;

            // Assuming modules[3] contains the weekData
        let weekData = modules[3].supplemental.weekData.firstDay;

        const day = accentUtils.startOfWeekToDayName(startOfWeek);

        // Update all locales to start the week on Sunday
        for (let locale in weekData) {
            if (weekData.hasOwnProperty(locale)) {
                weekData[locale] = day;
            }
        }

        



        load(
            modules[1],
            modules[2],
            modules[3],
            modules[4],
            modules[5],
            modules[6],
            modules[7],
            modules[8],
            modules[9],
        );
    }

   // await Promise.all(otherPromises);

    // not required for loading
    MessageService.Helper.Initialize();
    JobService.Helper.Initialize();
    registerPermanentQueries();
    return true;

}


export { google, appInsytes, appInsightsReactPlugin };
