import * as React from 'react'
import { accentUtils, t, showDialog } from '../services/HelperService'
import { queryNoTracking, update } from '../services/DataService'
import { AccentComboUnbound } from './../controls/unbound/AccentComboUnbound'
import { AccentSpinner} from '../controls/AccentSpinner'
import { AccentDialog } from './AccentDialog'
import { AccentButton } from '../controls/AccentButton'



const query_PL_CommitmentReasons = queryNoTracking("PL_CommitmentReasons");

export class UpdateCommitReasonDlg extends React.Component { 

    static Show(jobIDs) {

        return new Promise(p => {

            showDialog(<UpdateCommitReasonDlg />).then(s => {
                if (!s.canceled) {
                    update('Jobs_SetCommitSeason', {
                        CommitReasonID: s.commitReason,
                        JobIDs: jobIDs
                    }).then(r => {
                        p(r);
                    });
                }
            });
        });
    }


    constructor(props) {
        super(props)

        this.state = {
            commitReason: null
        };

        this.allowOk = this.allowOk.bind(this);
        this.getBody = this.getBody.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.onClose = this.onClose.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onChanged = this.onChanged.bind(this);


    }

    onChanged(e) {
        this.setState({ commitReason: e });

    }

    componentDidMount() {

        
        query_PL_CommitmentReasons.getAllWithCache({includeID:null, activeOnly:true})
            .then(r => {
            this.setState({ data: r });
        });

    }

    getTitle() {
        return t("application_strings.views.tracking.updateCommitReasonTitle");
    }

    allowOk() {
        return !accentUtils.isEmpty(this.state.commitReason);
    }

    onClose(e) {
        this.props.onClose({ ...e, commitReason: this.state.commitReason });        
    }

    getBody() {

        if (accentUtils.isNull(this.state.data)) return <AccentSpinner />;


        return <div className="center">

            <AccentComboUnbound displayField="Description" valueField="ID" onChange={this.onChanged} label="application_strings.views.tracking.updateCommitReasonLabel" data={this.state.data} />

        </div>;
    }

    render() {
        return <AccentDialog
            headerContent={this.getTitle()}
            maxWidth={ 600 }
            onClose={this.props.onClose}
            actionsContent={[
                <AccentButton key="2" tagName="calCloseBtn" onClick={this.onClose}>
                    {t("application_strings.application.buttons.save")}
                </AccentButton>
            ]}
        >
            { this.getBody()}
        </AccentDialog>
    }
}