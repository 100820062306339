import * as React from 'react';
import { Col, Row, Table } from 'reactstrap';
import { accentUtils, format, from } from '../../services/HelperService';

export const EstimatedCommissionsCtrl = props => {
    const list = props.data.Transactions.map(t => <EstimatedCommissionsRuleCtrl {...t} />);



    return <Row>
        <Col md={12}>

            <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                {list }
            </ul>                      

        </Col>
    </Row>;

};


export const EstimatedCommissionsRuleCtrl = props => {


    return <li><EstimatedCommissionsValueCtrl description={props.Description} amount={ props.Amount} details={props.Details}/> </li>;
};


export const EstimatedCommissionsValueCtrl = props => {


    const details = from(props.details).select(d => JSON.parse(d.Values)).selectMany(v => v.Values).select(v => v.value).where(v=>v.ShowOnStatement).orderBy(v => v.SortIndex).toArray();

    const columnHeaders = from( details).select(v => v.Name).distinct().toArray();

    const headerRow = <thead className="thead-light">
        <tr>
            <th colSpan={columnHeaders.length}>{props.description} : {format.formatCurrency(props.amount)} </th>
        </tr>
        <tr>{columnHeaders.map(h => <th className="acc-num-header-col">{h.replaceAll("_"," ")}</th>)}</tr>
    </thead>;

    const rows = from(props.details).select(d => JSON.parse(d.Values)).select(d => {




        const cols = columnHeaders.map(h => (accentUtils.isNull(d.Values[h])) ? <td /> : <td className="acc-num-col">{d.Values[h].IsText ? d.Values[h].TextValue : format.formatNumber(d.Values[h].Amount)}</td>);

        return <tr>{cols}</tr>;
    }).toArray();

    return <ul style={{ listStyle : "none", paddingLeft : "0px"}}><li><Table>
        {headerRow}
        <tbody>
            {rows}
        </tbody>
    </Table>
    </li>
    </ul>;
};