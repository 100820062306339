import React from 'react'
import classNames from 'classnames'
import { Button, ButtonGroup } from 'reactstrap'
import { MultiSelect } from '@progress/kendo-react-dropdowns'
import { t, accentUtils, from } from '../services/HelperService';
import { AccentSpinner } from './AccentSpinner'

export const FilterButtons = (props) => {


    const [cSelected, setCSelected] = React.useState(accentUtils.isEmpty(props.defaultValue) ? [] : props.defaultValue);
    const [rSelected, setRSelected] = React.useState(accentUtils.isEmpty(props.defaultValue) ? null : props.defaultValue);

    const onCheckboxBtnClick = (selected) => {
        const index = cSelected.indexOf(selected);
        if (index < 0) {
            cSelected.push(selected);
        } else {
            cSelected.splice(index, 1);
        }

        var newVal = [...cSelected];
        setCSelected(newVal);

        if (props.onChange)
            props.onChange({ value: newVal });

    }


    const buttons = props.options.map(o => {

        const active = props.check ? cSelected.includes(o.ID) : rSelected === o.ID;
        const click = props.check ? () => {
            onCheckboxBtnClick(o.ID);
        } : () => {
            setRSelected(o.ID);
            if (props.onChange)
                props.onChange({ value: o.ID });
        }

        const color = accentUtils.isEmpty(o.Color) ? "primary" : o.Color;

        const outline = !active;

        let bTagName = accentUtils.isEmpty(o.tagName) ? undefined : `${props.tagName}_${o.tagName}`;

        return <Button disabled={props.disabled} key={o.ID} outline={outline} color={color} onClick={click} active={active} data-tagname={bTagName} data-tagtype="Button">{o.Text}</Button>;

    });

    const label = accentUtils.isEmpty(props.label) ? undefined : <label>{t(props.label)}</label>;

    const className = classNames("acc-filter-btn", props.className, {
        "has-label": !accentUtils.isNull(label)
    })
    
    const tagName = accentUtils.isEmpty(props.tagName) ? undefined : props.tagName;

    return (
        <div className={className} data-tagname={tagName} data-tagtype="FilterButton">
            {label}
            <ButtonGroup className="acc-fitler-btn">
                {buttons}
            </ButtonGroup>
        </div>
    );
}



export const FilterDropdown = (props) => {

    
    const loading = React.useRef(false);
    const [value, setValue] = React.useState(props.value);
    const [data, setData] = React.useState(null);

    const [filterState, setFilterState] = React.useState({
        data: []
    });

    const timeout = React.useRef();


    React.useImperativeHandle(props.methods, () => ({
        hasValue: () => {
            return !accentUtils.isEmpty(value);
        },
    }));


    const getNewItem = (text, value) => {
        var res = {};
        res[props.textField] = text;
        res[props.valueField] = value;
        return res;
    };
    const getTagItem = (text, data) => {
        return { text : text, data: data};        
    };

    const handleChange = React.useCallback((event) => {

        const newValues = event.target.value;

        const allInNewValue = from(newValues).any(v => v[props.valueField] == "ALL");
        const allInPrevValue = from(value).any(v => v[props.valueField] == "ALL");

        const allClicked = allInNewValue != allInPrevValue;

        const args = {
            value: [],
            all : false
        };

        if (allClicked) {
            if (allInNewValue) {
                setValue(data);
                args.value = from(data).where(v => v[props.valueField] !== "ALL").toArray();
                args.all = true;
            } else {
                setValue([]);
            }
        } else {
            args.value = from(newValues).where(v => v[props.valueField] !== "ALL").toArray();
            setValue(args.value);
        }

        if (props.onChange) {
            props.onChange(args);
        }

    }, [value, setValue, data, props]);


    const filterChange = (event) => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {

            loading.current = false;
            setFilterState({
                data: data.slice().filter(i => from(event.value).any(x => x[props.valueField] == i[props.valueField])  ||  i[event.filter.field].toLowerCase().startsWith(event.filter.value.toLowerCase()))
            });
        }, 500);


        loading.current = true;

        setFilterState({...filterState});
        
    };


    const itemRender = React.useCallback((li, itemProps) => {
        const itemChildren = (
            <span> 
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" checked={itemProps.selected} onChange={e => { }} />                    
                    {!props.labelRender && <label className="form-check-label" style={{ display: "inline-block" }} >{li.props.children}</label>}
                    {props.labelRender ? props.labelRender(itemProps) : null}
                </div>
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    });

    React.useEffect(() => {
        if (accentUtils.isNull(data) && !loading.current) {
            loading.current = true;
            props.dataSource().then(res => {

                const r = res.map(x => ({ ...x})); //don't mutate result

                if (props.translate) {
                    r.map(i => {
                        i[props.textField] = t(i[props.textField]);                        
                    });
                }

                if (!props.hideAll) {

                    if (!from(r).any(x => x[props.valueField] === "ALL")) {
                        r.unshift(getNewItem("ALL", "ALL"));
                    }
                }

                if (props.selectAllOnLoad || accentUtils.isNull(value)) {
                    setValue(r);
                } else {

                    setValue(from(r).where(i=> from(value).any(v=> i[props.valueField] == v[props.valueField] )).toArray());
                }

                setData(r);

                loading.current = false;
                setFilterState({ data: r});

                if (props.onLoaded) {
                    props.onLoaded();
                }

            });
        }
    }, [value]);


  
    if (accentUtils.isNull(data)) {
        return <AccentSpinner />;
    }

    var d = (props.onFilter) ? props.onFilter(filterState.data) : filterState.data;

    const v = value.filter(i => from(d).any(x => x[props.valueField] == i[props.valueField]));

    const selected = v.filter(i=>i[props.valueField] !== "ALL").length;

    const tags = (selected == data.length - 1 && selected > 3) ? [getTagItem("ALL", [...v])] : selected > 3 ? [getTagItem(`${selected} items selected`, [...v])] : from(v).where(i => i[props.valueField] !== "ALL").select(i => { return getTagItem(i[props.textField], [i]); }).toArray();

    const tagName = accentUtils.isEmpty(props.tagName) ? undefined : props.tagName;


    if (d.length === 1) {
        if (d[0][props.valueField] == "ALL") {
            d = [];
        }
    }


    const className = classNames("acc-filter-drop", { "accentInvalidCtrl": props.required && selected === 0 });

    return <div className={className } style={props.style} data-tagname={tagName} data-tagtype="FilterDropdown">
        <MultiSelect
            disabled={props.readOnly}
            opened={props.readOnly ? false : undefined}
            style={{ width: "100%" }}
            data={d}
            label={t(props.label)}
            itemRender={itemRender}
            autoClose={false}
            textField={props.textField}
            dataItemKey={props.valueField}
            value={v}
            onChange={handleChange}
            filterable={true}
            onFilterChange={filterChange}
            loading={loading.current}
            tags={tags}
            placeholder={t(props.label)}
            />
        </div>

};



export const ToolbarToggleButton = (props) => {


    
    const [rSelected, setRSelected] = React.useState(accentUtils.isEmpty(props.defaultValue) ? null : props.defaultValue);


    const buttons = props.options.map(o => {

        const active = rSelected === o.ID;
        const click = () => {
            setRSelected(o.ID);
            if (props.onChange)
                props.onChange({ value: o.ID });
        };

        const color = accentUtils.isEmpty(o.Color) ? "primary" : o.Color;

        const outline = !active;

        let bTagName = accentUtils.isEmpty(o.tagName) ? undefined : `${props.tagName}_${o.tagName}`;
        const childComponent = accentUtils.isEmpty(o.child) ? <div /> : o.child;

        return <Button key={o.ID} outline={outline} color={color} onClick={click} active={active} data-tagname={bTagName} data-tagtype="Button">{o.Text}{childComponent}</Button>;

    });

    

    const tagName = accentUtils.isEmpty(props.tagName) ? undefined : props.tagName;

    return (
        <ButtonGroup className={`acc-fitler-btn ${!accentUtils.isNull(props.className) ? props.className : '' }`}>
            {buttons}
        </ButtonGroup>
    );
}


