import React from 'react'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { IntlProvider } from '@progress/kendo-react-intl';
import { accentUserLang, accentUtils, newGuid, t } from './../../services/HelperService'
import { AccentTooltip } from '../AccentTooltip';

export class AccentDateTimeUnbound extends React.Component {

    constructor(props) {
        super(props);

        this.controlID = `cid_${newGuid()}`;

        var value = this.props.defaultValue;

        if (accentUtils.isEmpty(value)) {
            value = null;
        }

        if (!accentUtils.isNull(value)) {
            value = new Date(value);
        }

        this.state = { value: value };
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        if (!accentUtils.isNull(this.props.onChange)) {
            this.props.onChange(accentUtils.isNull(e.target.value) ? null : new Date(e.target.value));
        }
        this.setState({ value: accentUtils.isNull(e.target.value) ? null : new Date(e.target.value) })
    }

    render() {

        const validationError = this.props.onValidate
            ? this.props.onValidate(this.state.value)
            : this.props.required && accentUtils.isNull(this.state.value) ? "Required" : null;

        var isValid = accentUtils.isEmpty(validationError);
        var className = isValid ? "accentDateUnbound" : "accentDateUnbound invalid";
        

        var tooltipID = "tooltip-" + this.controlID;

        var tooltip = isValid ? null : <AccentTooltip text={validationError} target={tooltipID} />;

        
        return (
            <IntlProvider locale={accentUserLang}>
                <div id={tooltipID} data-tagname={this.props.dataTagName}>
                    <DateTimePicker
                        className={ className}
                        value={this.state.value}
                        onChange={this.onChange}
                        disabled={this.props.disabled}
                        label={t(this.props.label)}
                        style={{ width: "100%" }}
                    />
                    {tooltip}
                </div>
            </IntlProvider>
        );
    }
}
