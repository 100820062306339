
import React from 'react'
import $ from 'jquery'
import { Row, Col, InputGroup, InputGroupText, Button, Input } from 'reactstrap';
import { accentUtils, t, from} from '../../services/HelperService'
import { AccentButton } from './../AccentButton'
import { RTLGridQuickFilterBar } from './RTLGridQuickFilterBar'
import AccentIcons from '../../icons/Icons'
import { getAccess } from '../../services/UserService';
import { GridToolbarActions } from './ActionsGridMenuCtrl';
import { RTLGridFilterCtrl } from '../advancedfilter/RTLGridFilterCtrl'
import { AccentDisplay } from '../AccentDisplay';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getAdhocFilter, getAdvancedFilter, getGridDetails, getGridExpandedItems, getQuickFilters } from './GridDataManager';



const quickFilterSessionStates = {

};

export const RTLGridToolbarCtrl = React.memo(props => {


    const [showQuickFilter, setShowQuickFilter] = React.useState(accentUtils.isNull(quickFilterSessionStates[props.gridID]) ? false : quickFilterSessionStates[props.gridID]);
    const [showFilters, setShowFilters] = React.useState(false);
    const [showDetails, setShowDetails] = useRecoilState(getGridDetails(props.gridID));
    const setExpanded = useSetRecoilState(getGridExpandedItems(props.gridID));

    const adhocFilter = useRecoilValue(getAdhocFilter(props.gridID));
    const advancedFilter = useRecoilValue(getAdvancedFilter(props.gridID));
    const quickFilter = useRecoilValue(getQuickFilters(props.gridID));

    const getSearchFieldID = () => {
        return `gridSearchInput_${props.gridID}`;
    };

    const toggleFilter = () => {
        setShowFilters(s => !s);
    };

    const toggleQuickFilter = () => {
        setShowQuickFilter(s => {
            quickFilterSessionStates[props.gridID] = !s;
            return !s;
        });
    };
    
    const toggleDetails = () => {

        if (showDetails) {
            setExpanded([]);
        }

        setShowDetails(s => !s);
        
    }

    const clear = e => {

        if (props.onSearch) {
            props.onSearch(null);
        }

        $(`#${getSearchFieldID()}`).val(null);

    };

    const keyPress = (event) => {

        if (event.charCode === 13) {
            var value = $(`#${getSearchFieldID()}`).val();

            if (props.onSearch) {
                props.onSearch(value);
            }
        }
    };

    React.useImperativeHandle(
        props.methods,
        () => ({
            clearText: () => $(`#${getSearchFieldID()}`).val(null),
            setText: txt => $(`#${getSearchFieldID()}`).val(txt),
            getText: () => $(`#${getSearchFieldID()}`).val()            
        }),
        [props]
    );


    if (!props.visible) return null;

    const hasAdvancedFilter = !accentUtils.isNull(advancedFilter);
    const hasAddhocFilter = !accentUtils.isNull(adhocFilter);
    const hasQuickFilter = !accentUtils.isNull(quickFilter) && from(quickFilter).any(x=>x.Selected);

    const detailsLabel = !showDetails ? "Show Details" : "Hide Details";
    const refreshStyle = true ? "success" : "danger";
    const filterStyle = hasAdvancedFilter || hasAddhocFilter ? "danger" : undefined;
    const quickFilterStyle = hasQuickFilter ? "danger" : undefined;

    const filterTooltip = hasAdvancedFilter ? advancedFilter.Name : (hasAddhocFilter ? adhocFilter.Name :  undefined);
    const readOnly = props.readOnly;
    const btnStyle = { height: "37px" };


    const showMobileActions = props.actions
        || (getAccess().modules().RTL_AdvancedFiltering() && props.allowFilter)
        || props.hasDetails;

    const additionalActions = [];

    if (props.hasDetails) {
        additionalActions.push({
            title: detailsLabel,
            onClick: toggleDetails,
            active: showDetails
        });
    }

    if ((getAccess().modules().RTL_AdvancedFiltering() && props.allowFilter)) {
        additionalActions.push({
            title: "Advanced Filters",
            onClick: toggleFilter,
            active: hasAdvancedFilter || hasAddhocFilter 
        });
        additionalActions.push({
            title: "Quick Filters",
            onClick: toggleQuickFilter,
            active: hasQuickFilter 
        });

    }


    const tagName = `tag_toolbar_${props.gridID}`;

    return <Row className="acc-grid-toolbar" data-tagname={tagName}>
        <Col lg={12} md={12}>

            <AccentDisplay exp={e => e.mobile && !e.landscape}>
                <InputGroup id="gridSearch" className="trackingSearch" size="sm" >
                    <InputGroupText><AccentButton style={btnStyle} disabled={readOnly} bsStyle={refreshStyle} outline={false} tagName="gridRefreshBtn" onClick={props.onRefresh}><AccentIcons.Refresh /></AccentButton></InputGroupText>
                    {props.showSearch && <InputGroupText><Button disabled={readOnly} style={btnStyle} id="gridSearch_Clear" onClick={clear}>X</Button></InputGroupText>}
                    {props.showSearch && <Input disabled={readOnly} onKeyPress={keyPress} defaultValue={props.defaultSearchText} type="text" name="search" id={getSearchFieldID()} data-tagname={`${tagName}_search`} placeholder={t("application_strings.views.tracking.searchPlaceholder")} />}
                    {showMobileActions && <InputGroupText><GridToolbarActions bsStyle={filterStyle ?? quickFilterStyle} additionalItems={ additionalActions} readOnly={readOnly} actions={props.actions}  /></InputGroupText>}
                </InputGroup>

            </AccentDisplay>

            <AccentDisplay exp={e => !e.mobile || e.landscape} >
                <InputGroup id="gridSearch" className="trackingSearch">
                    <InputGroupText><AccentButton style={btnStyle} disabled={readOnly} bsStyle={refreshStyle} outline={false} tagName="gridRefreshBtn" onClick={props.onRefresh}><AccentIcons.Refresh /></AccentButton></InputGroupText>
                    {props.showSearch && <InputGroupText><Button disabled={readOnly} style={btnStyle} id="gridSearch_Clear" onClick={clear}>X</Button></InputGroupText>}
                    {props.showSearch && <Input disabled={readOnly} onKeyPress={keyPress} defaultValue={props.defaultSearchText} type="text" name="search" id={getSearchFieldID()} data-tagname={`${tagName}_search`} placeholder={t("application_strings.views.tracking.searchPlaceholder")} />}

                    {props.hasDetails && <InputGroupText><AccentButton disabled={readOnly} outline={false} tagName="gridToggleDetailsBtn" onClick={toggleDetails}>{detailsLabel}</AccentButton></InputGroupText>}
                    {props.actions && <InputGroupText><GridToolbarActions readOnly={readOnly} actions={props.actions} /></InputGroupText>}
                    {getAccess().modules().RTL_AdvancedFiltering() && props.allowFilter && <InputGroupText><AccentButton disabled={readOnly} style={btnStyle} tooltip={filterTooltip} bsStyle={filterStyle} outline={false} tagName="gridAdvancedFilterBtn" onClick={toggleFilter}><AccentIcons.Filter toolTipText="Advanced Filters" top="-1" /></AccentButton></InputGroupText>}
                    {getAccess().modules().RTL_AdvancedFiltering() && props.allowFilter && <InputGroupText><AccentButton disabled={readOnly} style={btnStyle} tooltip={filterTooltip} bsStyle={quickFilterStyle} outline={false} tagName="gridQuickFilterBtn" onClick={toggleQuickFilter}><AccentIcons.Text style={{ fontSize: "18px" }} toolTipText="Quick Filters" text="QF" top="-2" /></AccentButton></InputGroupText>}
                </InputGroup>
            </AccentDisplay>

            {props.allowFilter && <RTLGridFilterCtrl gridID={props.gridID} readOnly={false} show={showFilters} filterSchemaName={props.filterSchemaName} />}
            {props.allowFilter && <RTLGridQuickFilterBar gridID={props.gridID} readOnly={readOnly} show={showQuickFilter} filterSchemaName={props.filterSchemaName} />}

        </Col>
    </Row>;
    
});