import { accentUtils } from './../../services/HelperService'
import { showWizard } from './../../wizards/RTLWizard'
import NewContactWizard  from './../../wizards/NewContactWizard'


export function onSelectAddCompany(e) {

    var defaults = null;

    if (!accentUtils.isEmpty(e.value) && !accentUtils.isEmpty(e.value.Name)) {
        defaults = {
            Name: e.value.Name,
        };
    }

    return new Promise(p => {

        showWizard(NewContactWizard, true, { defaultValues: defaults }).then(function (res) {
            if (!accentUtils.isNull(res)) {

                if (!accentUtils.isNull(e.done)) {
                    e.done(res);
                }


                p({ ID: res.CompanyID, Name: res.CompanyName });
                return;
            } else {

                if (!accentUtils.isNull(e.canceled)) {
                    e.canceled();
                }
            }

            p(null);
        });

    });
}


export function onSelectAddContact(e) {

    var defaultValues = null;

    if (!accentUtils.isEmpty(e.value)) {

        var parts = e.value.FullName.split(' ');

        var firstName = parts[0];
        var lastName = "";
        if (parts.length > 1) {
            lastName = parts.splice(1).join(' ');
        }


        if (accentUtils.isNull(defaultValues)) {
            defaultValues = {
                FirstName: firstName,
                LastName: lastName,
                CompanyID: e.companyID,
                ContactID: e.contactID,
            };
        }

    }



    return new Promise(p => {

        showWizard(NewContactWizard, true, { defaultValues: defaultValues }).then(function (res) {
            if (!accentUtils.isNull(res) && !res.canceled) {
                if (!accentUtils.isNull(e.done)) {
                    e.done(res);
                }
                p({ ID: res.ContactID, FullName: res.ContactFullName, CompanyID: res.CompanyID });
                return;
            } else {

                if (!accentUtils.isNull(e.canceled)) {
                    e.canceled();
                }
            }


            p(null);
        });

    });
}