import * as React from 'react';
import { createPortal } from 'react-dom'
import $ from 'jquery'
import { Popup} from '@progress/kendo-react-popup';
import { Card, CardActions, CardBody, CardHeader} from '@progress/kendo-react-layout'
import { useAccentDisplay } from '../controls/AccentDisplay';
import { accentUtils, newGuid, showDialog } from '../services/HelperService';
import { AccentDialog } from './AccentDialog';
import { OutsideAlerter } from '../controls/OutsiderAlerter';



export const AccentPopup = React.memo(props => {


    const closeDlg = React.useRef(null);

    const [refresCount, setRefreshCount] = React.useState(1);

    const id = React.useRef(`id_${newGuid() }`);
    
    const display = useAccentDisplay();

    const onOutSideClick = React.useCallback(e => {
        const elementClicked = e.target;
        const clickElement = props.anchor.current;

        const isClickElement = clickElement && (clickElement === elementClicked || clickElement.contains(elementClicked));

        if (!isClickElement) {
            props.onAutoClose(e);
        }

    }, [props]);

    
    React.useEffect(() => {        
        if (display.mobile && !props.noFullScreen) {
            if (props.show) {

                const dlg = <AccentDialog
                    fullscreen
                    key={id.current}
                    className="acc-popup-modal"
                >
                    <div id={id.current} style={{ height: "100%" }}></div>
                </AccentDialog>;

                
                closeDlg.current = window.insytwebAppDlg.setDisplayItem(
                    React.cloneElement(dlg, {
                    onClose: e => {
                            closeDlg.current();
                            closeDlg.current = null;                            
                    }
                    }),
                    () => setRefreshCount(c => c + 1),
                    e => {

                        if (closeDlg.current) {
                            closeDlg.current();
                        }
                        closeDlg.current = null;                            
                    }
                );

            } else {

                if (!accentUtils.isNull(closeDlg.current)) {
                    closeDlg.current();
                    closeDlg.current = null;
                }

            }
        }


    }, [props.show]);


    if (display.mobile && !props.noFullScreen) {

        const dlg = $(`#${id.current}`);

        if (dlg.length === 0) return null;

        return createPortal(
            <AccentPopupCard {...props}  />,
            dlg[0]);
    }


    const body = <div className={props.className} style={{ minWidth: props.minWidth ?? "300px", maxWidth: props.maxWidth }}>
        <AccentPopupCard {...props} />
    </div>;


    const content = (props.onAutoClose) ? < OutsideAlerter onAlert={ onOutSideClick }>{body}</OutsideAlerter> : body;

    return <Popup
        anchor={props.anchor.current}
        anchorAlign={props.anchorAlign}
        popupAlign={props.popupAlign}
        show={props.show}
    >
        {content}
    </Popup>;

});

const AccentPopupCard = React.memo(props => {

    const showHeader = !accentUtils.isNull(props.headerContent);
    const showActions = !accentUtils.isNull(props.actionsContent);

    return <Card style={props.style} className={ props.className}>
        {
            showHeader && <CardHeader style={props.headerStyle}>
                {
                    props.headerContent
                }
            </CardHeader>
        }
        <CardBody className="accent-popup-body" style={props.bodyStyle}>
            {props.children}
        </CardBody>
        {
            showActions && <CardActions
                style={{
                    display: "flex",
                    ...props.actionsStyle
                }}
            >
                {props.actionsContent}
            </CardActions>
        }
    </Card>; 
});

