import React from 'react'
import { Row, Col, InputGroup } from 'reactstrap'
import { Upload } from '@progress/kendo-react-upload'
import { accentUtils, t, from} from '../services/HelperService'
import { AccentTokenCtrl } from './AccentTokenCtrl'

export class AccentUploadButton extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();
        this.attmtCtrl = React.createRef();


        this.attachmentRefresh = 1;
        this.attachments = this.props.defaultAttachments;


        if (accentUtils.isNull(this.attachments)) {
            this.attachments = [];
        }



        this.maxFileSize = 12000000;
        this.maxFileNameLength = 70;
        this.promis = null;

        this.onAttachmentsChanged = this.onAttachmentsChanged.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.getFilesForUpload = this.getFilesForUpload.bind(this);
        this.hasInvalidFile = this.hasInvalidFile.bind(this);
        this.fileCount = this.fileCount.bind(this);
        this.saveFiles = this.saveFiles.bind(this);
        this.onRemoveURL = this.onRemoveURL.bind(this);
    }

    onRemoveURL(files, e) {
        return Promise.resolve(files[0]);
    }

    onAttachmentsChanged(e) {

        var me = this;

        if (!accentUtils.isNull(e.value)) {

            var removedFiles = from(this.attachments)
                .where(function (a) {
                    return !from(e.value)
                        .any(function (v) {
                            return v.value == a.value;
                        }) && !a.readOnly
                }).toArray();

            this.attachments = from(this.attachments)
                .where(function (a) {
                    return from(e.value)
                        .any(function (v) {
                            return v.value == a.value;
                        }) || a.readOnly
                }).toArray();



            me.attmtCtrl.current.setValue(this.attachments);


            if (removedFiles.length > 0) {



                var files = from(me.ctrl.current.files)
                    .where(f => from(removedFiles).any(r => f.name === r.value))
                    .toArray();


                if (files.length > 0) {

                    var uid = files[0].uid;

                    var args = {};
                    args[uid] = files;

                    me.ctrl.current.removeFiles(args);
                }
            }

            me.attachmentRefresh++;
            me.forceUpdate();

        }

        this.props.onChange(this.attachments);
    }


    fileCount() {
        return this.attachments.length;
    }


    uploadCount() {
        return this.ctrl.current.files.length;
    }

    hasInvalidFile() {

        return from(this.attachments).any(a => a.error);

    }

    onSelect(e) {

        var me = this;


        if (!me.props.multiple) {
            me.attachments = [];
        }

        if (!accentUtils.isNull(e.newState)) {

            var existing = from(me.attachments).toArray();

            e.newState.forEach(function (f) {

                if (!from(existing).any(function (a) { return a.value === f.name; })) {

                    var fname = f.name;
                    var err = undefined;
                    if (f.size > me.maxFileSize) {
                        fname = t("application_strings.application.general.fileTooLarge", { file: fname });
                        err = true;
                    } else if (fname.length > me.maxFileNameLength) {
                        fname = t("application_strings.application.general.fileNameTooLong", { file: fname, maxLength: me.maxFileNameLength });
                        err = true;
                    } else if (f.size == 0) {
                        fname = t("application_strings.application.general.emptyFile", { file: fname });
                        err = true;
                    } else if (!accentUtils.isEmpty(me.props.allowedExtensions)) {

                        const hasValidExt = from(me.props.allowedExtensions).any(ext => fname.endsWith(ext));

                        if (!hasValidExt) {
                            fname = t("application_strings.application.general.fileTypeNotAllowed", { type: fname });
                            err = true;
                        }

                    }

                    me.attachments.push({ text: fname, value: f.name, error: err });
                }
            });

        }



        if (!accentUtils.isNull(this.props.onChange)) {
            this.props.onChange(this.attachments);
        }


        this.attmtCtrl.current.setValue(this.attachments);

        this.forceUpdate();


    }

    onRemove(e) {

    }

    onSuccess(e) {




    }




    getFilesForUpload() {

        var me = this;

        return new Promise(function (p) {

            me.promis = p;

            me.ctrl.current.uploadFiles({ dummyGroup: me.ctrl.current.files });

        });
    }


    async saveFiles(e, options, done) {

        const files = e.map(f => f.getRawFile());

        this.promis({ files: files });

    }



    render() {

        var restrictions = undefined;

        if (this.props.allowedExtensions) {
            restrictions = {
                allowedExtensions: this.props.allowedExtensions
            };

        }

        return (
            <Row className="accentUploadBtn">
                <Col md={12} className="d-flex">
                    <div className="flex-fill">
                        <AccentTokenCtrl key={this.attachmentRefresh} noPopup noCustom ref={this.attmtCtrl} value={this.attachments} data={this.attachments} onChange={this.onAttachmentsChanged} label={this.props.label} noClearAll placeholder={this.props.placeholder} />
                    </div>
                    <div>
                        <Upload
                            ref={this.ctrl}
                            autoUpload={false}
                            defaultFiles={[]}
                            showActionButtons={false}
                            showFileList={false}
                            saveUrl={this.saveFiles}
                            saveField="files"
                            multiple={accentUtils.isNull(this.props.multiple) ? true : this.props.multiple}
                            restrictions={restrictions}
                            removeUrl={ this.onRemoveURL }
                            onAdd={this.onSelect}
                            onBeforeRemove={this.onRemove}
                            onStatusChange={this.onSuccess} />
                    </div>
                </Col>
            </Row>
        );
    }
}
