import * as React from 'react'
import { AccentButton } from '../controls/AccentButton';
import { usePosition } from '../controls/AccentDisplay';
import { AccentSpinner } from '../controls/AccentSpinner';
import { RTLDocumentGroupPreview } from '../controls/documents/DocumentPreview';
import { accentUtils, t } from '../services/HelperService';
import { AccentDialog } from './AccentDialog';




export const PrintDlg = React.memo(props => {


    const [document, setDocument] = React.useState(null);

    const pos = usePosition("printDlg-doc");

    const loadJobDoc = React.useCallback(jobID => {

        props.query().then(function (res) {

            res.HeaderItem = props.paramsCtrl;

            setDocument(res);
        });


    }, [setDocument]);


    const close = React.useCallback((e) => {
        props.onClose({
            document: document
        });
    }, [props, document]);

    React.useEffect(() => {

        loadJobDoc(props.jobID);

    }, []);

    const height = pos.toScreenHeight - 25;

    let contents = null;

    if (accentUtils.isNull(document)) {
        contents = <AccentSpinner />;
    } else {
        var docs = [document];
        contents = <RTLDocumentGroupPreview elementID="printDlg-doc" documents={docs} maxHeight={height } />;
    }



    return (
        <AccentDialog
            fullscreen
            onClose={props.onClose}
            headerContent={t(props.title)}
            actionsContent={[
                <AccentButton key="2" tagName="printDlgOKBtn" onClick={close}>{t("application_strings.application.buttons.ok")}</AccentButton>
            ]}
        >
            <div id="printDlg">
                {contents}
            </div>
        </AccentDialog>
    );


});
