import * as React from 'react';
import { queryNoTracking } from '../../services/DataService';

const query_Contact = queryNoTracking("Contact");

export class ActivityContactViewCtrl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        var me = this;
        query_Contact.getFirstOrDefault({ id: this.props.contactID, include: null }).then(function (r) {
            me.setState({ contact: r });
        });

    }

    componentDidMount() {
        var me = this;
        query_Contact.getFirstOrDefault({ id: this.props.contactID, include: null }).then(function (r) {
            me.setState({ contact: r });
        });
    }

    render() {
        var customerName = this.state.contact ? this.state.contact.FullName : '';

        return (
            <div className="im-sum-txt">
                <div>{customerName}</div>
            </div>
        );
    }
}