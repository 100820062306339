import * as React from 'react'
import $ from 'jquery';
import { Router } from 'react-router-dom';
import { Action } from 'history'
import { useSetRecoilState } from 'recoil';
import { breadCrums } from './Breadcrumb';
import { accentUtils, cancelAllDialogs, from, setToolbarActionState } from '../services/HelperService';
import { clearCache } from '../services/DataService';


let updateRouteTriggered = false;

export const navigateBackMainWindow = async () => {
    updateRouteTriggered = false;
    let count = 0;

    return new Promise((resolve) => {
        const navigateBackUntilRouteUpdated = () => {
            if (count < 10 && !updateRouteTriggered) {
                window.accentRouter.back();
                setTimeout(navigateBackUntilRouteUpdated, 50);
                count++;
            }
        };

        navigateBackUntilRouteUpdated();
    });
};


window.onOneTimeAccentRouted = {};


let animationKey = 1000;

export const AccentRouter = ({
    basename,
    children,
    history,
}) => {
    const [state, setState] = React.useState({
        action: history.action,
        location: history.location,
        path: history.location.pathname
    });

    const lastPath = React.useRef(history.location.pathname);
    const setCrumbs = useSetRecoilState(breadCrums);

    const updateRoute = React.useCallback(e => {

        updateRouteTriggered = true;

        setToolbarActionState(null);
        clearCache();
        cancelAllDialogs();

        const samePath = lastPath.current === e.location.pathname;
        const wasNew = lastPath.current.includes("/NEW");

        if (samePath) {        
            setState({ action: Action.Replace, location : e.location });
        } else {

            const search = accentUtils.isEmpty(e.location.search) ? '' : e.location.search.startsWith('?') ? e.location.search : `?${e.location.search}`;

            const breadcrumPath = `${e.location.pathname}${search}`;
            
            if (e.action === Action.Pop) {
                setCrumbs(c => from(c).takeExceptLast().toArray());
            } else if (e.action === Action.Replace) {
                setCrumbs(c => c.map((crum, ix) => ((ix === c.length - 1) ? { path: breadcrumPath, prompt: wasNew ? e.location?.state?.defaultPrompt : "...", animationKey: animationKey++ } : crum )));
            } else {
                setCrumbs(c => [...(c.filter(i => i.path !== e.location.pathname)), { path: breadcrumPath, prompt: "...", animationKey : animationKey++ }]);
            }

            setState(e);
        }

        lastPath.current = e.location.pathname;


        setTimeout(() => {
            if (window.registertags) {
                window.registertags($, e.location.pathname);
            }

            

        },1000);


        if (window.onOneTimeAccentRouted) {
            if (window.onOneTimeAccentRouted[e.location.pathname]) {
                setTimeout(() => {

                    window.onOneTimeAccentRouted[e.location.pathname]();
                    window.onOneTimeAccentRouted[e.location.pathname] = null;

                }, 0);
            }
        }


    }, [state, setState]);


    React.useLayoutEffect(() => history.listen(updateRoute), [history]);

    return (
        <Router
            basename={basename}
            children={children}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};