import * as React from "react";
import { Row, Col, Input } from "reactstrap";
import $ from 'jquery'
import { accentUtils, goToNew, t } from "../services/HelperService";
import { Helper, jobStages, jobTypes } from "../services/JobService";
import { AccentWizard, AccentWizardPage } from "./RTLWizard";
import { AccentSpinner } from "../controls/AccentSpinner";
import { getUser } from "../services/UserService";
import { getContextData } from "../services/EntityContextService";
import { AccentRadioGroup } from "../controls/unbound/AccentRadioUnbound";



export default class NewServiceJobWizard extends React.Component {


    static CreateNewServiceJob(entityContext, contactOnly) {

        const user = getUser();

        goToNew(
            "/Job",
            "Job",
            {
                JobType: jobTypes.Service,
                BusinessUnitID: !accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.BusinessUnitID) ? entityContext.BusinessUnitID : user.DefaultSettings.BusinessUnit.ID,
                CompanyID: !accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.CompanyID) ? entityContext.CompanyID : null,
                ContactID: !accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.ContactID) ? entityContext.ContactID : null,
                SiteContactID: !accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.SiteContactID) ? entityContext.SiteContactID : !accentUtils.isNull(entityContext) ? entityContext.ContactID : null,
                OpportunityID: !accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.OpportunityID) ? entityContext.OpportunityID : null,
                ParentID: !accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.JobID) && !contactOnly ? entityContext.JobID : null,
                LinkedReferenceID: !accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.LinkedReferenceID) && !contactOnly ? entityContext.LinkedReferenceID : null,
                ProjectCustomerPO: !accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.JobProjectRef) && !contactOnly ? entityContext.JobProjectRef : null,
                ParentGroupKey: !accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.ParentGroupKey) && !contactOnly ? entityContext.ParentGroupKey : '00000000-0000-0000-0000-000000000000',
            },
            null,
            null,
            null,
            e => {

                return new Promise(p => {

                    if (!accentUtils.isNull(entityContext) && !accentUtils.isNull(entityContext.SiteAddress)) {

                        if (!accentUtils.isNull(e.Address)) {

                            e.Address.AddressLines = entityContext.SiteAddress.AddressLines;
                            e.Address.City = entityContext.SiteAddress.City;
                            e.Address.State = entityContext.SiteAddress.State;
                            e.Address.Postcode = entityContext.SiteAddress.Postcode;
                            e.Address.Country = entityContext.SiteAddress.Country;
                            e.Address.PosLong = entityContext.SiteAddress.PosLong;
                            e.Address.PosLat = entityContext.SiteAddress.PosLat;

                        }

                    }

                    p();

                });
            }
        );
        
    }

    constructor(props) {
        super(props);



        this.jobReference = null;
        this.contactName = null;
        this.contactID = null;
        this.jobID = null;
        this.entityContext = null;
        this.isQuote = false;


        this.state = {
            isLoading: true,
            selected : "1"
        };


        this.onFinish = this.onFinish.bind(this);
        this.onChange = this.onChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    }

    componentDidMount() {

        var me = this;
        var context = this.props.pageprops.context;
        getContextData(context).then(function (res) {
            if (!accentUtils.isNull(res)) {
                me.jobReference = res.JobReference;
                me.jobID = res.JobID;
                me.contactName = res.ContactName;
                me.contactID = res.ContactID;
                me.entityContext = res;
                me.isQuote = res.JobStage == jobStages.Quote;
            }
            me.setState({ isLoading: false });

        });


    }

    onChange(e) {
        this.setState({ selected: e.value });
    }

    onFinish() {

        var me = this;
        
        var val = this.state.selected;

        return new Promise(function (p) {

            if (val == "1") {
                NewServiceJobWizard.CreateNewServiceJob(me.entityContext, false);
            } else if (val == "2") {
                NewServiceJobWizard.CreateNewServiceJob(me.entityContext, true);
            } else {
                NewServiceJobWizard.CreateNewServiceJob(null);
            }

            p();
        });

    }


    render() {



        let options = [];

        if (!this.isQuote && this.jobID !== null) {
            options.push({ value: "1", label: t("application_strings.wizards.newServiceJob.page01JobMessage", { Reference: this.jobReference }) });
        }

        if (!accentUtils.isNull(this.contactID)) {
            options.push({ value: "2", label: t("application_strings.wizards.newServiceJob.page01ContactMessage", { Contact: this.contactName }) });
        }

        options.push({ value: "3", label: t("application_strings.wizards.newServiceJob.page01OtherMessage") });

        return (
            <AccentWizard wizardProps={this.props}>
                <AccentWizardPage title={"application_strings.wizards.newServiceJob.page01Title"} >
                    {this.state.isLoading ? <AccentSpinner /> :
                        <React.Fragment>
                            <div className="alert alert-info" role="alert">{t("application_strings.wizards.newServiceJob.page01Message")}</div>
                            <Row>
                                <Col md={12}>
                                    <AccentRadioGroup defaultChecked="1" data={options} onChange={this.onChange } />
                                </Col>
                            </Row>
                        </React.Fragment>
                    }
                </AccentWizardPage>
            </AccentWizard>
        );
    }

}