import { breeze } from 'breeze-client'
import { filterBy, orderBy } from '@progress/kendo-data-query'
import { accentUtils, t, from } from '../services/HelperService'
import * as DataService from '../services/DataService'

export class RTLQueryFetcher {

    constructor(props) {

        this.Predicate = breeze.Predicate;
        this.Operators = breeze.FilterQueryOp;
        this.props = props;

        this.getQuery = this.getQuery.bind(this);
        this.makeOperator = this.makeOperator.bind(this);
        this.makeFilterOp = this.makeFilterOp.bind(this);
        this.makeFilters = this.makeFilters.bind(this);
        this.applyDataStateToQuery = this.applyDataStateToQuery.bind(this);
        this.applyDataStateToArrayResult = this.applyDataStateToArrayResult.bind(this);
        this.applyDataStateToArray = this.applyDataStateToArray.bind(this);
        this.makeResults = this.makeResults.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.isPromise = this.isPromise.bind(this);

    }

    isPromise(p) {
        if (typeof p === 'object' && typeof p.then === 'function') {
            return true;
        }

        return false;
    }

    async getQuery() {

        var query = this.props.query;

        if (query != null) {

            if (typeof query == "function") {
                query = query();
            }
        }

        if (this.isPromise(query)) {
            return await query;
        }


        return query;

    }


    makeOperator(op) {
        return {
            eq: this.Operators.Equals,
            neq: this.Operators.NotEquals,
            lt: this.Operators.LessThan,
            lte: this.Operators.LessThanOrEqual,
            gt: this.Operators.GreaterThan,
            gte: this.Operators.GreaterThanOrEqual,
            startswith: this.Operators.StartsWith,
            endswith: this.Operators.EndsWith,
            contains: this.Operators.Contains,
            isnull: this.Operators.Equals,
            isnotnull: this.Operators.NotEquals,
            isempty: this.Operators.Equals,
            isnotempty: this.Operators.NotEquals,
        }[op];
    }


    makeFilterOp(filters) {

        var res = [];

        for (var i = 0; i < filters.length; i++) {

            var filter = filters[i];

            if (filter.field) {
                var field = filter.field;
                var operator = this.makeOperator(filter.operator);
                var value = filter.value;

                if (filter.operator === "isnull" || filter.operator === "isnotnull") {
                    value = null;
                }
                if (filter.operator === "isempty" || filter.operator === "isnotempty") {
                    value = "";
                }


                res.push(this.Predicate.create(field, operator, value));

            } else if (filter.logic) {

                var items = this.makeFilterOp(filter.filters);

                if (filter.logic == "and") res.push(this.Predicate.and(items));
                if (filter.logic == "or") res.push(this.Predicate.or(items));

            }



        }

        return res;
    }


    makeFilters(args) {

        var filters = this.makeFilterOp([args]);
        if (args.logic == "and") return this.Predicate.and(filters);
        if (args.logic == "or") return this.Predicate.or(filters);
        throw new Error("Unsupported predicate logic " + args.logic);
    }

    applyDataStateToArray(query, dataState) {

        let result = query;

        if (dataState) {

            if (!accentUtils.isEmpty(dataState.filter)) {
                result = filterBy(result, dataState.filter.filters[0]);
            }

            if (dataState.sort && dataState.sort.length > 0) {
                result = orderBy(result, dataState.sort);
            }

            if (!accentUtils.isNull(dataState.take) && !accentUtils.isNull(dataState.skip)) {
                result = from(result).skip(dataState.skip).take(dataState.take).toArray();
            }

        }


        return result;

    }

    async applyDataStateToArrayResult(arrayResult, dataState) {

        const cacheKey = JSON.stringify(dataState);
        let isPaged = false;

        let finalResult = await arrayResult.executeWithAlter(array => {

            let result = array;

            

            if (dataState) {

                if (!accentUtils.isEmpty(dataState.filter)) {
                    result = filterBy(result, dataState.filter.filters[0]);
                }
            }

            return result;

        }, cacheKey);

        let length = finalResult.length;

        if (dataState.sort && dataState.sort.length > 0) {
            finalResult = orderBy(finalResult, dataState.sort);
        }

        if (!accentUtils.isNull(dataState.take) && !accentUtils.isNull(dataState.skip)) {
            isPaged = true;
            finalResult = from(finalResult).skip(dataState.skip).take(dataState.take).toArray();
        }


        if (isPaged) {
            return {
                data: finalResult,
                total: length
            };
        }

        return finalResult;
        

    }

    applyDataStateToQuery(query, dataState) {


        if (dataState) {

            

            if (!accentUtils.isEmpty(dataState.filter)) {

                    var f = this.makeFilters(dataState.filter);
                    if (f != null) {
                        query = query.where(f);
                    }
            }

            if (dataState.sort && dataState.sort.length > 0) {
                query = query.orderBy(dataState.sort.map(function (col) {
                    return col.field + (col.dir == "desc" ? " desc" : "");
                }).join(", "));
            }

            if (!accentUtils.isNull(dataState.take) && !accentUtils.isNull(dataState.skip)) {
                query = query
                    .skip(dataState.skip)
                    .take(dataState.take)
                    .inlineCount();
            }

            if (!accentUtils.isNull(dataState.customHeaders) && from(Object.values(dataState.customHeaders)).any(v=> !accentUtils.isNull(v))) {
                query.customHeaders = dataState.customHeaders;
            }

        }

        return query;
    }

    makeResults(data, query) {

        

        var manager = DataService.entityManagerProvider.activeManager;

        try {
            var meta = manager.metadataStore;

            var typeName = meta.getEntityTypeNameForResourceName(query.resourceName);
            var typeObj = meta.getEntityType(typeName);
        } catch (ex) {
            data.results.total = accentUtils.isNull(data.inlineCount) ? data.results.length : data.inlineCount;
            return data.results;
        }


        var props = typeObj.dataProperties;
        var a = data.results.map(function (rec) {
            var obj = {};
            props.forEach(function (prop) {
                obj[prop.name] = rec[prop.name];
            });
            //not sure what this should be... obj = new kendo.data.Model(obj);
            return obj;
        });

        a.total = accentUtils.isNull(data.inlineCount) ? data.results.length : data.inlineCount;
        return a;
    }

    async fetchData(dataState, bustCache) {

        const q = await this.getQuery();

        const isArray = Array.isArray(q);

        if (isArray) {
            const arrayData = this.applyDataStateToArray(q, dataState);
            return {
                data: arrayData,
                total: arrayData.length
            };
        }

        if (bustCache) {
            if (q.queryResult) {
                if (q.queryResult.clearCache) {
                    q.queryResult.clearCache();
                }
            }
            else if (q.arrayResult) {
                if (q.arrayResult.clearCache) {
                    q.arrayResult.clearCache();
                }
            }
        }
        
        if (q instanceof DataService.QuariableArray) {
            return await this.applyDataStateToArrayResult(q, dataState);
        }

        const res =  await q.executeWithAlter(query => this.applyDataStateToQuery(query, dataState));

        return res;


    }

}



