import { AccentDialog } from "../../dialogs/AccentDialog";
import { accentUtils, t } from "../../services/HelperService";
import { AccentButton } from "../AccentButton";

export const ApplyChangesErrorDlg = ({ onClose, expection, change, config }) => {


    const execptionMessage = expection?.message;

    const showException = !accentUtils.isEmpty(execptionMessage);


    return <AccentDialog
        noCancel
        onClose={onClose}
        headerContent={"Apply Related Change Failed"}
        actionsContent={[<AccentButton key="2" tagName="sizeCloseBtn" onClick={onClose}>{t("application_strings.application.buttons.ok")}</AccentButton>]}
    >
        {showException && <div className="read-only-text alert">{execptionMessage}</div>}

        <div className="read-only-text alert">{JSON.stringify(change)}</div>
    </AccentDialog>
};