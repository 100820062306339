import { queryNoTracking } from './DataService'
import { accentUtils } from './HelperService'

const generateTempateContent = queryNoTracking("GenerateTemplateContent", 200, true);


export const documentTemplateTypes = {
    quote: "doc_temp_type_quote",
    orderConfirmation: "doc_temp_type_oc",
    invoice: "doc_temp_type_inv",
    statement: "doc_temp_type_stmt",
    installer: "doc_temp_type_install",
    jobSummary: "doc_temp_type_js"
};

export const documentTemplateIDs = {
    quote01: "F5ACEE39-9AEF-4A88-B4E4-F094C8B5FE5B",
    orderConfirmation01: "9810A50D-161D-414D-9E27-A51DB6D8154F",
    invoice01: "222D0652-7AAC-4EAA-8364-F33BEC1D0097",
    statement01: "6DDC6CF7-4894-42CE-87EA-69563AED3E53",
    installerPrint01: "3D854440-89A8-4349-93F8-B538567FDED7",
    jobSummary01: "604A6738-B656-49E3-A975-8CE3A6E9C388"
};


export function processGenericEmailTempate(template, data) {

    alert('no longer user?');
    var res = {
        Subject: template.Subject,
        Body: template.Body
    };


    var exps = [

        {
            exp: /\{\{Invoice.Rep\}\}/g,
            val: data.Invoice.Rep
        },
        {
            exp: /\{\{Invoice.Number\}\}/g,
            val: data.Invoice.InvoiceNo
        },
        {
            exp: /\{\{SupplyJob.Supplier\}\}/g,
            val: data.PurchaseOrder.SupplierName
        },
        {
            exp: /\{\{SupplyJob.Rep\}\}/g,
            val: data.PurchaseOrder.Rep
        },

        {
            exp: /\{\{SupplyJob.OrderNumber\}\}/g,
            val: data.PurchaseOrder.OrderNumber
        },
        {
            exp: /\{\{SupplyJob.CustomerReference\}\}/g,
            val: data.PurchaseOrder.CustomerReference
        },
        {
            exp: /\{\{SupplyJob.Business\}\}/g,
            val: data.PurchaseOrder.SupplierName
        },

        {
            exp: /\{\{SupplyJob.JobType\}\}/g,
            val: data.PurchaseOrder.JobType
        },
        {
            exp: /\{\{Activity.AppointmentType\}\}/g,
            val: data.Activity.ActivityType
        },
        {
            exp: /\{\{Activity.AppointmentDate\}\}/g,
            val: data.Activity.AppointmentDate
        },
        {
            exp: /\{\{Activity.AppointmentDateTime\}\}/g,
            val: data.Activity.AppointmentDateTime
        },
        {
            exp: /\{\{Activity.AppointmentOwner.FirstName\}\}/g,
            val: data.Activity.OwnerFirstName
        },
        {
            exp: /\{\{Activity.AppointmentOwner.LastName\}\}/g,
            val: data.Activity.OwnerLastName
        },
        {
            exp: /\{\{Activity.AppointmentOwner.Email\}\}/g,
            val: data.Activity.OwnerEmail
        },
        {
            exp: /\{\{Activity.AppointmentOwner.JobTitle\}\}/g,
            val: data.Activity.OwnerJobTitle
        },
        {
            exp: /\{\{Activity.AppointmentOwner.Mobile\}\}/g,
            val: data.Activity.OwnerMobile
        },
        {
            exp: /\{\{Activity.Category\}\}/g,
            val: data.Activity.Category
        },
        {
            exp: /\{\{Activity.Notes\}\}/g,
            val: data.Activity.Notes
        },
        {
            exp: /\{\{Activity.ProductSummary\}\}/g,
            val: data.Activity.ProductSummary
        },
        {
            exp: /\{\{Activity.Opportunity.Business.Address\}\}/g,
            val: data.Activity.OpportunityBusinessAddress
        },
        {
            exp: /\{\{Activity.Opportunity.Business.Phone\}\}/g,
            val: data.Activity.OpportunityBusinessPhone
        },
        {
            exp: /\{\{Activity.Opportunity.Business.Name\}\}/g,
            val: data.Activity.OpportunityBusinessName
        },
        {
            exp: /\{\{Activity.Owner.EmailSignature\}\}/g,
            val: data.Activity.OwnerEmailSignature
        },
        {
            exp: /\{\{Activity.Owner.FullName\}\}/g,
            val: data.Activity.OwnerFullName
        },
        {
            exp: /\{\{Activity.Owner.WorkPhone\}\}/g,
            val: data.Activity.OwnerWorkPhone
        },
        {
            exp: /\{\{Activity.Subject\}\}/g,
            val: data.Activity.Subject
        },
        {
            exp: /\{\{User.FullName\}\}/g,
            val: data.User.FullName
        },
        {
            exp: /\{\{Contact.FirstName\}\}/g,
            val: data.Contact.FirstName
        },
        {
            exp: /\{\{Contact.LastName\}\}/g,
            val: data.Contact.LastName
        },
        {
            exp: /\{\{Contact.Mobile\}\}/g,
            val: data.Contact.Mobile
        },
        {
            exp: /\{\{Contact.Email\}\}/g,
            val: data.Contact.Email
        },
        {
            exp: /\{\{Job.Reference\}\}/g,
            val: data.Job.Reference
        },
        {
            exp: /\{\{Job.Number\}\}/g,
            val: data.Job.Reference
        },
        {
            exp: /\{\{Job.ProjectCustomerPO\}\}/g,
            val: data.Job.CustomerReference
        },
        {
            exp: /\{\{Job.SalesRep.EmailSignature\}\}/g,
            val: data.Job.SalesRepEmailSignature
        },
        {
            exp: /\{\{CSS.Link\}\}/g,
            val: data.CssLink
        },
        {
            exp: /\{\{CurrentUser.FirstName\}\}/g,
            val: data.User.FirstName
        },
        {
            exp: /\{\{CurrentUser.LastName\}\}/g,
            val: data.User.LastName
        },
        {
            exp: /\{\{User.JobTitle\}\}/g,
            val: data.User.JobTitle
        },
        {
            exp: /\{\{User.Mobile\}\}/g,
            val: data.User.Mobile
        },
        {
            exp: /\{\{CurrentUser.WorkPhone\}\}/g,
            val: data.User.WorkPhone
        },
        {
            exp: /\{\{User.Email\}\}/g,
            val: data.User.Email
        },
        {
            exp: /\{\{CurrentUser.EmailSignature\}\}/g,
            val: data.User.EmailSignature
        },
        {
            exp: /\{\{Job.SalesRep.WorkPhone\}\}/g,
            val: data.Job.SalesRepWorkPhone
        },
        {
            exp: /\{\{Job.Amount\}\}/g,
            val: data.Job.Amount
        },
        {
            exp: /\{\{Job.BalanceOwing\}\}/g,
            val: data.Job.BalanceOwing
        },
        {
            exp: /\{\{Job.ExpiryDate\}\}/g,
            val: data.Job.ExpiryDate
        },
        {
            exp: /\{\{Job.Summary\}\}/g,
            val: data.Job.Summary
        },
        {
            exp: /\{\{Job.ProductCustomerPO\}\}/g,
            val: data.Job.ProductCustomerPO
        },
        {
            exp: /\{\{Job.ProductCustomerPO\}\}/g,
            val: data.Job.ProductCustomerPO
        },
        {
            exp: /\{\{Job.SalesRep.Email\}\}/g,
            val: data.Job.SalesRepEmail
        },
        {
            exp: /\{\{Job.SalesRep.FirstName\}\}/g,
            val: data.Job.SalesRepFirstName
        },
        {
            exp: /\{\{Job.SalesRep.FullName\}\}/g,
            val: data.Job.SalesRepFullName
        },
        {
            exp: /\{\{Job.SalesRep.JobTitle\}\}/g,
            val: data.Job.SalesRepJobTitle
        },
        {
            exp: /\{\{Job.SalesRep.LastName\}\}/g,
            val: data.Job.SalesRepLastName
        },
        {
            exp: /\{\{Job.SalesRep.Mobile\}\}/g,
            val: data.Job.SalesRepMobile
        },
        {
            exp: /\{\{Business.Name\}\}/g,
            val: data.User.BusinessName
        },
        {
            exp: /\{\{Business.Phone\}\}/g,
            val: data.User.BusinessPhone
        },
        {
            exp: /\{\{Business.Address\}\}/g,
            val: data.User.BusinessAddress
        },

    ];

    exps.forEach(function (e) {

        var val = e.val;

        if (accentUtils.isNull(val)) {
            val = "";
        }

        res.Subject = accentUtils.isEmpty(res.Subject) ? '' : res.Subject.replace(e.exp, val);
        res.Body = accentUtils.isEmpty(res.Body) ? '' : res.Body.replace(e.exp, val);
    });


    return res;
}



export function generateTemplateContent(m) {
    return generateTempateContent.getAll(m); 
}