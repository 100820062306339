import React from 'react'
import { DropdownItem } from 'reactstrap'

export class MenuItem extends React.Component {


    constructor(props) {
        super(props);

        this.ctrl = React.createRef();
    
        this.onItemClicked = this.onItemClicked.bind(this);
    }

    

    onItemClicked() {
        this.props.onClick(this.props.eventKey);
    }

    
    render() {

        var me = this;


        if (this.props.divider) {
            return <DropdownItem divider />;
        }

        return (
            <DropdownItem ref={this.ctrl} onClick={me.onItemClicked} >
                {this.props.children}
            </DropdownItem >
        );
    }
}