import React from 'react'
import { Checkbox } from '@progress/kendo-react-inputs'
import { accentUtils, t } from '../../services/HelperService'


export class AccentCheckUnbound extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        var defaultValue = false;

        if (!accentUtils.isNull(this.props.defaultValue)) {
            defaultValue = this.props.defaultValue;
        }


        this.state = {
            checked: defaultValue
        };

        this.onChange = this.onChange.bind(this);
        
    }


    onChange(e) {


        this.props.onChange({
            tagName : this.props.tagName, 
            checked: e.value
        });

        this.setState({ checked: e.value});

    }


    render() {

        var ctrl = null;
        const controlClass = "accent-check-unbound";
        var labelClass = "";

        if (!this.props.hide) {

            const hasLabel = !accentUtils.isEmpty(this.props.label);

            const label = !hasLabel ? undefined : t(this.props.label);

            if (!hasLabel) {
                labelClass = 'accent-check-unbound-no-lbl';
            }

            ctrl = <Checkbox id={this.props.tagName} disabled={this.props.disabled} checked={this.state.checked}  label={label} onChange={this.onChange} />
        }


        return <div style={this.props.style} className={`${controlClass} ${labelClass}`} data-tagname={ this.props.dataTagName} >{ctrl}</div>;
    }
}