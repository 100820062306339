import * as React from 'react'
import { queryNoTracking } from '../services/DataService'
import { accentUtils, t} from '../services/HelperService'
import { AccentComboUnbound } from '../controls/unbound/AccentComboUnbound'
import { AccentDialog } from './AccentDialog'
import { useValidation } from '../controls/bound/AccentFormCtrl'
import { AccentSpinner } from '../controls/AccentSpinner'
import { AccentButton } from '../controls/AccentButton'
import { AccentTextAreaUnbound } from '../controls/unbound/AccentTextAreaUnbound'



export const RestockLineDlg = React.memo(props => {

    const [location, setLocation] = React.useState(null);
    const [notes, setNotes] = React.useState(null);
    const [data, setData] = React.useState(null);

    const validation = useValidation("#restock-line-dlg");

    const onChanged = React.useCallback((e) => {
        setLocation(e);
    }, [setLocation]);

    const onNotesChanged = React.useCallback((e) => {
        setNotes(e);
    }, [setNotes]);

    const onClose = React.useCallback((e) => {
        props.onClose({ canceled: false, LocationID: location, Notes: notes });
    }, [location, notes, props]);


    React.useEffect(() => {

        queryNoTracking('PL_StockLocations').getAll({ includeID: null, activeOnly: true })
            .then(r => {
                setData(r);
            });

    }, []);




    if (accentUtils.isNull(data)) return <AccentSpinner />;

    const isValid = !accentUtils.isEmpty(location) && validation.isValid;

    return <AccentDialog
        maxWidth={600 }
        headerContent={t("application_strings.application.dialogs.restockLine.title")}
        onClose={props.onClose}
        actionsContent={[
            <AccentButton key="2" tagName="ptCloseBtn" disabled={!isValid} onClick={onClose}>{t("application_strings.application.buttons.restock")}</AccentButton>
        ]}
    >
        <div id="restock-line-dlg" className="center">
            <AccentComboUnbound required displayField="Description" valueField="ID" onChange={onChanged} label="application_strings.application.dialogs.restockLine.locationsLabel" data={data} />
            <AccentTextAreaUnbound required style={{ marginTop: "20px" }} onChange={onNotesChanged} label="Enter notes here..." />
        </div>
    </AccentDialog>;

});