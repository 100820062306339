import * as React from 'react';
import { accentUtils } from '../../services/HelperService';
import { AccentCheckUnbound } from '../unbound/AccentCheckUnbound';
import { ConfiguratorContext } from './ConfigContextProvider';

import { isReadOnly, onSimpleOptionValueChanged, fromInt, useChanged, useValidation, getOptionModel } from './ProductConfiguratorShared';

export const ConfigCheckbox = props => {

    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);

    const changed = useChanged(props.optionID, "ConfigNumeric", false);
    const validation = useValidation(props.optionID, "ConfigNumeric");

    const [value, setValue] = React.useState(accentUtils.isEmpty(optionModel.CurrentValueAsString) ? null : optionModel.CurrentValueAsString?.toString()?.toUpperCase() === "TRUE");

    const fireChange = React.useMemo(() => onSimpleOptionValueChanged(), [props.optionID]);


    const onChange = React.useCallback(e => {

        fireChange(ctx, optionModel, e.checked?.toString(), changed.tag);

        setValue(e.checked);

    }, [setValue, ctx, optionModel, fireChange, changed.tag]);


    const readOnly = isReadOnly(optionModel);


    return <div className="config-control config-checkbox">
        <AccentCheckUnbound
            key={changed.changeID}
            required={validation.required}
            defaultValue={accentUtils.isEmpty(optionModel.CurrentValueAsString) ? null : optionModel.CurrentValueAsString?.toString()?.toUpperCase() === "TRUE"}
            onChange={onChange}
            disabled={readOnly}
        />
    </div>;
};

