//ActivityDlg
import * as React from 'react'
import { Alert } from 'reactstrap'
import { AccentButton } from '../controls/AccentButton'
import { t, format, showDialog } from '../services/HelperService'
import * as JobService from '../services/JobService'
import { AccentDialog } from './AccentDialog'
import { showActivity } from './ActivityDlg'






export class OpenServiceJobActivityDlg extends React.Component { 

    static Show(jobID, activities, rootPromise) {
        return showDialog(<OpenServiceJobActivityDlg jobID={jobID} activities={activities} rootPromise={rootPromise} />);        
    }


    constructor(props) {
        super(props)


        this.state = {};

        this.hideCancel = this.hideCancel.bind(this);
        this.allowOk = this.allowOk.bind(this);
        this.getBody = this.getBody.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.onClose = this.onClose.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onOpenActivity = this.onOpenActivity.bind(this);

    }


    componentDidMount() {

        
    }

    getTitle() {
        return t("application_strings.views.job.openDispatchAppointment");
    }

    allowOk() {
        return this.props.activities.length ===0 ;
    }

    hideCancel() {
        return true;
    }

    onClose(e) {

        this.props.rootPromise.resolve(this.props.activities.length == 0);

        this.props.onClose(e);        
    }

    onOpenActivity(a) {

        const me = this;

        me.props.onClose(a);        
        
        const currURL = window.location.pathname;


        setTimeout(() => {

            showActivity({
                activityID: a.ID
            }).then(e => {

                setTimeout(() => {

                    const hasNavigatedAway = window.location.pathname != currURL;

                    if (e.showInCalendar || hasNavigatedAway) {
                        me.props.rootPromise(false);
                    } else {
                        JobService.Helper.showServiceJobUnresolvedActivities(me.props.jobID, me.props.rootPromise);
                    }


                }, 1000);

            });

        }, 50);

        

    }


    getBody() {

        return <div className="center">

            <Alert color="warning">{t("application_strings.views.job.closeDispatchAppointments")}</Alert>
            <ul>
                {this.props.activities.map(a => {
                    return <li key={a.ID}><div onClick={e=> this.onOpenActivity(a)} className="acc-link">{`${format.formatDate(a.Start)} - ${a.Subject}`}</div></li>;
                })}
            </ul>
        </div>;
    }


    render() {


        return <AccentDialog
            maxWidth={ 1024 }
            headerContent={this.getTitle()}
            onClose={this.props.onClose}
            actionsContent={[
                <AccentButton key="2" disabled={!this.allowOk()} tagName="okBtn" onClick={this.onClose}>{t("application_strings.application.buttons.ok")}</AccentButton> 
            ]}
        >
            { this.getBody()}
        </AccentDialog>;
    }

}