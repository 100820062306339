import * as React from 'react';
import { accentUtils } from '../../services/HelperService';
import { usePosition } from '../AccentDisplay';
import { StetchCtrl } from './SketchCtrl';


const SketchPad = props => {

    const ctrl = React.useRef();
    const [notifiedDirty, setNotifiedDirty] = React.useState(false);
    
    const position = usePosition("acc-sketchpad");

    const onChange = React.useCallback(e => {

        if (props.notifyDirty && e.elements.length > 0) {
            if (!notifiedDirty) {
                setNotifiedDirty(true);
                props.notifyDirty(true);
            }
        }

    }, [props, notifiedDirty, setNotifiedDirty]);


    const width = position?.toScreenWidth ?? window.innerWidth;
    const height = position?.toScreenHeight ?? window.innerHeight;

    return <div id="acc-sketchpad" style={{ width: width - (props.widthAdjustment ?? 0), height: height - (props.heightAdjustment ?? 0) }} >
        <StetchCtrl methods={props.methods} defaultValue={props.defaultValue} onChange={onChange } />
    </div>;
};


export default SketchPad;