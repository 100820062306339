import React from 'react'
import { Menu } from '@progress/kendo-react-layout'
import { accentUtils, t , from} from '../../services/HelperService'
import { filterOps, filterTypes, findSchemaItem, getQueryTypeDefault } from './AdvancedFilterConstants';


export const InsyteQueryFieldCtrl = React.memo(props => {


    const currentFieldName = props.item.field;
    const schemaItem = findSchemaItem(props.schema, currentFieldName);




    const processEntityList = l => {
        return from(l).select(e => processEntity(e)).orderBy(i=>i.text).toArray();
    };

    const processEntity = e => {

        var fields = from(e.Fields).select(f => {
            return {
                text: t(`${props.fieldStringBase}.${f.Prompt}`),
                itemId: f.ID,
            };
        }).orderBy(i=>i.text).toArray();


        var childEntities = accentUtils.isNull(e.Entities) ? [] : processEntityList(e.Entities);

        return {
            text: t(`${props.fieldStringBase}.${e.Prompt}`),
            itemId: e.ID,
            items: from(childEntities.concat(fields)).orderBy(i=> i.text).toArray()
        };
    }

    const items = processEntityList(props.schema.Entities);




    const fieldItems = [
        {
            text: accentUtils.isNull(schemaItem) ? t(`application_strings.views.filterView.schemas.${currentFieldName}`) : t(`${props.fieldStringBase}.${schemaItem.Prompt}`),
            itemId: currentFieldName,
            items: items,
            disabled: props.readOnly
        }

    ]

    const onSelect = React.useCallback(e => {
        if (!accentUtils.isNull(e.item)) {
            const id = e.item.itemId;

            const originalField = props.item.field;


            if (id != originalField) {

                const schemaItem = findSchemaItem(props.schema, id);
                const type = accentUtils.isNull(schemaItem) ? "text" : schemaItem.Type;

                


                const op = type === filterTypes.select ? filterOps.in : filterOps.eq;

                var defaultValue = getQueryTypeDefault(type);

                props.item.field = id;
                props.item.op = op;
                props.item.value = defaultValue;

                props.onItemChanged();
            }

        }
    }, [props]);


    return <Menu
        className="acc-qry-pred-flds"
        onSelect={onSelect}
        items={fieldItems} />;

});
