import React from 'react';
import { motion, AnimatePresence } from "framer-motion"
import { Link, useLocation } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import { useAccentDisplay } from '../controls/AccentDisplay';
import AccentIcons from '../icons/Icons';

import { accentUtils, from, A } from './../services/HelperService'

let lastAnimationID = 0;

export const breadCrums = atom({
    key: `breadCrums`,
    default: [{ path: window.location.pathname, prompt: "...", animationKey: -1}]
});


const removeQueryArgs = path => {

    const parts = path.split('?');


    return from(parts).firstOrDefault();
};


function scrollToBreadcrumbEnd() {
    var breadcrumbContainer = document.querySelector('.acc-breadcrumb-container');

    if (accentUtils.isNull(breadcrumbContainer)) return;

    breadcrumbContainer.scrollLeft = breadcrumbContainer.scrollWidth;
}

const Breadcrumb = React.memo(props => {


    const location = useLocation();

    const display = useAccentDisplay();

    const maxLen = display.mobile || display.tablet ? 3 : 15;

    const [crumbs, setCrumbs] = useRecoilState(breadCrums);


    const onClear = React.useCallback(() => {

        setCrumbs(c => from(c).takeFromLast(1).toArray());

    }, [setCrumbs]);



    React.useEffect(() => {
        setCrumbs(list => list.map((crum, ix) => {

            const isLast = (ix === list.length - 1);


            if (isLast) {
                return { ...crum, prompt: props.prompt };
            }

            //if (crum.path.includes("/NEW?")) {
            //    return null;
            //}

            return crum;

        }).filter(r => !accentUtils.isNull(r)));    


        setTimeout(() => {
            scrollToBreadcrumbEnd();
        }, 300)
        

    }, [setCrumbs, props.prompt]);


    const startIndex = (crumbs.length <= maxLen) ? 0 : crumbs.length - maxLen;

    const clear = crumbs.length > 1 ? <AccentIcons.Clear onClick={onClear} noPadding top="1" style={{ fontSize: "20px", paddingLeft:"5px" }} /> : null;

    const result = crumbs.map((c, i) => {
        const isLast = i === crumbs.length - 1;

        return <motion.li
            style={{ display: "inline-block" }}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: .2 }}
            whileHover={{ scale: isLast ? undefined : 0.8 }}
            transition={{ opacity: { duration: 1 } }}
            key={`key_${c.path}_${c.animationKey}`}
        >
            <span> {" > "} <A href={c.path}>{c.prompt?.replace(/&#39;/g, "'")}</A></span>
            {isLast ? clear : null}
        </motion.li>;
    }).filter(r=> !accentUtils.isNull(r));




    return <div className="acc-breadcrumb">
        <ul style={{ listStyleType: "none" }}>
            <AnimatePresence>
                {result}
            </AnimatePresence>
        </ul>
    </div>;
});


export default Breadcrumb