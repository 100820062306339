import  * as React from 'react'
import { EditorUtils, Editor, EditorTools } from '@progress/kendo-react-editor';
import { Schema } from '@progress/kendo-editor-common';
import { accentUtils } from '../../services/HelperService';

const {
    Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
    AlignLeft, AlignCenter, AlignRight, AlignJustify,
    Indent, Outdent, OrderedList, UnorderedList,
    Undo, Redo, FontSize, FontName, FormatBlock,
    Link, Unlink, InsertImage, ViewHtml,
    InsertTable,
    AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
    DeleteRow, DeleteColumn, DeleteTable,
    MergeCells, SplitCell
} = EditorTools;


export const AccentHtmlUnbound = (props) => {

    const [value, setValue] = React.useState(EditorUtils.createDocument(new Schema({
        nodes: EditorUtils.nodes,
        marks: EditorUtils.marks
    }), props.defaultValue??"<p><p/>"));

    const onChange = event => {
        setValue(event.value);        

        if (props.onChange) {

            const val = accentUtils.isEmpty(event.value.textContent) ? null : event.html;

            props.onChange(val);
        }

    };

    var tools = undefined;

    const allTools = props.allTools;
    const minTools = props.minTools;
    const height = (accentUtils.isNull(props.height)) ? 500 : props.height;


    if (allTools) {
        tools = [
            [Bold, Italic, Underline, Strikethrough],
            [Subscript, Superscript],
            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
            [Indent, Outdent],
            [OrderedList, UnorderedList],
            FontSize, FontName, FormatBlock,
            [Undo, Redo],
            [Link, Unlink, InsertImage, ViewHtml],
            [InsertTable],
            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
            [DeleteRow, DeleteColumn, DeleteTable],
            [MergeCells, SplitCell]
        ];
    } else {

        if (minTools) {
            tools =
                [
                    [Bold, Italic, Underline, Strikethrough],
                    [OrderedList, UnorderedList],
                    [Undo]
                ];

        } else {

            tools =
                [
                    [Bold, Italic, Underline, Strikethrough],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize, FontName, FormatBlock,
                    [Undo, Redo],
                    [Link, Unlink]
                ];

        }
    }


    return <div className="accentHtml" data-tagname={props.dataTagName}>
        <Editor value={value} onChange={onChange} tools={tools} contentStyle={{ height: height }} />
    </div>;
};
