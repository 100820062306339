import React from 'react';
import { Table, Badge } from 'reactstrap';
import { download, useQueryAllNoTracking } from '../../services/DataService';
import { AccentSpinner } from '../AccentSpinner';
import { accentUtils, format } from '../../services/HelperService';


export const ActivityOutcomeCtrl = ({ activityID }) => {

    const args = React.useMemo(() => ({ activityID: activityID }), [activityID]);

    const outcomeLines = useQueryAllNoTracking("Activity_Outcomes", args);


    const renderRow = (line, index) => {


        const reasonNotes = line.Outcome === "Reschedule" ? line.RescheduleNotes : line.FaultReasonNotes;

        const showDetails = !accentUtils.isEmpty(line.Documents) || !accentUtils.isEmpty(reasonNotes);

        const details = showDetails && <tr key={`${index}_b`}>
            <td colSpan="4" style={{ width: '50%' }}><strong>NOTES:&nbsp;</strong>{ reasonNotes }</td>
            <td colSpan="4" style={{ width: '50%' }}>
                <ul>
                    {line.Documents?.map((item, idx) => (
                        <li key={idx}><div className="acc-link" onClick={() => {
                            download('/Download/DocumentSketchUri/?uri=' + encodeURIComponent(item.Uri));
                        }}>{item.Description}</div></li>
                    ))}
                </ul>
            </td>
        </tr>;


        const color = (line.Outcome === "Reschedule") ? "warning" : (line.Outcome === "Completed") ? "success" : "danger";


        return (<>
            <tr key={`${index}_a`}>
                <td>{line.JobLineNo}</td>
                <td>{line.LocationOther}</td>
                <td>{line.ProductName}</td>
                <td>{line.Qty}</td>
                <td>{format.formatCurrency(line.ExpectedAmount)}</td>
                <td>{line.StockLocation}</td>
                <td><Badge color={color}> {line.Outcome}</Badge></td>
                <td>{line.Outcome === "Reschedule" ? line.RescheduleReason : line.FaultReason}</td>
            </tr>
            {details}
        </>);
    };


    if (outcomeLines.loading) return <AccentSpinner />;

    return (
        <Table striped style={{ tableLayout: 'fixed', width: '100%' }}>
            <thead>
                <tr>
                    <th>Line</th>
                    <th>Location</th>
                    <th>Product</th>
                    <th>Qty</th>
                    <th>Expected</th>
                    <th>Stock Location</th>
                    <th>Outcome</th>
                    <th>Reason</th>
                </tr>
            </thead>
            <tbody>
                {outcomeLines.data.map((line, index) => renderRow(line, index))}
            </tbody>
        </Table>
    );
};


