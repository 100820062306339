import * as React from 'react'
import { filterSchemaNames } from '../controls/advancedfilter/RTLGridFilterCtrl';
import { SummaryCellTemplate } from '../controls/grids/GridColumnTemplates';
import { RTLBoundGridCtrl } from '../controls/grids/RTLBoundGridCtrl';
import { queryNoTracking } from '../services/DataService';
import { accentUtils, from } from '../services/HelperService';
import { jobTypes } from '../services/JobService';
import GRIDS from './_GridDefinitions';


const query_GridFilter_BusinessUnit = queryNoTracking("GridFilter_BusinessUnit");
const query_GridFilter_SalesRep = queryNoTracking("GridFilter_SalesRep");
const query_GridFilter_CommitReason = queryNoTracking("GridFilter_CommitReason");
const query_GridFilter_CustomStatus = queryNoTracking("GridFilter_CustomStatus");

export class JobGrid extends React.Component {


    static ProcessColumn(col) {
        
        if (col.field === "Stages" || col.field === "Products" || col.field === "Dispatch") {
            col.cellTemplate = (p) => <SummaryCellTemplate value={ p.value} translate />;
            col.filterable = false;
        } else if (col.field === "BusinessUnit") {
            col.filterQuery = () => query_GridFilter_BusinessUnit.getAllWithCache();
        } else if (col.field === "SalesRep") {
            col.filterQuery = () => query_GridFilter_SalesRep.getAllWithCache();
        } else if (col.field === "CommitReason") {
            col.filterQuery = () => query_GridFilter_CommitReason.getAllWithCache();
        } else if (col.field === "CustomStatus") {
            col.filterQuery = () => query_GridFilter_CustomStatus.getAllWithCache();
        } else if (col.field === "JobType") {
            col.filterQuery = () => Promise.resolve(from(jobTypes.getPicklistSource()).select(s => { return { ID: s.ID, JobType: s.Description } }).toArray());
        }

        return col;
    }



    constructor(props) {
        super(props);


        this.gridCtrl = React.createRef();
        this.getSelected = this.getSelected.bind(this);

    }

    getSelected() {
        return this.gridCtrl.current.getSelected();
    }


    

    render() {

    


        return (
            <RTLBoundGridCtrl
                methods={this.gridCtrl}
                query={this.props.query}
                height={this.props.height}
                gridID={this.props.gridID}
                detailsLookupKey="Job"
                contextKey={GRIDS.JobGridView.ContextKey}
                columns={GRIDS.JobGridView.Default}
                showToolbar={!this.props.hideToolbar}
                title="application_strings.grids.jobs.title"
                allowFilter={!this.props.hideFilter}
                allowSearch={this.props.allowSearch}
                filterSchemaName={filterSchemaNames.job}
                showSectionHeader={this.props.showSectionHeader}
                processColumn={JobGrid.ProcessColumn}
                strings="jobGridView"
                defaultSearchText={this.props.defaultSearchText}
                clearFilters={this.props.clearFilters}
                collapsed={this.props.collapsed}
            />
        );

    }
}
