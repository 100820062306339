import * as React from 'react';
import { Row, Col} from 'reactstrap';
import classNames from 'classnames'
import { accentUtils, from, showDialog, t } from '../../services/HelperService';
import { ConfiguratorContext } from './ConfigContextProvider';
import { useValidation, setOptionValueChanged, isReadOnly, useChanged, toGuid, fabric_GetRailroadingOption, fabric_GetIgnoreFabricWidth, fabric_GetIgnoreRailroadedFabricWidth, fabric_GetAllowContinuous, fabric_GetDropAdjustment, fabric_GetWidthAdjustment, fabric_GetAllowRailroading, fabric_GetCustomFabric, fabric_OrderLineOptionSet, fabric_OrderLineOptionClear, useActionType, getOptionModel } from './ProductConfiguratorShared';
import AccentIcons from '../../icons/Icons';
import { AccentDialog } from '../../dialogs/AccentDialog';
import { AccentButton } from '../AccentButton';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';


function showPanelPatternDlg(ctx, optionModel, readOnly){

    const viewModel = new window.InsyteProduct.Models.PanelPatternViewModel(optionModel, (g) => ctx.config.GetProductOptionValue(g));
    viewModel.Value = optionModel.OrderLineOption.AdditionalValue ?? "";
    showDialog(<PanelPatternDlg ctx={ctx} optionModel={optionModel} viewModel={viewModel} readOnly={readOnly} />).then(res => {

        if (!res.canceled && !readOnly) {

            optionModel.OrderLineOption.AdditionalValue = res.additionalValue;
            optionModel.OrderLineOption.CustomValue = res.customValue;

            setOptionValueChanged(ctx, optionModel);

        }

    });

}

export const PanelPatternActionBtn = props => {

    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);

    const validation = useValidation(props.optionID, "PanelPatternActionBtn");


    const actionType = useActionType(props.optionID);

    const readOnly = isReadOnly(optionModel);

    const onClick = React.useCallback(e => {

        showPanelPatternDlg(ctx, optionModel, readOnly);

    }, [ctx, optionModel, readOnly]);



    if (!actionType.isPanelPattern) {
        return null;
    }

    return <div className="config-value-action">
        <AccentIcons.More onClick={onClick} noPadding />
    </div>;
    
}

export const ConfigPanelPattern = props => {
    
    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);
    
    const changed = useChanged(props.optionID, "ConfigPanelPattern", true);
    const validation = useValidation(props.optionID, "ConfigPanelPattern");

    const readOnly = isReadOnly(optionModel);


    const onClick = React.useCallback(e => {

        showPanelPatternDlg(ctx, optionModel, readOnly);

    }, [ctx, optionModel, readOnly]);



    const className = classNames("config-panel-pattern",
        {
            "required": (!accentUtils.isEmpty(validation.error)) && !readOnly
        }
    );


    return <div className={`config-control config-panel-pattern ${readOnly ? " readonly" : ""}`}>
        <div className={className} >
            <div key={changed.changeID} className="config-panel-pattern-display" onClick={ onClick}>
                {optionModel.OrderLineOption.CustomValue}
            </div>
        </div>
    </div>;
};




const PanelPatternDlg = props => {

    
    
    const [refreshKey, setRefreshKey] = React.useState(0);

    const onClose = React.useCallback(e => {

        props.onClose({
            additionalValue: props.viewModel.Value,
            customValue: props.viewModel.CustomValue
        });

    }, [props]);

    const onValueValued = React.useCallback((e, row, isNumber) => {

        const value = e;

        const fieldID = isNumber ? row.NumberFieldID : row.OptionFieldID;

        if (accentUtils.isEmpty(value)) {
            props.viewModel.SetRowValue(fieldID, toGuid(accentUtils.getEmptyGuid()));
        }else if (isNumber) {
            props.viewModel.SetRowValue(fieldID, value);
        } else {
            props.viewModel.SetRowValue(fieldID, toGuid(value))
        }

        setRefreshKey(k => k + 1);

    }, [props.viewModel, setRefreshKey]);


    const numbers = props.viewModel.Numbers.map(n => ({ ID: n, Value: n }));
    const values = props.optionModel.FilteredItems.toArray().map(i => ({ ID: i.ItemKey.toString(), Value: i.ItemValue.toString() }));


    const rows = from(props.viewModel.Rows.toArray())
        .reverse()
        .select(r => <Row key={r.NumberFieldID}>
            <Col md={6}><AccentComboUnbound disabled={r.NumberReadOnly || props.readOnly} displayField="Value" valueField="ID" data={numbers} onChange={e => onValueValued(e, r, true)} defaultValue={r.NumberValue} openOnFocus /></Col>
            <Col md={6}><AccentComboUnbound disabled={r.ValueReadOnly || props.readOnly} displayField="Value" valueField="ID" data={values} onChange={e => onValueValued(e, r, false)} defaultValue={(r.OptionValue?.ID?.toString()) ?? accentUtils.getEmptyGuid()} openOnFocus /></Col>
        </Row>)
        .toArray();





    return <AccentDialog
        onClose={props.onClose}
        headerContent={"Pattern"}
        actionsContent={[<AccentButton key="2" tagName="panelCloseBtn" onClick={onClose}>{t("application_strings.application.buttons.ok")}</AccentButton>]}
    >
        <Row>
            <Col md={12} key={refreshKey}>
                {rows}
            </Col>

        </Row>
    </AccentDialog>;
};
