import React from 'react'
import { t } from '../services/HelperService'

export const ByPassCMCtrl = props => {

    return <div className="acc-bypass-cm-warn">{t("application_strings.views.calendar.byPassCMMsg")}</div>;

};

export const ByPassCMNoPermissionCtrl = props => {

    return <div className="acc-bypass-cm-warn">{t("application_strings.views.calendar.byPassCMNoPermissionMsg")}</div>;

};