import * as React from 'react';
import { accentUtils } from '../../services/HelperService';
import { AccentTextUnbound } from '../unbound/AccentTextUnBound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { configEventControlType, configEvents, getOptionModel, isReadOnly, onSimpleOptionValueChanged,  toGuid,  useChanged, useValidation } from './ProductConfiguratorShared';

export const ConfigText = props => {


    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);

    const changed = useChanged(props.optionID, "ConfigNumeric", false);
    const validation = useValidation(props.optionID, "ConfigNumeric");

    const [value, setValue] = React.useState(accentUtils.isEmpty(optionModel.CurrentValueAsString) ? null : optionModel.CurrentValueAsString?.toString());

    const fireChange = React.useMemo(() => onSimpleOptionValueChanged(), [props.optionID]);


    const onChange = React.useCallback(e => {

        fireChange(ctx, optionModel, e, changed.tag).then(() => {
            ctx.config?.Publish(configEvents.controlLostFocus, configEventControlType.text);
        })

        setValue(e);

    }, [setValue, ctx, optionModel, fireChange, changed.tag]);

    const onFocus = React.useCallback(e => {

        ctx.config?.Publish(configEvents.controlGotFocus, configEventControlType.text);

    }, [ctx]);

    const notifyDirty = React.useCallback(e => {

        if (ctx.notifyDirty) {
            ctx.notifyDirty();
        }

    }, [ctx]);


    const readOnly = isReadOnly(optionModel);


    return <div className="config-control config-text">
        <AccentTextUnbound
            key={changed.changeID}
            required={validation.required}
            defaultValue={accentUtils.isEmpty(optionModel.CurrentValueAsString) ? null : optionModel.CurrentValueAsString?.toString()}
            onBlur={onChange}
            onFocus={onFocus}
            onChange={notifyDirty}
            disabled={readOnly}            
        />
    </div>;
};

