import * as React from "react";
import { Alert } from "reactstrap";
import { TreeListBooleanEditor, TreeList, mapTree, treeToFlat } from '@progress/kendo-react-treelist'
import { AccentButton } from "../controls/AccentButton";
import { accentUtils, from, t } from "../services/HelperService";
import { AccentDialog } from "./AccentDialog";
import { AccentSpinner } from "../controls/AccentSpinner";
import { usePosition } from "../controls/AccentDisplay";
import { queryNoTracking, update } from "../services/DataService";



const query_Job_SelectLinesForHoldHold = queryNoTracking("Job_SelectLinesForHoldHold");


const processTree = (dataTree, childrenField, processItem) => {
    return mapTree(dataTree, childrenField, (item) => processItem(item));
};


export const JobOnHoldDlg = props => {

    const [data, setData] = React.useState(null);
    const [expanded, setExpanded] = React.useState({});
    const [selected, setSelected] = React.useState({});
    const [noLines, setNoLines] = React.useState(false);
    const pos = usePosition("jobOnHoldDlg");

    const onExpandChanged = React.useCallback(e => {

        setExpanded(existing => {
            return { ...existing, [e.dataItem.ID]: !e.value };
        });

    }, [setExpanded])

    const onItemChanged = React.useCallback(e => {


        const items = treeToFlat([e.dataItem], "ID", "Children");

        const newChecked = from(items).select(i => i.ID).toArray().reduce((a, v) => ({ ...a, [v]: e.value }), {});

        setSelected(existing => {
            return { ...existing, ...newChecked };
        });
    }, [setSelected]);


    const onSelect = React.useCallback(e => {

        const allItems = treeToFlat(data, "ID", "Children");

        props.onClose({
            canceled: false,
            ID: props.id,
            LineIDs: from(allItems)
                .where(i => selected[i.ID] && !accentUtils.isEmpty(i.LineID))
                .select(i => i.LineID)
                .toArray()
        });

    }, [props, data, selected]);



    React.useEffect(() => {

        query_Job_SelectLinesForHoldHold.getFirstOrDefault({ id: props.id, onHoldLines: !props.setOnHold }).then(model => {


            const allExpanded = {};
            const defaultSelection = {};
            const r = model.Tree;

            if (accentUtils.isNull(model.Tree)) {
                setNoLines(true);
            } else {

                setData(processTree([r], "Children", item => {

                    allExpanded[item.ID] = true;

                    if (accentUtils.isEmpty(props.defaultLinefilter)) {
                        defaultSelection[item.ID] = true;
                    } else {
                        if (from(props.defaultLinefilter).any(x => x == item.ID)) {
                            defaultSelection[item.ID] = true;
                        }
                    }



                    return { ...item, expanded: true, editing: true, Stage: t(item.Stage), Dispatch: t(item.Dispatch) };
                }));



                setSelected(defaultSelection);
                setExpanded(allExpanded);
            }


        })


    }, []);


    const hasSelection = from(Object.values(selected)).any(s => s === true);

    let qty = 0;
    const treeData = processTree(data ?? [], "Children", item => {

        const itemSelected = selected[item.ID] ?? false;

        const isLineItem = (item.Children?.length ?? 0) === 0;

        if (isLineItem && itemSelected) {
            qty += (item.Qty ?? 0);
        }


        return { ...item, expanded: expanded[item.ID] ?? false, selected: itemSelected };
    });



    const height = pos.toScreenHeight - 50;

    return <AccentDialog
        fullscreen
        headerContent={t("application_strings.application.dialogs.jobOnHoldDlg.title")}
        onClose={props.onClose}
        actionsContent={[<AccentButton key="1" disabled={!hasSelection} tagName="save" onClick={onSelect}>{t(`application_strings.application.buttons.${props.setOnHold ? "hold":"release"}`)}</AccentButton>]}
    >
        {noLines && <Alert color="danger">{t("application_strings.application.dialogs.jobOnHoldDlg.noLines")}</Alert>}
        {!noLines && accentUtils.isNull(data) && <AccentSpinner />}
        {!noLines && !accentUtils.isNull(data) &&

            <div id="jobOnHoldDlg">
                <TreeList
                    data={treeData}
                    style={{
                        height: `${height}px`,
                        overflow: "auto",
                    }}
                    editField="editing"
                    expandField="expanded"
                    subItemsField="Children"
                    validationField="validation"
                    columns={columns}
                    onExpandChange={onExpandChanged}
                    onItemChange={onItemChanged} />
            </div>
        }
    </AccentDialog>

};



const columns = [
    {
        field: "selected",
        title: " ",
        expandable: true,
        editCell: TreeListBooleanEditor,
        locked: true,
        width: 30,
    },
    {
        field: "Item",
        title: "Item",
        width: 250,
        expandable: true,

    },
    {
        field: "Qty",
        title: "Qty",
        width: 70,
        expandable: false,
    },
    {
        field: "Stage",
        title: "Stage",
        width: 250,
        expandable: false,
    },
    {
        field: "Location",
        title: "Location",
        width: 250,
        expandable: false,
    },
    {
        field: "Dispatch",
        title: "Dispatch",
        width: 250,
        expandable: false,
    },
    {
        field: "DisplayOption1",
        title: "Option 1",
        width: 250,
        expandable: false,
    },
    {
        field: "DisplayOption2",
        title: "Option 2",
        width: 250,
        expandable: false,
    },
    {
        field: "DisplayOption3",
        title: "Option 3",
        width: 250,
        expandable: false,
    }

];