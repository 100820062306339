import React from 'react'
import { Col, Row } from 'reactstrap';
import { accentUtils } from '../services/HelperService'

export class RTLGridLayout extends React.Component {

    constructor(props) {
        super(props);

    }



    render() {



        const rows = [];

        var cols = [];
        const colWidth = accentUtils.roundDown(12 / this.props.cols, 0);

        for (var i = 0; i < this.props.items.length; i++) {

            if (i % this.props.cols == 0) {

                if (cols.length > 0) {
                    rows.push(<Row key={rows.length}>{cols}</Row>);
                }

                cols = [];
            }

            cols.push(<Col key={ i} md={colWidth} key={cols.length}>{this.props.items[i]}</Col>);

        }

        if (cols.length > 0) {
            rows.push(<Row key={rows.length+1}>{cols}</Row>);
        }


        return (
            <Row >
                <Col md={12}>
                    {rows}
                </Col>
            </Row>
        );
        

    }

}