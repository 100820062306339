import * as React from "react";
import { Table, Row, Col } from "reactstrap";
import { accentUtils, format, showOK, t } from "../../services/HelperService";
import { getAccess, getUser } from "../../services/UserService";
import { AccentButton } from "../AccentButton";



var diffType = {
    Line: "diff_type_line",
    Configuration: "diff_type_configuration",
    Margin: "diff_type_margin",
    SalePice: "diff_type_sale_price",
    LineAdded: "diff_type_line_added",
    LineRemoved: "diff_type_line_removed"
};



export class JobDiffCtrl extends React.Component {

    constructor(props) {
        super(props);

        this.quoted = this.props.quoted;
        this.diff = this.props.diff;
        this.showDetails = this.showDetails.bind(this);
    }


    showDetails(line) {


        showOK("application_strings.views.jobDiff.details", <JobDiffDetailDlg diff={line} userModel={getUser()} />, ["application_strings.application.buttons.ok"], false, null, true).then(function (res) {

        });

    }

    render() {

        if (accentUtils.isNull(this.diff)) {
            return <label>{t("application_strings.views.jobDiff.noChanges")}</label>;
        }

        const userAllowCost = getAccess().features().FeatureAllowViewCostPrice();

        var me = this;

        var salePriceClass = (this.diff.NewSalePrice - this.diff.OriginalSalePrice > 0) ? "accentNumCol diff-warn" : "accentNumCol diff-ok";
        var marginClass = (this.diff.NewMargin - this.diff.OriginalMargin < 0) ? "accentNumCol diff-warn" : "accentNumCol diff-ok";



        var lines = this.diff.Lines.filter(l => l.Items.length > 0).map(function (l) {

            var salePriceIndicator = <span className={(l.SalePriceChange > 0 ? "accentUp diff-warn" : l.SalePriceChange < 0 ? "accentDown diff-warn" : undefined)} />;
            var marginIndicator = <span className={(l.MarginChange > 0 ? "accentUp diff-warn" : l.MarginChange < 0 ? "accentDown diff-warn" : undefined)} />;

            return <tr key={l.ID}>
                <td>{l.LineNo}</td>
                <td>{l.Location}</td>
                <td>{l.Supplier}</td>
                <td>{l.Product}</td>
                <td className="accentNumCol">{format.formatCurrency(l.SalePriceInc)}{salePriceIndicator}</td>
                <td className="accentNumCol">{userAllowCost ? format.formatPercentage(l.Margin) : ""}{marginIndicator}</td>
                <td className="accentNumCol"><AccentButton tagName={"changeDetailBtn_" + l.ID} onClick={function () { me.showDetails(l); }}>...</AccentButton></td>
            </tr>;

        });


        var removed = this.diff.RemovedLines.map(function (l) {

            var salePriceIndicator = <span className={(l.SalePriceChange > 0 ? "accentUp diff-warn" : l.SalePriceChange < 0 ? "accentDown diff-warn" : undefined)} />;
            var marginIndicator = <span className={(l.MarginChange > 0 ? "accentUp diff-warn" : l.MarginChange < 0 ? "accentDown diff-warn" : undefined)} />;

            return <tr key={l.ID} className="diff-removed">
                <td>{l.LineNo}</td>
                <td>{l.Location}</td>
                <td>{l.Supplier}</td>
                <td>{l.Product}</td>
                <td className="accentNumCol">{format.formatCurrency(l.SalePriceInc)}{salePriceIndicator}</td>
                <td className="accentNumCol">{userAllowCost ? format.formatPercentage(l.Margin) : ""}{marginIndicator}</td>
                <td className="accentNumCol"><AccentButton tagName={"changeDetailBtn_" + l.ID} onClick={function () { me.showDetails(l); }}>...</AccentButton></td>
            </tr>;

        });

        var noChanges = lines.length === 0 && removed.length === 0;

        return <Col md="12">
            <Row>
                <Col md="12">
                    <Table className="table-sm">
                        <thead>
                            <tr>
                                <th>{t("application_strings.views.jobDiff.jobDetails")}</th>
                                <th className="accentNumCol">{t("application_strings.views.jobDiff.originalValue")}</th>
                                <th className="accentNumCol">{t("application_strings.views.jobDiff.newValue")}</th>
                                <th className="accentNumCol">{t("application_strings.views.jobDiff.change")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t("application_strings.views.jobDiff.salePriceInc")}</td>
                                <td className="accentNumCol">{format.formatCurrency(this.diff.OriginalSalePrice)}</td>
                                <td className="accentNumCol">{format.formatCurrency(this.diff.NewSalePrice)}</td>
                                <td className={salePriceClass}>{format.formatCurrency(this.diff.NewSalePrice - this.diff.OriginalSalePrice)}</td>
                            </tr>
                            {!userAllowCost ? null :
                                <tr>
                                    <td>{t("application_strings.views.jobDiff.margin")}</td>
                                    <td className="accentNumCol">{format.formatPercentage(this.diff.OriginalMargin)}</td>
                                    <td className="accentNumCol">{format.formatPercentage(this.diff.NewMargin)}</td>
                                    <td className={marginClass}>{format.formatPercentage(this.diff.NewMargin - this.diff.OriginalMargin)}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Table className="table-sm">
                        <tbody>
                            <tr>
                                <th>{t("application_strings.views.jobDiff.lineNo")}</th>
                                <th>{t("application_strings.views.jobDiff.location")}</th>
                                <th>{t("application_strings.views.jobDiff.supplier")}</th>
                                <th>{t("application_strings.views.jobDiff.product")}</th>
                                <th className="accentNumCol">{t("application_strings.views.jobDiff.salePriceInc")}</th>
                                <th className="accentNumCol">{t("application_strings.views.jobDiff.margin")}</th>
                                <th>{t("application_strings.views.jobDiff.details")}</th>
                            </tr>
                            {lines}
                            {removed}
                            {noChanges ? <td colSpan="7"><label>No changes found</label></td> : null}
                        </tbody>
                    </Table>
                </Col>
            </Row>

        </Col>;
    }
}




class JobDiffDetailDlg extends React.Component {

    constructor(props) {
        super(props);

        this.diff = this.props.diff;
    }

    render() {
        const userAllowCost = getAccess().features().FeatureAllowViewCostPrice();

        var index = 0;
        var changes = this.diff.Items.length == 0 ? <tr key={index++}>
            <td colSpan="3"><label>No changes found</label></td>
        </tr> :
            this.diff.Items.filter(i => !i.IsCostItem || userAllowCost).map(function (i) {

                var originalValue = null;
                var newValue = null;

                if (i.IsCurrency) {
                    originalValue = format.formatCurrency(i.OriginalValue);
                    newValue = format.formatCurrency(i.NewValue);
                } else if (i.Type === diffType.Margin) {
                    originalValue = format.formatPercentage(i.OriginalValue);
                    newValue = format.formatPercentage(i.NewValue);
                } else {
                    originalValue = decodeURIComponent(i.OriginalValue);
                    const origLines = originalValue.split('\n');
                    var divs = origLines.filter(f => !f.startsWith("@@")).map(function (l) {
                        return <div>{l}</div>
                    });
                    originalValue = <div>{divs}</div>;
                    newValue = (decodeURIComponent(i.NewValue));
                    const newLines = newValue.split('\n');
                    var newDivs = newLines.filter(f => !f.startsWith("@@")).map(function (l) {
                        return <div>{l}</div>
                    });
                    newValue = <div>{newDivs}</div>;
                }


                var fieldName = t("application_strings.views.jobDiff." + i.FieldName);

                if (fieldName.includes("application_strings")) {
                    fieldName = t("application_strings.entities.jobLine." + i.FieldName);
                }

                if (fieldName.includes("application_strings")) {
                    fieldName = i.FieldName;
                }


                return <tr key={index++}>
                    <td>{fieldName}</td>
                    <td className="accentNumCol">{originalValue}</td>
                    <td className="accentNumCol">{newValue}</td>
                </tr>;
            });


        return <Col md="12">
            <Row>
                <Col md="3"><label>{t("application_strings.views.jobDiff.lineNo")}</label></Col>
                <Col md="3">{this.diff.LineNo}</Col>
                <Col md="3"><label>{t("application_strings.views.jobDiff.location")}</label></Col>
                <Col md="3">{this.diff.Location}</Col>
            </Row>
            <Row>
                <Col md="3"><label>{t("application_strings.views.jobDiff.supplier")}</label></Col>
                <Col md="3">{this.diff.Supplier}</Col>
                <Col md="3"><label>{t("application_strings.views.jobDiff.product")}</label></Col>
                <Col md="3">{this.diff.Product}</Col>
            </Row>
            <Table className="table-sm">
                <tbody>
                    <tr>
                        <th>{t("application_strings.views.jobDiff.property")}</th>
                        <th className="accentNumCol">{t("application_strings.views.jobDiff.originalValue")}</th>
                        <th className="accentNumCol">{t("application_strings.views.jobDiff.newValue")}</th>
                    </tr>
                    {changes}
                </tbody>
            </Table>
        </Col>;

    }
}