import * as React from 'react';


export const RTLGridContext = React.createContext();


export class RTLGridContextProvider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            details: props.detailsContext
        }

    }

    

    render() {
        return <RTLGridContext.Provider value={{ details: { ...this.props.detailsContext } }}>
            { this.props.children}
        </RTLGridContext.Provider>
    }

}
