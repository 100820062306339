import * as React from 'react'
import { accentUtils, t } from '../../services/HelperService'

export class AccentImage extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();
        this.getModelValue = this.getModelValue.bind(this);
        this.setModelValue = this.setModelValue.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.getFileInput = this.getFileInput.bind(this);
        var image = this.getModelValue();
        this.state = {
            showPreview: image != null,
            url: (image != null) ? "data:image;base64," + image : ""
        };
    }



    getModelValue() {
        
        var image = this.props.model.getValue();
        
        if (image == null)
            return null;
        
        return image;
        
    }

    setModelValue(result) {
        
        if (result == null)
            this.props.model.setValue(null);
        else{
            var val = result.substring(result.indexOf(",") + 1);
            
            this.props.model.setValue(val);
        }
            
    }
    
    handleChangeFile(evt) {
        var me = this;
        var files = evt.target.files;
        if (files.length == 1) {
                this.props.model.modified();
            var reader = new FileReader();
            reader.onload = function (e) {
                    
                   
                me.setState({showPreview: true, url: this.result});
                    me.setModelValue(this.result);    
            };
            reader.readAsDataURL(files[0]);
        } else {
            // if no file was chosen (e.g., if file-choosing was cancelled), 
            //     explicity ensure that the content is set back to null: 
                
            me.setState({showPreview: false});
            me.setModelValue(null);
        }
    }

    getFileInput(){
        return (<input type="file" onChange={this.handleChangeFile} ref={this.ctrl}  />);
    }


    render() {
    
        let preview = <div></div>
        
        if (this.state.showPreview)
            preview = <img className="accentLogo" src={this.state.url} /> 
        
        const c = this.getFileInput()
        
        
        var disabled = false;
        return (<div>{preview}{c}</div>);
    }
}
