import * as React from 'react'
import { savePDF } from "@progress/kendo-react-pdf";
import { Table } from 'reactstrap';
import { AccentButton } from '../controls/AccentButton';
import { AccentSpinner } from '../controls/AccentSpinner';
import { useQueryAllNoTracking, useURL } from '../services/DataService';
import { t, A, accentUtils, showDialog, format, ConvertDateFromUTC } from '../services/HelperService';
import { AccentDialog } from './AccentDialog';
import AccentIcons from '../icons/Icons';



const ActivitySummary = props => {

    const phoneNumbers = [];
    const companyPhoneNumbers = [];
    
    if (!accentUtils.isEmpty(props.data.Activity.Mobile)) phoneNumbers.push(props.data.Activity.Mobile);
    if (!accentUtils.isEmpty(props.data.Activity.WorkPhone)) phoneNumbers.push(props.data.Activity.WorkPhone);
    if (!accentUtils.isEmpty(props.data.Activity.HomePhone)) phoneNumbers.push(props.data.Activity.HomePhone);

    if (!accentUtils.isEmpty(props.data.Activity.CompanyMobile)) companyPhoneNumbers.push(props.data.Activity.CompanyMobile);
    if (!accentUtils.isEmpty(props.data.Activity.CompanyPhone)) companyPhoneNumbers.push(props.data.Activity.CompanyPhone);


    return <div>
        <h5>Appointment</h5>
        <Table>
            <tbody>
                <tr >
                    <td><label>ACTIVITY TYPE</label><div>{props.data.Activity.ActivityType}</div></td>
                    <td><label>PRIORITY</label><div>{t(props.data.Activity.Priority)}</div></td>
                </tr>
                <tr >
                    <td><label>CATEGORY</label><div>{props.data.Activity.Category}</div></td>
                    <td><label>START</label><div>{format.formatDateTime(ConvertDateFromUTC(props.data.Activity.Start, props.data.TimeZone))}</div></td>
                </tr>
                <tr >
                    <td><label>SUBJECT</label><div>{props.data.Activity.Subject}</div></td>
                    <td><label>END</label><div>{format.formatDateTime(ConvertDateFromUTC(props.data.Activity.End, props.data.TimeZone))}</div></td>
                </tr>
                <tr >
                    <td><label>OWNER</label><div>{props.data.Activity.Owner}</div></td>
                    <td><label>ATTENDEES</label><div>{props.data.Activity.Attendees.join(", ")}</div></td>
                </tr>
                <tr >
                    <td>
                        <label>COMPANY</label>
                        <div>{props.data.Activity.Company}</div>
                        <div>{props.data.Activity.CompanyEmail}</div>
                        <div>{companyPhoneNumbers.join(", ")}</div>
                    </td>
                    <td>
                        <label>CONTACT</label>
                        <div>{props.data.Activity.Contact}</div>
                        <div>{props.data.Activity.Email}</div>
                        <div>{phoneNumbers.join(", ")}</div>
                    </td>
                </tr>
                <tr >
                    <td><label>JOB</label><div>{props.data.Activity.Job}</div></td>
                    <td><label>ADDRESS</label><div>{props.data.Activity.Address.Formatted}</div></td>
                </tr>
                <tr >
                    <td colSpan={2}><label>JOB LINES</label><div>{props.data.Activity.JobLines.map(x => `${x.ProductName} (${x.Qty})`).join(", ")}</div></td>
                </tr>
            </tbody>
        </Table>
        <div>
            <b>ACTIVITY NOTES</b>
            <pre className="read-only-text" style={{ whiteSpace: "break-spaces"}}>
                {props.data.Activity.Notes}
            </pre>
        </div>

    </div>;
};



const ActivitySummaryDlg = React.memo(props => {

    const pdfExportComponent = React.useRef(null);
    const args = React.useRef({ id: props.id });

    const dataResult = useQueryAllNoTracking("Activity_Summary", args.current);


    const exportPDFWithMethod = () => {

        if (accentUtils.isNull(pdfExportComponent.current)) return;

        let element = pdfExportComponent.current;

        const data = dataResult.data[0].Activity;

        const fileName = `${data.Owner}_${format.formatDateTimeFileName(data.Start)}_${data.ID}`

        savePDF(element, {
            paperSize: "A4",
            margin: 40,
            scale: 0.85,
            fileName: fileName,
        });
    };




    const content = dataResult.loading ? <div><AccentSpinner />{t("application_strings.application.dialogs.pleaseWait")}</div> : <div ref={pdfExportComponent}><ActivitySummary data={dataResult.data[0]} /></div>;



    return <AccentDialog
        onClose={props.onClose}
        headerContent={props.title}
        actionsContent={[
            <AccentButton key="2" tagName="sctSumPrint" onClick={exportPDFWithMethod}>
                {t("application_strings.application.buttons.print")}
            </AccentButton>,
        ]}
    >
        {content}
    </AccentDialog>;

});


export const ActivitySummaryIcon = props => {

    const onPrint = async () => {

        if (props.onBeforePrint) {
            var res = await props.onBeforePrint();

            if (res?.canceled) return;
        }


        showDialog(<ActivitySummaryDlg id={props.id} />);
    };

    return <div style={{ display: "inline-block" }} ><AccentIcons.Print onClick={onPrint} top={props.top} noPadding={props.noPadding} /></div>;
};