import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import { accentUtils } from '../services/HelperService';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("AccentTooltip error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return null; // Or a fallback UI if preferred
        }

        return this.props.children;
    }
}

export const AccentTooltip = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [targetElement, setTargetElement] = useState(null);

    useEffect(() => {
        const findTarget = () => {
            const element = document.getElementById(props.target);
            if (element) {
                setTargetElement(element);
            } else {
                console.warn(`Target element with id "${props.target}" not found.`);
            }
        };

        findTarget();

        return () => {
        };
    }, [props.target]);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const renderContent = () => {
        if (!accentUtils.isNull(props.html)) {
            return <span dangerouslySetInnerHTML={{ __html: props.html }} />;
        } else if (!accentUtils.isNull(props.text)) {
            return <span>{props.text}</span>;
        } else {
            return <div>{props.children}</div>;
        }
    };

    if (!targetElement) {
        return null; // Don't render the tooltip if the target element is not found
    }

    return (
        <ErrorBoundary>
            <Tooltip
                data-tagname={encodeURIComponent(props.text)}
                className={props.className}
                delay={{
                    show: accentUtils.isNull(props.delayShow) ? 500 : Number(props.delayShow),
                    hide: accentUtils.isNull(props.delayHide) ? 0 : Number(props.delayHide)
                }}
                placement={accentUtils.isNull(props.placement) ? 'top' : props.placement}
                isOpen={tooltipOpen}
                autohide={false}
                target={targetElement}
                toggle={toggle}
                style={props.style}
            >
                {renderContent()}
            </Tooltip>
        </ErrorBoundary>
    );
};

