import React from 'react'
import $ from 'jquery'
import { Popup, TreeListBooleanEditor, TreeList } from '@progress/kendo-react-treelist'
import { t, accentUtils, from, newGuid } from '../../services/HelperService';
import { OutsideAlerter } from './../OutsiderAlerter'
import { AccentSpinner } from './../AccentSpinner'
import { AccentTooltip } from './../AccentTooltip'
import AccentIcons from './../../icons/Icons'
import { AccentPopup } from '../../dialogs/AccentPopup';

export const JobLineTreeMultiSelectCtrl = props => {

    const anchor = React.useRef(null);

    const [key, setKey] = React.useState(newGuid());
    const [showDetail, setShowDetail] = React.useState(false);
    const [selection, setSelection] = React.useState(accentUtils.isNull(props.delfaultSelection) ? {} : props.delfaultSelection.reduce((a, v) => ({ ...a, [v]: v }), {}) );
    const [lineData, setLineData] = React.useState(null);


    const targetID = `key${key}`;
    const btnID = `btn${targetID}`;


    const onToggle = React.useCallback(e => {
        setShowDetail(showDetail => !showDetail);
    }, [showDetail, setShowDetail]);

    const onShow = React.useCallback(e => {
        if (!showDetail) {
            setShowDetail(true);
        }
    }, [showDetail, setShowDetail]);

    const onOutsideClicked = React.useCallback(e => {

        const elem = $(e.target);

        const isBtn = (elem.attr("id") == btnID || (!accentUtils.isNull(elem.parent()) && elem.parent().attr("id") == btnID));

        if (!isBtn) {
            setShowDetail(false);
        }

    }, [showDetail, setShowDetail]);


    

    const onSelectionChanged = React.useCallback(e => {
        setSelection(e.reduce((a, v) => ({ ...a, [v]: v }), {}));


        if (props.onChange)
            props.onChange(e);

    }, [props, selection, setSelection]);


    const onRemove = React.useCallback(e => {

        const newSelection = lineData.filter(l => l.ProductID != e && selection[l.ID]).map(l => l.ID);

        onSelectionChanged(newSelection);
        
    }, [selection, setSelection, lineData, setLineData]);


    


    React.useEffect(e => {

        if (accentUtils.isNull(lineData)) {
            props.lineQuery().then(r => {

                setLineData(r);
                if (props.onDataLoaded) {
                    var newValue = props.onDataLoaded(r);

                    if (!accentUtils.isNull(newValue)) {
                        onSelectionChanged(newValue); 
                    }

                }
            });
        }

    },[]);


    if (accentUtils.isNull(lineData)) return <AccentSpinner />;


    const data = lineData.map(l => {
        return {
            ...l, selected: !accentUtils.isNull(selection[l.ID])
        };
    });


    const productQty = from(data)
        .groupBy(l => l.ProductID)
        .select(g => { return { productID: g.key(), qty: g.select(i => i.Qty).sum() } })
        .toDictionary(g => g.productID);

    const list = from(data)
        .where(l=> l.selected)
        .groupBy("{ProductID: $.ProductID, ProductName: $.ProductName}", "$", "{ProductID: $.ProductID, ProductName:$.ProductName, Lines:$$.getSource()}", "$.ProductID")
        .select(p => {


            var pQty = from(p.Lines).select(l => l.Qty).sum();

            const invalidMsg = accentUtils.isNull(props.onValidate) ? undefined : props.onValidate(p);

            const ttID = `p${p.ProductID}`;

            const tt = invalidMsg ? <AccentTooltip text={invalidMsg} placement="bottom" target={ttID} /> : null;

            const totalProductQty = productQty.get(p.ProductID).qty;

            return <li key={ttID }>
                {tt}
                <span id={ttID} className={`acc-sel-tag ${invalidMsg ? "accentInvalidCtrl" : ""}`}>
                    {`${p.ProductName} (${pQty}/${totalProductQty})`}
                    {!props.readOnly && <MultiSelectClearBtn productID={p.ProductID} onClick={onRemove} />}
                </span>
            </li>;
        });


    const hasLabel = !accentUtils.isEmpty(props.label);

    const dropWidth = anchor.current ? `${anchor.current.offsetWidth}px` : undefined;

    const className = !accentUtils.isNull(props.className) ? props.className : "";


    return <div ref={anchor} className={`d-flex acc-multi-sel-ctrl ${className}`} id={targetID}>
        <div className="flex-fill">
            <ul style={{ display: "inline-block", width: "calc(100% - 30px)", padding: "0" }}>{list}</ul>
        </div>
        <div>
            <MultiSelectDropBtn id={btnID} onClick={onShow} readOnly={props.readOnly} />
        </div>
        {hasLabel && <label>{props.label}</label>}
        <AccentPopup
            anchor={anchor}
            show={showDetail}
            bodyStyle={{ maxWidth: dropWidth }}
            actionsContent={[<div key="1" className="me-auto" />, <AccentIcons.Close key="2" /> ]}
        >
            <OutsideAlerter onAlert={onOutsideClicked}>
                <MultiSelectTree items={data} onChange={onSelectionChanged} minHeight="180" />
            </OutsideAlerter>
        </AccentPopup>
        
        
    </div>;

};


export const MultiSelectTree = props => {


    const [expandedItems, setExpandedItems] = React.useState({});

    const onExpandChanged = React.useCallback(e => {

        const expanded = !e.dataItem.expanded;

        const itms = { ...expandedItems };
        itms[e.dataItem.ID] = expanded;
        setExpandedItems(itms);

    }, [expandedItems, setExpandedItems]);


    const onCheckChanged = React.useCallback(e => {

        var selectedIDs = props.items.filter(i => i.selected && ((!e.dataItem.isProduct && e.dataItem.ID != i.ID) || (e.dataItem.isProduct && e.dataItem.ID != i.ProductID))).map(i => i.ID);

        if (e.value) {
            if (e.dataItem.isProduct) {

                e.dataItem.children.map(c => {
                    selectedIDs.push(c.ID);
                });

            } else {
                selectedIDs.push(e.dataItem.ID);
            }
        }

        props.onChange(selectedIDs);
        

    }, [props]);

    const columns = [
        {
            field: "selected",
            title: " ",
            expandable: true,
            editCell: TreeListBooleanEditor,
            locked: true
        },
        {
            field: "Item",
            title: "Item",
            width: 250,
            expandable: true,
            
        },
        {
            field: "Qty",
            title: "Qty",
            width: 70,
            expandable: false,
        },
        {
            field: "Stage",
            title: "Stage",
            width: 250,
            expandable: false,
        },
        {
            field: "DispatchStage",
            title: "Dispatch",
            width: 250,
            expandable: false,
        },
        {
            field: "DisplayOption1",
            title: "Option 1",
            width: 250,
            expandable: false,
        },
        {
            field: "DisplayOption2",
            title: "Option 2",
            width: 250,
            expandable: false,
        },
        {
            field: "DisplayOption3",
            title: "Option 3",
            width: 250,
            expandable: false,
        },
        {
            field: "DisplayOption4",
            title: "Option 4",
            width: 250,
            expandable: false,
        },
        {
            field: "DisplayOption5",
            title: "Option 5",
            width: 250,
            expandable: false,
        },
        {
            field: "StockLocation",
            title: "Stock Location",
            width: 250,
            expandable: false,
        }       
    ];


    if (props.validationField) {
        columns.unshift({
            field: props.validationField,
            title: " ",
            expandable: true,
            editCell: p => <RTLTreeValidationColumn {...p}  />,
            locked: true
        });
    }

    const jobIDs = from(props.items).select(i => i.JobID).distinct().toArray();

    const data = from(props.items).groupBy(
        l => jobIDs.length > 1 ? `${l.JobID}_${l.ProductID}` : l.ProductID,
        v => {
            return {
                ...v,
                ParentKey: jobIDs.length > 1 ? `${v.JobID}_${v.ProductID}` : v.ProductID,
                Qty: v.Qty,
                ID: v.ID,
                Item: v.JobLineNo + ' - ' + v.LocationOther,
                DisplayOption1: v.DisplayOption1,
                DisplayOption2: v.DisplayOption2,
                DisplayOption3: v.DisplayOption3,
                DisplayOption4: v.DisplayOption4,
                DisplayOption5: v.DisplayOption5,
                StockLocation: v.StockLocation,
                editing: true,
                ProductName: v.ProductName,
                Stage: t(v.Stage),
                DispatchStage: t(v.DispatchStage),
                selected: v.selected,                
            };
        },
        (key, itms) => {
            var prod = {
                ID: key,
                Item: from(itms).select(i => jobIDs.length > 1 ? `${i.Reference} : ${i.ProductName}` : i.ProductName).firstOrDefault(),
                isProduct: true,
                editing: true,
                editing: true,
                selected: from(itms).all(i => i.selected),
                expanded: expandedItems[key],                
                Qty: from(itms).select(i => i.Qty).sum(), children: itms.toArray()
            };

            if (props.validationField && !prod.expanded  && from(itms).where(i => i.selected).selectMany(i => i[props.validationField]).any()) {
                prod[props.validationField] = [t("application_strings.wizards.scheduleActivity.validation.productHasErrors")];
            }

            return prod;
        }, r => r)
        .orderBy(r=>r.Item)
        .toArray();

    return <TreeList
        style={{
            maxHeight: "510px",
            minHeight: `${accentUtils.isNull(props.minHeight) ? 210 : props.minHeight}px`,
            overflow: "auto",
        }}
        editField="editing"
        expandField="expanded"
        subItemsField="children"
        onExpandChange={onExpandChanged}
        onItemChange={onCheckChanged}
        data={data}
        columns={columns}
        />;
};


export const MultiSelectClearBtn = props => {


    return <div style={{ display: "inline-block", cursor: "pointer", paddingLeft:"7px" }} onClick={e=> props.onClick(props.productID)}>
        <span >
            <AccentIcons.Clear top="4" />
        </span>
    </div>;
};

export const MultiSelectDropBtn = React.forwardRef((props, ref) => {


    const color = props.readOnly ? "#c9c8c8" : "#949494";
    const cursor = props.readOnly ? "default" : "pointer";

    return <div ref={ref} id={props.id} style={{ display: "inline-block", cursor: cursor,  color: color }} onClick={e => {
        if (!props.readOnly) props.onClick(props.productID);
    }}>
        <AccentIcons.More top="3" style={{padding: "4px 6px 4px 6px"} } />
    </div>;
});



export const RTLTreeValidationColumn = (props) => {
    const { dataItem, readOnly } = props;
    const field = props.field || "";


    const cellData = accentUtils.isNull(dataItem[field]) ? [] : dataItem[field];

    
    const tt = (cellData) ? cellData.join("<br/>") : "";


    return (
        <td>
            {cellData && cellData.length > 0 && <div style={{ color:"orangered"}}>
                <AccentIcons.Warning toolTipHtml={ tt } />
            </div>}
        </td>);
};