import * as React from 'react';
import AccentIcons from '../../icons/Icons';
import { accentUtils, newGuid } from '../../services/HelperService';
import { AccentComboUnbound } from '../unbound/AccentComboUnbound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { fromInt, toDecimal, useSizeChanged, setSizeChanged } from './ProductConfiguratorShared';


function getAllowedSizes(value) {
    return JSON.parse(value);
}

export const ConfigSizeAllowed = React.memo(props => {

    const ctx = React.useContext(ConfiguratorContext);
    const orderLine = ctx.config.OrderLine;

    const sizeChanged = useSizeChanged(accentUtils.getEmptyGuid(), `ConfigSizeAllowed_${props.width}`);

    const data = React.useRef(props.sizes.map(s => ({ ID: s, Value: s.toString() })));


    const onChange = React.useCallback(e => {

        if (props.width) {
            orderLine.Width = toDecimal(e ?? 0);
        } else {
            orderLine.Height = toDecimal(e ?? 0);
        }

        setSizeChanged(ctx);
        

    }, [ctx, orderLine, props]);


    const showMore = false;


    const value = props.width ? fromInt(orderLine.Width) : fromInt(orderLine.Height);

    

    return <div className="config-size-single d-flex">
        <div>
            <AccentComboUnbound
                key={sizeChanged.changeID}
                defaultValue={value}
                displayField="Value"
                valueField="ID"
                onChange={onChange}
                required
                disabled={props.readOnly}
                data={data.current}
                openOnFocus
                hideClear={props.small}
                popupClassName="config-popup"
            />
        </div>
        {showMore && <div>
            <AccentIcons.More />
        </div>}
    </div>;
});