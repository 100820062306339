import { DataServiceAdapter } from './interface-registry';
import { core, Callback, ErrorCallback } from './core';
import { assertParam, assertConfig } from './assert-param';
import { config } from './config';
import { BreezeEvent } from './event';
import { EntityAspect, Entity, ComplexObject, StructuralObject, PropertyChangedEventArgs } from './entity-aspect';
import { MetadataStore, EntityType, ComplexType, DataProperty, NavigationProperty, AutoGeneratedKeyType } from './entity-metadata';
import { EntityKey } from './entity-key';
import { EntityAction  } from './entity-action';
import { EntityState } from './entity-state';
import { DataService } from './data-service';
import { DataType } from './data-type';
import { ValidationError } from './validate';
import { ValidationOptions } from './validation-options';
import { QueryOptions, MergeStrategy, FetchStrategy } from './query-options';
import { SaveOptions } from './save-options';
import { KeyGenerator } from './key-generator';
import { EntityGroup } from './entity-group';
import { MappingContext } from './mapping-context';
import { EntityQuery } from './entity-query';
import { UnattachedChildrenMap } from './unattached-children-map';

export interface HttpResponse {
  config: any;
  data: any;
  error?: any;
  saveContext?: any;
  status: number;
  getHeaders(headerName: string): string;
}

export interface ImportResult {
  entities: Entity[];
  tempKeyMapping: ITempKeyMap;
}

// subclasses of Error

/** Base shape of any errors returned from the server. */
export interface ServerError extends Error {
  httpResponse: HttpResponse;
  status: number;
  message: string;
  statusText?: string;
  body?: any;
  url?: string;
}

/** Shape of a save error returned from the server. 
For use by breeze plugin authors only. The class is for use in building a [[IDataServiceAdapter]] implementation. 
@adapter (see [[IDataServiceAdapter]])    
@hidden @internal 
*/
export interface SaveErrorFromServer extends ServerError {
  entityErrors: EntityErrorFromServer[];
}

/** Shape of a save error when returned to the client. */
export interface SaveError extends ServerError {
  entityErrors: EntityError[];
}

// not subclasses of Error
/** 
For use by breeze plugin authors only. The class is for use in building a [[IDataServiceAdapter]] implementation. 
@adapter (see [[IDataServiceAdapter]])    
@hidden @internal 
*/
export interface EntityErrorFromServer {
  entityTypeName: string;
  keyValues: any[];

  errorName: string;
  errorMessage: string;
  propertyName: string;
  custom?: any;
}

/** Shape of an error on a specific entity.  Part of a [[ISaveError]] */
export interface EntityError {
  entity: Entity;
  errorName: string;
  errorMessage: string;
  propertyName: string;
  isServerError: boolean;
  custom?: any;
}

/** The shape of the Promise returned by an [[EntityManager.executeQuery]] call. */
export interface QueryResult {
  /** Top level entities returned */
  results: any[];
  /** Query that was executed */
  query: EntityQuery | string;
  /** EntityManager that executed the query */
  entityManager?: EntityManager;
  /** Total number of results available on the server */
  inlineCount?: number;
  /** All entities returned by the query.  Differs from results when an expand is used. */
  retrievedEntities?: Entity[];
    /** Raw response from the server */
  httpResponse?: HttpResponse;
}

export interface QuerySuccessCallback {
  (data: QueryResult): void;
}

export interface QueryErrorCallback {
  (error: { query: EntityQuery; httpResponse: HttpResponse; entityManager: EntityManager; message?: string; stack?: string }): void;
}

/** Key mapping information returned as part of an [[ISaveResult]]. */
export interface KeyMapping {
  entityTypeName: string;
  tempValue: any;
  realValue: any;
}

interface ITempKeyMap {
  [index: string]: EntityKey;
}

/** Configuration info to be passed to the [[EntityManager.importEntities]] method */
export interface ImportConfig {
  /** If true, merge Added entities (with temp keys) as well.  This can be dangerous. */
  mergeAdds?: boolean;
  mergeStrategy?: MergeStrategy;  
  metadataVersionFn?: (arg: { metadataVersion: any, metadataStoreName: any }) => void;
}

interface ImportConfigExt extends ImportConfig {
  tempKeyMap?: ITempKeyMap;
}

/** The shape of the Promise returned by an [[EntityManager.saveChanges]] call. */
export interface SaveResult {
  entities: Entity[];
  keyMappings: KeyMapping[];
  deletedKeys?: { entityTypeName: string, keyValues: any[]}[];
  httpResponse?: HttpResponse;
}

/** For use by breeze plugin authors only. The class is for use in building a [[IDataServiceAdapter]] implementation. 
@adapter (see [[IDataServiceAdapter]])    
@hidden 
*/
export interface SaveContext {
  entityManager: EntityManager;
  dataService: DataService;
  processSavedEntities: (saveResult: SaveResult) => Entity[];
  resourceName: string;
  adapter?: DataServiceAdapter;
  routePrefix?: string;
}

/** For use by breeze plugin authors only. The class is for use in building a [[IDataServiceAdapter]] implementation. 
@adapter (see [[IDataServiceAdapter]])    
@hidden 
*/
export interface SaveBundle {
  entities: Entity[];
  saveOptions: SaveOptions;
}

/** Configuration info to be passed to the [[EntityManager]] constructor */
export interface EntityManagerConfig {
  /** The service name associated with this EntityManager.  **/
  serviceName?: string;
  /** The DataService associated with this EntityManager. **/
  dataService?: DataService;
  /** The [[QueryOptions]] associated with this EntityManager.  **/
  queryOptions?: QueryOptions;
  /** The [[SaveOptions]] associated with this EntityManager. **/
  saveOptions?: SaveOptions;
  /** The [[ValidationOptions]] associated with this EntityManager.  **/
  validationOptions?: ValidationOptions;
  /** The [[KeyGenerator]] associated with this EntityManager. **/
  keyGenerator?: KeyGenerator;
  /** The [[KeyGenerator]] constructor associated with this EntityManager. **/
  keyGeneratorCtor?: { new (): KeyGenerator }; // TODO: review this
  /** The [[MetadataStore]] associated with this EntityManager. **/
  metadataStore?: MetadataStore;
}

/** The shape returned by callbacks registered with [[EntityManager.entityChanged]] event */
export interface EntityChangedEventArgs {
  entityAction: EntityAction;
  entity?: Entity;
  args?: PropertyChangedEventArgs;
}

export interface ValidationErrorsChangedEventArgs {
  entity: Entity; 
  added: ValidationError[]; 
  removed: ValidationError[];
}

export interface HasChangesChangedEventArgs {
  entityManager: EntityManager; 
  hasChanges: boolean;
}

/**
Instances of the EntityManager contain and manage collections of entities, either retrieved from a backend datastore or created on the client.
**/
export class EntityManager {
  /** @hidden @internal */
  _$typeName: string; // actually defined on prototype

  /** The service name associated with this EntityManager. __Read Only__ **/
  serviceName: string;
  /** The DataService associated with this EntityManager. __Read Only__ **/
  dataService: DataService;
  /** The [[QueryOptions]] associated with this EntityManager. __Read Only__ **/
  queryOptions: QueryOptions;
  /** The [[SaveOptions]] associated with this EntityManager. __Read Only__ **/
  saveOptions: SaveOptions;
  /** The [[ValidationOptions]] associated with this EntityManager. __Read Only__ **/
  validationOptions: ValidationOptions;
  /** The [[KeyGenerator]] associated with this EntityManager. __Read Only__ **/
  keyGenerator: KeyGenerator;
  /** The [[KeyGenerator]] constructor associated with this EntityManager. __Read Only__ **/
  keyGeneratorCtor: { new (): KeyGenerator }; // TODO: review this
  /** The [[MetadataStore]] associated with this EntityManager. __Read Only__ **/
  metadataStore: MetadataStore;
  isLoading: boolean;
  isRejectingChanges: boolean;

  // events
  /**
  A [[BreezeEvent]] that fires whenever a change to any entity in this EntityManager occurs. __Read Only__

  @eventArgs - 
  - entityAction - The [[EntityAction]] that occured.
  - entity - The entity that changed.  If this is null, then all entities in the entityManager were affected.
  - args - Additional information about this event. This will differ based on the entityAction.

  >      let em = new EntityManager( {serviceName: "breeze/NorthwindIBModel" });
  >      em.entityChanged.subscribe(function(changeArgs) {
  >          // This code will be executed any time any entity within the entityManager 
  >          // is added, modified, deleted or detached for any reason.
  >          let action = changeArgs.entityAction;
  >          let entity = changeArgs.entity;
  >          // .. do something to this entity when it is changed.
  >      });
  >  });
  @event
  **/
  entityChanged: BreezeEvent<EntityChangedEventArgs>;

  /**
  An [[BreezeEvent]] that fires whenever validationErrors change for any entity in this EntityManager. __Read Only__
  @eventArgs -
    - entity - The entity on which the validation errors have been added or removed.
    - added - An array containing any newly added [[ValidationError]]s
    - removed - An array containing any newly removed [[ValidationError]]s. This is those errors that have been 'fixed'  

  >      let em = new EntityManager( {serviceName: "breeze/NorthwindIBModel" });
  >      em.validationErrorsChanged.subscribe(function(changeArgs) {
  >              // This code will be executed any time any entity within the entityManager experiences a change to its validationErrors collection.
  >              function (validationChangeArgs) {
  >                  let entity == validationChangeArgs.entity;
  >                  let errorsAdded = validationChangeArgs.added;
  >                  let errorsCleared = validationChangeArgs.removed;
  >                  // ... do something interesting with the order.
  >              });
  >          });
  >      });
  @event
  **/
  validationErrorsChanged: BreezeEvent<ValidationErrorsChangedEventArgs>;

  /**
  A [[BreezeEvent]] that fires whenever an EntityManager transitions to or from having changes. __Read Only__
  @eventArgs -
    - entityManager - The EntityManager whose 'hasChanges' status has changed.
    - hasChanges - Whether or not this EntityManager has changes.

  >      let em = new EntityManager( {serviceName: "breeze/NorthwindIBModel" });
  >      em.hasChangesChanged.subscribe(function(args) {
  >              let hasChangesChanged = args.hasChanges;
  >              let entityManager = args.entityManager;
  >          });
  >      });
  @event 
  **/
  hasChangesChanged: BreezeEvent<HasChangesChangedEventArgs>;


  /** @hidden @internal */
  _pendingPubs?: any[]; // TODO: refine later
  /** @hidden @internal */
  _hasChangesAction?: (() => boolean); // TODO refine later
  /** @hidden @internal */
  _hasChanges: boolean;
  /** @hidden @internal */
  _entityGroupMap: { [index: string]: EntityGroup };
  /** @hidden @internal */
  _unattachedChildrenMap: UnattachedChildrenMap;
  /** @hidden @internal */
  _inKeyFixup: boolean;

  helper = {
    unwrapInstance: unwrapInstance,
    unwrapOriginalValues: unwrapOriginalValues,
    unwrapChangedValues: unwrapChangedValues
  };

  /**
  EntityManager constructor.

  At its most basic an EntityManager can be constructed with just a service name
  >     let entityManager = new EntityManager( "breeze/NorthwindIBModel");

  This is the same as calling it with the following configuration object
  >     let entityManager = new EntityManager( {serviceName: "breeze/NorthwindIBModel" });

  Usually however, configuration objects will contain more than just the 'serviceName';
  >     let metadataStore = new MetadataStore();
  >     let entityManager = new EntityManager( {
  >       serviceName: "breeze/NorthwindIBModel",
  >       metadataStore: metadataStore
  >     });

  or
  >     return new QueryOptions({
  >         mergeStrategy: obj,
  >         fetchStrategy: this.fetchStrategy
  >     });
  >     let queryOptions = new QueryOptions({
  >         mergeStrategy: MergeStrategy.OverwriteChanges,
  >         fetchStrategy: FetchStrategy.FromServer
  >     });
  >     let validationOptions = new ValidationOptions({
  >         validateOnAttach: true,
  >         validateOnSave: true,
  >         validateOnQuery: false
  >     });
  >     let entityManager = new EntityManager({
  >         serviceName: "breeze/NorthwindIBModel",
  >         queryOptions: queryOptions,
  >         validationOptions: validationOptions
  >     });
  @param emConfig - Configuration settings or a service name.  
  **/
  constructor(emConfig?: EntityManagerConfig | string) {

    if (arguments.length > 1) {
      throw new Error("The EntityManager ctor has a single optional argument that is either a 'serviceName' or a configuration object.");
    }
    let config: EntityManagerConfig;
    if (arguments.length === 0) {
      config = { serviceName: "" };
    } else if (typeof emConfig === 'string') {
      config = { serviceName: emConfig };
    } else {
      config = emConfig || {};
    }

    EntityManager._updateWithConfig(this, config, true);

    this.entityChanged = new BreezeEvent("entityChanged", this);
    this.validationErrorsChanged = new BreezeEvent("validationErrorsChanged", this);
    this.hasChangesChanged = new BreezeEvent("hasChangesChanged", this);

    this.clear();

  }

  /**
  General purpose property set method.  Any of the properties in the [[EntityManagerConfig]]
  may be set.
  >      // assume em1 is a previously created EntityManager
  >      // where we want to change some of its settings.
  >      em1.setProperties( {
  >          serviceName: "breeze/foo"
  >      });
  @param config - An object containing the selected properties and values to set.
  **/
  setProperties(config: EntityManagerConfig) {
    EntityManager._updateWithConfig(this, config, false);
  }

  /** @hidden @internal */
  static _updateWithConfig(em: EntityManager, config: EntityManagerConfig, isCtor: boolean) {
    let defaultQueryOptions = isCtor ? QueryOptions.defaultInstance : em.queryOptions;
    let defaultSaveOptions = isCtor ? SaveOptions.defaultInstance : em.saveOptions;
    let defaultValidationOptions = isCtor ? ValidationOptions.defaultInstance : em.validationOptions;

    let configParam = assertConfig(config)
      .whereParam("serviceName").isOptional().isString()
      .whereParam("dataService").isOptional().isInstanceOf(DataService)
      .whereParam("queryOptions").isInstanceOf(QueryOptions).isOptional().withDefault(defaultQueryOptions)
      .whereParam("saveOptions").isInstanceOf(SaveOptions).isOptional().withDefault(defaultSaveOptions)
      .whereParam("validationOptions").isInstanceOf(ValidationOptions).isOptional().withDefault(defaultValidationOptions)
      .whereParam("keyGeneratorCtor").isFunction().isOptional();
    if (isCtor) {
      configParam = configParam
        .whereParam("metadataStore").isInstanceOf(MetadataStore).isOptional().withDefault(new MetadataStore());
    }
    configParam.applyAll(em);

    // insure that entityManager's options versions are completely populated
    core.updateWithDefaults(em.queryOptions, defaultQueryOptions);
    core.updateWithDefaults(em.saveOptions, defaultSaveOptions);
    core.updateWithDefaults(em.validationOptions, defaultValidationOptions);

    if (config.serviceName) {
      em.dataService = new DataService({
        serviceName: em.serviceName
      });
    }
    em.serviceName = em.dataService && em.dataService.serviceName;

    em.keyGeneratorCtor = em.keyGeneratorCtor || KeyGenerator;
    if (isCtor || config.keyGeneratorCtor) {
      em.keyGenerator = new em.keyGeneratorCtor();
    }
  }

  createEntity(typeName: string, initialValues?: Object, entityState?: EntityState, mergeStrategy?: MergeStrategy): Entity;
  createEntity(entityType: EntityType, initialValues?: Object, entityState?: EntityState, mergeStrategy?: MergeStrategy): Entity;
  /**
  Creates a new entity of a specified type and optionally initializes it. By default the new entity is created with an EntityState of Added
  but you can also optionally specify an EntityState.  An EntityState of 'Detached' will insure that the entity is created but not yet added
  to the EntityManager. 
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      // create and add an entity;
  >      let emp1 = em1.createEntity("Employee");
  >      // create and add an initialized entity;
  >      let emp2 = em1.createEntity("Employee", { lastName: "Smith", firstName: "John" });
  >      // create and attach (not add) an initialized entity
  >      let emp3 = em1.createEntity("Employee", { id: 435, lastName: "Smith", firstName: "John" }, EntityState.Unchanged);
  >      // create but don't attach an entity;
  >      let emp4 = em1.createEntity("Employee", { id: 435, lastName: "Smith", firstName: "John" }, EntityState.Detached);
  @param typeName - The name of the EntityType for which an instance should be created.
  @param entityType - The EntityType of the type for which an instance should be created.
  @param initialValues - (default=null) Configuration object of the properties to set immediately after creation.
  @param entityState - (default = [[EntityState.Added]]) The EntityState of the entity after being created and added to this EntityManager.
  @param mergeStrategy - (default = [[MergeStrategy.Disallowed]]) - How to handle conflicts if an entity with the same key already exists within this EntityManager.
  @return {Entity} A new Entity of the specified type. 
  */
  createEntity(entityType: EntityType | string, initialValues: Object, entityState: EntityState, mergeStrategy: MergeStrategy) {
    assertParam(entityType, "entityType").isString().or().isInstanceOf(EntityType).check();
    assertParam(entityState, "entityState").isEnumOf(EntityState).isOptional().check();
    assertParam(mergeStrategy, "mergeStrategy").isEnumOf(MergeStrategy).isOptional().check();

    let et = (typeof entityType === "string") ? this.metadataStore._getStructuralType(entityType) as EntityType : entityType;
    entityState = entityState || EntityState.Added;
    let entity = {} as Entity;
    core.using(this, "isLoading", true, function () {
      entity = et.createEntity(initialValues);
    });
    if (entityState !== EntityState.Detached) {
      entity = this.attachEntity(entity, entityState, mergeStrategy);
    }
    return entity;
  }

  static importEntities(exportedString: string, config?: ImportConfig): EntityManager;
  static importEntities(exportedData: Object, config?: ImportConfig): EntityManager;
  /**
  Creates a new EntityManager and imports a previously exported result into it.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let bundle = em1.exportEntities();
  >      // can be stored via the web storage api
  >      window.localStorage.setItem("myEntityManager", bundle);
  >      // assume the code below occurs in a different session.
  >      let bundleFromStorage = window.localStorage.getItem("myEntityManager");
  >      // and imported
  >      let em2 = EntityManager.importEntities(bundleFromStorage);
  >      // em2 will now have a complete copy of what was in em1
  @param exportedString - The result of a previous 'exportEntities' call as a string
  @param exportedData - The result of a previous 'exportEntities' call as an Object.
  @param config - A configuration object.
  @param config.mergeStrategy - A  [[MergeStrategy]] to use when 
  merging into an existing EntityManager.
  @param config.metadataVersionFn - A function that takes two arguments (the current metadataVersion and the imported store's 'name')
  and may be used to perform version checking.
  @return A new EntityManager.  Note that the return value of this method call is different from that
  provided by the same named method on an EntityManager instance. Use that method if you need additional information
  regarding the imported entities.
  **/
  static importEntities(exported: string | Object, config?: ImportConfig) {
    let em = new EntityManager();
    em.importEntities(exported, config);
    return em;
  }

  // instance methods

  /**
  Calls [[EntityAspect.acceptChanges]] on every changed entity in this EntityManager.
  **/
  acceptChanges() {
    this.getChanges().map(function (entity) {
      return entity.entityAspect._checkOperation("acceptChanges");
    }).forEach(function (aspect) {
      aspect.acceptChanges();
    });
  }

  /**
  Exports selected entities, all entities of selected types, or an entire EntityManager cache.

  This method takes a snapshot of an EntityManager that can be stored offline or held in memory.
  Use the [[EntityManager.importEntities]] method to restore or merge the snapshot
  into another EntityManager at some later time.
  >      // let em1 be an EntityManager containing a number of existing entities.
  >     // export every entity in em1.
  >     let bundle = em1.exportEntities();
  >     // save to the browser's local storage
  >     window.localStorage.setItem("myEntityManager", bundle);
  >     // later retrieve the export
  >     let bundleFromStorage = window.localStorage.getItem("myEntityManager");
  >     // import the retrieved export bundle into another manager
  >     let em2 = em1.createEmptyCopy();
  >     em2.importEntities(bundleFromStorage);
  >     // em2 now has a complete, faithful copy of the entities that were in em1

  You can also control exactly which entities are exported.
  >     // get em1's unsaved changes (an array) and export them.
  >     let changes = em1.getChanges();
  >     let bundle = em1.exportEntities(changes);
  >     // merge these entities into em2 which may contains some of the same entities.
  >     // do NOT overwrite the entities in em2 if they themselves have unsaved changes.
  >     em2.importEntities(bundle, { mergeStrategy: MergeStrategy.PreserveChanges} );

  Metadata are included in an export by default. You may want to exclude the metadata
  especially if you're exporting just a few entities for local storage.
  >     let bundle = em1.exportEntities(arrayOfSelectedEntities, {includeMetadata: false});
  >     window.localStorage.setItem("goodStuff", bundle);

  You may still express this option as a boolean value although this older syntax is deprecated.
  >     // Exclude the metadata (deprecated syntax)
  >     let bundle = em1.exportEntities(arrayOfSelectedEntities, false);

  You can export all entities of one or more specified EntityTypes.
  >     // Export all Customer and Employee entities (and also exclude metadata)
  >     let bundle = em1.exportEntities(['Customer', 'Employee'], {includeMetadata: false});

  All of the above examples return an export bundle as a string which is the default format.
  You can export the bundle as JSON if you prefer by setting the `asString` option to false.
  >     // Export all Customer and Employee entities as JSON and exclude the metadata
  >     let bundle = em1.exportEntities(['Customer', 'Employee'],
  >                                     {asString: false, includeMetadata: false});
  >     // store JSON bundle somewhere ... perhaps indexDb ... and later import as we do here.
  >     em2.importEntities(bundle);
  @param entities - The entities to export or the EntityType(s) of the entities to export;
    all entities are exported if this parameter is omitted or null.
  @param exportConfig - Export configuration options or a boolean
    - asString - (boolean) - If true (default), return export bundle as a string.
    - includeMetadata - (boolean) - If true (default), include metadata in the export bundle.
  @return The export bundle either serialized as a string (default) or as a JSON object.
  The bundle contains the metadata (unless excluded) and the entity data grouped by type.
  The entity data include property values, change-state, and temporary key mappings (if any).

  The export bundle internals are deliberately undocumented.  This Breeze-internal representation of entity data is
  suitable for export, storage, and import. The schema and contents of the bundle may change in future versions of Breeze.
  Manipulate it at your own risk with appropriate caution.
  **/
  exportEntities(entities?: Entity[] | EntityType[] | string[], exportConfig?: { asString?: boolean, includeMetadata?: boolean } | boolean): string | Object {
    assertParam(entities, "entities").isArray().isEntity()
      .or().isNonEmptyArray().isInstanceOf(EntityType)
      .or().isNonEmptyArray().isString()
      .or().isOptional().check();

    // assertParam(exportConfig, "exportConfig").isObject()
    //   .or().isBoolean()
    //   .or().isOptional().check();

    if (exportConfig == null) {
      exportConfig = { includeMetadata: true, asString: true };
    } else if (typeof exportConfig === 'boolean') { // deprecated
      exportConfig = { includeMetadata: exportConfig, asString: true };
    }

    assertConfig(exportConfig)
      .whereParam("asString").isBoolean().isOptional().withDefault(true)
      .whereParam("includeMetadata").isBoolean().isOptional().withDefault(true)
      .applyAll(exportConfig);

    let exportBundle = exportEntityGroups(this, entities);
    let json = core.extend({}, exportBundle, ["tempKeys", "entityGroupMap"]);

    if (exportConfig.includeMetadata) {
      json = core.extend(json, this, ["dataService", "saveOptions", "queryOptions", "validationOptions"]);
      (json as any).metadataStore = this.metadataStore.exportMetadata();
    } else {
      (json as any).metadataVersion = MetadataStore.metadataVersion;
      (json as any).metadataStoreName = this.metadataStore.name;
    }

    let result = exportConfig.asString ? JSON.stringify(json, null, config.stringifyPad) : json;
    return result;
  }

  // TODO: type the return value { entities: entitiesToLink, tempKeyMapping: tempKeyMap }
  importEntities(exportedString: string, config?: ImportConfig): ImportResult;
  importEntities(exportedData: Object, config?: ImportConfig): ImportResult;
  /**
  Imports a previously exported result into this EntityManager.

  This method can be used to make a complete copy of any previously created entityManager, even if created
  in a previous session and stored in localStorage. The static version of this method performs a
  very similar process.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let bundle = em1.exportEntities();
  >     // bundle can be stored in window.localStorage or just held in memory.
  >     let em2 = new EntityManager({
  >         serviceName: em1.serviceName,
  >         metadataStore: em1.metadataStore
  >     });
  >     em2.importEntities(bundle);
  >     // em2 will now have a complete copy of what was in em1

  It can also be used to merge the contents of a previously created EntityManager with an
  existing EntityManager with control over how the two are merged.
  >     let bundle = em1.exportEntities();
  >     // assume em2 is another entityManager containing some of the same entities possibly with modifications.
  >     em2.importEntities(bundle, { mergeStrategy: MergeStrategy.PreserveChanges} );
  >     // em2 will now contain all of the entities from both em1 and em2.  Any em2 entities with previously
  >     // made modifications will not have been touched, but all other entities from em1 will have been imported.
  @param exportedString - The result of a previous 'export' call.
  @param importConfig - A configuration object.
  @param importConfig.mergeStrategy -  A [[MergeStrategy]] to use when
  merging into an existing EntityManager.
  @param importConfig.metadataVersionFn - A function that takes two arguments (the current metadataVersion and the imported store's 'name')
  and may be used to perform version checking.
  @return result 
    - result.entities {Array of Entities} The entities that were imported.
    - result.tempKeyMap {Object} Mapping from original EntityKey in the import bundle to its corresponding EntityKey in this EntityManager.
  **/
  importEntities(exported: string | Object, importConfig?: ImportConfig) {
    importConfig = importConfig || {};
    assertConfig(importConfig)
      .whereParam("mergeStrategy").isEnumOf(MergeStrategy).isOptional().withDefault(this.queryOptions.mergeStrategy)
      .whereParam("metadataVersionFn").isFunction().isOptional()
      .whereParam("mergeAdds").isBoolean().isOptional()
      .applyAll(importConfig);

    let json = (typeof exported === "string") ? JSON.parse(exported) : exported;
    if (json.metadataStore) {
      this.metadataStore.importMetadata(json.metadataStore);
      // the || clause is for backwards compat with an earlier serialization format.
      this.dataService = (json.dataService && DataService.fromJSON(json.dataService)) || new DataService({ serviceName: json.serviceName });

      this.saveOptions = new SaveOptions(json.saveOptions);
      this.queryOptions = QueryOptions.fromJSON(json.queryOptions);
      this.validationOptions = new ValidationOptions(json.validationOptions);
    } else {
      importConfig.metadataVersionFn && importConfig.metadataVersionFn({
        metadataVersion: json.metadataVersion,
        metadataStoreName: json.metadataStoreName
      });
    }

    let tempKeyMap: ITempKeyMap = {};
    json.tempKeys.forEach((k: any) => {
      let oldKey = EntityKey.fromJSON(k, this.metadataStore);
      // try to use oldKey if not already used in this keyGenerator.
      tempKeyMap[oldKey.toString()] = new EntityKey(oldKey.entityType, this.keyGenerator.generateTempKeyValue(oldKey.entityType, oldKey.values[0]));
    });

    let entitiesToLink: Entity[] = [];
    let impConfig = importConfig as ImportConfigExt;

    impConfig.tempKeyMap = tempKeyMap;
    core.wrapExecution(() => {
      this._pendingPubs = [];
    }, (state) => {
      this._pendingPubs!.forEach((fn) => fn());
      this._pendingPubs = undefined;
      this._hasChangesAction && this._hasChangesAction();
    }, () => {
      core.objectForEach(json.entityGroupMap, (entityTypeName, jsonGroup) => {
        let entityType = this.metadataStore._getStructuralType(entityTypeName, false) as EntityType;
        let targetEntityGroup = findOrCreateEntityGroup(this, entityType);
        let entities = importEntityGroup(targetEntityGroup, jsonGroup, impConfig);
        if (entities && entities.length) {
          entitiesToLink = entitiesToLink.concat(entities);
        }
      });
      entitiesToLink.forEach((entity) => {
        if (!entity.entityAspect.entityState.isDeleted()) {
          this._linkRelatedEntities(entity);
        }
      });
    });
    return {
      entities: entitiesToLink,
      tempKeyMapping: tempKeyMap
    };
  }

  /**
  Clears this EntityManager's cache but keeps all other settings. Note that this
  method is not as fast as creating a new EntityManager via 'new EntityManager'.
  This is because clear actually detaches all of the entities from the EntityManager.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     em1.clear();
  >     // em1 is will now contain no entities, but all other setting will be maintained.
  **/
  clear() {
    core.objectMap(this._entityGroupMap, function (key: string, entityGroup: EntityGroup) {
      return entityGroup._checkOperation('clear');
    }).forEach((entityGroup: EntityGroup) => {
      entityGroup._clear();
    });

    this._entityGroupMap = {};
    this._unattachedChildrenMap = new UnattachedChildrenMap();
    this.keyGenerator = new this.keyGeneratorCtor();
    this.entityChanged.publish({ entityAction: EntityAction.Clear });
    this._setHasChanges(false);
  }

  /**
  Creates an empty copy of this EntityManager but with the same DataService, MetadataStore, QueryOptions, SaveOptions, ValidationOptions, etc. 
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let em2 = em1.createEmptyCopy();
  >     // em2 is a new EntityManager with all of em1's settings
  >     // but no entities.
  @return A new EntityManager.
  **/
  createEmptyCopy() {
    let copy = new EntityManager(core.extend({}, this,
      ["dataService", "metadataStore", "queryOptions", "saveOptions", "validationOptions", "keyGeneratorCtor"]));
    return copy;
  }

  /**
  Attaches an entity to this EntityManager with an  [[EntityState]] of 'Added'.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let custType = em1.metadataStore.getEntityType("Customer");
  >     let cust1 = custType.createEntity();
  >     em1.addEntity(cust1);

  Note that this is the same as using 'attachEntity' with an [[EntityState]] of 'Added'.

  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let custType = em1.metadataStore.getEntityType("Customer");
  >     let cust1 = custType.createEntity();
  >     em1.attachEntity(cust1, EntityState.Added);
  @param entity - The entity to add.
  @return The added entity.
  **/
  addEntity(entity: Entity) {
    return this.attachEntity(entity, EntityState.Added);
  }

  /**
  Attaches an entity to this EntityManager with a specified [[EntityState]].
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     let custType = em1.metadataStore.getEntityType("Customer");
  >     let cust1 = custType.createEntity();
  >     em1.attachEntity(cust1, EntityState.Added);
  @param entity - The entity to add.
  @param entityState - (default=EntityState.Unchanged) The EntityState of the newly attached entity. If omitted this defaults to EntityState.Unchanged.
  @param mergeStrategy - (default = MergeStrategy.Disallowed) How the specified entity should be merged into the EntityManager if this EntityManager already contains an entity with the same key.
  @return The attached entity.
  **/
  attachEntity(entity: Entity, entityState?: EntityState, mergeStrategy?: MergeStrategy) {
    assertParam(entity, "entity").isRequired().check();
    this.metadataStore._checkEntityType(entity);
    let esSymbol = assertParam(entityState, "entityState").isEnumOf(EntityState).isOptional().check(EntityState.Unchanged) as EntityState;
    let msSymbol = assertParam(mergeStrategy, "mergeStrategy").isEnumOf(MergeStrategy).isOptional().check(MergeStrategy.Disallowed) as MergeStrategy;

    if (entity.entityType.metadataStore !== this.metadataStore) {
      throw new Error("Cannot attach this entity because the EntityType (" + entity.entityType.name +
        ") and MetadataStore associated with this entity does not match this EntityManager's MetadataStore.");
    }
    let aspect = entity.entityAspect;
    if (aspect) {
      // to avoid reattaching an entity in progress
      if (aspect._inProcessEntity) return aspect._inProcessEntity;
    } else {
      // this occur's when attaching an entity created via new instead of via createEntity.
      aspect = new EntityAspect(entity);
    }
    let manager = aspect.entityManager;
    if (manager) {
      if (manager === this) {
        return entity;
      } else {
        throw new Error("This entity already belongs to another EntityManager");
      }
    }

    let attachedEntity = {} as Entity;
    core.using(this, "isLoading", true, () => {
      if (esSymbol.isAdded()) {
        checkEntityKey(this, entity);
      }
      // attachedEntity === entity EXCEPT in the case of a merge.
      attachedEntity = this._attachEntityCore(entity, esSymbol, msSymbol);
      aspect._inProcessEntity = attachedEntity;
      try {
        // entity ( not attachedEntity) is deliberate here.
        attachRelatedEntities(this, entity, esSymbol, msSymbol);
      } finally {
        // insure that _inProcessEntity is cleared.
        aspect._inProcessEntity = undefined;
      }
    });
    if (this.validationOptions.validateOnAttach) {
      attachedEntity.entityAspect.validateEntity();
    }
    if (!esSymbol.isUnchanged()) {
      this._notifyStateChange(attachedEntity, true);
    }
    this.entityChanged.publish({ entityAction: EntityAction.Attach, entity: attachedEntity });

    return attachedEntity;
  }


  /**
  Detaches an entity from this EntityManager.
  >     // assume em1 is an EntityManager containing a number of existing entities.
  >     // assume cust1 is a customer Entity previously attached to em1
  >     em1.detachEntity(cust1);
  >     // em1 will now no longer contain cust1 and cust1 will have an
  >     // entityAspect.entityState of EntityState.Detached
  @param entity - The entity to detach.
  @return Whether the entity could be detached. This will return false if the entity is already detached or was never attached.
  **/
  detachEntity(entity: Entity) {
    assertParam(entity, "entity").isEntity().check();
    let aspect = entity.entityAspect;
    if (!aspect) {
      // no aspect means in couldn't appear in any group
      return false;
    }

    if (aspect.entityManager !== this) {
      throw new Error("This entity does not belong to this EntityManager.");
    }
    return aspect.setDetached();
  }

  /**
  Fetches the metadata associated with the EntityManager's current 'serviceName'.  This call
  occurs internally before the first query to any service if the metadata hasn't already been
  loaded. __Async__

  Usually you will not actually process the results of a fetchMetadata call directly, but will instead
  ask for the metadata from the EntityManager after the fetchMetadata call returns.
  >     let em1 = new EntityManager( "breeze/NorthwindIBModel");
  >     em1.fetchMetadata()
  >       .then(function() {
  >           let metadataStore = em1.metadataStore;
  >           // do something with the metadata
  >       }).catch(function(exception) {
  >           // handle exception here
  >       });
  
  @param callback - Function called on success.
  @param errorCallback - Function called on failure.
  @return {Promise}
    - schema {Object} The raw Schema object from metadata provider - Because this schema will differ depending on the metadata provider
        it is usually better to access metadata via the 'metadataStore' property of the EntityManager instead of using this 'raw' data.
  **/
  fetchMetadata(dataService?: DataService, callback?: Callback, errorCallback?: ErrorCallback) {
    if (typeof (dataService) === "function") {
      // legacy support for when dataService was not an arg. i.e. first arg was callback
      errorCallback = callback;
      callback = dataService;
      dataService = undefined;
    } else {
      assertParam(dataService, "dataService").isInstanceOf(DataService).isOptional().check();
      assertParam(callback, "callback").isFunction().isOptional().check();
      assertParam(errorCallback, "errorCallback").isFunction().isOptional().check();
    }

    let promise = this.metadataStore.fetchMetadata(dataService || this.dataService);
    return promiseWithCallbacks(promise, callback, errorCallback);
  }


  executeQuery(query: string, callback?: QuerySuccessCallback, errorCallback?: QueryErrorCallback): Promise<QueryResult>;
  executeQuery(query: EntityQuery, callback?: QuerySuccessCallback, errorCallback?: QueryErrorCallback): Promise<QueryResult>;
  /**
  Executes the specified query. __Async__ 
  
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders");
  >     em.executeQuery(query).then( function(data) {
  >         let orders = data.results;
  >         ... query results processed here
  >     }).catch( function(err) {
  >         ... query failure processed here
  >     });

  or with callbacks
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders");
  >     em.executeQuery(query,
  >         function(data) {
  >             let orders = data.results;
  >             ... query results processed here
  >         },
  >         function(err) {
  >             ... query failure processed here
  >         });

  Either way this method is the same as calling the The [[EntityQuery]] 'execute' method.
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders").using(em);
  >     query.execute().then( function(data) {
  >         let orders = data.results;
  >         ... query results processed here
  >     }).catch( function(err) {
  >         ... query failure processed here
  >     });
  @param query - The [[EntityQuery]] or OData query string to execute.
  @param callback - Function called on success.
  @param errorCallback - {Function} Function called on failure.
  @return Promise of 
    - results - An array of entities
    - retrievedEntities - A array of all of the entities returned by the query.  Differs from results (above) when .expand() is used.
    - query - The original [[EntityQuery]] or query string
    - entityManager -  The EntityManager.
    - httpResponse - The [[IHttpResponse]] returned from the server.
    - inlineCount -  Only available if 'inlineCount(true)' was applied to the query.  Returns the count of
    items that would have been returned by the query before applying any skip or take operators, but after any filter/where predicates
    would have been applied.
  **/
  executeQuery(query: EntityQuery | string, callback?: QuerySuccessCallback, errorCallback?: QueryErrorCallback) {
    assertParam(query, "query").isInstanceOf(EntityQuery).or().isString().check();
    assertParam(callback, "callback").isFunction().isOptional().check();
    assertParam(errorCallback, "errorCallback").isFunction().isOptional().check();
    let promise: Promise<any>;
    // 'resolve' methods create a new typed object with all of its properties fully resolved against a list of sources.
    // Thought about creating a 'normalized' query with these 'resolved' objects
    // but decided not to because the 'query' may not be an EntityQuery (it can be a string) and hence might not have a queryOptions or dataServices property on it.
    let queryOptions = QueryOptions.resolve([(query as any).queryOptions, this.queryOptions, QueryOptions.defaultInstance]);
    let dataService = DataService.resolve([(query as any).dataService!, this.dataService]);

    if ((!dataService.hasServerMetadata) || this.metadataStore.hasMetadataFor(dataService.serviceName)) {
      promise = executeQueryCore(this, query, queryOptions, dataService);
    } else {
      promise = this.fetchMetadata(dataService).then(() => {
        return executeQueryCore(this, query, queryOptions, dataService);
      });
    }

    return promiseWithCallbacks(promise, callback, errorCallback as ErrorCallback);
  }

  /**
  Executes the specified query against this EntityManager's local cache.

  Because this method is executed immediately there is no need for a promise or a callback
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders");
  >     let orders = em.executeQueryLocally(query);

  Note that this can also be accomplished using the 'executeQuery' method with
  a FetchStrategy of FromLocalCache and making use of the Promise or callback
  >     let em = new EntityManager(serviceName);
  >     let query = new EntityQuery("Orders").using(FetchStrategy.FromLocalCache);
  >     em.executeQuery(query).then( function(data) {
  >         let orders = data.results;
  >         ... query results processed here
  >     }).catch( function(err) {
  >         ... query failure processed here
  >     });
  @param query - The [[EntityQuery]] to execute.
  @return  {Array of Entity}  Array of entities from cache that satisfy the query
  **/
  executeQueryLocally(query: EntityQuery) {
    return executeQueryLocallyCore(this, query).results;
  }

  /**
  Saves either a list of specified entities or all changed entities within this EntityManager. If there are no changes to any of the entities
  specified then there will be no server side call made but a valid 'empty' saveResult will still be returned. __Async__

  Often we will be saving all of the entities within an EntityManager that are either added, modified or deleted
  and we will let the 'saveChanges' call determine which entities these are.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      // This could include added, modified and deleted entities.
  >      em.saveChanges().then(function(saveResult) {
  >          let savedEntities = saveResult.entities;
  >          let keyMappings = saveResult.keyMappings;
  >      }).catch(function (e) {
  >          // e is any exception that was thrown.
  >      });

  But we can also control exactly which entities to save and can specify specific SaveOptions

  >      // assume entitiesToSave is an array of entities to save.
  >      let saveOptions = new SaveOptions({ allowConcurrentSaves: true });
  >      em.saveChanges(entitiesToSave, saveOptions).then(function(saveResult) {
  >          let savedEntities = saveResult.entities;
  >          let keyMappings = saveResult.keyMappings;
  >      }).catch(function (e) {
  >          // e is any exception that was thrown.
  >      });

  Callback methods can also be used
  >      em.saveChanges(entitiesToSave, null,
  >          function(saveResult) {
  >              let savedEntities = saveResult.entities;
  >              let keyMappings = saveResult.keyMappings;
  >          }, function (e) {
  >              // e is any exception that was thrown.
  >          }
  >      );

  @param entities - The list of entities to save.
  Every entity in that list will be sent to the server, whether changed or unchanged,
  as long as it is attached to this EntityManager.
  If this parameter is omitted, null or empty (the usual case),
  every entity with pending changes in this EntityManager will be saved.
  @param saveOptions - [[SaveOptions]] for the save - will default to
  [[EntityManager.saveOptions]] if null.
  @param callback -  Function called on success.
  @param errorCallback - Function called on failure.
  @return {Promise} Promise
  **/
  saveChanges(entities?: Entity[] | null, saveOptions?: SaveOptions, callback?: Function, errorCallback?: Function) {
    assertParam(entities, "entities").isOptional().isArray().isEntity().check();
    assertParam(saveOptions, "saveOptions").isInstanceOf(SaveOptions).isOptional().check();
    assertParam(callback, "callback").isFunction().isOptional().check();
    assertParam(errorCallback, "errorCallback").isFunction().isOptional().check();

    saveOptions = saveOptions || this.saveOptions || SaveOptions.defaultInstance;

    let entitiesToSave = getEntitiesToSave(this, entities ? entities : undefined);

    if (entitiesToSave.length === 0) {
      let result = { entities: [], keyMappings: [] } as SaveResult;
      if (callback) callback(result);
      return Promise.resolve(result);
    }

    if (!saveOptions.allowConcurrentSaves) {
      let anyPendingSaves = entitiesToSave.some(function (entity) {
        return entity.entityAspect.isBeingSaved;
      });
      if (anyPendingSaves) {
        let err = new Error("Concurrent saves not allowed - SaveOptions.allowConcurrentSaves is false");
        if (errorCallback) errorCallback(err);
        return Promise.reject(err);
      }
    }

    clearServerErrors(entitiesToSave);

    let valError = this.saveChangesValidateOnClient(entitiesToSave);
    if (valError) {
      if (errorCallback) errorCallback(valError);
      return Promise.reject(valError);
    }

    let dataService = DataService.resolve([saveOptions.dataService, this.dataService]);
    let saveContext: SaveContext = {
      entityManager: this,
      dataService: dataService,
      processSavedEntities: processSavedEntities,
      resourceName: saveOptions.resourceName || this.saveOptions.resourceName || "SaveChanges"
    };

    // TODO: need to check that if we are doing a partial save that all entities whose temp keys
    // are referenced are also in the partial save group

    let saveBundle = { entities: entitiesToSave, saveOptions: saveOptions };


    try { // Guard against exception thrown in dataservice adapter before it goes async
      updateConcurrencyProperties(entitiesToSave);
      return dataService.adapterInstance!.saveChanges(saveContext, saveBundle)
        .then(saveSuccess).then((r) => r, saveFail);
    } catch (err) {
      // undo the marking by updateConcurrencyProperties
      markIsBeingSaved(entitiesToSave, false);
      if (errorCallback) errorCallback(err);
      return Promise.reject(err);
    }

    function saveSuccess(saveResult: SaveResult) {
      let em = saveContext.entityManager;
      markIsBeingSaved(entitiesToSave, false);
      let savedEntities = saveContext.processSavedEntities(saveResult);
      saveResult.entities = savedEntities;

      // update _hasChanges after save.
      em._setHasChanges();

      // can't do this anymore because other changes might have been made while saved entities in flight.
      //      let hasChanges = (isFullSave && haveSameContents(entitiesToSave, savedEntities)) ? false : null;
      //      em._setHasChanges(hasChanges);

      if (callback) callback(saveResult);
      return Promise.resolve(saveResult);
    }

    function processSavedEntities(saveResult: SaveResult) {
      let savedEntities = saveResult.entities;
      let deletedKeys = saveResult.deletedKeys || [];
      if (savedEntities.length === 0 && deletedKeys.length === 0) {
        return [];
      }
      let keyMappings = saveResult.keyMappings;
      let em = saveContext.entityManager;

      // must occur outside of isLoading block
      fixupKeys(em, keyMappings);

      core.using(em, "isLoading", true, () => {

        let mappingContext = new MappingContext({
          query: undefined, // tells visitAndMerge this is a save instead of a query
          entityManager: em,
          mergeOptions: { mergeStrategy: MergeStrategy.OverwriteChanges },
          dataService: dataService
        });

        // The visitAndMerge operation has been optimized so that we do not actually perform a merge if the
        // the save operation did not actually return the entity - i.e. during OData and Mongo updates and deletes.
        savedEntities = mappingContext.visitAndMerge(savedEntities, { nodeType: "root" });
      });

      // detach any entities found in the em that appear in the deletedKeys list. 
      deletedKeys.forEach(key => {
        let entityType = em.metadataStore._getStructuralType(key.entityTypeName) as EntityType;
        let ekey = new EntityKey(entityType, key.keyValues);
        let entity = em.getEntityByKey(ekey);
        if (entity) {
          entity.entityAspect.setDetached();
        }
      });

      return savedEntities;
    }

    function saveFail(serverError: SaveErrorFromServer) {
      markIsBeingSaved(entitiesToSave, false);
      let clientError = processServerErrors(saveContext, serverError);
      if (errorCallback) errorCallback(clientError);
      return Promise.reject(clientError);
    }
  }

  /**
  Run the "saveChanges" pre-save client validation logic.
  
  This is NOT a general purpose validation method.
  It is intended for utilities that must know if saveChanges
  would reject the save due to client validation errors.
  
  It only validates entities if the EntityManager's
  [[ValidationOptions]].validateOnSave is true.
  
  @param entitiesToSave {Array of Entity} The list of entities to save (to validate).
  @return {Error} Validation error or null if no error
  **/
  saveChangesValidateOnClient(entitiesToSave: Entity[]) {

    if (this.validationOptions.validateOnSave) {
      let failedEntities = entitiesToSave.filter(function (entity) {
        let aspect = entity.entityAspect;
        let isValid = aspect.entityState.isDeleted() || aspect.validateEntity();
        return !isValid;
      });
      if (failedEntities.length > 0) {
        let valError = new Error("Client side validation errors encountered - see the entityErrors collection on this object for more detail");
        (valError as any).entityErrors = createEntityErrors(failedEntities);
        return valError; // TODO: type this.
      }
    }
    return null;
  }

  /** @hidden @internal */
  _findEntityGroup(entityType: EntityType) {
    return this._entityGroupMap[entityType.name];
  }

  /**
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let employeeType = em1.metadataStore.getEntityType("Employee");
  >      let employeeKey = new EntityKey(employeeType, 1);
  >      let employee = em1.getEntityByKey(employeeKey);
  >      // employee will either be an entity or null.
  **/
  getEntityByKey(entityKey: EntityKey): Entity | null;

  /**  
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let employee = em1.getEntityByKey("Employee", 1);
  >      // employee will either be an entity or null.
  **/
  getEntityByKey(typeName: string, keyValues: any | any[]): Entity | null;

  /**  
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let employeeType = em1.metadataStore.getEntityType("Employee");
  >      let employee = em1.getEntityByKey(employeeType, 1);
  >      // employee will either be an entity or null.
  **/
  getEntityByKey(type: EntityType, keyValues: any | any[]): Entity | null;

  /**  
  Attempts to locate an entity within this EntityManager by its [EntityKey].
  @param entityKey - The [[EntityKey]] of the Entity to be located.
  @param type - The [[EntityType]] for this key.
  @param typeName - The EntityType name for this key.
  @param keyValues - The values for this key - will usually just be a single value; an array is only needed for multipart keys.  
  @return An Entity or null;
  **/
  getEntityByKey(...args: any[]) {
    let entityKey = createEntityKey(this, args).entityKey;
    let entityTypes = entityKey._subtypes || [entityKey.entityType];
    let e: Entity | undefined;
    // hack use of some to simulate mapFirst logic.
    entityTypes.some((et) => {
      let group = this._findEntityGroup(et);
      // group version of findEntityByKey doesn't care about entityType
      e = group && group.findEntityByKey(entityKey);
      return e != null;
    });
    return e || null;
  }

  fetchEntityByKey(typeName: string, keyValues: any | any[], checkLocalCacheFirst?: boolean): Promise<IEntityByKeyResult>;
  fetchEntityByKey(entityType: EntityType, keyValues: any | any[], checkLocalCacheFirst?: boolean): Promise<IEntityByKeyResult>;
  fetchEntityByKey(entityKey: EntityKey, checkLocalCacheFirst?: boolean): Promise<IEntityByKeyResult>;
  /**
  Attempts to fetch an entity from the server by its [[EntityKey]] with
  an option to check the local cache first. Note the this EntityManager's queryOptions.mergeStrategy
  will be used to merge any server side entity returned by this method.
  >     // assume em1 is an EntityManager containing a number of preexisting entities.
  >     let employeeType = em1.metadataStore.getEntityType("Employee");
  >     let employeeKey = new EntityKey(employeeType, 1);
  >     em1.fetchEntityByKey(employeeKey).then(function(result) {
  >       let employee = result.entity;
  >       let entityKey = result.entityKey;
  >       let fromCache = result.fromCache;
  >     });
  @param typeName  - The EntityType name for this key.
  @param entityType  - The EntityType for this key.
  @param keyValues - The values for this key - will usually just be a single value; an array is only needed for multipart keys.
  @param entityKey - The [[EntityKey]] of the Entity to be located.
  @param checkLocalCacheFirst - (default = false) - Whether to check this EntityManager first before going to the server. By default, the query will NOT do this.
  @return {Promise}
    - Properties on the promise success result
      - entity {Object} The entity returned or null
      - entityKey {EntityKey} The entityKey of the entity to fetch.
      - fromCache {Boolean} Whether this entity was fetched from the server or was found in the local cache.
  **/
  fetchEntityByKey(...args: any[]) {
    let dataService = DataService.resolve([this.dataService]);
    if ((!dataService.hasServerMetadata) || this.metadataStore.hasMetadataFor(dataService.serviceName)) {
      return fetchEntityByKeyCore(this, args);
    } else {
      return this.fetchMetadata(dataService).then(() => {
        return fetchEntityByKeyCore(this, args);
      });
    }
  }

  /**
  [Deprecated] - Attempts to locate an entity within this EntityManager by its  [[EntityKey]].
  >     // assume em1 is an EntityManager containing a number of preexisting entities.
  >     let employeeType = em1.metadataStore.getEntityType("Employee");
  >     let employeeKey = new EntityKey(employeeType, 1);
  >     let employee = em1.findEntityByKey(employeeKey);
  >     // employee will either be an entity or null.
  @deprecated    Use getEntityByKey instead
  @param entityKey - The  [[EntityKey]] of the Entity to be located.
  @return An Entity or null;
  **/
  findEntityByKey(entityKey: EntityKey) {
    return this.getEntityByKey(entityKey);
  }

  /**
  Generates a temporary key for the specified entity.  This is used to insure that newly
  created entities have unique keys and to register that these keys are temporary and
  need to be automatically replaced with 'real' key values once these entities are saved.
  
  The [[EntityManager.keyGeneratorCtor]] property is used internally by this method to actually generate
  the keys - See the  KeyGenerator interface interface description to see
  how a custom key generator can be plugged in.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let customer = custType.createEntity();
  >      let customerId = em.generateTempKeyValue(customer);
  >      // The 'customer' entity 'CustomerID' property is now set to a newly generated unique id value
  >      // This property will change again after a successful save of the 'customer' entity.
  >  
  >      em1.saveChanges().then( function( data) {
  >          let sameCust1 = data.results[0];
  >          // cust1 === sameCust1;
  >          // but cust1.getProperty("CustomerId") != customerId
  >          // because the server will have generated a new id
  >          // and the client will have been updated with this
  >          // new id.
  >      })
  @param entity - The Entity to generate a key for.
  @return The new key value
  **/
  generateTempKeyValue(entity: Entity) {
    // TODO - check if this entity is attached to this EntityManager.
    assertParam(entity, "entity").isEntity().check();
    let entityType = entity.entityType;
    let nextKeyValue = this.keyGenerator.generateTempKeyValue(entityType);
    let keyProp = entityType.keyProperties[0];
    entity.setProperty(keyProp.name, nextKeyValue);
    entity.entityAspect.hasTempKey = true;
    return nextKeyValue;
  }

  hasChanges(): boolean;
  hasChanges(entityTypeNames: string | string[]): boolean;
  hasChanges(entityTypes: EntityType | EntityType[]): boolean;
  /**
  Returns whether there are any changed entities of the specified [[EntityType]]s. A 'changed' Entity has
  has an [[EntityState]] of either Added, Modified or Deleted.

  This method can be used to determine if an EntityManager has any changes
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      if ( em1.hasChanges() {
  >          // do something interesting
  >      }

  or if it has any changes on to a specific [[EntityType]].
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      if ( em1.hasChanges(custType) {
  >          // do something interesting
  >      }

  or to a collection of [[EntityType]]s
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let orderType = em1.metadataStore.getEntityType("Order");
  >      if ( em1.hasChanges( [custType, orderType]) {
  >          // do something interesting
  >      }
  @param entityTypes - The [[EntityType]] or EntityTypes for which 'changed' entities will be found.
  @param entityTypeNames - The [[EntityType]] name or names for which 'changed' entities will be found.
  @return Whether there are any changed entities that match the types specified..
  **/
  hasChanges(entityTypes?: EntityType | EntityType[] | string | string[]) {
    if (!this._hasChanges) return false;
    if (entityTypes === undefined) return this._hasChanges;
    return this._hasChangesCore(entityTypes);
  }


  /** @hidden @internal */
  // backdoor to "really" check for changes.
  _hasChangesCore(entityTypes?: EntityType | EntityType[] | string | string[]) {
    let ets = checkEntityTypes(this, entityTypes);
    let entityGroups = getEntityGroups(this, ets);
    return entityGroups.some(function (eg) {
      return eg && eg.hasChanges();
    });
  }

  getChanges(): Entity[];
  getChanges(entityTypeNames: string | string[]): Entity[];
  getChanges(entityTypes: EntityType | EntityType[]): Entity[];
  /**
  Returns a array of all changed entities of the specified [[EntityType]]s. A 'changed' Entity has
  has an [[EntityState]] of either Added, Modified or Deleted.
  
  This method can be used to get all of the changed entities within an EntityManager
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let changedEntities = em1.getChanges();

  or you can specify that you only want the changes on a specific [[EntityType]]
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let changedCustomers = em1.getChanges(custType);

  or to a collection of [[EntityType]]s
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let orderType = em1.metadataStore.getEntityType("Order");
  >      let changedCustomersAndOrders = em1.getChanges([custType, orderType]);
  @param entityTypes - The [[EntityType]] or EntityTypes for which 'changed' entities will be found.
  @param entityTypeNames - The [[EntityType]] name or names for which 'changed' entities will be found.
  @return An array of Entities
  **/
  getChanges(entityTypes?: EntityType | EntityType[] | string | string[]) {
    let ets = checkEntityTypes(this, entityTypes);
    return getChangesCore(this, ets);
  }

  /**
  Rejects (reverses the effects) all of the additions, modifications and deletes from this EntityManager.
  Calls [[EntityAspect.rejectChanges]] on every changed entity in this EntityManager.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let entities = em1.rejectChanges();
  @return The entities whose changes were rejected. These entities will all have EntityStates of
  either 'Unchanged' or 'Detached'
  **/
  rejectChanges() {
    if (!this._hasChanges) return [];
    let changes = getChangesCore(this);
    // next line stops individual reject changes from each calling _hasChangesCore
    let aspects = changes.map(function (e) {
      return e.entityAspect._checkOperation("rejectChanges");
    });
    this._hasChanges = false;
    aspects.forEach(function (aspect) {
      aspect.rejectChanges();
    });
    this.hasChangesChanged.publish({ entityManager: this, hasChanges: false });
    return changes;
  }

  getEntities(entityTypeNames?: string | string[], entityStates?: EntityState | EntityState[]): Entity[];
  getEntities(entityTypes?: EntityType | EntityType[], entityStates?: EntityState | EntityState[]): Entity[];
  /**
  Returns a array of all entities of the specified [[EntityType]]s with the specified [[EntityState]]s.

  This method can be used to get all of the entities within an EntityManager
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let entities = em1.getEntities();

  or you can specify that you only want the changes on a specific [[EntityType]]
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let customers = em1.getEntities(custType);

  or to a collection of [[EntityType]]s
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let orderType = em1.metadataStore.getEntityType("Order");
  >      let customersAndOrders = em1.getChanges([custType, orderType]);

  You can also ask for entities with a particular [[EntityState]] or EntityStates.
  >      // assume em1 is an EntityManager containing a number of preexisting entities.
  >      let custType = em1.metadataStore.getEntityType("Customer");
  >      let orderType = em1.metadataStore.getEntityType("Order");
  >      let addedCustomersAndOrders = em1.getEntities([custType, orderType], EntityState.Added);
 
  @param entityTypeName - The [[EntityType]] name or names for which entities will be found.
  If this parameter is omitted, all EntityTypes are searched.  
  @param entityTypes - The [[EntityType]] or EntityTypes for which entities will be found.
  If this parameter is omitted, all EntityTypes are searched. 
  @param entityStates - The [[EntityState]]s for which entities will be found.
  If this parameter is omitted, entities of all EntityStates are returned.
  @return An array of Entities
  **/
  getEntities(entityTypes?: EntityType | EntityType[] | string | string[], entityStates?: EntityState | EntityState[]) {
    let entTypes = checkEntityTypes(this, entityTypes);
    assertParam(entityStates, "entityStates").isOptional().isEnumOf(EntityState).or().isNonEmptyArray().isEnumOf(EntityState).check();

    let states = validateEntityStates(this, entityStates);
    return getEntitiesCore(this, entTypes, states);
  }


  // protected methods
  /** @hidden @internal */
  _notifyStateChange(entity: Entity, needsSave: boolean) {
    let ecArgs = { entityAction: EntityAction.EntityStateChange, entity: entity };

    if (needsSave) {
      if (!this._hasChanges) this._setHasChanges(true);
    } else {
      // called when rejecting a change or merging an unchanged record.
      // NOTE: this can be slow with lots of entities in the cache.
      // so defer it during a query/import or save and call it once when complete ( if needed).
      if (this._hasChanges) {
        if (this.isLoading) {
          this._hasChangesAction = this._hasChangesAction || function () {
            this._setHasChanges(null);
            this.entityChanged.publish(ecArgs);
          }.bind(this);
          return;
        } else {
          this._setHasChanges();
        }
      }
    }
    this.entityChanged.publish(ecArgs);
  }

  /** @hidden @internal */
  _setHasChanges(hasChanges?: boolean) {
    if (hasChanges == null) hasChanges = this._hasChangesCore();
    let hadChanges = this._hasChanges;
    this._hasChanges = hasChanges;
    if (hasChanges !== hadChanges) {
      this.hasChangesChanged.publish({ entityManager: this, hasChanges: hasChanges });
    }
    this._hasChangesAction = undefined;
  }

  /** @hidden @internal */
  _linkRelatedEntities(entity: Entity) {
    let em = this;
    let entityAspect = entity.entityAspect;
    // we do not want entityState to change as a result of linkage.
    core.using(em, "isLoading", true, function () {

      let unattachedMap = em._unattachedChildrenMap;
      let entityKey = entityAspect.getKey();
      let entityType = entityKey.entityType;

      while (entityType) {
        let keystring = entityKey.toString(entityType);

        // attach any unattachedChildren
        let tuples = unattachedMap.getTuplesByString(keystring);
        if (tuples) {
          tuples.slice(0).forEach(function (tpl) {

            let unattachedChildren = tpl.children.filter(function (e) {
              return e.entityAspect.entityState !== EntityState.Detached;
            });

            let childToParentNp: NavigationProperty;
            let parentToChildNp: NavigationProperty;

            // np is usually childToParentNp
            // except with unidirectional 1-n where it is parentToChildNp;
            let np = tpl.navigationProperty;

            let inverseNp = np.inverse;
            if (inverseNp) {
              // bidirectional
              childToParentNp = np;
              parentToChildNp = inverseNp;

              if (parentToChildNp.isScalar) {
                let onlyChild = unattachedChildren[0];
                entity.setProperty(parentToChildNp.name, onlyChild);
                onlyChild.setProperty(childToParentNp.name, entity);
              } else {
                let currentChildren = entity.getProperty(parentToChildNp.name);
                unattachedChildren.forEach(function (child) {
                  currentChildren.push(child);
                  child.setProperty(childToParentNp.name, entity);
                });
              }
              unattachedMap.removeChildren(keystring, childToParentNp);
            } else {
              // unidirectional
              // if (np.isScalar || np.parentType !== entity.entityType) {
              if (np.isScalar) {
                // n -> 1  eg: child: OrderDetail parent: Product
                // 1 -> 1 eg child: Employee parent: Employee ( only Manager, no DirectReports property)
                childToParentNp = np;
                unattachedChildren.forEach(function (child) {
                  child.setProperty(childToParentNp.name, entity);
                });
                unattachedMap.removeChildren(keystring, childToParentNp);
              } else {
                // 1 -> n  eg: parent: Region child: Terr
                // TODO: need to remove unattached children from the map after this; only a perf issue.
                parentToChildNp = np;
                let currentChildren = entity.getProperty(parentToChildNp.name);
                unattachedChildren.forEach(function (child) {
                  // we know if can't already be there.
                  currentChildren._push(child);
                });
              }
            }
          });
        }
        entityType = entityType.baseEntityType; // look for relationships up the hierarchy
      }


      // now add to unattachedMap if needed.
      entity.entityType.navigationProperties.forEach(function (np) {
        if (np.isScalar) {
          let value = entity.getProperty(np.name);
          // property is already linked up
          if (value) return;
        }

        // first determine if np contains a parent or child
        // having a parentKey means that this is a child
        // if a parent then no need for more work because children will attach to it.
        let parentKey = entityAspect.getParentKey(np);
        if (parentKey) {
          // check for empty keys - meaning that parent id's are not yet set.
          if (parentKey._isEmpty()) return;
          // if a child - look for parent in the em cache
          if (np.invForeignKeyNames.length) {
            // np relates to non-PK property of parent entity
            const query = new EntityQuery(parentKey.entityType.defaultResourceName).where(np.invForeignKeyNames[0], 'eq', parentKey.values[0]);
            const qresult = em.executeQueryLocally(query);
            if (qresult.length === 1) {
              let parent = qresult[0];
              entity.setProperty(np.name, parent);
            }
          } else {
            // np relates to PK of parent entity
            let parent = em.getEntityByKey(parentKey);
            if (parent) {
              // if found hook it up
              entity.setProperty(np.name, parent);
            } else {
              // else add parent to unresolvedParentMap;
              unattachedMap.addChild(parentKey, np, entity);
            }
          }
        } else if (np.inverse && np.inverse.invForeignKeyNames.length) {
          // np relates to non-PK property of parent entity; query entities by FK
          const akValue = entity.getProperty(np.inverse.invForeignKeyNames[0]);
          const query = new EntityQuery(np.entityType.defaultResourceName).where(np.invForeignKeyNames[0], 'eq', akValue);
          const qresult = em.executeQueryLocally(query);
          qresult.forEach((child: Entity) => {
            child.setProperty(np.inverse.name, entity);
          });
        }
      });

      // handle unidirectional 1-x where we set x.fk
      entity.entityType.foreignKeyProperties.forEach(function (fkProp) {
        let invNp = fkProp.inverseNavigationProperty;
        if (!invNp) return;
        // unidirectional fk props only
        let fkValue = entity.getProperty(fkProp.name);
        let parentKey = new EntityKey(invNp.parentType, [fkValue]);
        let parent = em.getEntityByKey(parentKey);

        if (parent) {
          if (invNp.isScalar) {
            parent.setProperty(invNp.name, entity);
          } else {
            if (em.isLoading) {
              parent.getProperty(invNp.name)._push(entity);
            } else {
              parent.getProperty(invNp.name).push(entity);
            }
          }
        } else {
          // else add parent to unresolvedParentMap;
          unattachedMap.addChild(parentKey, invNp, entity);
        }
      });
    });

  }

  /** @hidden @internal */
  _attachEntityCore(entity: Entity, entityState: EntityState, mergeStrategy: MergeStrategy) {
    let group = findOrCreateEntityGroup(this, entity.entityType);
    let attachedEntity = group.attachEntity(entity, entityState, mergeStrategy);
    this._linkRelatedEntities(attachedEntity);
    return attachedEntity;
  }

  /** @hidden @internal */
  _updateFkVal(fkProp: DataProperty, oldValue: any, newValue: any) {
    let group = this._entityGroupMap[fkProp.parentType.name];
    if (!group) return;
    group._updateFkVal(fkProp, oldValue, newValue);
  }
}

EntityManager.prototype._$typeName = "EntityManager";

BreezeEvent.bubbleEvent(EntityManager.prototype);

function clearServerErrors(entities: Entity[]) {
  entities.forEach(function (entity) {
    let serverKeys: string[] = [];
    let aspect = entity.entityAspect;
    core.objectForEach(aspect._validationErrors, function (key, ve) {
      if (ve.isServerError) serverKeys.push(key);
    });
    if (serverKeys.length === 0) return;
    aspect._processValidationOpAndPublish(function () {
      serverKeys.forEach(function (key) {
        aspect._removeValidationError(key);
      });
    });
  });
}

function createEntityErrors(entities: Entity[]) {
  let entityErrors: EntityError[] = [];
  entities.forEach((entity) => {
    core.objectForEach(entity.entityAspect._validationErrors, function (key, ve) {
      let cfg = core.extend({
        entity: entity,
        errorName: ve.validator.name
      }, ve, ["errorMessage", "propertyName", "isServerError", "custom"]) as EntityError;
      entityErrors.push(cfg);
    });
  });
  return entityErrors;
}


function processServerErrors(saveContext: SaveContext, saveError: SaveErrorFromServer) {
  // converting ISaveErrorFromServer -> ISaveError
  let serverErrors = saveError.entityErrors;
  if (!serverErrors) return <SaveError> <any> saveError;
  let entityManager = saveContext.entityManager;
  let metadataStore = entityManager.metadataStore;
  let entityErrors = serverErrors.map((serr) => {
    let entity: Entity | null = null;
    let entityType: EntityType | undefined;
    if (serr.keyValues) {
      entityType = metadataStore._getStructuralType(serr.entityTypeName) as EntityType;
      let ekey = new EntityKey(entityType, serr.keyValues);
      entity = entityManager.getEntityByKey(ekey);
    }

    if (entityType && entity) {
      let context = serr.propertyName ?
        {
          propertyName: serr.propertyName,
          property: entityType.getProperty(serr.propertyName)
        } : {
        };
      let key = ValidationError.getKey(serr.errorName || serr.errorMessage, serr.propertyName);

      let ve = new ValidationError(null, context, serr.errorMessage, key);
      ve.isServerError = true;
      entity.entityAspect.addValidationError(ve);
    }

    let entityError = core.extend({
      entity: entity,
      isServerError: true
    }, serr, ["errorName", "errorMessage", "propertyName", "custom"]) as EntityError;
    return entityError;
  });
  // converting ISaveErrorFromServer -> ISaveError 
  saveError.entityErrors = entityErrors as any;
  return <SaveError> <any> saveError;
}

export interface IEntityByKeyResult {
  entity?: Entity;
  entityKey: EntityKey;
  fromCache: boolean;
}

function fetchEntityByKeyCore(em: EntityManager, args: any[]): Promise<IEntityByKeyResult> {
  let tpl = createEntityKey(em, args);
  let entityKey = tpl.entityKey;

  let checkLocalCacheFirst = tpl.remainingArgs.length === 0 ? false : !!tpl.remainingArgs[0];
  let entity: Entity | null = null;
  let foundIt = false;
  if (checkLocalCacheFirst) {
    entity = em.getEntityByKey(entityKey);
    foundIt = entity != null;
    if (entity != null &&
      // null the entity if it is deleted and we should exclude deleted entities
      !em.queryOptions.includeDeleted && entity.entityAspect.entityState.isDeleted()) {
      entity = null;
      // but resume looking if we'd overwrite deleted entity with a remote entity
      // note: em.queryOptions is always fully resolved by now
      foundIt = em.queryOptions.mergeStrategy !== MergeStrategy.OverwriteChanges;
    }
  }
  if (foundIt) {
    return Promise.resolve({ entity: entity || undefined, entityKey: entityKey, fromCache: true });
  } else {
    return EntityQuery.fromEntityKey(entityKey).using(em).execute().then(function (data: any) {
      entity = (data.results.length === 0) ? null : data.results[0];
      return Promise.resolve({ entity: entity || undefined, entityKey: entityKey, fromCache: false });
    });
  }
}


// private fns

// takes in entityTypes as either strings or entityTypes or arrays of either
// and returns either an entityType or an array of entityTypes or throws an error
function checkEntityTypes(em: EntityManager, entityTypes?: EntityType | EntityType[] | string | string[]) {
  assertParam(entityTypes, "entityTypes").isString().isOptional().or().isNonEmptyArray().isString()
    .or().isInstanceOf(EntityType).or().isNonEmptyArray().isInstanceOf(EntityType).check();
  let resultTypes: EntityType | EntityType[] | undefined;
  if (typeof entityTypes === "string") {
    resultTypes = em.metadataStore._getStructuralType(entityTypes, false) as (EntityType | EntityType[]);
  } else if (Array.isArray(entityTypes) && typeof entityTypes[0] === "string") {
    resultTypes = (entityTypes as string[]).map(function (etName) {
      return em.metadataStore._getStructuralType(etName, false) as EntityType;
    });
  } else {
    resultTypes = entityTypes as (EntityType | EntityType[] | undefined);
  }

  return resultTypes;
}

function getChangesCore(em: EntityManager, entityTypes?: EntityType | EntityType[]) {
  let entityGroups = getEntityGroups(em, entityTypes);

  // TODO: think about writing a core.mapMany method if we see more of these.
  let selected: Entity[] = [];
  entityGroups.forEach(function (eg) {
    // eg may be undefined or null
    if (!eg) return;
    let entities = eg.getChanges();
    if (selected && selected.length) {
      selected = selected.concat(entities);
    } else {
      selected = entities;
    }
  });
  return selected;
}

function getEntitiesCore(em: EntityManager, entityTypes: EntityType | EntityType[] | undefined, entityStates: EntityState[]) {
  let entityGroups = getEntityGroups(em, entityTypes);

  // TODO: think about writing a core.mapMany method if we see more of these.
  let selected: Entity[] = [];
  entityGroups.forEach(function (eg) {
    // eg may be undefined or null
    if (!eg) return;
    let entities = eg.getEntities(entityStates);
    if (selected && selected.length) {
      selected = selected.concat(entities);
    } else {
      selected = entities;
    }
  });
  return selected;
}


function createEntityKey(em: EntityManager, args: any[]) {
  try {
    if (args[0] instanceof EntityKey) {
      return { entityKey: args[0] as EntityKey, remainingArgs: core.arraySlice(args, 1) };
    } else if (args.length >= 2) {
      let entityType = (typeof args[0] === 'string') ? em.metadataStore._getStructuralType(args[0], false) : args[0];
      return { entityKey: new EntityKey(entityType, args[1]), remainingArgs: core.arraySlice(args, 2) };
    }
  } catch (e) {/* throw below */
    // throw new Error("Must supply an EntityKey OR an EntityType name or EntityType followed by a key value or an array of key values.");
  }
  throw new Error("Must supply an EntityKey OR an EntityType name or EntityType followed by a key value or an array of key values.");
}

function markIsBeingSaved(entities: Entity[], flag: boolean) {
  entities.forEach(function (entity) {
    entity.entityAspect.isBeingSaved = flag;
  });
}

function exportEntityGroups(em: EntityManager, entitiesOrEntityTypes: Entity[] | EntityType[] | string[]) {
  let entityGroupMap: { [index: string]: EntityGroup };
  let first = entitiesOrEntityTypes && entitiesOrEntityTypes[0];
  // check if array
  if (first) {
    // group entities by entityType and
    // create 'groups' that look like entityGroups.
    entityGroupMap = {};
    if ((first as any).entityType) {
      let entities = entitiesOrEntityTypes as Entity[];
      // assume "entities" is an array of entities;
      entities.forEach(function (e) {
        if (e.entityAspect.entityState === EntityState.Detached) {
          throw new Error("Unable to export an entity with an EntityState of 'Detached'");
        }
        let group = entityGroupMap[e.entityType.name];
        if (!group) {
          group = {} as EntityGroup;
          group.entityType = e.entityType;
          group._entities = [];
          entityGroupMap[e.entityType.name] = group;
        }
        group._entities.push(e);
      });
    } else {
      // assume "entities" is an array of EntityTypes (or names)
      let entityTypes = checkEntityTypes(em, entitiesOrEntityTypes as EntityType[] | string[]) as EntityType[];
      if (entityTypes != null) {
        entityTypes.forEach((et) => {
          let group = em._entityGroupMap[et.name];
          if (group && group._entities.length) {
            entityGroupMap[et.name] = group;
          }
        });
      }
    }
  } else if (entitiesOrEntityTypes && entitiesOrEntityTypes.length === 0) {
    // empty array = export nothing
    entityGroupMap = {};
  } else {
    entityGroupMap = em._entityGroupMap;
  }

  let tempKeys: ITempKey[] = [];
  let newGroupMap = {};
  core.objectForEach(entityGroupMap, (entityTypeName, entityGroup) => {
    newGroupMap[entityTypeName] = exportEntityGroup(entityGroup, tempKeys);
  });

  return { entityGroupMap: newGroupMap, tempKeys: tempKeys };
}

function exportEntityGroup(entityGroup: EntityGroup, tempKeys: ITempKey[]) {
  let resultGroup = {} as { entities: any[] };
  let entityType = entityGroup.entityType;
  let dps = entityType.dataProperties;
  let serializerFn = getSerializerFn(entityType);
  let rawEntities: any[] = [];
  entityGroup._entities.forEach((entity) => {
    if (entity) {
      let rawEntity = structuralObjectToJson(entity, dps, serializerFn, tempKeys);
      rawEntities.push(rawEntity);
    }
  });
  resultGroup.entities = rawEntities;
  return resultGroup;
}

function structuralObjectToJson(so: StructuralObject, dps: DataProperty[], serializerFn?: (dp: DataProperty, value: any) => any, tempKeys?: ITempKey[]) {

  let result = {};
  dps.forEach(function (dp) {
    let dpName = dp.name;
    let value = so.getProperty(dpName);
    if (value == null && dp.defaultValue == null) return;

    if (value && dp.isComplexProperty) {
      let coDps = (dp.dataType as ComplexType).dataProperties;
      value = core.map(value, function (v: ComplexObject) {
        return structuralObjectToJson(v, coDps, serializerFn);
      });
    } else {
      value = serializerFn ? serializerFn(dp, value) : value;
      if (dp.isUnmapped) {
        value = core.toJSONSafe(value, core.toJSONSafeReplacer);
      }
    }
    if (value === undefined) return;
    result[dpName] = value;
  });

  // if (so.entityAspect) {
  if (EntityAspect.isEntity(so)) {
    let aspect = so.entityAspect;
    let entityState = aspect.entityState;
    let newAspect = {
      tempNavPropNames: exportTempKeyInfo(aspect, tempKeys || []),
      entityState: entityState.name
    } as any;
    if (aspect.extraMetadata) {
      newAspect.extraMetadata = aspect.extraMetadata;
    }
    if (entityState.isModified() || entityState.isDeleted()) {
      newAspect.originalValuesMap = aspect.originalValues;
    }
    (result as any).entityAspect = newAspect;
  } else {
    let aspect = so.complexAspect;
    let newAspect = {} as any;
    if (aspect.originalValues && !core.isEmpty(aspect.originalValues)) {
      newAspect.originalValuesMap = aspect.originalValues;
    }

    (result as any).complexAspect = newAspect;
  }

  return result;
}

interface ITempKey {
  entityType: string;
  values: any[];
}

function exportTempKeyInfo(entityAspect: EntityAspect, tempKeys: ITempKey[]) {
  let entity = entityAspect.entity as Entity;
  if (entityAspect.hasTempKey) {
    tempKeys.push(entityAspect.getKey().toJSON());
  }
  // create map for this entity with foreignKeys that are 'temporary'
  // map -> key: tempKey, value: fkPropName
  let tempNavPropNames: string[] = [];
  entity.entityType.navigationProperties.forEach(function (np) {
    if (np.relatedDataProperties) {
      let relatedValue = entity.getProperty(np.name);
      if (relatedValue && relatedValue.entityAspect.hasTempKey) {
        tempNavPropNames.push(np.name);
      }
    }
  });
  return tempNavPropNames;
}

function importEntityGroup(entityGroup: EntityGroup, jsonGroup: { entities: any[] }, importConfig: ImportConfigExt) {

  let tempKeyMap = importConfig.tempKeyMap;
  let mergeAdds = !!importConfig.mergeAdds;

  let entityType = entityGroup.entityType;
  let mergeStrategy = importConfig.mergeStrategy;

  let targetEntity: Entity | undefined;

  let em = entityGroup.entityManager;
  let entityChanged = em.entityChanged;
  let entitiesToLink: Entity[] = [];
  let rawValueFn = DataProperty.getRawValueFromClient;
  jsonGroup.entities.forEach(function (rawEntity: any) {
    let newAspect = rawEntity.entityAspect;

    let entityKey = entityType.getEntityKeyFromRawEntity(rawEntity, rawValueFn);
    let entityState = EntityState.fromName(newAspect.entityState) as EntityState;
    if (!entityState || entityState === EntityState.Detached) {
      throw new Error("Only entities with a non detached entity state may be imported.");
    }

    // Merge if raw entity is in cache UNLESS this is a new entity w/ a temp key
    // Cannot safely merge such entities even if could match temp key to an entity in cache.
    // Can enable merge of entities w/temp key using "mergeAdds" - use at your own risk!
    let newTempKey = !mergeAdds && entityState.isAdded() && getMappedKey(tempKeyMap!, entityKey);
    targetEntity = newTempKey ? undefined : entityGroup.findEntityByKey(entityKey);

    if (targetEntity) {
      if (mergeStrategy === MergeStrategy.SkipMerge) {
        // deliberate fall thru
      } else if (mergeStrategy === MergeStrategy.Disallowed) {
        throw new Error("A MergeStrategy of 'Disallowed' prevents " + entityKey.toString() + " from being merged");
      } else {
        let targetEntityState = targetEntity.entityAspect.entityState;
        let wasUnchanged = targetEntityState.isUnchanged();
        if (mergeStrategy === MergeStrategy.OverwriteChanges || wasUnchanged) {
          entityType._updateTargetFromRaw(targetEntity, rawEntity, rawValueFn);
          targetEntity.entityAspect.setEntityState(entityState);
          entityChanged.publish({ entityAction: EntityAction.MergeOnImport, entity: targetEntity });
        }
      }
    } else {
      targetEntity = entityType._createInstanceCore() as Entity;
      entityType._updateTargetFromRaw(targetEntity, rawEntity, rawValueFn);
      if (newTempKey) {
        targetEntity.entityAspect.hasTempKey = true;
        // fixup pk
        targetEntity.setProperty(entityType.keyProperties[0].name, newTempKey.values[0]);

        // fixup foreign keys
        // This is safe because the entity is detached here and therefore originalValues will not be updated.
        if (newAspect.tempNavPropNames) {
          newAspect.tempNavPropNames.forEach(function (npName: string) {
            let np = entityType.getNavigationProperty(npName);
            let fkPropName = np!.relatedDataProperties[0].name;
            let oldFkValue = targetEntity!.getProperty(fkPropName);
            let fk = new EntityKey(np!.entityType, [oldFkValue]);
            let newFk = getMappedKey(tempKeyMap!, fk);
            targetEntity!.setProperty(fkPropName, newFk!.values[0]);
          });
        }
      }
      // Now performed in attachEntity
      targetEntity = entityGroup.attachEntity(targetEntity, entityState);
      entityChanged.publish({ entityAction: EntityAction.AttachOnImport, entity: targetEntity });
      if (!entityState.isUnchanged()) {
        em._notifyStateChange(targetEntity, true);
      }
    }

    entitiesToLink.push(targetEntity);
  });
  return entitiesToLink;
}

function getMappedKey(tempKeyMap: ITempKeyMap, entityKey: EntityKey) {
  let newKey = tempKeyMap[entityKey.toString()];
  if (newKey) return newKey;
  let subtypes = entityKey._subtypes;
  if (!subtypes) return null;
  for (let i = 0, j = subtypes.length; i < j; i++) {
    newKey = tempKeyMap[entityKey.toString(subtypes[i])];
    if (newKey) return newKey;
  }
  return null;
}

function promiseWithCallbacks<T>(promise: Promise<T>, callback?: Callback, errorCallback?: ErrorCallback) {
  promise = promise.then(function (data) {
    if (callback) callback(data);
    return Promise.resolve(data);
  }, function (error) {
    if (errorCallback) errorCallback(error);
    return Promise.reject(error);
  });
  return promise;
}

function getEntitiesToSave(em: EntityManager, entities?: Entity[]) {
  let entitiesToSave: Entity[];
  if (entities) {
    entitiesToSave = entities.filter(function (e) {
      if (e.entityAspect.entityManager !== em) {
        throw new Error("Only entities in this entityManager may be saved");
      }
      return !e.entityAspect.entityState.isDetached();
    });
  } else {
    entitiesToSave = em.getChanges();
  }
  return entitiesToSave;
}

function fixupKeys(em: EntityManager, keyMappings: KeyMapping[]) {
  em._inKeyFixup = true;
  keyMappings.forEach(function (km) {
    let group = em._entityGroupMap[km.entityTypeName];
    if (!group) {
      throw new Error("Unable to locate the following fully qualified EntityType name: " + km.entityTypeName);
    }
    group._fixupKey(km.tempValue, km.realValue);
  });
  em._inKeyFixup = false;
}

function getEntityGroups(em: EntityManager, entityTypes?: EntityType | EntityType[]) {
  let groupMap = em._entityGroupMap;
  if (entityTypes) {
    return core.toArray(entityTypes).map(function (et: EntityType) {
      if (et instanceof EntityType) {
        return groupMap[et.name];
      } else {
        throw new Error("The EntityManager.getChanges() 'entityTypes' parameter must be either an entityType or an array of entityTypes or null");
      }
    });
  } else {
    return core.getOwnPropertyValues(groupMap) as EntityGroup[];
  }
}

function checkEntityKey(em: EntityManager, entity: Entity) {
  let ek = entity.entityAspect.getKey();
  // return properties that are = to defaultValues
  let keyPropsWithDefaultValues = core.arrayZip(entity.entityType.keyProperties, ek.values, function (kp, kv) {
    return (kp.defaultValue === kv) ? kp : null;
  }).filter(function (kp) {
    return kp !== null;
  });
  if (keyPropsWithDefaultValues.length) {
    if (entity.entityType.autoGeneratedKeyType !== AutoGeneratedKeyType.None) {
      em.generateTempKeyValue(entity);
    } else {
      // we will allow attaches of entities where only part of the key is set.
      if (keyPropsWithDefaultValues.length === ek.values.length) {
        throw new Error("Cannot attach an object of type  (" + entity.entityType.name + ") to an EntityManager without first setting its key or setting its entityType 'AutoGeneratedKeyType' property to something other than 'None'");
      }
    }
  }
}

function validateEntityStates(em: EntityManager, entityStates?: EntityState | EntityState[]) {
  if (!entityStates) return [] as EntityState[];
  let entStates = core.toArray(entityStates) as EntityState[];
  entStates.forEach((es) => {
    if (!(es instanceof EntityState)) {
      throw new Error("The EntityManager.getChanges() 'entityStates' parameter must either be null, an entityState or an array of entityStates");
    }
  });
  return entStates;
}

function attachRelatedEntities(em: EntityManager, entity: Entity, entityState: EntityState, mergeStrategy: MergeStrategy) {
  let navProps = entity.entityType.navigationProperties;
  navProps.forEach(function (np) {
    let related = entity.getProperty(np.name);
    if (np.isScalar) {
      if (!related) return;
      em.attachEntity(related, entityState, mergeStrategy);
    } else {
      related.forEach(function (e: Entity) {
        em.attachEntity(e, entityState, mergeStrategy);
      });
    }
  });
}

// returns a promise
function executeQueryCore(em: EntityManager, query: EntityQuery | string, queryOptions: QueryOptions, dataService: DataService): Promise<QueryResult> {
  try {
    let results: any[];
    let metadataStore = em.metadataStore;

    if (metadataStore.isEmpty() && dataService.hasServerMetadata) {
      throw new Error("cannot execute _executeQueryCore until metadataStore is populated.");
    }

    if (queryOptions.fetchStrategy === FetchStrategy.FromLocalCache) {
      try {
        if (typeof query === 'string') {
          throw new Error("cannot execute 'string' EntityQuery locally.");
        }
        let qr = executeQueryLocallyCore(em, query);
        return Promise.resolve({ results: qr.results, entityManager: em, inlineCount: qr.inlineCount, query: query });
      } catch (e) {
        return Promise.reject(e);
      }
    }

    let mappingContext: MappingContext | undefined = new MappingContext({
      query: query,
      entityManager: em,
      dataService: dataService,
      mergeOptions: {
        mergeStrategy: queryOptions.mergeStrategy,
        noTracking: !!(query as any).noTrackingEnabled,
        includeDeleted: queryOptions.includeDeleted
      }
    });

    let validateOnQuery = em.validationOptions.validateOnQuery;

    return dataService.adapterInstance!.executeQuery(mappingContext).then(function (data: any) {
      let result = core.wrapExecution(function () {
        let state = { isLoading: em.isLoading };
        em.isLoading = true;
        em._pendingPubs = [];
        return state;
      }, function (state) {
        // cleanup
        em.isLoading = state.isLoading;
        em._pendingPubs!.forEach(function (fn) {
          fn();
        });
        em._pendingPubs = undefined;
        em._hasChangesAction && em._hasChangesAction();
        // TODO: removed - not sure why needed in first place...
        // // HACK for GC
        // query = undefined;
        mappingContext = undefined;
        // HACK: some errors thrown in next function do not propogate properly - this catches them.

        if (state.error) {
          return Promise.reject(state.error);
        }

      }, function () {
        let nodes = dataService.jsonResultsAdapter.extractResults(data);
        nodes = core.toArray(nodes);

        results = mappingContext!.visitAndMerge(nodes, { nodeType: "root" });
        if (validateOnQuery) {
          results.forEach(function (r: any) {
            // anon types and simple types will not have an entityAspect.
            r.entityAspect && r.entityAspect.validateEntity();
          });
        }
        mappingContext!.processDeferred();
        // if query has expand clauses walk each of the 'results' and mark the expanded props as loaded.
        if (query instanceof EntityQuery) {
          markLoadedNavProps(results, query);
        }
        let retrievedEntities = core.objectMap(mappingContext!.refMap);
        return { results: results, query: query, entityManager: em, httpResponse: data.httpResponse, inlineCount: data.inlineCount, retrievedEntities: retrievedEntities };
      });
      return Promise.resolve(result);
    }, function (e: any) {
      if (e) {
        e.query = query;
        e.entityManager = em;
      }
      return Promise.reject(e);
    });

  } catch (e) {
    if (e) {
      e.query = query;
    }
    return Promise.reject(e);
  }
}

function markLoadedNavProps(entities: Entity[], query: EntityQuery) {
  if (query.noTrackingEnabled) return;
  let expandClause = query.expandClause;
  if (expandClause == null) return;
  expandClause.propertyPaths.forEach(function (propertyPath) {
    let propNames = propertyPath.split('.');
    markLoadedNavPath(entities, propNames);
  });
}

function markLoadedNavPath(entities: Entity[], propNames: string[]) {
  let propName = propNames[0];
  entities.forEach((entity) => {
    let ea = entity.entityAspect;
    if (!ea) return; // entity may not be a 'real' entity in the case of a projection.
    ea._markAsLoaded(propName);
    if (propNames.length === 1) return;
    let next = entity.getProperty(propName);
    if (!next) return; // no children to process.
    // strange logic because nonscalar nav values are NOT really arrays
    // otherwise we could use Array.isArray
    if (!next.arrayChanged) next = [next];
    markLoadedNavPath(next, propNames.slice(1));
  });
}

function updateConcurrencyProperties(entities: Entity[]) {
  let candidates = entities.filter((e) => {
    e.entityAspect.isBeingSaved = true;
    return e.entityAspect.entityState.isModified()
      && e.entityType.concurrencyProperties.length > 0;

  });
  if (candidates.length === 0) return;
  candidates.forEach(function (c) {
    c.entityType.concurrencyProperties.forEach(function (cp) {
      updateConcurrencyProperty(c, cp);
    });
  });
}

function updateConcurrencyProperty(entity: Entity, property: DataProperty) {
  // check if property has already been updated
  if (entity.entityAspect.originalValues[property.name]) return;
  let value = entity.getProperty(property.name);
  let dataType = property.dataType as DataType;
  if (!value) value = dataType.defaultValue;
  if (dataType.isNumeric) {
    entity.setProperty(property.name, value + 1);
  } else if (dataType.getConcurrencyValue) {
    // DataType has its own implementation
    let nextValue = dataType.getConcurrencyValue(value);
    entity.setProperty(property.name, nextValue);
  } else if (dataType === DataType.Binary) {
    // best guess - that this is a timestamp column and is computed on the server during save
    // - so no need to set it here.
    return;
  } else {
    // this just leaves DataTypes of Boolean, String and Byte - none of which should be the
    // type for a concurrency column.
    // NOTE: thought about just returning here but would rather be safe for now.
    throw new Error("Unable to update the value of concurrency property before saving: " + property.name);
  }
}


function findOrCreateEntityGroup(em: EntityManager, entityType: EntityType) {
  let group = em._entityGroupMap[entityType.name];
  if (!group) {
    group = new EntityGroup(em, entityType);
    em._entityGroupMap[entityType.name] = group;
  }
  return group;
}

function findOrCreateEntityGroups(em: EntityManager, entityType: EntityType) {
  let entityTypes = entityType.getSelfAndSubtypes();
  return entityTypes.map((et) => {
    return findOrCreateEntityGroup(em, et);
  });
}

function unwrapInstance(structObj: StructuralObject, transformFn?: (dp: DataProperty, val: any) => any) {

  let rawObject: any = {};
  let stype = EntityAspect.isEntity(structObj) ? structObj.entityType : structObj.complexType;
  let serializerFn = getSerializerFn(stype);
  let unmapped = {};
  stype.dataProperties.forEach(function (dp) {
    if (dp.isComplexProperty) {
      rawObject[dp.nameOnServer] = core.map(structObj.getProperty(dp.name), function (co) {
        return unwrapInstance(co, transformFn);
      });
    } else {
      let val = structObj.getProperty(dp.name);
      val = transformFn ? transformFn(dp, val) : val;
      if (val === undefined) return;
      val = serializerFn ? serializerFn(dp, val) : val;
      if (val !== undefined) {
        if (dp.isUnmapped) {
          unmapped[dp.nameOnServer] = core.toJSONSafe(val, core.toJSONSafeReplacer);
        } else {
          rawObject[dp.nameOnServer] = val;
        }
      }
    }
  });

  if (!core.isEmpty(unmapped)) {
    // TODO: review this.
    (rawObject as any).__unmapped = unmapped;
  }
  return rawObject;
}

function unwrapOriginalValues(target: StructuralObject, metadataStore: MetadataStore, transformFn?: (dp: DataProperty, val: any) => any) {
  let stype = EntityAspect.isEntity(target) ? target.entityType : target.complexType;
  let aspect = EntityAspect.isEntity(target) ? target.entityAspect : target.complexAspect;
  let fn = metadataStore.namingConvention.clientPropertyNameToServer;
  let result = {};
  core.objectForEach(aspect.originalValues, function (propName, val) {
    let prop = stype.getProperty(propName) as DataProperty;
    val = transformFn ? transformFn(prop, val) : val;
    if (val !== undefined) {
      result[fn(propName, prop)] = val;
    }
  });
  stype.complexProperties.forEach(function (cp) {
    let nextTarget = target.getProperty(cp.name);
    if (cp.isScalar) {
      let unwrappedCo = unwrapOriginalValues(nextTarget, metadataStore, transformFn);
      if (!core.isEmpty(unwrappedCo)) {
        result[fn(cp.name, cp)] = unwrappedCo;
      }
    } else {
      let unwrappedCos = nextTarget.map((item: any) => {
        return unwrapOriginalValues(item, metadataStore, transformFn);
      });
      result[fn(cp.name, cp)] = unwrappedCos;
    }
  });
  return result;
}

function unwrapChangedValues(entity: Entity, metadataStore: MetadataStore, transformFn: (dp: DataProperty, val: any) => any) {
  let stype = entity.entityType;
  let serializerFn = getSerializerFn(stype);
  let fn = metadataStore.namingConvention.clientPropertyNameToServer;
  let result = {};
  core.objectForEach(entity.entityAspect.originalValues, function (propName, value) {
    let prop = stype.getProperty(propName) as DataProperty;
    let val = entity.getProperty(propName);
    val = transformFn ? transformFn(prop, val) : val;
    if (val === undefined) return;
    val = serializerFn ? serializerFn(prop, val) : val;
    if (val !== undefined) {
      result[fn(propName, prop)] = val;
    }
  });
  // any change to any complex object or array of complex objects returns the ENTIRE
  // current complex object or complex object array.  This is by design. Complex Objects
  // are atomic.
  stype.complexProperties.forEach((cp) => {
    if (cpHasOriginalValues(entity, cp)) {
      let coOrCos = entity.getProperty(cp.name);
      result[fn(cp.name, cp)] = core.map(coOrCos, function (co) {
        return unwrapInstance(co, transformFn);
      });
    }
  });
  return result;
}

function cpHasOriginalValues(structuralObject: StructuralObject, cp: DataProperty): boolean {
  let coOrCos = structuralObject.getProperty(cp.name);
  if (cp.isScalar) {
    return coHasOriginalValues(coOrCos);
  } else {
    // this occurs when a nonscalar co array has had cos added or removed.
    if (coOrCos._origValues) return true;
    return coOrCos.some(function (co: ComplexObject) {
      return coHasOriginalValues(co);
    });
  }
}

function executeQueryLocallyCore(em: EntityManager, query: EntityQuery) {
  assertParam(query, "query").isInstanceOf(EntityQuery).check();

  let metadataStore = em.metadataStore;
  let entityType = query._getFromEntityType(metadataStore, true);
  // there may be multiple groups is this is a base entity type.
  let groups = findOrCreateEntityGroups(em, entityType!);
  // filter then order then skip then take
  let filterFunc = query.wherePredicate && query.wherePredicate.toFunction({ entityType: entityType });

  let queryOptions = QueryOptions.resolve([query.queryOptions, em.queryOptions, QueryOptions.defaultInstance]);
  let includeDeleted = queryOptions.includeDeleted === true;

  let newFilterFunc = function (entity: Entity) {
    return entity && (includeDeleted || !entity.entityAspect.entityState.isDeleted()) && (filterFunc ? filterFunc(entity) : true);
  };

  let result: any[] = [];
  // TODO: mapMany
  groups.forEach((group) => {
    let entities = group._entities.filter(newFilterFunc) as Entity[];
    if (entities.length) {
      result = result.length ? result.concat(entities) : entities;
    }
  });

  let orderByComparer = query.orderByClause && query.orderByClause.getComparer(entityType!);
  if (orderByComparer) {
    result.sort(orderByComparer);
  }

  let inlineCount = query.inlineCountEnabled ? result.length : undefined;

  let skipCount = query.skipCount;
  if (skipCount) {
    result = result.slice(skipCount);
  }
  let takeCount = query.takeCount;
  if (takeCount) {
    result = result.slice(0, takeCount);
  }

  let selectClause = query.selectClause;
  if (selectClause) {
    let selectFn = selectClause.toFunction();
    result = result.map(selectFn);
  }
  return { results: result, inlineCount: inlineCount };
}

function coHasOriginalValues(co: ComplexObject) {
  // next line checks all non complex properties of the co.
  if (!core.isEmpty(co.complexAspect.originalValues)) return true;
  // now need to recursively check each of the cps
  return co.complexType.complexProperties.some(function (cp) {
    return cpHasOriginalValues(co, cp);
  });
}

function getSerializerFn(stype: EntityType | ComplexType) {
  return stype.serializerFn || (stype.metadataStore && stype.metadataStore.serializerFn);
}

