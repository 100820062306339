import * as React from 'react'
import { InsyteQueryGroupCtrl } from './InsyteQueryGroupCtrl'
import { accentUtils } from '../../services/HelperService';
import { InsyteQueryExcludeGroupCtrl } from './InsyteQueryExcludeGroupCtrl';
import { getDefaultFilter } from './AdvancedFilterConstants';


export class InsyteQueryCtrl extends React.Component {

    constructor(props) {
        super(props);


        var defaultFilter = props.value;

        if (accentUtils.isNull(defaultFilter)) {
            defaultFilter = getDefaultFilter();
        }

        this.state = {
            filter: defaultFilter
        };

        this.onItemChanged = this.onItemChanged.bind(this);

        this.refreshKey = 0;


    }

    onItemChanged(preventRedraw){


        if (!preventRedraw)
            this.refreshKey++;

        var newFilter = {...this.state.filter};

        this.setState({ filter: newFilter})        

        this.props.onChange(newFilter);

    }

    render() {

        


        return <div>
            <InsyteQueryGroupCtrl key={`main_${this.refreshKey}`} readOnly={this.props.readOnly} item={this.state.filter} level={0} last onItemChanged={this.onItemChanged} schema={this.props.schema} disableDelete fieldStringBase={this.props.fieldStringBase} />            
            <InsyteQueryExcludeGroupCtrl key={`except_${this.refreshKey}`} readOnly={this.props.readOnly} item={this.state.filter} onItemChanged={this.onItemChanged} schema={this.props.schema} disableDelete fieldStringBase={this.props.fieldStringBase} />
        </div>;
        
    }
}


