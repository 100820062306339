import * as React from 'react'
import { Row, Col } from 'reactstrap'

import * as UserService from './../services/UserService'
import { AccentCheckUnbound } from './../controls/unbound/AccentCheckUnbound'
import { AccentDialog } from './AccentDialog'
import { AccentButton } from '../controls/AccentButton'
import { t } from '../services/HelperService'




export class MFReleaseDlg extends React.Component {

    constructor(props) {
        super(props)


        this.state = { xls: true, xml: false, updateStatus: true };

        this.allowOk = this.allowOk.bind(this);
        this.getBody = this.getBody.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.onClose = this.onClose.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onChangeXML = this.onChangeXML.bind(this);
        this.onChangeXLS = this.onChangeXLS.bind(this);
        this.onUpdateStatus = this.onUpdateStatus.bind(this);

    }


    onUpdateStatus(e) {
        this.setState({ updateStatus: e.checked });

    }
    onChangeXML(e) {
        this.setState({ xml: e.checked });

    }


    onChangeXLS(e) {
        this.setState({ xls: e.checked });

    }

    componentDidMount() {

    }

    getTitle() {

        if (this.props.isPreview)
            return "application_strings.views.mfScheduling.preview";

        return "application_strings.views.mfScheduling.release";
    }

    allowOk() {
        return this.state.xml || this.state.xls;
    }

    onClose(e) {
        this.props.onClose({ ...e, xml: this.state.xml, xls: this.state.xls, updateStatus: this.props.isPreview ? false : this.state.updateStatus });
    }

    getBody() {


        const user = UserService.getUser();

        return <Row >
            <Col md={12}>
                {!this.props.isPreview && <Row>
                    <Col md={12}>
                        <AccentCheckUnbound tagName={"updateStatus"} defaultValue={true} onChange={this.onUpdateStatus} label="application_strings.views.mfScheduling.updateStatus" style={{ marginTop: "20px" }} />
                    </Col>
                </Row>}
                <Row>
                    <Col md={12}>
                        <AccentCheckUnbound tagName={"downloadXLS"} defaultValue={true} onChange={this.onChangeXLS} label="application_strings.views.mfScheduling.dowloadXLS" style={{ marginTop: "20px" }} />
                    </Col>
                </Row>
                {user.IsDeveloperMode && <Row>
                    <Col md={12}>
                        <AccentCheckUnbound tagName={"downloadXML"} defaultValue={false} onChange={this.onChangeXML} label="application_strings.views.mfScheduling.dowloadXML" style={{ marginTop: "20px" }} />
                    </Col>
                </Row>}
            </Col>
        </Row>;

    }



    render() {


        return <AccentDialog
            headerContent={t(this.getTitle())}
            maxWidth={ 600}
            onClose={this.props.onClose}
            actionsContent={[
                <AccentButton key="2" disabled={!this.allowOk()} tagName="okBtn" onClick={this.onClose}>{t("application_strings.application.buttons.ok")}</AccentButton>
            ]}
        >
            {this.getBody()}
        </AccentDialog>;
    }
}