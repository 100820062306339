import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { RadioButton, RadioGroup } from '@progress/kendo-react-inputs'
import { accentUtils, t } from '../../services/HelperService';


export class AccentRadioUnbound extends React.Component {

    constructor(props) {
        super(props);

        
    }


    render() {

        var me = this;
        var preformatted = this.props.preformatted !== undefined && this.props.preformatted;
        var options = this.props.options.map(function (o) {
            return (
                <FormGroup check key={"k_" + o.value}>
                    <Label check>
                        <Input onChange={function () { me.props.onChange(o); }} type="radio" name={me.props.name} defaultChecked={o.defaultChecked} data-tagname={o.tagName} value={o.value} />{' '}
                        {preformatted?o.label:t(o.label)}
                    </Label>
                </FormGroup>);
        });


        var className = "accentRadioUnbd ";

        if (this.props.vertical) {
            className += "vertical";
        } else {
            className += "horizontal";
        }

        return <div className={className} data-tagname={this.props.dataTagName}>
            {options}
        </div>

    }

}


export class AccentRadioUnboundCustom extends React.Component {

    constructor(props) {
        super(props);

    }


    render() {
        return <div data-tagname={this.props.dataTagName}><RadioButton onChange={this.props.onChange} defaultChecked={this.props.checked} id={this.props.id} name={this.props.name} label={t(this.props.label)} /></div>;
    }

}


export class AccentRadioGroup extends React.Component {

    constructor(props) {
        super(props);

    }


    render() {
        return <div data-tagname={this.props.dataTagName}><RadioGroup defaultValue={this.props.defaultValue} data={this.props.data} onChange={this.props.onChange} /></div>;
    }

}