import React from 'react'
import { Row, Col } from 'reactstrap'
import { accentUtils, t, newGuid, from, showDialog } from '../../services/HelperService'
import { download, update, updateAsFormData } from '../../services/DataService'
import { AccentCheckUnbound } from '../unbound/AccentCheckUnbound'
import { AccentButton } from '../AccentButton'
import {  AccentUploadButton} from '../AccentUpload'
import { AccentDialog } from '../../dialogs/AccentDialog'



let closeDownloadDlg = null;

export class RTLFilterExportDlg extends React.Component {


    static ExportFilters(filters) {

        const ids = JSON.stringify(filters);


        download(`/Download/FilterExport/${ids}`);


    }

    constructor(props) {
        super(props);

       


        this.select = this.select.bind(this);        
        this.selected = [];

    }


    

    select(e, f) {
        if (e.checked) {
            this.selected.push(f.ID);
            this.selected = from(this.selected).distinct().toArray();

        } else {
            this.selected = this.selected.filter(i => i != f.ID);
        }

        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(this.selected);
        }

    }

    render() {

        var me = this;

        const selection = this.props.filters.map(f => {
            return <Row>
                <Col md={6}><label>{f.Name}</label></Col>
                <Col md={6}><AccentCheckUnbound tagName={f.Name} onChange={(e) => me.select(e, f)} /></Col>
            </Row>;
        });

        return <Row>
            <Col md={12}>
                {selection}
            </Col>
        </Row>;

    }
}



export class RTLFilterImportDlg extends React.Component {


    constructor(props) {
        super(props);

        this.uploadCtrl = React.createRef();

        this.onFilesChange = this.onFilesChange.bind(this);
        this.doUpload = this.doUpload.bind(this);
    }

    onFilesChange() {
        this.forceUpdate();
    }

    doUpload(e) {
        var me = this;
        this.uploadCtrl.current.getFilesForUpload().then(r => {

            updateAsFormData('ImportFilter', { Files: r.files }).then(i => {

                me.props.onClose({
                    canceled: false,
                });

                if (e.promise) e.promise();
                
            });



        });


    }



    render() {


        return <AccentDialog
            className="importFilterDlg"
            onClose={this.props.onClose}
            headerContent={t("application_strings.views.filterView.importFilterTitle")}
            actionsContent={[                
                <AccentButton key="2" tagName="filterImpPkBtn" onClick={this.doUpload}>
                    {t("application_strings.application.buttons.ok")}
                </AccentButton>
            ]}
        >
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                            <AccentUploadButton ref={this.uploadCtrl} onChange={this.onFilesChange} allowedExtensions={['.filters']} multiple={true} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
        </AccentDialog>;

    }
}