import React from 'react'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import { IntlProvider } from '@progress/kendo-react-intl';
import { accentUserLang, accentUtils, t } from './../../services/HelperService'


export class AccentDateUnbound extends React.Component {

    constructor(props) {
        super(props);

        var value = this.props.defaultValue;

        if (accentUtils.isEmpty(value)) {
            value = null;
        }

        if (!accentUtils.isNull(value)) {
            value = new Date(value);
        }

        this.state = { value: value };

        

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {


        if (!accentUtils.isNull(this.props.onChange)) {
            this.props.onChange(accentUtils.isNull(e.target.value) ? null : new Date(e.target.value));
        }

        this.setState({ value: accentUtils.isNull(e.target.value) ? null : new Date(e.target.value) })

    }


    render() {


        var isValid = !this.props.required || !accentUtils.isNull(this.state.value);

        var className = isValid ? "accentDateUnbound" : "accentDateUnbound invalid";

        //defaultValue


        return <IntlProvider locale={accentUserLang}>
            <div data-tagname={this.props.dataTagName}>
                <DatePicker
                    className={className}
                    format="dd-MM-yyyy"
                    value={this.state.value}
                    onChange={this.onChange}
                    disabled={this.props.disabled}
                    label={t(this.props.label)}
                    style={{ width: "100%" }}
                />
            </div>            
        </IntlProvider>;


    }
}
