import * as React from "react";
import { Row, Col } from "reactstrap";
import { AccentCheckUnbound } from "../controls/unbound/AccentCheckUnbound";
import { from, t } from "../services/HelperService";
import { Helper } from "../services/JobService";
import { AccentWizard, AccentWizardPage } from "./RTLWizard";



export default class EditJobWizard extends React.Component {

    constructor(props) {
        super(props);

        var me = this;
        this.jobID = this.props.pageprops.jobID;
        this.editModel = this.props.pageprops.editModel;
        this.contactedSuppliers = false;
        this.wizardModel = {
            viewModel: {
                entity: {
                    setProperty: function (name, value) {
                        this[name] = value;
                    }
                }
            }
        };


        this.contactedSuppliersChanged = this.contactedSuppliersChanged.bind(this);
        this.onFinish = this.onFinish.bind(this);

    }

    contactedSuppliersChanged(e) {
        this.contactedSuppliers = e.checked;
        this.forceUpdate();
    }

    onFinish() {
        return Promise.resolve(true);
    }

    render() {


        var allowNext = this.contactedSuppliers;

        var supplierInfo = from(this.editModel.SupplyInfo)
            .groupBy("x => { ID : x.ID, SupplierName: x.SupplierName}")
            .select(function (s) {

                var sup = s.key();

                var sJobs = s.select(sj => <li>{sj.SupplyJobNumber} ({sj.ProductName})</li>).toArray();

                return <React.Fragment>
                    <li>{sup.SupplierName}</li>
                    <ul>
                        {sJobs}
                    </ul>
                </React.Fragment>;
            }).toArray();

        return (
            <AccentWizard wizardProps={this.props} allowNext={allowNext}>
                <AccentWizardPage title="application_strings.wizards.editJob.page01Title" >
                    <React.Fragment>
                        <div className="alert alert-info" role="alert">{t("application_strings.wizards.editJob.page01Message", { reference: this.editModel.Reference, contactName: this.editModel.Contact })}</div>
                        <Row>
                            <Col md="12">
                                <ul>
                                    {supplierInfo}
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <AccentCheckUnbound tagName="contactedSupplier" defaultValue={this.contactedSuppliers} onChange={this.contactedSuppliersChanged} label="application_strings.wizards.editJob.confirmSuppliersContacted" />
                            </Col>
                        </Row>
                    </React.Fragment>
                </AccentWizardPage>
            </AccentWizard>
        );
    }

}
