import React from 'react'
import { Row, Col} from 'reactstrap'
import { accentUtils, t, from, formHelper } from '../../services/HelperService'
import { AccentCheckUnbound } from './../unbound/AccentCheckUnbound'
import { AccentDisplay } from './../AccentDisplay'
import { AccentButton } from './../AccentButton'
import AccentIcons from '../../icons/Icons'
import { AccentDialog } from '../../dialogs/AccentDialog'

export class RTLGridColumnChooser extends React.PureComponent {

    constructor(props) {
        super(props);


        this.state = { hidden: props.hidden };

        this.onClose = this.onClose.bind(this);


    }


    onClose(e) {

        if (e.promise) e.promise();
        this.props.onClose({
            hidden: this.state.hidden,
            canceled: false,
        });


    }

    render() {

        var me = this;

        

        var rows = from(this.props.columns)
            .where(c=> accentUtils.isNull(c.details))
            .select(c => {
                return {
                    field: c.fieldName,
                    title: !accentUtils.isNull(c.title) ? c.title : t(formHelper.getFieldLabel(t, this.props.queryEntity, c.fieldName, true))
                };
            })
            .orderBy(c=>c.title)
            .select(c => {

                const hide = me.props.hidden.includes(c.field);


                return <Row>
                    <Col md={12}>
                        <AccentCheckUnbound tagName={c.field} label={c.title} defaultValue={!hide} onChange={function (e) {

                    if (e.checked) {
                        me.setState({ hidden: from(me.state.hidden).where(v => v != c.field).toArray() });
                    } else {
                        me.setState({ hidden: from(me.state.hidden).union([c.field]).toArray() });
                    }

                }} />
                    </Col>
                </Row>;

            })
            .toArray();

        const allowSave = rows.length != me.state.hidden.length;


        return (
            <AccentDialog className="accentColChooser"
                onClose={this.props.onClose}
                headerContent={t("application_strings.wizards.columnChooser.title")}
                actionsContent={[
                    <AccentButton key="2" tagName="calCloseBtn" disabled={!allowSave} onClick={this.onClose}>
                        <AccentDisplay large tablet>
                            {t("application_strings.application.buttons.save")}
                        </AccentDisplay>
                        <AccentDisplay mobile>
                            <AccentIcons.Save />
                        </AccentDisplay>
                    </AccentButton>]}
            >
                    {rows}
            </AccentDialog>

        );


    }

}




