import React from 'react'
import { Row, Col, Collapse, ButtonGroup } from 'reactstrap';
import { accentUtils, t, showDialog, from } from '../../services/HelperService'
import { queryNoTracking, useQueryAllNoTracking } from '../../services/DataService'
import { AccentSpinner } from './../AccentSpinner'
import { AccentButton } from './../AccentButton'
import { AccentCheckUnbound } from './../unbound/AccentCheckUnbound'
import { FilterButtons } from './../FilterButtons'
import { AccentDialog } from '../../dialogs/AccentDialog';
import { useRecoilState } from 'recoil';
import { getGridFilter, getQuickFilters } from './GridDataManager';
import { advancedFilterCache } from '../advancedfilter/AdvancedFilterConstants';




export const RTLGridQuickFilterBar = React.memo(props => {

    const missing = React.useRef([]);
    const [queryArgs, setQueryArgs] = React.useState({ entityType: (accentUtils.isNull(props.filterSchemaName) ? null : props.filterSchemaName) });
    const [quickFilters, setQuickFilters] = useRecoilState(getQuickFilters(props.gridID));

    const [filters, setFilters] = React.useState(null);


    const isFiltersLoading = accentUtils.isNull(filters);


    const onSelectFilters = React.useCallback((e) => {

        const filters = accentUtils.isNull(quickFilters) ? [] : quickFilters.map(v => v.ID);

        showDialog(<RTLGridQuickFilterSelector filterSchemaName={props.filterSchemaName} selectedFilters={filters} />).then(s => {
            if (!s.canceled) {

                const sel = s.selected.map(v => {
                    return { ID: v, Selected: false };
                });


                setQuickFilters(sel);

            }

            if (e.promise) e.promise();
        });

    }, [quickFilters, setQuickFilters]);


    const onFilterChanged = React.useCallback((e) => {

        const filters = accentUtils.isNull(quickFilters) ? [] : quickFilters;

        const value = filters.map(v => {
            return { ID: v.ID, Selected: from(e.value).any(i => v.ID == i) };
        });


        setQuickFilters(value);
    }, [quickFilters, setQuickFilters]);


    


    React.useEffect(() => {

        if (missing.current.length > 0) {
            setQuickFilters(f => from(f).where(f => !from(missing.current).any(m => m === f.ID)).toArray());
            missing.current = [];
        }

    },[missing, setQuickFilters]);


    React.useEffect(() => {

        advancedFilterCache.getQuickFilters(queryArgs.entityType).then(r => {
            setFilters(r);
        });

    }, [quickFilters]);


    if (isFiltersLoading) return <AccentSpinner />;


    const quickFilterList = accentUtils.isNull(quickFilters) ? [] : quickFilters;


    

    const filterItems = quickFilterList.map(f => {

        var filter = from(filters).firstOrDefault(i => i.ID == f.ID);


        if (accentUtils.isNull(filter)) {
            missing.current.push(f.ID);
            return null;
        }


        return {
            ID: f.ID,
            Text: filter.Name,
            tagName: f.ID
        };
    }).filter(f => !accentUtils.isNull(f));

    

    const defaultValue = from(quickFilterList).where(v => v.Selected).select(v => v.ID).toArray();

    return <Collapse isOpen={props.show} className="acc-grid-filter-ctr">
        <Row>            
            <Col md={12} style={{ overflowX: "auto" }}>
                <div className="d-flex" style={{ gap: "3px" }}>
                    <div>
                        <AccentButton tagName="btnSelectFilters" disabled={props.readOnly} onClick={onSelectFilters}>...</AccentButton>
                    </div>
                    <div><FilterButtons check defaultValue={defaultValue} options={filterItems} disabled={props.readOnly} onChange={onFilterChanged} tagName="DispatchType" /></div>
                </div>                                
            </Col>
        </Row>
    </Collapse>

});




export const RTLGridQuickFilterSelector = React.memo(props => {

    const [queryArgs,] = React.useState({ entityType: (accentUtils.isNull(props.filterSchemaName) ? null : props.filterSchemaName) });
    const [selected, setSelected] = React.useState(accentUtils.isNull(props.selectedFilters) ? [] : props.selectedFilters);
    const [filters, setFilters] = React.useState(null);


    const isFiltersLoading = accentUtils.isNull(filters);


    const onClose = React.useCallback((e) => {
        props.onClose({ ...e, selected: selected });        
    }, [props, selected]);



    React.useEffect(() => {

        advancedFilterCache.getFilters(queryArgs.entityType).then(r => {
            setFilters(r);
        });

    }, []);


    
    const content = isFiltersLoading ? <AccentSpinner /> : filters.map(f => {

        const isSelected = from(selected).any(v => v == f.ID);

        return <Row key={f.ID}>
            <Col md={12}>
                <AccentCheckUnbound tagName={f.ID} label={f.Name} defaultValue={isSelected} onChange={function (e) {

                    setSelected(s => {

                        if (e.checked) {
                            return [...s, Number(e.tagName)];
                        } else {
                            return s.filter(v => v !== e.tagName);
                        }
                    });

                }} />
            </Col>
        </Row>;
    });
    


    return <AccentDialog
        onClose={props.onClose}
        headerContent="Select Filters"
        actionsContent={[
            <AccentButton key="2" tagName="filterImpPkBtn" onClick={onClose}>
                {t("application_strings.application.buttons.ok")}
            </AccentButton>
        ]}
    >
        {content}
        </AccentDialog>;

});