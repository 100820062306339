import * as React from 'react'
import {AccentSpinner} from './../controls/AccentSpinner'
import { AccentButton } from './../controls/AccentButton'
import { accentUtils, showOK, t, formHelper, showDialog} from './../services/HelperService'
import * as KendoLayout from '@progress/kendo-react-layout'
import { AccentDialog } from '../dialogs/AccentDialog'

const RTLWizardTitle = (props) => {
    return (
        <span className="rtl-wiz-title">{props.children}</span>
    )
};


class RTLWizardPageContent extends React.Component {

    constructor(props) {
        super(props);

        this.loading = false;
        this.content = undefined;
    }

    render() {
        var me = this;

        if (this.content === undefined) {

            if (!this.loading) {
                this.loading = true;
                this.props.loadContent().then(function (c) {
                    me.content = c;
                    me.loading = false;
                    me.forceUpdate();
                });
            }

            return <AccentSpinner />;
        } else {
            return this.content;
        }


    }
}

class RTLWizard extends React.Component {

    constructor(props) {
        super(props);

        

        this.state = {
            model: props.model,
            promise: props.promise
        };


        this.pageRefreshKey = 0;
        this.handleSelect = this.handleSelect.bind(this);
        this.finish = this.finish.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onAction = this.onAction.bind(this);
        this.refresh = this.refresh.bind(this);
        this.render = this.render.bind(this);

        props.model.refresh = this.refresh;

    }

    refresh() {
        this.forceUpdate();
    }

    handleSelect(e) {

        if (!accentUtils.isNull(this.state.model)) {

            var index = e.selected - 1;

            this.state.model.select(index).then(this.onAction);
        }
    }

    onAction(e) {

        var me = this;

        if (e.isValid) {

            if (e.isFinished) {
                this.finish();
            } else {
                this.forceUpdate();
            }
        } else {

            if (accentUtils.isNull(e.error)) {
                showOK("application_strings.application.dialogs.missingDataTitle", "application_strings.application.dialogs.missingDataMsg", ["application_strings.application.buttons.ok"])
            } else {
                showOK(e.error.title, e.error.msg, ["application_strings.application.buttons.ok"]).then(function () {
                    me.forceUpdate();
                });
            }
        }
    }

    onButtonClick(b) {
        b.onClick(b).then(this.onAction);
    }

  

    finish() {

        this.props.onClose({
            canceled: false
        });

        

    }

    render() {


        var me = this;
        var show = !accentUtils.isNull(this.state.model);


        if (!show) return null;

        var content = null;

        var currentPageIndex = this.state.model.getActivePageIndex();

        var currentPage = null;

        const className = this.state.model.showDisabledTabsOnMobile ? "acc-tab-mob-vis" : undefined;

        var tabs = this.state.model.getPages().map((p, i) => {

            var isCurr = i === currentPageIndex;
            //key={me.pageRefreshKey++}
            var pageContent = <RTLWizardPageContent index={i} loadContent={function () { return me.state.model.getPageContent(i) }} />;

            if (isCurr) currentPage = p;


            return <KendoLayout.TabStripTab key={p.updateID} title={accentUtils.isNull(p.titleItem) ? t(p.title) : p.titleItem} disabled={p.disabled} style={{width: "100%"}} >
                {pageContent}
            </KendoLayout.TabStripTab>;
        });


        tabs.unshift(
            <KendoLayout.TabStripTab title={<RTLWizardTitle>{t(this.state.model.title)}</RTLWizardTitle>} disabled={true}>
                <p></p>
            </KendoLayout.TabStripTab>
        );
        content = <KendoLayout.TabStrip className={className} selected={currentPageIndex + 1} onSelect={this.handleSelect} keepTabsMounted>
            {tabs}
        </KendoLayout.TabStrip>;


        var buttons =
            this.state.model.getPageButtons(currentPageIndex)
                .map(function (b) {
                    return <AccentButton tagName={"rtlWizardBtn_" + b.tagName} onClick={function () { me.onButtonClick(b); }}>{t(b.text)}</AccentButton>;
                });


        return <AccentDialog
            onClose={ this.props.onClose }
            className="rtl-wizard " fullscreen={this.props.fullscreen}
            actionsContent={buttons}
            actionLayout="end"
        >
                {content}
        </AccentDialog>;

    }
}






export class AccentWizard extends React.Component{

    constructor(props) {

        super(props);

        this.state = {
            visible: props.visible,
        };

        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.getPageAsVisible = this.getPageAsVisible.bind(this);
        this.updateVisibility = this.updateVisibility.bind(this);
        this.toggle = this.toggle.bind(this);

    }

    componentDidUpdate() {
    }

    componentDidMount() {
    }


    getPageAsVisible(page, visible) {
        return React.cloneElement(page, { visible: visible }, page.props.children);
    }

    updateVisibility(index) {

        var me = this;
        var res = [];
        var isArray = Array.isArray(this.props.children);

        if (this.props.children) {
            if (isArray) {

                this.props.children.forEach(function (c) {
                    res.push(me.getPageAsVisible(c, false));
                });

            } else {
                res.push(me.getPageAsVisible(this.props.children, false));
            }


            res[index] = me.getPageAsVisible(res[index], true);

        }

        return res;

    }

  

    toggle() {

        if (this.props.wizardProps.allowCancel) {
            this.props.wizardProps.onCancel();
        }

    }

    render() {


        var index = this.props.wizardProps.pageIndex;

        if (!accentUtils.isNull(this.props.indexOverride)) {
            index = this.props.indexOverride;
        }

        var children = this.updateVisibility(index);

        var len = children.length;

        var title = children[index].props.title;
        var tipID = children[index].props.tip;

        var nextButtonTitleOverride = children[index].props.nextTitle;
        var isOverrideFinish = !accentUtils.isNull(children[index].props.isFinish) && children[index].props.isFinish === true;
        var allowBack = true;

        if (!accentUtils.isNull(children[index].props.allowBack)) {
            allowBack = children[index].props.allowBack;
        }

        var tip = null;

        if (tipID != null) {
            tip = (
                <div className="float-right">
                    <a href={'#TIP/' + tipID} title={'tip:https://myinsyte.helpdocsonline.com/' + tipID}><small>Whats this?</small></a>&nbsp;
                </div>
            );
        }


        var nextTitle = "application_strings.application.buttons.next";
        var finishTitle = "application_strings.application.buttons.finish";


        if (!accentUtils.isNull(nextButtonTitleOverride)) {
            nextTitle = nextButtonTitleOverride;
            finishTitle = nextButtonTitleOverride;
        }


        var buttons = [];

        var nextEnabled = true;

        if (!accentUtils.isNull(this.props.allowNext)) {
            nextEnabled = this.props.allowNext;
        }

        

        if (index > 0 && allowBack)
            buttons.push(<AccentButton tagName="wizBackBtn" onClick={this.props.wizardProps.onPrev}>{t("application_strings.application.buttons.back")}</AccentButton>);

        if (index + 1 < len && !isOverrideFinish) {
            buttons.push(<AccentButton ref="btnNext" disabled={!nextEnabled} tagName="wizNextBtn" onClick={this.props.wizardProps.onNext}>{t(nextTitle)}</AccentButton>);
        }


        if (index + 1 === len || isOverrideFinish) {
            buttons.push(<AccentButton ref="btnFinish" disabled={!nextEnabled} submit={true} tagName="wizFinishBtn" onClick={this.props.wizardProps.onFinish}>{t(finishTitle)}</AccentButton>);
        }

        var className = "accentWizard";

        

        return (
            <AccentDialog                
                fullscreen={this.props.wizardProps.large}
                minWidth={this.props.wizardProps.minWidth}
                noCancel={!this.props.wizardProps.allowCancel}
                onClose={this.props.wizardProps.onCancel}
                className={className}
                headerContent={t(title)}
                actionsContent={buttons}
                actionLayout="end"
            >
                    {children}
            </AccentDialog>
        );
    }
}



export class AccentWizardContainer extends React.Component {

    constructor(props) {

        super(props);


        this.ctrl = React.createRef();

        this.state = {
            pageIndex: 0,
            model: props.model,
            allowCancel: props.allowCancel,
            large: props.large,
            minWidth: props.minWidth,
            visible: false,
            props: props.props
        };


        this.canMoveforward = this.canMoveforward.bind(this);
        this.onOverridePage = this.onOverridePage.bind(this);
        this.nextClick = this.nextClick.bind(this);
        this.prevClick = this.prevClick.bind(this);
        this.cancelClicked = this.cancelClicked.bind(this);
        this.finishClick = this.finishClick.bind(this);

    }

    canMoveforward() {
        return formHelper.isWizardValid();
    }


    onOverridePage(index) {
        this.setState({ pageIndex: index });
    }

    nextClick() {

        if (this.canMoveforward()) {
            this.setState({
                pageIndex: this.state.pageIndex + 1,
                model: this.state.model,
            });
        }
    }
    prevClick() {
        this.setState({
            pageIndex: this.state.pageIndex - 1,
            model: this.state.model,
        });
    }

    cancelClicked() {

        const me = this;
        if (accentUtils.isNull(me.ctrl.current.onCancel)) {            
            me.props.onClose({ canceled: true });
        } else {


            me.ctrl.current.onCancel().then(function () {                
                me.props.onClose({ canceled: true });
            });


        }




    }

    finishClick() {

        var me = this;

        if (this.canMoveforward()) {



            this.ctrl.current.onFinish().then(function (res) {

                me.props.onClose({
                    ...res,
                    canceled: false                    
                })
                
            });


        }
    }

    

  

    render() {


        if (this.state.model == null) {
            return <div />;
        }

        var wizard = React.createElement(
            this.state.model,
            {
                ref: this.ctrl,
                onNext: this.nextClick,
                onPrev: this.prevClick,
                onFinish: this.finishClick,
                pageIndex: this.state.pageIndex,
                large: this.state.large,
                minWidth: this.state.minWidth,
                onCancel: this.cancelClicked,
                allowCancel: this.state.allowCancel,
                pageprops: this.state.props,
                onOverridePage: this.onOverridePage
            }
        );


        return (
            wizard
        );
    }
}



export class AccentWizardPage extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            visible: props.visible,
        };

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    show() {
        this.setState({ visible: true });
    }
    hide() {
        this.setState({ visible: false });
    }

    render() {

        if (!this.props.visible) {
            return (<div></div>);
        }

        return (
            <div>{this.props.children}</div>
        );
    }
}



export function showWizardModel(model, fullscreen) {
    return showDialog(<RTLWizard model={model} fullscreen={fullscreen} />);    
}

export function showWizard(wizard, allowCancel, props, large, minWidth) {
    return showDialog(<AccentWizardContainer model={wizard} allowCancel={allowCancel} large={large} props={props} minWidth={ minWidth } />)
}

