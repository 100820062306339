import { AccentButton } from "../controls/AccentButton";
import { JobPriceDetailsCtrl } from "../controls/jobs/JobPriceDetailsCtrl";
import { t } from "../services/HelperService";
import { AccentDialog } from "./AccentDialog";

export const JobPriceDetailsDlg = props => {

    

    return <AccentDialog
        fullscreen
        headerContent={t("application_strings.application.dialogs.priceDetailsTitle")}
        onClose={props.onClose}
        actionsContent={[<AccentButton key="1" tagName="ok" onClick={props.onClose}>{t("application_strings.application.buttons.ok")}</AccentButton>]}
    >
        <JobPriceDetailsCtrl jobID={props.jobID} lines={ props.lines} />

    </AccentDialog>;

};