import * as React from 'react'
import * as Moment from 'moment'
import { AccentButton } from '../controls/AccentButton';
import { useValidation } from '../controls/bound/AccentFormCtrl';
import { t } from '../services/HelperService';
import { AccentDialog } from './AccentDialog';
import { AccentSnoozeUnbound } from '../controls/unbound/AccentSnoozeUnbound';
import { AccentTextAreaUnbound } from '../controls/unbound/AccentTextAreaUnbound';
import accentUtils from '../services/helpers/AccentUtils';

var lastSnoozeDate = Moment(new Date()).add(1, 'days').startOf('day').toDate();



export const SnoozeDlg = React.memo(({ onClose, title, settingsKey, showNotes, notesRequired }) => {

    const id = `${settingsKey}SnoozeDlg`;
    
    const [snoozeState, setSnoozeState] = React.useState(lastSnoozeDate);
    const [notes, setNotes] = React.useState("");

    const validation = useValidation(`#${id}`);


    const onSnoozeChange = React.useCallback(e => {
        if (e != null) {
            setSnoozeState(new Date(e));
            lastSnoozeDate = new Date(e);
        } else {
            setSnoozeState(null);
        }

    }, [setSnoozeState]);


    const isValid = validation.isValid;




    return (<AccentDialog
        headerContent={t(title)}
        onClose={onClose}
        maxWidth={600}
        actionsContent={[
            <AccentButton key="2" tagName="closeSnoozeBtn" disabled={!isValid} onClick={e => onClose({ date: snoozeState, notes: accentUtils.isEmpty(notes) ? null : notes})}>{t("application_strings.application.buttons.ok")}</AccentButton>
        ]}
    >
        <div id={id}>
            <AccentSnoozeUnbound required defaultValue={snoozeState} onChange={onSnoozeChange} settingsKey={settingsKey ?? "general"} />
            {showNotes && <AccentTextAreaUnbound label="Notes" required={notesRequired} onChange={setNotes} rows={5} />}
        </div>
    </AccentDialog>);
});