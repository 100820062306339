import * as React from 'react';
import { accentUtils } from '../../services/HelperService';
import { AccentNumberUnbound } from '../unbound/AccentNumberUnbound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { onSizeChanged, fromInt, toDecimal, useSizeChanged, configEvents, configEventControlType } from './ProductConfiguratorShared';




export const ConfigSizeSingle = props => {


    const ctx = React.useContext(ConfiguratorContext);
    const orderLine = ctx.config.OrderLine;

    const controlKey = `ConfigSizeSingle_${props.width}_${ctx.productID}`;
    const changeTag = `ConfigSizeSingle_${ctx.productID}`;

    const sizeChanged = useSizeChanged(accentUtils.getEmptyGuid(), controlKey, changeTag);

    const fireChange = React.useMemo(() => onSizeChanged(), [ctx.productID]);


    const onChange = React.useCallback(e => {

        if (props.width) {
            orderLine.Width = toDecimal( e ?? 0);
        } else {
            orderLine.Height = toDecimal( e ?? 0);
        }

        fireChange(ctx, changeTag).then(() => {
            ctx.config?.Publish(configEvents.controlLostFocus, configEventControlType.singleSize);
        });

        

    }, [ctx, orderLine, props, fireChange, changeTag]);


    const onFocus = React.useCallback(e => {

        ctx.config?.Publish(configEvents.controlGotFocus, configEventControlType.singleSize);

    }, [ctx]);

    const notifyDirty = React.useCallback(e => {

        if (ctx.notifyDirty) {
            ctx.notifyDirty();
        }

    }, [ctx]);

    const value = props.width ? fromInt(orderLine.Width) : fromInt(orderLine.Height);

    const key = sizeChanged.changeID;
//    const key = (props.isMulti || (!accentUtils.isNull(sizeChanged.lastTag) && sizeChanged.lastTag !== changeTag)) ? sizeChanged.changeID : 0;


    return <div className="config-size-single d-flex">
        <div>
            <AccentNumberUnbound key={key} defaultValue={value} onBlur={onChange} onChange={notifyDirty} onFocus={onFocus} required disabled={props.readOnly || ctx.isReadOnly} min={0} invalid={accentUtils.isEmpty(value)} debounceMilliseconds={ 50} />
        </div>
    </div>;
};