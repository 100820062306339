import * as React from 'react';
import classNames from 'classnames';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { accentUtils, from, newGuid, showOK, t, waitForCondition } from '../../services/HelperService';
import { getMultiConfigConfig, getProductConfig, getProducts, loadProductModel } from '../../services/ProductService';
import { AccentSpinner } from '../AccentSpinner';
import { ConfiguratorContextProvider, getNewMultiConfigManager, reallocateProductIDsAndCreateLines} from './ConfigContextProvider';
import { saveOrderLineOptions, toGuid} from './ProductConfiguratorShared';
import { TableConfiguratorCtrl } from './TableConfiguratorCtrl';
import { VerticalConfiguratorCtrl } from './VerticalConfiguratorCtrl';

import './ProductConfigurator.css';
import AccentIcons from '../../icons/Icons';
import { deleteEntity, detachEntity, isNewEntity } from '../../services/DataService';
import { RTLGridRowDeleteAction } from '../AccentButton';
import InProgressOverlay from '../../app-framework/InProgressOverlay';



export const TabTitle = ({ orderLine, selected, multiConfigManager, allowIncludeExclude, onButtonClick }) => {


    const excluded = orderLine.__configExcluded;

    const multiCtx = multiConfigManager;

    const label = multiCtx.getProductLabelForLineID(orderLine.ID) ?? orderLine.ProductID;

    const required = label.endsWith("*");


    const onDeleteClick = e => {

        multiCtx.excludeLine(orderLine);

        if (onButtonClick) {
            onButtonClick({ line: orderLine, delete: true });
        }

    };

    const onAddClick = async e => {

        await multiCtx.includeLine(orderLine);


        if (onButtonClick) {
            onButtonClick({line: orderLine, add : true});
        }
    };

    


    const className = classNames("multi-config-tab", {
        "multi-config-tab-excluded": excluded,
        "multi-config-tab-no-icon": required,
        "multi-config-tab-selected": selected
    })


        

    const icon = !allowIncludeExclude ? null : (excluded ? <AccentIcons.New onClick={onAddClick} top={7} /> : !required ? < RTLGridRowDeleteAction inline top={7} onDelete={onDeleteClick} /> : null);

    return (
        <>            
            <span className={className}>{label} {icon}</span>
        </>
    );
};


const MultiProductConfiguratorCtrl = React.memo(props => {

    const multiCtx = props.manager;
    const lineRefs = React.useRef({});
    const [applyingChanges, setApplyingChanges] = React.useState(null);
    const [changingTabs, setChangingTabs] = React.useState(null);
   

    const [selectedTab, setSelectedTab] = React.useState(props.defaultIndex > 0 ? props.defaultIndex : 0);


    const onValueChanged = React.useCallback(e => {

        if (props.onChange) props.onChange(e);

    }, [props]);

    const onSizeChanged = React.useCallback(e => {


        if (props.onChange) props.onChange(e);

    }, [props]);

    const onTabChange = async (e) => {

        
        setChangingTabs(true);
        setSelectedTab(e.selected);


        setTimeout(async () => {


            const hasChanges = multiCtx.hasChanges();

            if (hasChanges) {

                setApplyingChanges(true);

                setTimeout(async () => {

                    const orderLine = multiCtx.orderLines[e.selected];

                    await multiCtx.applyingChanges(orderLine.ID);

                    setTimeout(() => {
                        setApplyingChanges(false);
                        setChangingTabs(false);
                    }, 1000);

                }, 0);

            } else {
                setApplyingChanges(false);
                setChangingTabs(false);
            }

        }, 1000);


        

    };

    const isInProgress = React.useCallback(() => {
        if (accentUtils.isNull(lineRefs.current)) return false;
        return from(Object.keys(lineRefs.current)).any(k => lineRefs.current[k]?.isInProgress());
    }, [lineRefs]);


    React.useImperativeHandle(
        props.methods,
        () => ({
            save: async () => {
                await multiCtx.save();
            },
            isInProgress: () => {
                return isInProgress();
            },
            getLines: () =>{
                return multiCtx.orderLines;
            },
            getLineIDs: () => {
                return multiCtx.orderLines.map(x=>x.ID);
            }
        }),
        [multiCtx, isInProgress]
    );


    return <InProgressOverlay text="Applying changes please wait" block={changingTabs} showText={applyingChanges}>
        <TabStrip className="multi-config-tab-container" selected={selectedTab} onSelect={onTabChange} keepTabsMounted>
            {multiCtx.orderLines.map((orderLine, i) => {      

                const exluded = orderLine.__configExcluded;

                return <TabStripTab key={orderLine.ID} title={<TabTitle key={orderLine.ID} orderLine={orderLine} selected={selectedTab === i} multiConfigManager={multiCtx} allowIncludeExclude={props.allowIncludeExclude} />}>
                    {exluded && <div>Product not included</div> }
                    {!exluded && <ProductConfigurator {...multiCtx.configProps} productID={orderLine.ProductID} orderLine={orderLine} methods={r => lineRefs.current[orderLine.ID] = r} onValueChanged={onValueChanged} onSizeChanged={onSizeChanged} multiConfigManager={multiCtx} />}
                </TabStripTab>;
            })}

        </TabStrip>
    </InProgressOverlay>;
});


export const MultiProductConfigurator = React.memo(props => {


    const [refreshKey, setRefreskKey] = React.useState(1);
    const primaryLine = props.orderLine;
    const provider = React.useRef(null);
    const [multiConfig, setMultiConfig] = React.useState(null);
    const isMulti = props.product?.IsMultiConfig ?? false;
    const [orderLines, setOrderLines] = React.useState(null);

    const notifyDirty = props.notifyDirty;
    const allowExcludeExistingLine = props.allowExcludeExistingLine;
    const onExcludeExistingLineAttempt = props.onExcludeExistingLineAttempt;

    const forceCreateMissing = props.forceCreateMissing;

    const onGetMultiConfig = React.useCallback(() => {

        return multiConfig;

    }, [multiConfig]);


    const onIncludeLine = React.useCallback(line => {

        line.__configExcluded = false;

        setRefreskKey(k => k + 1);

        notifyDirty();

    }, [setRefreskKey, notifyDirty]);

    const onExcludeLine = React.useCallback(line => {

        if (!allowExcludeExistingLine && line.ID > 0) {
            onExcludeExistingLineAttempt(line);
            return;
        }


        line.__configExcluded = true;

        setRefreskKey(k => k + 1);

        notifyDirty();

    }, [setRefreskKey, notifyDirty, allowExcludeExistingLine, onExcludeExistingLineAttempt]);

    provider.current = React.useMemo(() => !isMulti ? provider.current :  (accentUtils.isNull(multiConfig) || accentUtils.isEmpty(orderLines) ? null : provider.current ? provider.current : getNewMultiConfigManager({
        ...props,
        getMultiConfig: onGetMultiConfig,
        primaryLine: primaryLine,
        orderLines: orderLines,
        includeLine: onIncludeLine,
        excludeLine: onExcludeLine
    })), [orderLines, multiConfig, provider, isMulti]);


    React.useImperativeHandle(
        props.methods,
        () => ({
            save: async () => {
                await provider.current.save();

                orderLines.forEach(line => {

                    if (line.__configExcluded) {

                        if (isNewEntity(line)) {
                            detachEntity(line);
                        } else {
                            deleteEntity(line);
                        }

                        
                        
                    }

                });

            },
            isInProgress: () => {
                return provider.current.isInProgress();
            },
            getLines: () => {
                return orderLines;
            },
            getLineIDs: () => {
                return orderLines.map(l=> l.ID);
            }
        }),
        [orderLines, provider]
    );





   


    React.useEffect(() => {

        if (isMulti) {

            getMultiConfigConfig(props.productID).then(c => {
                    if (c) {
                        setMultiConfig(c.GetMemberProducts());

                    }
                });
        } else {
            setMultiConfig(null);
        }

    }, [isMulti, props.productID]);


    React.useEffect(() => {

        if (!accentUtils.isNull(provider.current)) {

            if (!accentUtils.isNull(provider.current.setFilter)) {
                provider.current.setFilter(props.filter);
                setRefreskKey(k => k + 1);
            }
            
        }

    }, [props.filter])

    React.useEffect(() => {

        const init = async () => {


            let lines = [];


            lines.push(props.orderLine);

            const lineHasProduct = !accentUtils.isNull(props.orderLine.ProductID);
            const memberProducts = (props.product?.MemberProducts ?? []);
            const firstMemberProduct = from(memberProducts).firstOrDefault();
            const multiGroupID = isMulti ? (props.orderLine.MultiGroupID ?? newGuid()) : null;

            const isNew = props.orderLine.ID <= 0;


            if (multiGroupID !== props.orderLine.MultiGroupID) {
                props.orderLine.MultiGroupID = multiGroupID;
            }


            if (!lineHasProduct) {
                props.orderLine.MultiConfigID = firstMemberProduct.ID;
                props.orderLine.ProductID = firstMemberProduct.MemberProductID;
            }



            if (!accentUtils.isEmpty(props.orderLine.MultiGroupID)) {

                const otherLines = await props.queryMultiLines(props.orderLine);

                lines = [...lines, ...otherLines];
            }


            lines = await reallocateProductIDsAndCreateLines(props.orderLine, lines, memberProducts, props.onNewMultiLine, forceCreateMissing || props.orderLine.ID <= 0);

            setOrderLines(lines);


        }

        if (isMulti) {
            init();
        } else {
            setOrderLines([props.orderLine]);
        }

    }, [props.productID, isMulti, forceCreateMissing]);


    if (!isMulti) return <ProductConfigurator {...props} methods={provider} />;

    if (accentUtils.isNull(multiConfig) || accentUtils.isEmpty(orderLines)) return <AccentSpinner />;

    const defaultIndex = orderLines.indexOf(props.orderLine);

    return <MultiProductConfiguratorCtrl defaultIndex={defaultIndex} manager={provider.current} refreshKey={refreshKey} onChange={props.onChange} allowIncludeExclude={props.allowIncludeExclude ?? true} />;    

});

export const ProductConfigurator = React.memo(props => {

    const provider = React.useRef(null);
    

    React.useImperativeHandle(
        props.methods,
        () => ({
            save: async () => {
                await provider.current.save();
            },
            isInProgress: () => {
                return provider.current?.isInProgress()??false;
            },
            getContext: () => {
                return provider.current;
            }
        }),
        [provider]
    );


    const onSaveOLOs = React.useCallback(e => {
        saveOrderLineOptions(props.orderLine, e);
    }, [props]);


    if (accentUtils.isEmpty(props.orderLine.ProductID)) return <div></div>;


    return <ConfiguratorContextProvider
        methods={provider}
        productID={props.productID}
        productVersionID={props.productVersionID}
        orderLine={props.orderLine}
        orderLineOptions={JSON.parse(props.orderLine.OrderLineOptionsData)}
        businessUnitID={props.businessUnitID}
        mpResellerKeys={props.mpResellerKeys}
        isQuote={props.isQuote}
        ignoreSizes={props.ignoreSizes}
        isReadOnly={props.isReadOnly}
        onValueChanged={props.onValueChanged}
        onSizeChanged={props.onSizeChanged}
        onSave={onSaveOLOs}        
        onLoaded={props.onLoaded}
        filter={props.filter}
        notifyDirty={props.notifyDirty}
        onProductSourceChanged={props.onProductSourceChanged}
        allowProductSourceScript={props.allowProductSourceScript}
        multiConfigManager={props.multiConfigManager }
    >
        <div>
            <VerticalConfiguratorCtrl                
                hideSize={props.hideSize}
                hideExtras={props.hideExtras}
            />
        </div>
    </ConfiguratorContextProvider>;


});


export const TableConfigurator = React.memo(props => {

    const ref = React.useRef();
    const [loading, setLoading] = React.useState(true);
    const [config, setConfig] = React.useState(null);

    React.useImperativeHandle(
        props.methods,
        () => ({
            save: async () => {
                if (accentUtils.isNull(ref.current)) return;

                await ref.current.save();
            },
            cleanUp: () => {
                if (accentUtils.isNull(ref.current)) return;

                ref.current.cleanUp();
            },
            notifyQtyChanged: (lineID) => {
                ref.current?.notifyQtyChanged(lineID);
            },
            notifyLocationChanged: (lineID) => {
                ref.current?.notifyLocationChanged(lineID);
            },
        }),
        [ref]
    );


    React.useEffect(() => {
        loadProductModel(props.productID, props.productVersionID).then(c => {
            setConfig(c);
            setLoading(false);
        });
    }, []);

    

   

    if (loading) return <AccentSpinner />;

    return <TableConfiguratorCtrl
        methods={ref}
        productID={props.productID}
        productVersionID={ props.productVersionID}
        onChange={props.onChange}
        notifyDirty={props.notifyDirty}
        onLoaded={props.onLoaded}
        lines={props.lines}
        isQuote={props.isQuote}
        ignoreSizes={props.ignoreSizes}
        isReadOnly={props.isReadOnly}
        isLineReadOnly={props.isLineReadOnly}
        businessUnitID={props.businessUnitID}
        mpResellerKeys={props.mpResellerKeys}
        preGroupHeaders={props.preGroupHeaders}
        postGroupHeaders={props.postGroupHeaders}
        preOptionHeaders={props.preOptionHeaders}
        postOptionHeaders={props.postOptionHeaders}
        getPreColumns={props.getPreColumns}
        getPostColumns={props.getPostColumns}
        config={config}
        configFilter={props.configFilter}
        getMultiConfigManagerAsync={props.getMultiConfigManagerAsync}
        onProductSourceChanged={props.onProductSourceChanged}
        getAllowSourceScript={props.getAllowSourceScript}
    />;


});
