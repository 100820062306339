import React from 'react'
import { accentUtils } from '../../services/HelperService';


export const JobLineItemRender = (li, itemProps) => {

    const displayOptions = [
        itemProps.dataItem.DisplayOption1,
        itemProps.dataItem.DisplayOption2,
        itemProps.dataItem.DisplayOption3,
        itemProps.dataItem.DisplayOption4,
        itemProps.dataItem.DisplayOption5
    ].filter(o => !accentUtils.isEmpty(o));

    const rest = displayOptions.length == 0 ? "" : `(${displayOptions.join(",")})`;

    const itemChildren = <span>{itemProps.dataItem.JobLineNo} | {itemProps.dataItem.Qty} | {itemProps.dataItem.LocationOther} | {itemProps.dataItem.ProductName} {rest}</span>;

    return React.cloneElement(li, li.props, itemChildren);
};
