//maps
import React from 'react'

import { Marker } from '@react-google-maps/api'
import { accentUtils, t } from '../services/HelperService'
import { AccentMap } from './AccentMap'
import { AccentButton } from './AccentButton'
import { AccentDialog } from '../dialogs/AccentDialog'


export class AccentAddressLocator extends React.Component {

    constructor(props) {
        super(props);


        this.changed = false;

        this.markerRef = React.createRef();
        this.close = this.close.bind(this);
        this.onPositionChanged = this.onPositionChanged.bind(this);
    }


    onPositionChanged() {
        this.changed = true;
    }

    close() {

        var pos = this.markerRef.current.marker.position;

        var result = null;

        if (!accentUtils.isNull(pos)) {
            result = {
                lat: pos.lat(),
                lng: pos.lng(),
                changed: this.changed
            };
        }
        this.props.onClose(result);
    }


    render() {

        var marker = (
            <Marker
                ref={this.markerRef}
                position={this.props.location}
                //onClick={this.onToggleOpen}
                //label={ml}
                //defaultIcon={icon}
                draggable={this.props.draggable}
                onPositionChanged={this.onPositionChanged}
            //onMouseOver={this.onMouseOver}
            //animation={this.state.animation}
            />
        );
        

        return (<AccentDialog
            fullscreen
            headerContent={t("application_strings.application.general.addressLocatorTitle")}
            onClose={this.props.onClose}
            actionsContent={[
                <AccentButton tagName="addressLocatorOKBtn" onClick={this.close}>{t("application_strings.application.buttons.close")}</AccentButton>
            ]}
        >
                <AccentMap
                    defaultLocation={this.props.location}
                    containerElement={<div style={{ height: "100%" }} />}
                    mapElement={<div style={{ height: "100%" }} />}
                    markers={[marker]}
                    onDropAppointment={function () { }}
                    zoom={18}
                />
        </AccentDialog>);

    }
}
