import * as React from 'react';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { AccentDialog } from '../../dialogs/AccentDialog';
import AccentIcons from '../../icons/Icons';
import { queryArray } from '../../services/DataService';
import { accentUtils, showDialog } from '../../services/HelperService';
import { AccentButton } from '../AccentButton';
import { RTLGridCtrl } from '../grids/RTLGridCtrl';
import { AccentCheckUnbound } from '../unbound/AccentCheckUnbound';
import { AccentNumberUnbound } from '../unbound/AccentNumberUnbound';
import { AccentTextUnbound } from '../unbound/AccentTextUnBound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { fabric_GetAllowContinuous, fabric_GetAllowRailroading, fabric_GetCustomFabric, fabric_GetDropAdjustment, fabric_GetIgnoreFabricWidth, fabric_GetIgnoreRailroadedFabricWidth, fabric_GetRailroadingOption, fabric_GetWidthAdjustment, fabric_OrderLineOptionSet, getOptionModel, getProductOptionValue, isReadOnly, setOptionValueChanged, toGuid, useActionType, useChanged } from './ProductConfiguratorShared';

const fabricQuery = queryArray();


export const FabricDetailsActionBtn = props => {

    const actionType = useActionType(props.optionID);

    const ctx = React.useContext(ConfiguratorContext);
    const optionModel = getOptionModel(ctx, props.optionID);


    const changed = useChanged(props.optionID, "FabricDetailsActionBtn", true);

    const value = getProductOptionValue(optionModel);

    const readOnly = isReadOnly(optionModel);

    const onClick = React.useCallback(e => {

        if (accentUtils.isNull(value)) return;


        const isCustom = value?.CustomValue ?? false;

        showDialog(<FabricDetailsDlg readOnly={readOnly || !isCustom} ctx={ctx} optionModel={optionModel} productOptionValue={value} />);        

    }, [ctx, optionModel, readOnly, value]);

    if (!actionType.isFabric) {
        return null;
    }


  

    const className = classNames("config-value-action",
        {
            "readonly": accentUtils.isNull(value)
        }
    );

    return <div className={ className}>
        <AccentIcons.Curtain outlined onClick={ onClick} noPadding/>
    </div>;

};


export const SelectFabricDlg = props => {

    const grid = React.useRef();
    const [fabrics, setFabrics] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);

    const onRowClick = React.useCallback(e => {
        setSelectedRow(e.row);
    }, [setSelectedRow]);


    const onClose = React.useCallback(() => {

        props.onClose({ selected: selectedRow });

    }, [props, selectedRow]);

    const getQuery = React.useCallback(s => {
        return fabricQuery.getAllAsQueryable(s, fabrics);
    }, [fabrics]);

    const processColumn = col => {
        if (col.field === "Supplier") {
            col.filterQuery = () => Promise.resolve(fabrics);
        } else if (col.field === "Fabric") {
            col.filterQuery = () => Promise.resolve(fabrics);
        } else if (col.field === "Color") {
            col.filterQuery = () => Promise.resolve(fabrics);
        };

        return col;
    }

    React.useEffect(() => {

        GetQuery(props.ctx.config, props.optionModel, false).then(res => {
            setFabrics(
                res.select(f => ({ ID: `${f.Value.ID}_${f.Supplier?.ID?.toString()}`, Fabric: f.LinkedProduct?.Fabric, Supplier: f.Supplier?.Name, Color: f.LinkedProduct?.FabricColour, Info: f })).toArray()
            );
        });

    }, [props]);

    const columns = [
        {
            "fieldName": "Supplier",
            "caption": "Supplier"
        },
        {
            "fieldName": "Fabric",
            "caption": "Fabric"
        },
        {
            "fieldName": "Color",
            "caption": "Colour"
        },
    ];

    const btns = accentUtils.isNull(selectedRow) ? [] : [<AccentButton key="1" tagName="fabCloseBtn" onClick={onClose}>{`${selectedRow.Supplier} | ${selectedRow.Fabric} | ${selectedRow.Color}`}</AccentButton>];

    return <AccentDialog
        fullscreen
        onClose={props.onClose}
        headerContent={"Select Fabric"}
        actionsContent={btns}
    >
        <RTLGridCtrl
            gridID="14BB2E2C-0A0A-446C-A395-A202DFB78DA8"
            methods={grid}
            showToolbar
            className="config-fabric-grid"
            height="screen"
            query={getQuery}
            columns={columns}
            processColumn={processColumn}
            clearSelection
            allowSearch
            onRowClick={onRowClick}
        />
    </AccentDialog>

};



export const FabricDetailsDlg = props =>{


    const ctx = props.ctx;
    const optionModel = props.optionModel;
    const pov = props.productOptionValue;

    const customfabric = React.useRef(window.SoftFurnishings.FabricHelper.GetCustomFabric(optionModel, pov));

    const [fabric, setFabric] = React.useState(customfabric.current.Fabric);
    const [color, setColor] = React.useState(customfabric.current.Colour);

    const [size, setSize] = React.useState(customfabric.current.Continuous ? customfabric.current.Drop : customfabric.current.Width);
    const [continuous, setContinuous] = React.useState(customfabric.current.Continuous);
    const [verticalPatternRepeat, setVerticalPatternRepeat] = React.useState(customfabric.current.VPatternRepeat);
    const [horizontalPatternRepeat, setHorizontalPatternRepeat] = React.useState(customfabric.current.HPatternRepeat);
    const [verticalPatternRepeatAllowance, setVerticalPatternRepeatAllowance] = React.useState(customfabric.current.VPatternRepeatAllowance);
    const [horizontalPatternRepeatAllowance, setHorizontalPatternRepeatAllowance] = React.useState(customfabric.current.HPatternRepeatAllowance);


    const onContinuousChanged = React.useCallback(e => {
        setContinuous(e.checked);
    }, [setContinuous]);

    const onVerticalPatternRepeatChanged = React.useCallback(e => {
        setVerticalPatternRepeat(e.checked);
    }, [setVerticalPatternRepeat]);

    const onHorizontalPatternRepeatChanged = React.useCallback(e => {
        setHorizontalPatternRepeat(e.checked);
    }, [setHorizontalPatternRepeat]);


    const onClose = React.useCallback(() => {

        if (props.readOnly) {
            props.onClose({ canceled: true });
        } else {

            const finalCustomFabric = new window.InsyteConfig.CustomFabric();
            const supplier = ctx.config.Data.GetSupplier(customfabric.current.Supplier);
            finalCustomFabric.Supplier = customfabric.current.Supplier;
            finalCustomFabric.SupplierName = supplier?.Name;
            finalCustomFabric.SupplierCode = supplier?.Code;
            finalCustomFabric.Fabric = fabric;
            finalCustomFabric.Continuous = continuous;
            finalCustomFabric.Colour = color;
            finalCustomFabric.VPatternRepeat = verticalPatternRepeat;
            finalCustomFabric.HPatternRepeat = horizontalPatternRepeat;
            finalCustomFabric.VPatternRepeatAllowance = verticalPatternRepeatAllowance;
            finalCustomFabric.HPatternRepeatAllowance = horizontalPatternRepeatAllowance;
            finalCustomFabric.Width = !continuous ? size : 0;
            finalCustomFabric.Drop = continuous ? size :0;
            finalCustomFabric.UserSpecified = true;


            fabric_OrderLineOptionSet(optionModel, pov.ID, customfabric.current.Supplier);
            optionModel.UpdateFrom(pov.ID, finalCustomFabric);

            setOptionValueChanged(ctx, optionModel);

            props.onClose({ customFabric: finalCustomFabric, productOptionValue : pov  });
        }
        

    }, [ctx, props, optionModel, pov, customfabric, fabric, color, verticalPatternRepeat, horizontalPatternRepeat, verticalPatternRepeatAllowance, horizontalPatternRepeatAllowance, size]);

    let sizeLabel = "Width (mm)";


    if (continuous) {
        sizeLabel = "Drop (mm)";
    }


    var vAllowanceDisabled = props.readOnly || !verticalPatternRepeat;
    var hAllowanceDisabled = props.readOnly || !horizontalPatternRepeat;


    const btns = props.readOnly ? [] : [<AccentButton key="fabdetailsCloseBtn" tagName="fabdetailsCloseBtn" onClick={onClose}>Close</AccentButton>];

    return <AccentDialog
        onClose={props.onClose}
        headerContent={"Fabric Details"}
        actionsContent={btns}
    >
        

        <Row className="config-fabric-details-dlg">
            <Col md="12">
                <Row>
                    <Col md={3}>
                        <label>Fabric</label>
                    </Col>
                    <Col md={9}>
                        <AccentTextUnbound disabled={props.readOnly} defaultValue={fabric} onChange={ setFabric} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <label>Colour</label>
                    </Col>
                    <Col md={9}>
                        <AccentTextUnbound disabled={props.readOnly} defaultValue={color} onChange={setColor} />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={3}>
                        <label>Continuous</label>
                    </Col>
                    <Col md={1}>
                        <AccentCheckUnbound disabled={props.readOnly} defaultValue={continuous} onChange={onContinuousChanged} />
                    </Col>
                    <Col md={3}>
                        <label>{sizeLabel}</label>
                    </Col>
                    <Col md={5}>
                        <AccentNumberUnbound int disabled={props.readOnly} defaultValue={size} onChange={ setSize} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <label>Vertical Pattern Repeat</label>
                    </Col>
                    <Col md={1}>
                        <AccentCheckUnbound disabled={props.readOnly} defaultValue={verticalPatternRepeat} onChange={ onVerticalPatternRepeatChanged} />
                    </Col>
                    <Col md={3}>
                        <label>Allowance</label>
                    </Col>
                    <Col md={5}>
                        <AccentNumberUnbound int disabled={vAllowanceDisabled} defaultValue={verticalPatternRepeatAllowance} onChange={ setVerticalPatternRepeatAllowance} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <label>Horizontal Pattern Repeat</label>
                    </Col>
                    <Col md={1}>
                        <AccentCheckUnbound disabled={props.readOnly} defaultValue={horizontalPatternRepeat} onChange={ onHorizontalPatternRepeatChanged} />
                    </Col>
                    <Col md={3}>
                        <label>Allowance</label>
                    </Col>
                    <Col md={5}>
                        <AccentNumberUnbound int disabled={hAllowanceDisabled} defaultValue={horizontalPatternRepeatAllowance} onChange={setHorizontalPatternRepeatAllowance} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </AccentDialog>

};



function GetQuery(config, optionModel, allowCurrentValue) {

    return new Promise(p => {

        const railroadingValue =
            config.GetOptionValue$1(fabric_GetRailroadingOption(optionModel));

        const railRoading = window.SoftFurnishings.FabricQuery.GetBoolValueFromOptionValue(railroadingValue);

        p(
            window.SoftFurnishings.FabricQuery.QueryFabrics(
                optionModel,
                fabric_GetIgnoreFabricWidth(optionModel),
                allowCurrentValue,
                fabric_GetIgnoreRailroadedFabricWidth(optionModel),
                railRoading,
                fabric_GetDropAdjustment(optionModel),
                fabric_GetWidthAdjustment(optionModel),
                fabric_GetAllowContinuous(optionModel),
                fabric_GetAllowRailroading(optionModel)
            ));




    });


}

