import * as Moment from 'moment'
import { Badge } from 'reactstrap'

export const InstallTimeBadge = props => {

    const hrs = Moment.utc(Moment.duration(props.EstInstallTime, "minutes").asMilliseconds()).format("H");
    const mins = Moment.utc(Moment.duration(props.EstInstallTime, "minutes").asMilliseconds()).format("mm");

    const instTime = `${hrs} hours and ${mins} mins`;


    return <Badge>{instTime}</Badge>;

};
