import React from 'react'
import classNames from 'classnames'
import { MultiSelect } from '@progress/kendo-react-dropdowns'
import { accentUtils, t, formHelper } from '../services/HelperService'
import { AccentLabel } from './AccentLabel';

export class AccentTokenCtrl extends React.Component {

    constructor(props) {
        super(props);

        this.ctrl = React.createRef();

        this.state = { value: this.props.value };

        this.isCustom = this.isCustom.bind(this);
        this.addKey = this.addKey.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.tagRender = this.tagRender.bind(this);
        this.setValue = this.setValue.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.data = this.props.data;

    }

    isCustom(item) { return item.value === undefined; }
    addKey(item) { item.value = new Date().getTime(); }


    onBlur() {
     
        if (!accentUtils.isEmpty(this.ctrl.current.state.text)) {
            
            var val = Array.isArray(this.ctrl.current.value) ? this.ctrl.current.value : [this.ctrl.current.value]

            val.push({ text: this.ctrl.current.state.text });

            this.ctrl.current.state.text = "";

            this.handleChange({ target: {value : val}});

        }

        
    }
    

    handleChange(event){
        const values = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
        const lastItem = values[values.length - 1];

        if (lastItem && this.isCustom(lastItem)) {
            values.pop();
            const sameItem = values.find(value => value.text === lastItem.text);
            if (sameItem === undefined) {
                this.addKey(lastItem);

                if (this.props.onValidateNewItem) {
                    this.props.onValidateNewItem(lastItem);
                }
                values.push(lastItem);
            }
        }


        if (!accentUtils.isNull(this.props.onChange)) {
            this.props.onChange({value: values});
        }

        this.setState({
            value: values
        });
    }

    onOpen(e) {

        if (this.props.noPopup) {
            

        }
    }

    setValue(value) {
        this.state.value = value;
    }

    tagRender(tagData, li) {

        var readOnly = false;

        if (!accentUtils.isNull(tagData.data[0].readOnly)) {
            readOnly = tagData.data[0].readOnly;
        }

        var children = li.props.children;

        if (readOnly && children) {
            children = [li.props.children[0]];
        }

        var props = {
            ...li.props
        }

        if (!accentUtils.isNull(tagData.data[0].error) && tagData.data[0].error) {

            if (accentUtils.isNull(props.className)) {
                props.className = "accentErrorToken";
            } else {
                props.className = props.className +  " accentErrorToken";
            }

            
        }

        


        return React.cloneElement(li, props, children);
    }

    render() {

        const isValid = !this.props.required || !accentUtils.isEmpty(this.state.value);
        const className = classNames("accentComboUnbound", {
            "invalid": !isValid,
            "noclearall": this.props.noClearAll
        });
    



        var popupWidth = undefined;

        if (this.props.noPopup) {
            popupWidth = 0;
        }

        const control = <MultiSelect
            className={className}
            placeholder={ this.props.placeholder}
            ref={this.ctrl}
            disabled={this.props.readOnly}
            data={this.data}
            onChange={this.handleChange}
            value={this.state.value}
            textField="text"
            dataItemKey="value"
            allowCustom={this.props.noCustom ? false : true}
            tagRender={this.tagRender}
            onOpen={this.onOpen}
            onBlur={this.onBlur}
            popupSettings={{
                width: popupWidth
            }}            
        />;

        if (accentUtils.isNullOrEmpty(this.props.label)) {
            return control;
        }

        return <AccentLabel text={ this.props.label}>
            { control}
        </AccentLabel>;
    }


}