import { Alert } from "bootstrap";
import * as React from "react";
import { MultiSelectTree } from "../controls/jobs/JobLineTreeMultiSelectCtrl";
import { accentUtils, from, t } from "../services/HelperService";
import { getAccess } from "../services/UserService";
import { AccentWizard, AccentWizardPage } from "./RTLWizard";




export default class MoveToDispatchedWizard extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            lines: from(this.props.pageprops.model.HasInstallAppointment).select(v => { return { ...v, selected: false, warnHasInstallAppointment: true } })
                .concat(from(this.props.pageprops.model.Dispatched).select(v => { return { ...v, selected: false, warnDispatched: true } }))
                .concat(from(this.props.pageprops.model.ReadyForDispatch).select(v => { return { ...v, selected: true, warnReadyForDispatch: true } }))
                .concat(from(this.props.pageprops.model.RequireCheckmeasure).select(v => { return { ...v, selected: false, warnRequireCheckmeasure: true } }))
                .concat(from(this.props.pageprops.model.Restocked).select(v => { return { ...v, selected: false, errorRestocked: true } }))
                .concat(from(this.props.pageprops.model.RequireInstall).select(v => { return { ...v, selected: false, warnRequireInstall: true } }))
                .concat(from(this.props.pageprops.model.RequireSupply).select(v => { return { ...v, selected: false, warnRequireSupply: true } }))
                .concat(from(this.props.pageprops.model.JobInEdit).select(v => { return { ...v, selected: false, warnJobInEdit: true } }))
                .toArray()
        };


        this.onFinish = this.onFinish.bind(this);
        this.getDetails = this.getDetails.bind(this);
        this.onItemChanged = this.onItemChanged.bind(this);
        this.onValidateRow = this.onValidateRow.bind(this);

    }

    onItemChanged(e) {

        this.setState({
            lines: this.state.lines.map(l => {
                return {
                    ...l,
                    selected: e.includes(l.ID) && !l.warnDispatched && !l.warnJobInEdit
                }
            })
        });


    }

    onValidateRow(e) {

    }

    onFinish() {


        const selectedItems = this.state.lines.filter(l => l.selected).map(l => l.ID).reduce((a, v) => ({ ...a, [v]: v }), {});

        const result = this.props.pageprops.model;

        result.HasInstallAppointment.map(l => l.Selected = !accentUtils.isNull(selectedItems[l.ID]));
        result.Dispatched.map(l => l.Selected = !accentUtils.isNull(selectedItems[l.ID]));
        result.ReadyForDispatch.map(l => l.Selected = !accentUtils.isNull(selectedItems[l.ID]));
        result.RequireCheckmeasure.map(l => l.Selected = !accentUtils.isNull(selectedItems[l.ID]));
        result.RequireInstall.map(l => l.Selected = !accentUtils.isNull(selectedItems[l.ID]));
        result.RequireSupply.map(l => l.Selected = !accentUtils.isNull(selectedItems[l.ID]));
        result.JobInEdit.map(l => l.Selected = false);

        return Promise.resolve(result);
    }




    getDetails() {


        const info = this.props.pageprops.model;




        if (this.state.lines.length == 0) {
            return <div>
                No lines to schedule
            </div>;
        }

        var qty = 0;

        const data = this.state.lines.map(l => {

            const v = [];


            if (l.selected) {
                qty += l.Qty;
            }

            if (l.warnJobInEdit) {
                v.push(t("application_strings.wizards.moveToDispatched.jobInEdit"));
            }
            if (l.warnHasInstallAppointment) {
                v.push(t("application_strings.wizards.moveToDispatched.installs"));
            }

            if (l.warnDispatched) {
                v.push(t("application_strings.wizards.moveToDispatched.dispatched"));
            }

            if (l.warnRequireCheckmeasure) {
                v.push(t("application_strings.wizards.moveToDispatched.requireCMs"));
            }

            if (l.errorRestocked) {
                v.push(t("application_strings.wizards.moveToDispatched.restocked"));
            }

            if (l.warnRequireInstall) {
                v.push(t("application_strings.wizards.moveToDispatched.requireInstall"));
            }
            if (l.warnRequireSupply) {
                v.push(t("application_strings.wizards.moveToDispatched.requireSupply"));
            }


            l.validation = v;

            return l;
        });

        return <div>
            <MultiSelectTree
                items={this.state.lines}
                minHeight={510}
                validationField="validation"
                onChange={this.onItemChanged} />
            <div className="float-right"><label>{t("application_strings.wizards.moveToDispatched.selectedQty")}</label>:<span style={{ laddingLeft: "20px" }}>{qty}</span></div>
        </div>;



    }


    render() {

        var me = this;

        const content = getAccess().features().FeatureAllowOverrideDispatch() ? this.getDetails() : <Alert color="danger">{t("application_strings.wizards.moveToDispatched.noAccess")}</Alert>;
        const hasSelected = from(this.state.lines).any(v => v.selected && !v.errorRestocked);
        const hasInvalidSelection = from(this.state.lines).any(v => v.selected && v.errorRestocked);


        const allowNext = hasSelected && !hasInvalidSelection;

        return (
            <AccentWizard wizardProps={this.props} allowNext={allowNext}>
                <AccentWizardPage title="application_strings.wizards.moveToDispatched.page01Title" >
                    {content}
                </AccentWizardPage>
            </AccentWizard>
        );
    }

}
