import * as React from 'react'
import { AccentButton } from '../controls/AccentButton';
import { usePosition } from '../controls/AccentDisplay';
import { AccentSpinner } from '../controls/AccentSpinner';
import { RTLDocumentGroupPreview } from '../controls/documents/DocumentPreview';
import SketchPad from '../controls/sketch/SketchPad';
import { update, useQueryAllNoTracking } from '../services/DataService';
import { accentUtils, t } from '../services/HelperService';
import { AccentDialog } from './AccentDialog';




export const SketchDlg = React.memo(props => {

    const args = React.useRef({ id: props.documentID });
    const sketchCtrl = React.useRef();

    const query = useQueryAllNoTracking("Sketch", args.current);

    const close = React.useCallback(async (e) => {


        const sketch = await sketchCtrl.current.getSketch();


        await update("SaveSketch", {
            DocumentID: props.documentID,
            SketchElements: JSON.stringify(sketch.elements),
            Image: sketch.image,
            Files: JSON.stringify(sketch.files),
            //Name: me.entity.SketchName,

        });

        props.onClose({
            
        });
    }, [props, query, sketchCtrl]);



    let contents = null;

    if (query.loading) {
        contents = <AccentSpinner />;
    } else {        

        const defaultValue = { elements: JSON.parse(query.data[0].Sketch?.Elements ?? "[]"), files: JSON.parse(query.data[0].Sketch?.Files ?? "{}")};

        contents = <SketchPad methods={sketchCtrl} widthAdjustment={30} heightAdjustment={0} defaultValue={ defaultValue } />;
    }


    return (
        <AccentDialog
            fullscreen
            onClose={props.onClose}
            headerContent={t("application_strings.application.dialogs.sketch.title")}
            actionsContent={[
                <AccentButton key="2" tagName="sketchDlgOKBtn" onClick={close}>{t("application_strings.application.buttons.save")}</AccentButton>
            ]}
        >
            {contents}
        </AccentDialog>
    );


});
