//insytedata

import * as React from 'react'
import $ from 'jquery'
import { suspend } from 'suspend-react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Row, Col, Badge } from 'reactstrap';
import { GridColumn, GridNoRecords, Grid, getSelectedState } from '@progress/kendo-react-grid'
import { IntlProvider } from "@progress/kendo-react-intl";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Menu} from '@progress/kendo-react-layout';
import { Popup } from "@progress/kendo-react-popup";
import { accentUtils, t, from, accentUserLang, formHelper, newGuid, showDialog } from '../../services/HelperService'
import { AccentSpinner, LoadingPanel } from './../AccentSpinner'
import { RTLGridColumnChooser } from './RTLGridColumnChooser'
import { RTLGridToolbarCtrl } from './RTLGridToolbar'
import { useGridData, gridSettingsManager, getGridFilter, getGridSort, getGridSearch, getGridDetails, getGridColumnsWidths, getGridHiddenColumns, getAdhocFilter, getAdvancedFilter, getQuickFilters, getGridExpandedItems, getGridSelectedItems, processDataForExport, getLastPage } from './GridDataManager';
import GridErrorBoundary from './GridErrorBoundary';
import { ColumnMenu, ColumnMenuCheckboxFilter } from './RTLGridColumnMenu';
import { RTLGridHeaderCellRender, RTLGridCellRender, RTLGridRowRender } from './RTLGridCellRender';
import { debounce } from 'perfect-debounce';
import { isMobileOnly, usePosition } from '../AccentDisplay';
import { classNames } from '@progress/kendo-react-common';
import { contextSources, setContext } from '../../services/EntityContextService';
import { OutsideAlerter } from '../OutsiderAlerter';
import { RTLGridContext, RTLGridContextProvider } from './RTLGridContext';



const filterOperators = {
    text: [
        {
            text: "grid.filterStartsWithOperator",
            operator: "startswith",
        },
        {
            text: "grid.filterEndsWithOperator",
            operator: "endswith",
        },
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ],
    numeric: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
        {
            text: "grid.filterGtOperator",
            operator: "gt",
        },
        {
            text: "grid.filterGteOperator",
            operator: "gte",
        },
        {
            text: "grid.filterLtOperator",
            operator: "lt",
        },
        {
            text: "grid.filterLteOperator",
            operator: "lte",
        },
    ],
    date: [
        {
            text: "grid.filterGtOperator",
            operator: "gt",
        },
        {
            text: "grid.filterGteOperator",
            operator: "gte",
        },
        {
            text: "grid.filterLtOperator",
            operator: "lt",
        },
        {
            text: "grid.filterLteOperator",
            operator: "lte",
        },
    ],
    boolean: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
    ],
};




function getDefaultColumnWidth(screen, colWidth, numberOfColumns) {
    const minWidth = 110;

    const expectedGridWidth = minWidth * numberOfColumns;

    if (!accentUtils.isNull(colWidth)) {
        return colWidth;
    }


    if (screen < expectedGridWidth) {
        gridColumnMode = 2;
        return minWidth;
    }


    gridColumnMode = 1;
    return undefined;

}


const setGridFilterDebounced = debounce(action => action(), 900, { trailing: false });


const DefaultNotesCtrl = props => {


    let notes = "";

    if (props.dataItem) {
        if (props.dataItem.detailsField) {
            notes = props.dataItem[props.dataItem.detailsField];
        }
    }
    

    return <section>
        <textarea key={props.dataItem.ID} disabled defaultValue={notes}></textarea>
    </section>;    
};


const GridCtrl = React.memo(props => {


    const toolbar = React.useRef();
    const exporter = React.useRef();
    const gridCtrl = React.useRef();

    const [search, setSearch] = useRecoilState(getGridSearch(props.gridID));
    const [lastPage, setLastPage] = useRecoilState(getLastPage(props.gridID));


    const settings = suspend(async () => await gridSettingsManager.getSettings(props.gridID), [props.gridID, props.gridLoadID]);

    const [requireFireRefresh, setRequireFireRefresh] = React.useState(false);
    const [contextMenuState, setContextMenuState] = React.useState({ open: false });


    const resetAdhocFilter = useSetRecoilState(getAdhocFilter(props.gridID));
    const resetAdvancedFilter = useSetRecoilState(getAdvancedFilter(props.gridID));
    const resetShowDetails = useSetRecoilState(getGridDetails(props.gridID));
    const resetQuickFilter = useSetRecoilState(getQuickFilters(props.gridID));

    const resetColumnWidths = useSetRecoilState(getGridColumnsWidths(props.gridID));
    const resetHiddenColumns = useSetRecoilState(getGridHiddenColumns(props.gridID));
    const resetSort = useSetRecoilState(getGridSort(props.gridID));
    const resetFilter = useSetRecoilState(getGridFilter(props.gridID));

    const resetSearch = useSetRecoilState(getGridSearch(props.gridID));




    const gridData = useGridData(props.gridID, props.query, props.customQueryHeader, e => setRequireFireRefresh(true), lastPage);
    const setGridColumnWidths = useSetRecoilState(getGridColumnsWidths(props.gridID));
    const [gridHiddenColumns, setGridHiddenColumns] = useRecoilState(getGridHiddenColumns(props.gridID));
    const [gridFilter, setGridFilter] = useRecoilState(getGridFilter(props.gridID));
    const [gridSort, setGridSort] = useRecoilState(getGridSort(props.gridID));
    const showDetails = useRecoilValue(getGridDetails(props.gridID));
    const [expanded, setExpanded] = useRecoilState(getGridExpandedItems(props.gridID));
    const [selected, setSelected] = useRecoilState(getGridSelectedItems(props.gridID));

    const [requireScrollToSelected, setRequireScrollToSelected] = React.useState(Object.keys(selected).length > 0);


    const [localFilterState, setLocalFilterState] = React.useState(accentUtils.isNull(gridFilter) ? null : JSON.parse(JSON.stringify(gridFilter)));

    const position = usePosition(props.gridID);

    const rtlGridContext = React.useContext(RTLGridContext);


    const onContextMenuOpen = React.useCallback((e, dataItem) => {
        e.preventDefault();

        const offset = { left: e.clientX, top: e.clientY + window.document.documentElement.scrollTop };

        setContextMenuState({ open: true, offset: offset });


    }, [setContextMenuState]);

    const onContextMenuSelect = React.useCallback((e) => {
        e.item.actionClick(e);

        setContextMenuState({ open: false });

    }, [setContextMenuState]);


    const onHeaderSelectionChange = React.useCallback((e) => {

        const checkboxElement = e.syntheticEvent.target;
        const checked = checkboxElement.checked;

        const moreThanOneSelected = from(Object.keys(selected)).where(k => selected[k] === true).take(2).toArray().length === 2;

        const shouldSelectAll = !checked && !moreThanOneSelected;



        const newSelectedState = {};
        e.dataItems.forEach((item) => {
            newSelectedState[item.ID] = shouldSelectAll || checked;
        });
        setSelected(newSelectedState);


    }, [selected, setSelected]);

    const onSelectionChange = React.useCallback((e) => {

        const isCheckBox = e.syntheticEvent?.currentTarget?.className === "k-checkbox k-checkbox-md k-rounded-md";

        const ev = {
            ...e,
            shiftKey: e.syntheticEvent?.shiftWas,
            ctrlKey: e.syntheticEvent?.ctrlWas,
            dataItem: !isCheckBox && (e.syntheticEvent?.shiftWas || !e.syntheticEvent?.ctrlWas) ? null : e.dataItem
        };

        const newSelectedState = getSelectedState({
            event: ev,
            selectedState: selected,
            dataItemKey: "ID",
        });

        setSelected(newSelectedState);



    }, [gridData]);

    const onExpandChange = e => {

        setExpanded(x => {
            const newVal = { ...x, [e.dataItem.ID]: e.value };
            return newVal;
        });

    };

    const onGetDOM = () => { return gridCtrl.current?.element; }


    const onFilterChange = React.useCallback(e => {
        setLocalFilterState(e.filter);
    }, [setGridFilter, setLocalFilterState]);

    const onSortChange = React.useCallback(e => {
        setGridSort(e.sort);
    }, [setGridSort]);




    const onExcelExport = async e => {

        if (!accentUtils.isNull(exporter.current)) {


            const data = await gridData.fetchExport();

            const result = processDataForExport(data, gridCtrl.current.columns, props.detailsField, props.processExport);

            exporter.current.save(result.data, result.cols);

        }


    };

    const onColumnChooser = React.useCallback((e) => {

        if (props.columns) {

            const cols = props.columns.map((c, index) => {

                const col = formHelper.getGridColumn(c, index)

                var pc = accentUtils.isNull(props.processColumn) ? col : props.processColumn(col);
                return { ...c, title: pc.title };
            });


            showDialog(<RTLGridColumnChooser
                columns={cols}
                hidden={gridHiddenColumns ?? []}
                queryEntity={[props.strings]}
            />).then(e => {
                if (!e.canceled) {
                    setGridHiddenColumns(e.hidden ?? []);

                }
            });

        }
    }, [setGridHiddenColumns, gridHiddenColumns, props])



    const onColumnResize = React.useCallback((e) => {


        const columns = { ...settings.ColumnWidths };

        var colToResize = e.columns[e.index];

        //console.log(colToResize.field + ' ' + e.index);

        columns[colToResize.field] = e.newWidth;

        setGridColumnWidths(columns);


    }, [setGridColumnWidths]);

    const getSelected = React.useCallback(() => {
        return gridData.data.filter(i => selected[i.ID] === true);
    }, [gridData, selected]);


    const updateDataItem = (changedItem) => {
        gridData.updateDataItem(changedItem);
    }

    const resetGridSettings = React.useCallback(() => {
        gridSettingsManager.resetGridSettings(props.gridID).then(() => {


            resetAdhocFilter(null);
            resetAdvancedFilter(null);
            resetShowDetails(false);
            resetQuickFilter(null);
            resetColumnWidths();
            resetFilter(null);
            resetHiddenColumns();
            resetSort();
            resetSearch(null);
            setLocalFilterState(null);
            props.refresh();


        });



    }, [props, resetColumnWidths, resetFilter, resetHiddenColumns, resetSort, resetSearch, setLocalFilterState]);

    const onRefresh = React.useCallback(() => {
        gridData.refresh();
    }, [gridData]);

    const onSearch = e => {
        setSearch(e);
    };


    const onRowClick = e => {

        if (props.onRowClick) {
            props.onRowClick({ row: e.dataItem });
        }

    };


    const onScroll = (event) => {

        const e = event.nativeEvent;


        const atBottom = e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight;

        const a = e.target.scrollTop;
        const b = e.target.scrollHeight - e.target.clientHeight;

        const scrollPercentage = a / b;


        if (scrollPercentage > 0.40 || atBottom) {

            const nextPageNumber = Math.ceil(gridData.data.length / 40) + 1;
            gridData.fetchPage(nextPageNumber);

        }
    };


    const onCellLinkClick = (dataItem) => {
        if (!accentUtils.isNull(dataItem)) {
            //gridStateManager.setLastSelectedRow(props.gridSettings, dataItem.ID, this.state.data.length, dataItem.expanded);

        }
    };

    const prepData = (r) => {

        const expandOverridden = !accentUtils.isNull(expanded[r.ID]);
        const isSelected = selected[r.ID];
        const isExpanded = expandOverridden ? expanded[r.ID] : showDetails;
        const detailsField = props.detailsField;

        if (isSelected) {
            setContext(accentUtils.isNull(props.contextSource) ? contextSources.grid : props.contextSource, props.contextKey, r);
        }

        const isChanged = r.selected !== isSelected || r.expanded !== isExpanded || r.detailsField !== detailsField;

        if (isChanged) {

            r.expanded = isExpanded;
            r.selected = isSelected;
            r.detailsField = detailsField;

        }

        return r;
    }

    React.useImperativeHandle(
        props.methods,
        () => ({
            getSearchText: () => toolbar.current.getText(),
            getSelected: () => gridData.data.filter(i => selected[i.ID] === true),
            refresh: () => onRefresh(),
            getData: () => gridData.data,
            select: ids => {

                const newSelection = {};

                ids.map(id => newSelection[id] = true);

                setSelected(newSelection);
            }
        }),
        [gridData, selected, onRefresh, setSelected, toolbar]
    );

    React.useEffect(() => {

        setGridFilterDebounced(() => setGridFilter(localFilterState));

    }, [localFilterState])

    React.useEffect(() => {

        if (requireFireRefresh) {
            if (props.onRefresh)
                props.onRefresh();

            setRequireFireRefresh(false);
        }

    }, [requireFireRefresh, setRequireFireRefresh]);

    React.useEffect(() => {

        if (props.clearSelection) {
            setSelected({});
        }
        if (props.clearFilters) {
            resetFilter(null);
            resetAdhocFilter(null);
            resetAdvancedFilter(null);
            resetQuickFilter(null);
            setLocalFilterState(null);
            if (!accentUtils.isEmpty(props.defaultSearchText)) {
                toolbar.current.setText(props.defaultSearchText);
                setSearch(props.defaultSearchText);

            } else {
                toolbar.current.clearText();
                resetSearch(null);

            }

        }






    }, [])


    React.useEffect(() => {
        setExpanded({});
    }, [showDetails]);

    React.useEffect(() => {


        if ((gridData?.data?.length ?? 0) !== 0) {

            const ids = Object.keys(selected);

            if (!from(ids).any(id => selected[id] === true )) {
                setSelected({ [gridData.data[0].ID]: true });
            }

        }

    }, [selected, gridData, setSelected]);


    React.useEffect(() => {

        setLastPage(last => gridData.lastPage === 1 ? 1 : last < gridData.lastPage ? gridData.lastPage : last);

    }, [gridData, setLastPage]);

    React.useEffect(() => {

        if (requireScrollToSelected && gridData.data.length > 0) {

            try {

                const grid = $(`#${props.gridID} > div.k-grid`);

                if (grid.length !== 0) {
                    const os = grid.offset().top;

                    const gridContainer = $(`#${props.gridID} > div.k-grid .k-grid-content`);

                    if (gridContainer.length !== 0) {

                        const firstSelectedRow = grid.find('.k-selected');

                        if (firstSelectedRow.length !== 0) {

                            const rowOffset = firstSelectedRow.offset().top;

                            setTimeout(() => {
                                gridContainer.animate({
                                    scrollTop: rowOffset - os - 50
                                }, 1500)
                            }, 10);

                            
                        }
                        
                    }
                }

                setRequireScrollToSelected(false);

            } catch (e) {
            }
            
            


        }

    }, [selected, requireScrollToSelected, setRequireScrollToSelected, gridData]);

    var queryEntity = props.strings;


    const gridSortable = accentUtils.isNull(props.sortable) ? true : props.sortable;

    const colInfos = [];

    const filterAsString = JSON.stringify(localFilterState);

    let detailsColumn = null;

    const cols = props.columns.map((c, index) => {

        const gCol = formHelper.getGridColumn(c, index);


        if (gCol.field === props.detailsField) {
            detailsColumn = gCol;
            return null;
        }


        const colHasFilter = filterAsString.search(`"field":"${gCol.field}"`);

        const headClass = classNames({
            "acc-numeric": gCol.filterType === "numeric",
            "acc-has-filter": colHasFilter !== -1
        });


        let colInfo = {
            field: gCol.field,
            title: !accentUtils.isNull(gCol.title) ? gCol.title : t(formHelper.getFieldLabel(t, [queryEntity], gCol.field, true)),
            editable: false,
            filterType: gCol.filterType,
            fieldType: gCol.fieldType,
            columnMenu: undefined,
            columnMenuQuery: undefined,
            headerCell: undefined,
            hide: gridHiddenColumns?.includes(gCol.field) ?? false,
            width: gCol.defaultWidth,
            headerClassName: headClass,
            cellTemplate: undefined,
            cellHeader: undefined,
            locked: gCol.locked,
            isDragCell: false,
            isDropDown: false,
            sortable: gridSortable,
            requireTranslate: gCol.requireTranslate,
            data: gridData,
            filterable: props.hideColumnFilters ? false : (gCol.allowFilter??true),
            isLink: !accentUtils.isNull(gCol.linkField),
            linkField: gCol.linkField,
            linkEntity: gCol.linkEntity,
            className: gCol.class,
            format: gCol.format
        };

        if (settings?.ColumnWidths) {
            if (!accentUtils.isNull(settings.ColumnWidths[gCol.field])) {
                colInfo.width = settings.ColumnWidths[gCol.field];
            }
        }

        if (props.processColumn) {
            colInfo = props.processColumn(colInfo);
        }

        if (colInfo.hide) {
            return null;
        }


        if (!accentUtils.isEmpty(colInfo.filterType) && colInfo.filterable) {
            if (accentUtils.isNull(colInfo.columnMenu)) {

                if (colInfo.filterQuery) {
                    colInfo.columnMenu = p => <ColumnMenuCheckboxFilter {...p} field={colInfo.field} query={colInfo.filterQuery} requireTranslate={colInfo.requireTranslate} />;
                } else {
                    colInfo.columnMenu = ColumnMenu;
                }
            }
        }

        colInfos.push(colInfo);

        const screen = position?.toScreenWidth ?? window.innerWidth;

        const adjustmentColWidth = getDefaultColumnWidth(
            screen,
            colInfo.width,
            props.columns.length
        );

        //console.log(`${colInfo.field}: '${colInfo.width}' '${adjustmentColWidth}'`);

        //if (isMobileOnly && props.columns.length > 3) {
        //    if (colInfo.width ?? 0 < 90) {
        //           colInfo.width = 90;
        //    }
        //}

        return (<GridColumn
            key={`col_${colInfo.field}`}
            id={colInfo.field}
            locked={colInfo.locked}
            sortable={colInfo.sortable}
            className={ colInfo.className }
            field={colInfo.field}
            title={colInfo.title}
            width={accentUtils.isNull(adjustmentColWidth) ? undefined : `${adjustmentColWidth}px`}
            columnMenu={colInfo.columnMenu}
            filter={colInfo.filterType}
            headerClassName={colInfo.headerClassName}
            headerCell={colInfo.cellHeader}
            filterable={colInfo.filterable}
            editable={false}
            format={colInfo.format}
        />);
    });

    if (props.showCheckbox) {
        cols.unshift(<GridColumn
            key="selectCol"
            field="selected"
            width="50px"
            headerSelectionValue={
                from(gridData.data).any(x => selected[x.ID] === true)
            } />);
    }


    const exportToExcelAction = [{
        title: "Export to Excel",
        onClick: onExcelExport
    }, {
        title: "Columns",
        onClick: onColumnChooser
    }, {
        title: "Reset Layout",
        onClick: resetGridSettings
    }];


    const actions = props.hideActions ? [] : (accentUtils.isNull(props.getActions) ? exportToExcelAction : props.getActions().concat(exportToExcelAction)).map(a => {
        return {
            ...a,
            title: t(a.title),
            onClick: e => {
                e.getSelectedGridRows = getSelected;
                a.onClick(e);
            }
        };

    });


    const contextItems = actions.map(a => { return { text: a.title, actionClick: a.onClick, cssClass: classNames("acc-grid-context-item", { "divider": a.divider }) } });

    if (!accentUtils.isNull(props.actionCell)) {

        const actionCellInfo = props.actionCell;

        cols.push(<GridColumn
            key="action"
            field="action"
            title={props.actionCell?.title ?? " "}
            width={actionCellInfo && actionCellInfo.width ? actionCellInfo.width : 50}
            filter="text"
            encoded={false}
            headerClassName={`act-header-action hidden`}
            sortable={false}
            filterable={false}
            editable={false}
            //cell={actionCellInfo && actionCellInfo.template ? actionCellInfo.template : undefined}
        />);
    }


    const notRecClearFilter = <GridNoRecords><div onContextMenu={ onContextMenuOpen}>
        {gridData.loading ? "Loading..." : "There is no data available."}
        </div>
    </GridNoRecords>;





    const adjustment = props.screenBottomMargin ?? 40;

    const height = props.height === "screen" && !accentUtils.isNull(position) ? `${position.toScreenHeight - adjustment}px` : accentUtils.isNull(props.height) ? "100%" : `${props.height}px`


    const detailsComponent = (rtlGridContext?.details?.component) ?? (!accentUtils.isEmpty(detailsColumn) ? DefaultNotesCtrl  : null);
    
    const hasDetails = !accentUtils.isNull(detailsComponent)


    return <div className={`rtl-grid ${props.className}`}  >
        <IntlProvider locale={accentUserLang}>
            <div>
                <ExcelExport
                    ref={exp => exporter.current = exp}
                />
                <RTLGridToolbarCtrl
                    methods={ toolbar}
                    gridID={props.gridID}
                    readOnly={gridData.loading}
                    visible={props.showToolbar}
                    showSearch={!accentUtils.isNull(props.onSearch) || props.allowSearch}
                    onSearch={onSearch}
                    onRefresh={onRefresh}
                    defaultSearchText={search}
                    actions={actions}
                    hasDetails={hasDetails}
                    allowFilter={props.allowFilter}
                    filterSchemaName={props.filterSchemaName}
                    defaultShowDetails={settings?.ShowDetails ?? false}
                />
                <div id={props.gridID}>
                    <Grid
                        key={gridColumnMode}
                        style={{ height: height, width: "100%" }}
                        onScroll={onScroll}
                        fixedScroll={true}
                        navigatable={false}
                        rowRender={(r, p) => RTLGridRowRender(r, p, onContextMenuOpen)}
                        headerCellRender={(c, p) => <RTLGridHeaderCellRender props={p} cell={c} />}
                        cellRender={(c, p) => RTLGridCellRender(c, p, colInfos, updateDataItem, onRowClick, props.actionCell?.template)}
                        ref={g => gridCtrl.current = g}
                        data={gridData.data.map(prepData)}
                        selectedField="selected"
                        onSelectionChange={onSelectionChange}
                        selectable={{
                            enabled: false,
                            drag: props.showCheckbox,
                            cell: false,
                            mode: props.showCheckbox ? "multiple" : "single",
                        }}
                        expandField="expanded"
                        onColumnResize={onColumnResize}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                        detail={detailsComponent}
                        onFilterChange={onFilterChange}
                        sort={gridSort}
                        onSortChange={onSortChange}
                        filter={localFilterState}
                        filterOperators={filterOperators}
                        pageable={false}
                        resizable={true}
                        reorderable={props.reorderable}
                        sortable={!gridData.loading && gridSortable}
                        dataItemKey={"ID"}
                        onExpandChange={!hasDetails ? undefined : onExpandChange}>
                        {notRecClearFilter}
                        {cols}
                    </Grid>
                </div>
                <Popup
                    offset={contextMenuState.offset}
                    show={contextMenuState.open}
                    popupClass={"popup-content"}
                >
                    <OutsideAlerter onAlert={e => setContextMenuState({open: false}) }>
                    <Menu
                        vertical={true}
                        style={{ display: "inline-block" }}
                        onSelect={onContextMenuSelect}
                        items={ contextItems }
                    />
                     </OutsideAlerter>
                </Popup>
                <Row className="acc-grid-footer">
                    <Col lg={12} md={12}>{`Showing ${gridData.data.length} of ${accentUtils.isNull(gridData.total) ? 0 : gridData.total}`}</Col>
                </Row>
            </div>
        </IntlProvider >
        {gridData.loading && <LoadingPanel onGetDOM={onGetDOM} onContextMenu={onContextMenuOpen}   />}
    </div>

});


var gridColumnMode = 1;

export function formartGridID(id){
    return `g_${id}`
}


export const RTLGridCtrl = React.memo(props => {

    const [loadID, setLoadID] = React.useState(`g_${newGuid()}`);

    


    const onRefresh = React.useCallback(() => {

        setLoadID(`g_${newGuid()}`);

    },[setLoadID])

    React.useEffect(() => {

        if (accentUtils.isEmpty(props.gridID)) {
            alert("All grids must have a unique ID");
        }

    }, []);


    return <GridErrorBoundary>
        <React.Suspense fallback={<div stlye={{ minHeight: "300px" }}><AccentSpinner /></div>}>
            <RTLGridContextProvider detailsContext={props.detailsContext}>
                <GridCtrl methods={props.methods} gridLoadID={loadID} {...props} gridID={formartGridID(props.gridID)} refresh={onRefresh} />
            </RTLGridContextProvider>            
        </React.Suspense>
    </GridErrorBoundary>;
});
