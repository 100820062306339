import * as React from 'react'
import { Table } from 'reactstrap'
import { newGuid, accentUtils, t } from './../services/HelperService'
import { AccentTooltip } from './../controls/AccentTooltip'
import { AccentSpinner } from './../controls/AccentSpinner'
import  AccentIcons  from './../icons/Icons'
import { queryNoTracking } from '../services/DataService'
import { AccentPopup } from './AccentPopup'
import { OutsideAlerter } from '../controls/OutsiderAlerter'
import { isMobileOnly } from '../controls/AccentDisplay'


const query_Job_HealthData = queryNoTracking("Job_HealthData");


export class JobHealthCheck extends React.Component {

    constructor(props) {
        super(props);

        this.id = 'id_' + newGuid();

        this.ctrl = React.createRef();


        this.state = {
            popoverOpen: false,
            data: props.defaultData,
            loaded: false
        };

        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.getClassName = this.getClassName.bind(this);
        this.getValue = this.getValue.bind(this);
        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
    }


    componentDidUpdate() {

    }

    getClassName(type) {
        return " acc-hc-" + type;
    }

    getValue(id, val, tooltip, props) {

        id = id.replace(/[^a-zA-Z0-9-_]/g, '-');
        if (!/^[a-zA-Z]/.test(id)) {
            id = 'z' + id;
        }

        var value = t(val, props);
        var tooltipValue = t(tooltip, props);

        var tooltipCtrl = accentUtils.isNull(tooltip) ? null : <AccentTooltip target={id} text={tooltipValue} />;


        return <React.Fragment>
            {tooltipCtrl}
            <span id={id} className="accentJobHealthValue">{value}</span>
        </React.Fragment>;
    }


    close(e) {

        if (e?.target?.id === this.id) return;

        this.setState({
            popoverOpen: false,
        });

    }

    toggle(e) {

        e.stopPropagation();

        var me = this;

        var open = !this.state.popoverOpen;

        this.setState({
            popoverOpen: open,
        });


        if (open && accentUtils.isNull(this.state.data)) {

            query_Job_HealthData.getFirstOrDefault({ jobID: this.props.jobID }).then(function (res) {
                me.setState({ data: res, loaded: true });
            });
        }

    }


    render() {

        var me = this;
        var content = null;

        if (accentUtils.isNull(this.state.data) && !this.state.loaded) {
            content = <AccentSpinner />;
        } else if (accentUtils.isNull(this.state.data) && this.state.loaded) {
            content = <label>{t("application_strings.views.jobHealthCheck.noData")}</label>;
        } else {
            const isQuote = this.state.data.Stage === "stage_job_quote";

            var productHeaders = this.state.data.Products.map(function (p) {
                return <th key={p.Product}>{p.Product}</th>;
            });

            var productCM = this.state.data.Products.map(function (p) {
                return <td key={p.Product} className={me.getClassName(p.CheckMeasuresDisplay)}>{me.getValue(`CM_${p.Product}`, p.CheckMeasures, p.CheckMeasuresTooltip)}</td>;
            });

            var productInst = this.state.data.Products.map(function (p) {
                return <td key={p.Product} className={me.getClassName(p.InstallsDisplay)}>{me.getValue(`INST_${p.Product}`, p.Installs, p.InstallsTooltip)}</td>;
            });

            var productSupply = this.state.data.Products.map(function (p) {
                return <td key={p.Product} className={me.getClassName(p.SupplyJobsDisplay)}>{me.getValue(`SUPPLY_${p.Product}`, p.SupplyJobs, p.SupplyJobsTooltip)}</td>;
            });

            var productInvoices = this.state.data.Products.map(function (p) {
                return <td key={p.Product} className={me.getClassName(p.InvoicesDisplay)}>{me.getValue(`INV_${p.Product}`, p.Invoices, p.InvoicesTooltip)}</td>;
            });

            const style = isMobileOnly ? { minWidth: "100vw" } : undefined;

            var colSpan = productHeaders.length;

            var partialConfirm = this.state.data.ConfirmedCount < this.state.data.LineCount;

            content = (<Table className="accentJobHealth" responsive style={ style}>
                <tbody>
                    <tr>
                        <td><label>{t("application_strings.views.jobHealthCheck.depost")}</label></td>
                        <td colSpan={colSpan} className={this.getClassName(this.state.data.DepostDisplay)}>{this.getValue('deposit', this.state.data.Depost, this.state.data.DepostTooltip)}</td>
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.jobHealthCheck.confirmed")}</label></td>
                        <td colSpan={colSpan} className={this.getClassName(this.state.data.ConfirmedDisplay)}>{this.getValue('confirmed', this.state.data.Confirmed, this.state.data.ConfirmedTooltip, { x: this.state.data.ConfirmedCount, n: this.state.data.LineCount })}</td>
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.jobHealthCheck.payment_terms")}</label></td>
                        <td colSpan={colSpan} className={this.getClassName(this.state.data.PaymentTermsDisplay)}>{this.getValue('terms', this.state.data.PaymentTerms, this.state.data.PaymentTermsTooltip)}</td>
                    </tr>
                    <tr>
                        <th></th>
                        {productHeaders}
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.jobHealthCheck.check_measures")}</label></td>
                        {productCM}
                    </tr>
                    <tr>
                        <td><label>{t("application_strings.views.jobHealthCheck.installs")}</label></td>
                        {productInst}
                    </tr>
                    {!isQuote && <tr>
                        <td><label>{t("application_strings.views.jobHealthCheck.supply_jobs")}</label></td>
                        {productSupply}
                    </tr>}
                    {!isQuote && <tr>
                        <td><label>{t("application_strings.views.jobHealthCheck.invoices")}</label></td>
                        {productInvoices}
                    </tr>}
                </tbody>
            </Table>);

        }

        var classname = "accentJobHealthCheck";

        if (!accentUtils.isNull(this.props.className)) {
            classname = classname + ' ' + this.props.className;
        }


        var clickElement = <AccentIcons.Health ref={this.ctrl} id={this.id} onClick={this.toggle} style={ this.props.iconStyle } top={ accentUtils.isNull(this.props.top)? 7 : this.props.top } />;

      

        return <div className={classname}>
            {clickElement}
            <AccentPopup
                className="accentHealthCheckPop"
                anchor={this.ctrl}
                show={this.state.popoverOpen}
                toggle={this.toggle}
                onAutoClose={this.close}
                headerContent={<div className="d-flex">
                    <div className="me-auto">{t("application_strings.views.jobHealthCheck.title")}</div>
                    <div><AccentIcons.Close className="acc-btn" onClick={this.close} /></div>
                </div>}

            >                
                    <div>
                        {content}                    
                    </div>
            </AccentPopup>

            
        </div>;
    }
}
