import * as React from 'react'
import AccentIcons from '../icons/Icons';
import { t } from '../services/HelperService';
import { AccentDialog } from './AccentDialog';


export const DisplayURLDlg = React.memo(props => {


    function print() {
        var iframe = document.getElementById('displayIframe');
        iframe.contentWindow.print();
    }

    const header = <div className="d-flex">
        <div><AccentIcons.Print onClick={print } /></div>
        <div>{t(props.title ?? "application_strings.application.dialogs.displayUrlDlg.title")}</div>
    </div>;

    return <AccentDialog
        fullscreen
        onClose={props.onClose}
        headerContent={header}
    >
        <iframe id="displayIframe" src={props.url} width="98%" height="98%" style={{ overflow : "hidden"}} />
    </AccentDialog>;

});
