import * as React from 'react'
import { Row, Col } from 'reactstrap'
import { accentUtils } from '../../services/HelperService';
import { AccentCheckUnbound } from '../unbound/AccentCheckUnbound';
import { DocumentTemplateSelection } from './DocumentTemplateSelection';
import { PrintSettingSelection } from './PrintSettingSelection';




export class QuoteParamsCtrl extends React.Component {

    constructor(props) {
        super(props);


        this.showTaxes = true;
        this.showDiscounts = true;
        this.useLineSort = true;
        this.printSettingID = null;
        this.showMultiProducts = false;

        this.documentTempateReady = false;
        this.printSettingsReady = false;

        this.signature = null;

        if (!accentUtils.isNull(this.props.document)) {

            if (!accentUtils.isNull(this.props.document.Params)) {
                this.showTaxes = this.props.document.Params.Tax;
                this.showDiscounts = this.props.document.Params.Disc;
                if (!accentUtils.isNull(this.props.document.Params.Sort)) {
                    this.useLineSort = this.props.document.Params.Sort;
                }
                if (!accentUtils.isNull(this.props.document.Params.Multi)) {
                    this.showMultiProducts = this.props.document.Params.Multi;
                }
                this.printSettingID = this.props.document.Params.PS;
                this.documentTemplateID = this.props.document.Params.DT;
                this.signature = this.props.document.Params.SIG;
            }

        }



        this.onIncTaxChanged = this.onIncTaxChanged.bind(this);
        this.onShowDicountChanged = this.onShowDicountChanged.bind(this);
        this.updateDocumentParams = this.updateDocumentParams.bind(this);
        this.onUseLineSortChanged = this.onUseLineSortChanged.bind(this);
        this.onShowMultiProductsChanged = this.onShowMultiProductsChanged.bind(this);
        this.onPrintSettingChanged = this.onPrintSettingChanged.bind(this);
        this.onDocumentTemplateChanged = this.onDocumentTemplateChanged.bind(this);
        this.onDocumentTempateReady = this.onDocumentTempateReady.bind(this);
        this.onPrintSettingsReady = this.onPrintSettingsReady.bind(this);
        this.notifyHeaderReady = this.notifyHeaderReady.bind(this);

        this.updateDocumentParams();

    }

    notifyHeaderReady() {

        if (this.documentTempateReady && this.printSettingsReady) {
            this.props.onReady();
        }

    }

    onDocumentTempateReady(e) {

        this.documentTempateReady = true;
        this.onDocumentTemplateChanged(e);
        this.notifyHeaderReady();

    }

    onPrintSettingsReady(e) {

        this.printSettingsReady = true;
        this.onPrintSettingChanged(e);
        this.notifyHeaderReady();
    }


    onShowMultiProductsChanged(e) {
        this.showMultiProducts = e.checked;
        this.updateDocumentParams();
        this.props.refresh();

    }

    onUseLineSortChanged(e) {
        this.useLineSort = e.checked;
        this.updateDocumentParams();
        this.props.refresh();
    }

    onShowDicountChanged(e) {
        this.showDiscounts = e.checked;
        this.updateDocumentParams();
        this.props.refresh();
    }

    onIncTaxChanged(e) {
        this.showTaxes = e.checked;
        this.updateDocumentParams();
        this.props.refresh();
    }

    onDocumentTemplateChanged(e) {

        this.documentTemplateID = e;
        this.updateDocumentParams();
        this.props.refresh();

    }

    onPrintSettingChanged(e) {

        this.printSettingID = e;
        this.updateDocumentParams();
        this.props.refresh();
    }

    updateDocumentParams() {
        if (!accentUtils.isNull(this.props.document)) {

            const params = {
                Disc: this.showDiscounts,
                Tax: this.showTaxes,
                Sort: this.useLineSort,
                Multi: this.showMultiProducts,
                PS: this.printSettingID,
                DT: this.documentTemplateID,
                SIG: this.signature
            };

            this.props.document.Params = params;
        }
    }


    render() {



        return <Row className="rtl-quote-params">
            <Col md={12}>
                <Row>
                    <Col md={6}>
                        <DocumentTemplateSelection required defaultValue={this.documentTemplateID} type={this.props.type} onChange={this.onDocumentTemplateChanged} onReady={this.onDocumentTempateReady} />
                    </Col>
                    <Col md={6}>
                        <PrintSettingSelection defaultValue={this.printSettingID} onChange={this.onPrintSettingChanged} onReady={this.onPrintSettingsReady} />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <AccentCheckUnbound tagName="checkIncTax" defaultValue={this.showTaxes} onChange={this.onIncTaxChanged} label="Show Tax Inclusive" />
                    </Col>
                    <Col md={3}>
                        <AccentCheckUnbound tagName="checkShowDisc" defaultValue={this.showDiscounts} onChange={this.onShowDicountChanged} label="Show Discounts" />
                    </Col>
                    <Col md={3}>
                        <AccentCheckUnbound tagName="checkUserSortOrder" defaultValue={this.useLineSort} onChange={this.onUseLineSortChanged} label="Use Line Sort" />
                    </Col>
                    <Col md={3}>
                        <AccentCheckUnbound tagName="checkMultiProducts" defaultValue={this.showMultiProducts} onChange={this.onShowMultiProductsChanged} label="Show Multi-Products" />
                    </Col>
                </Row>
            </Col>
        </Row>;
    }
}


export class InvoiceParamsCtrl extends React.Component {

    constructor(props) {
        super(props);


        this.showTaxes = true;
        this.showDiscounts = false;
        this.useLineSort = true;
        this.showMultiProducts = false;
        this.printSettingID = null;
        this.documentTemplateID = null;

        this.documentTempateReady = false;
        this.printSettingsReady = false;


        if (!accentUtils.isNull(this.props.document)) {

            if (!accentUtils.isNull(this.props.document.Params)) {
                this.showTaxes = this.props.document.Params.Tax;
            }

            if (!accentUtils.isNull(this.props.document.Params.Sort)) {
                this.useLineSort = this.props.document.Params.Sort;
            }
            if (!accentUtils.isNull(this.props.document.Params.Multi)) {
                this.showMultiProducts = this.props.document.Params.Multi;
            }

            this.documentTemplateID = this.props.document.Params.DT;
            this.printSettingID = this.props.document.Params.PS;
        }

        this.onDocumentTemplateChanged = this.onDocumentTemplateChanged.bind(this);
        this.onIncTaxChanged = this.onIncTaxChanged.bind(this);
        this.updateDocumentParams = this.updateDocumentParams.bind(this);
        this.onShowMultiProductsChanged = this.onShowMultiProductsChanged.bind(this);
        this.onUseLineSortChanged = this.onUseLineSortChanged.bind(this);
        this.onPrintSettingChanged = this.onPrintSettingChanged.bind(this);
        this.onDocumentTempateReady = this.onDocumentTempateReady.bind(this);
        this.onPrintSettingsReady = this.onPrintSettingsReady.bind(this);
        this.notifyHeaderReady = this.notifyHeaderReady.bind(this);


    }


    notifyHeaderReady() {

        if (this.documentTempateReady && this.printSettingsReady) {
            if (this.props.onReady) {
                this.props.onReady();
            }
        }

    }

    onDocumentTempateReady(e) {

        this.documentTempateReady = true;
        this.onDocumentTemplateChanged(e);
        this.notifyHeaderReady();

    }

    onPrintSettingsReady(e) {

        this.printSettingsReady = true;
        this.onPrintSettingChanged(e);
        this.notifyHeaderReady();
    }

    onShowMultiProductsChanged(e) {
        this.showMultiProducts = e.checked;
        this.updateDocumentParams();
        this.props.refresh();
    }

    onUseLineSortChanged(e) {
        this.useLineSort = e.checked;
        this.updateDocumentParams();
        this.props.refresh();
    }

    onIncTaxChanged(e) {
        this.showTaxes = e.checked;
        this.updateDocumentParams();
        this.props.refresh();
    }
    onPrintSettingChanged(e) {

        this.printSettingID = e;
        this.updateDocumentParams();
        this.props.refresh();
    }

    onDocumentTemplateChanged(e) {

        this.documentTemplateID = e;
        this.updateDocumentParams();
        this.props.refresh();

    }


    updateDocumentParams() {
        if (!accentUtils.isNull(this.props.document)) {

            const params = {
                Disc: false,
                Tax: this.showTaxes,
                Sort: this.useLineSort,
                Multi: this.showMultiProducts,
                PS: this.printSettingID,
                DT: this.documentTemplateID

            };

            this.props.document.Params = params;
        }
    }


    render() {


        return <Row className="rtl-quote-params">
            <Col md={12}>
                <Row>
                    <Col md={6}>
                        <DocumentTemplateSelection defaultValue={this.documentTemplateID} type="Invoice" onChange={this.onDocumentTemplateChanged} onReady={this.onDocumentTempateReady} />
                    </Col>
                    <Col md={6}>
                        <PrintSettingSelection defaultValue={this.printSettingID} onChange={this.onPrintSettingChanged} onReady={this.onPrintSettingsReady} />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <AccentCheckUnbound tagName="checkIncTax" defaultValue={this.showTaxes} onChange={this.onIncTaxChanged} label="Show Tax Inclusive" />
                    </Col>
                    <Col md={4}>
                        <AccentCheckUnbound tagName="checkUserSortOrder" defaultValue={this.useLineSort} onChange={this.onUseLineSortChanged} label="Use Job Line Sort" />
                    </Col>
                    <Col md={4}>
                        <AccentCheckUnbound tagName="checkMultiProducts" defaultValue={this.showMultiProducts} onChange={this.onShowMultiProductsChanged} label="Show Multi-Products" />
                    </Col>
                </Row>
            </Col>
        </Row>;
    }
}
