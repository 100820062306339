import * as React from 'react'
import { accentUtils, t, showDialog, from } from '../services/HelperService'
import { AccentComboUnbound, AccentMultiComboUnbound } from '../controls/unbound/AccentComboUnbound'
import { getProduct, getUserProductList } from '../services/ProductService'
import { ProductItemTemplate } from '../controls/products/ProductItemTemplate'
import { AccentDialog } from './AccentDialog'
import { AccentButton } from '../controls/AccentButton'


export function selectProduct(props) {

    return new Promise(p => {
        showDialog(<ProductSelectionDlg {...props} />).then(s => {
            p(!s.canceled ? s.product : null);
        });
    });

};


function getSelectProductFromProductVersionID(e) {

    const productID = accentUtils.isEmpty(e) ? null : e.split('_')[0];
    let versionID = accentUtils.isEmpty(e) ? null : e.split('_')[1];

    if (accentUtils.isEmpty(productID)) return null;

    if (versionID === "null") {
        versionID = null;
    }

    return { productID: productID, versionID: versionID };

}

export const ProductSelectionDlg = React.memo(props => {

    

    const [allData,] = React.useState(getUserProductList({ ...props,  includeDraft: props.includeDraft }).filter(i => (!props.selectMultiple || !accentUtils.isEmpty(i.ID)) && !from(props.excludeIDs ? props.excludeIDs : []).any(ex => ex.toUpperCase() == i.ID.toUpperCase())).map(x => ({ ...x, product_version_id: `${x.ID}_${x.VersionID ?? null}` })));
    const [product, setProduct] = React.useState(null);


    const onChanged = React.useCallback((e) => {

        setProduct(e);

    }, [setProduct]);

   

    const onClose = React.useCallback((e, canceled) => {

        const result = props.selectMultiple ? product?.map(getSelectProductFromProductVersionID) : getSelectProductFromProductVersionID(product);

        props.onClose({ canceled: canceled, product: result });        
    },[props, product]);



    const isValid = props.allowEmpty || !accentUtils.isNull(product);


    return <AccentDialog
        headerContent={t("application_strings.application.dialogs.productSelectionTitle")}
        maxWidth={600}
        onClose={props.onClose}
        actionsContent={[
            <AccentButton key="2" tagName="ptCloseBtn" disabled={!isValid} onClick={e => onClose(e, false)}>{t("application_strings.application.buttons.ok")} </AccentButton>
        ]}
    >
        <div id="productSelectionDlg" className="center">

            {props.selectMultiple && <AccentMultiComboUnbound displayField="Product" valueField="product_version_id" onChange={onChanged} label="application_strings.application.dialogs.productSelectionTitle" data={allData} itemRender={ProductItemTemplate} />}
            {!props.selectMultiple && <AccentComboUnbound displayField="Product" valueField="product_version_id" onChange={onChanged} label="application_strings.application.dialogs.productSelectionTitle" data={allData} itemRender={ProductItemTemplate} />}
        </div>
    </AccentDialog>;
});