import * as React from 'react'
import AccentIcons from "../../icons/Icons";
import { accentUtils } from "../../services/HelperService";
import { AccentButton } from "../AccentButton";
import { getDefaultFilter } from './AdvancedFilterConstants';
import { InsyteQueryGroupCtrl } from "./InsyteQueryGroupCtrl";

export const InsyteQueryExcludeGroupCtrl = props => {


    const addExceptFilter = React.useCallback(e => {


        props.item.exceptFilter = getDefaultFilter();

        props.onItemChanged();
    }, [props]);

    const removeExceptFilter = React.useCallback(e => {
        props.item.exceptFilter = null;
        props.onItemChanged();
    }, [props]);


    const onItemChanged = React.useCallback(e => {

        props.onItemChanged();
        
    }, [props]);

    const hasFilter = !accentUtils.isNull(props.item?.exceptFilter);

    if (!hasFilter) {
        return <div className="acc-query-excluing-header">EXCLUDING <AccentButton tagName="qAddExcluding" disabled={props.readOnly} bsStyle="link" outline={false} onClick={addExceptFilter} ><AccentIcons.New top="2" /></AccentButton></div>;
    }

    return <div>
        <div className="acc-query-excluing-header active">Excluding <AccentButton tagName="qDelExcluding" disabled={props.readOnly} bsStyle="link" outline={false} onClick={removeExceptFilter} ><AccentIcons.Delete top="2" /></AccentButton></div>
        <InsyteQueryGroupCtrl readOnly={props.readOnly} item={props.item?.exceptFilter} level={0} last onItemChanged={onItemChanged} schema={props.schema} disableDelete fieldStringBase={props.fieldStringBase} />
    </div>;

};